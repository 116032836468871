import React from 'react'
import FlowsGridListIndex from './FlowsGridListIndex';
import SchedulerGridListIndex from './SchedulerGridListIndex';
import '../../modules/flowsNew/Newflow.scss'
import "../../modules/flowsNew/flow.scss";
import { useDispatch } from 'react-redux';
import { setSidebarActiveRoute } from '../../../store/actions';
import { Literals } from '../../modules/common/literals';

const CombineLandingIndex = React.forwardRef((props, ref) => {

    const dispatch = useDispatch()
    const flowRef = React.useRef({})
    const schedulerRef = React.useRef({})

    React.useImperativeHandle(ref, () => {
        return {
            reload: (domainId) => {
                if(props.activeTabKey === 'flows') {
                    if(flowRef?.current) {
                        flowRef?.current?.reload(domainId)
                    }
                }
                else if(props.activeTabKey === 'scheduler') {
                    if(schedulerRef?.current) {
                        schedulerRef?.current?.reload(domainId)
                    }
                }
            }
        }
    }, [props.activeTabKey])

    React.useEffect(() => {
        dispatch(setSidebarActiveRoute(Literals.links.CLARISTA_LAB))
    }, [])

    const GetCurrentLandingPage = () => {
        switch (props.activeTabKey) {
            case 'flows':
                return <FlowsGridListIndex {...props} ref={flowRef} />
                break;
            // case 'alert':
            //     return <AlertGridListIndex {...props} />
            //     break; 
            case 'scheduler':
                return <SchedulerGridListIndex {...props} ref={schedulerRef} />
                break;

            default:
                return <></>
                break;
        }
    }
    return (
        <>
            { GetCurrentLandingPage() }
        </>
    )
})

export default CombineLandingIndex