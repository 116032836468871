import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDomainTags, setTagList } from '../../../store/actions'
import ClaristaLoader from '../claristaLoader/ClaristaLoader'
import { NoDataComponent, getReloadErrorTemplate } from '../../modules/common/helperFunctions'
import './../../modules/dataCatalogue/landingPage/landingPage.scss'
import { ICON_FILE, ICON_PLUS, ICON_TAG } from '../newIconSource'
import NoSearchResultFound from '../../modules/common/NoSearchResultFound'
import _ from 'lodash'
import { Collapse } from 'antd'
import { getRepositoryList } from '../../../store/modules/dataCatalogue/unstructured/unstructuredActions'
import LandingPageCard from '../../modules/dataCatalogue/podCreation/unstructured/components/LandingPageCard'
import Buttons from '../button/Buttons'
import { Literals } from '../../modules/common/literals'
import { useNavigate } from 'react-router-dom'

const UnstructuredGridIndex = React.forwardRef(({
    activeDomain = '',
    searchTerm = '',
    showFavourite = false,
    setFilterList = () => { },
    filterList = {},
    showPublished = false,
    groupByTags = false,
    wrapperRef = {},
    setDataLoading = () => { }
}, ref) => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const tagData = useSelector(state => state.DataCatalogue?.LandingPage?.tagList)

    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [empty, setEmpty] = React.useState(false)
    const [data, setData] = React.useState([])
    const [availableTags, setAvailableTags] = React.useState([])
    const [activeCollKeys, setActiveCollKeys] = React.useState([])

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    React.useImperativeHandle(ref, () => {
        return {
            reload: (domainId) => {
                getData(domainId)
            }
        }
    }, [])
    
    React.useEffect(() => {
        if (activeDomain !== '') {
            getData(activeDomain)
            callTagListAPI()
        }
    }, [activeDomain])

    React.useEffect(() => {
        if (filterList?.connections?.length === 0 || filterList?.tags?.length === 0) {
            setFilterList(
                {
                    tags: availableTags?.map(c => ({ name: c?.name?.toLowerCase(), selected: false }))
                }
            )
        }
    }, [availableTags])

    React.useEffect(() => {
        if (tagData && tagData?.length) {
            setAvailableTags([...tagData])
            if (availableTags?.length !== 0) {
                setFilterList(prev =>
                ({
                    ...prev,
                    tags: tagData?.map(c => ({ name: c?.name?.toLowerCase(), selected: false }))
                })
                )
            }
        }
    }, [tagData])

    const dataListObj = React.useMemo(() => {
        if (
            _.isEmpty(searchTerm) && !showFavourite && !showPublished
            && !filterList?.tags?.some(c => c?.selected)
        ) {
            setActiveCollKeys([])
        }

        const FILTER_COLUMNS = ["name", "description", "updated_by_user", "tag"]
        const clone = [...data]
        let dat = clone?.filter(item => {
            return FILTER_COLUMNS?.some(key => {
                let data = item[key] ?? ''
                if (key === 'updated_by_user') {
                    data = item[key]?.email
                }
                if (key === 'tag') {
                    data = item[key]?.map(v => v?.name)?.join(', ')
                }
                return data?.toLowerCase()?.includes(searchTerm.toLowerCase())
            })
        })
        if (showFavourite) {
            dat = dat?.filter(d => d?.bookmark)
        }
        if (showPublished) {
            dat = dat?.filter(d => d?.published)
        }
        if (filterList) {
            const selTags = filterList?.tags?.filter(c => c?.selected)?.map(v => v?.name)
            if (selTags?.length > 0) {
                dat = dat?.filter(c => c?.tag?.some(t => selTags?.includes(t?.name?.toLowerCase())))
            }
        }
        
        let tags = new Set([...dat?.map(d => d?.tag)?.flat()])
        let tagWisePod = [...tags]?.reduce((prev, curr) => {
            return {
                ...prev,
                [curr?.name]: {
                    data: dat?.filter(d => d?.tag?.map(t => t?.name)?.includes(curr?.name)),
                    tagDetail: { ...curr }
                }
            }
        }, {})

        let tagsPod = []
        for (const key in tagWisePod) {
            const element = tagWisePod[key];
            tagsPod.push({
                title: key,
                tagDetail: element?.tagDetail,
                data: element?.data
            })
        }

        // console.log({tags, tagWisePod, tagsPod})

        if (
            !_.isEmpty(searchTerm) || showFavourite || showPublished
            || !filterList?.tags?.some(c => c?.selected)
        ) {
            setActiveCollKeys(tagsPod?.map(d => d?.title))
        }

        return { tagsPod, allData: dat }
    }, [searchTerm, data, showFavourite, filterList, showPublished])

    const getData = React.useCallback((domainId) => {
        if (domainId) {
            setEmpty(false)
            setLoading(true)
            setDataLoading(true)
            let prevData = [...data]
            setData([])
            dispatch(getRepositoryList(domainId, false))
            .then(res => {

                const data = res?.data?.results ?? []
                setEmpty(data?.length === 0)
                setLoading(false)
                setDataLoading(false)
                setData([...data])
                setError(undefined)

            })
            .catch(err => {
                setEmpty(false)
                setLoading(false)
                setDataLoading(false)
                setError(err?.message)
                setData([...prevData])
            })
        }
    }, [data])

    const callTagListAPI = React.useCallback(() => {
        // if (tagData?.length > 0) return

        dispatch(getDomainTags(activeDomain))
            .then(res => {
                const data = res?.data ?? []
                dispatch(setTagList([...data]))
            })
            .catch(() => { })
        // dispatch(getTagList())
    }, [activeDomain, dispatch])

    const checkNoRecordFound = React.useCallback(() => {
        return (
            showFavourite
            || searchTerm?.length > 0
            || filterList?.tags?.filter(v => v?.selected)?.length > 0
        ) && dataListObj?.allData?.length === 0
    }, [showFavourite, searchTerm, filterList, dataListObj])
    
    return (
        <div className={`catalog-grid-index-lp landingPage ${!noOpenAiAccess ? 'float-talkdata-present' : ''}`} ref={wrapperRef}>
            <div className='landing-list-main pt-0 px-0'>

                {
                    loading ? <ClaristaLoader height='200px' />
                        :
                        error ? getReloadErrorTemplate({ errorMessage: error, onReload: () => getData() })
                            :
                            empty ? <NoDataComponent logo={<ICON_FILE />} message={"Click on (+) to Create Document"} />
                                :
                                checkNoRecordFound() ? <NoSearchResultFound />
                                    :
                                    groupByTags
                                        ?
                                        <>

                                            <Collapse
                                                key={`unstructured-grid-collapse-${activeCollKeys?.length}`}
                                                className='catalog-grid-collapse unstructured-doc-collapse'
                                                collapsible='header'
                                                ghost={true}
                                                defaultActiveKey={activeCollKeys}
                                                items={
                                                    dataListObj?.tagsPod?.map(t => ({
                                                        key: t?.title,
                                                        label: <>
                                                            <div className='round-tags-container nav-landing'>
                                                                <div className={`${t?.tagDetail?.color} round-tags-item`}>
                                                                    <span>{<ICON_TAG />}</span>
                                                                    <span>{t?.title}</span>
                                                                </div>
                                                            </div>
                                                        </>,
                                                        children: <div className='landinggridview-responsive p-0'>
                                                            {
                                                                t?.data?.map((d, i) => (
                                                                    <LandingPageCard
                                                                        key={`${d?.id}-unstpod-cat-tag-agg`}
                                                                        details={d}
                                                                        refreshData={() => getData(activeDomain)}
                                                                        index={i}
                                                                        setData={setData}
                                                                        groupByTags={groupByTags}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    }))
                                                }
                                            />
                                            {
                                                dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.length !== 0
                                                    ?
                                                    <>
                                                        <div className='bg-light px-3 py-2 fontInterSemiBold rounded border mb-2 fontSizeHeading'>
                                                            {`Untagged`}
                                                        </div>
                                                        <div className={`landinggridview-responsive p-0`}>
                                                            {
                                                                dataListObj?.allData?.filter(d => d?.tag?.length === 0)?.map((d, i) => (
                                                                    <LandingPageCard
                                                                        key={`${d?.id}-unstpod-cat`}
                                                                        details={d}
                                                                        refreshData={() => getData(activeDomain)}
                                                                        index={i}
                                                                        setData={setData}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    </>
                                                    : ''
                                            }
                                        </>
                                        :
                                        <div className='landinggridview-responsive p-0'>
                                            {
                                                dataListObj?.allData?.map((d, i) => (
                                                    <LandingPageCard
                                                        key={`${d?.id}-unstpod-cat`}
                                                        details={d}
                                                        refreshData={() => getData(activeDomain)}
                                                        index={i}
                                                        setData={setData}
                                                    />
                                                ))
                                            }
                                        </div>
                }
            </div>
            <Buttons 
                props={{
                    buttonId: `createDocumentBtn`,
                    buttonText: '',
                    tooltipPlacement: 'left',
                    buttonClassName: "custom-btn-primary custom-btn fixed-button addbutton_plusicon",
                    buttonEvent: () => {
                        history(Literals.links.UNSTRUCTURED_DOCUMENT_CREATION)
                    },
                    tooltip: 'Create Unstructured Pod',
                    ImgSrc: () => <ICON_PLUS />,
                    isDisable: noOpenAiAccess,
                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                }} 
            />
        </div>
    )
})

export default UnstructuredGridIndex