import React, { useEffect, useRef } from 'react'
import './commonModal.scss'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Buttons from '../button/Buttons'
import { CLOSE } from '../iconSource'
import { Literals } from '../../modules/common/literals'

const CommonModal = ({
    open = false,
    setOpen = () => { },
    onEnterCall = () => { },
    header = 'Heading',
    footer = 'Footer',
    body = 'Body',
    size = 'lg',
    className = '',
    onClose = () => { },
    hideFooter = false
}) => {
    const modalRef = useRef(null)
    // useEffect(() => {
    //     if (modalRef?.current && open) {
    //         let primaryBtn = document.getElementById('primary-focus-btn');

    //         if (primaryBtn) {
    //             primaryBtn.focus();
    //         }
    //     }
    // }, [modalRef.current])
   
    return (
        <Modal
            ref={modalRef}
            id='commonModal'
            isOpen={open}
            centered
            size={size}
            className={`pro-common-modal ${className}`}
        >
            <ModalHeader >
                <h2 className={`pro-common-modal-heading`} id="exampleModalLabel">
                    {header}
                </h2>
                <Buttons
                    props={{
                        tooltip: '',
                        buttonText: '',
                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                        buttonEvent: () => {
                            setOpen(false)
                            onClose()
                        },
                        ImgSrc: () => <CLOSE />,
                        isDisable: false,
                        buttonType: Literals.BTN_TERTIARY
                    }}
                />
            </ModalHeader>

            <ModalBody>

                {body}


            </ModalBody>

            {
                hideFooter ? ''
                    :
                    <ModalFooter>
                        {footer}
                    </ModalFooter>
            }

        </Modal>
    )
}

export default CommonModal