import React from 'react'
import { documentFileTypeColor, documentFileTypeIcon, getDeleteMessage, getJourneyTime, getMonthName, getRelativeTime, stringToColor } from '../../common/helperFunctions'
import { ICON_CHAT_TAIL, ICON_CLARISTA, ICON_DELETE, ICON_FILE_CSV, ICON_FILE_PDF, ICON_REFRESH } from '../../../Common/newIconSource'
import Buttons from '../../../Common/button/Buttons'
import { Literals } from '../../common/literals'
import MarkdownText from '../../../Common/MarkdownText'
import { Avatar, Collapse, Tooltip } from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import { deleteChat, deleteChatConversation } from '../../../../store/modules/common/GenAi/GenAiReducer'
import UnstructuredFileName from '../../dataCatalogue/podCreation/components/UnstructuredFileName'
import { useNavigate } from 'react-router-dom'

const UnstructuredChatContent = React.memo(({
    data = {},
    setData = () => {},
    setQuestValue = () => {},
    retryQuery = () => {},
    deleteFullChat,
    isFloating
}) => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)
    const [chatTime, setChatTime] = React.useState(getRelativeTime(data?.created_on))
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [isDeleting, setDeleting] = React.useState(false)

    const readableDate = React.useMemo(() => {
        let date = new Date(data?.created_on)
        return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
    }, [data])

    const handleDelete = () => {
        setDeleting(true)
        dispatch(deleteChatConversation(data?.id))
        .then(() => {
            setDeleting(false)
            setDeleteModal(false)
            if(deleteFullChat && !isFloating) {
                dispatch(deleteChat(data?.chat_detail?.id))
                .then(() => {
                    history(Literals?.links?.PULSE_LANDING_UNSTRUCTURED)
                })
                .catch(() => {
                    history(Literals?.links?.PULSE_LANDING_UNSTRUCTURED)
                })
            }
            else {
                setData(prev => prev?.filter(d => d?.id !== data?.id))
            }
        })
        .catch(() => {
            setDeleting(false)
        })
    }

    const FileViewer = React.useCallback(({
        details,
        child
    }) => {

        const fileType = details?.file_type ?? _.last(details?.file_name?.split('.'))?.toLowerCase()

        return <UnstructuredFileName
            key={`${details?.id}-${details?.file_name}`}
            connectionName={details?.connection}
            dataObj={{document_name: details?.file_name, document_type: fileType, prefix: details?.prefix, connection_name: details?.connection}}
            useCustomPopoverChild={true}
            customPopoverChild={child}
            popoverPlacement={'bottomLeft'}
        />
    }, [data])

    const assetDetails = React.useMemo(() => {
        let list = data?.content_asset_detail
        if(!list || list?.length === 0) return <></>
        return [
            {
            key: '1',
            label: 'Sources',
            children: <>
                <div className='w-100'>
                    {
                        list?.map(a => (        
                            <React.Fragment key={`${data?.id}-asset${a?.id}`}>
                                <FileViewer
                                    details={a}
                                    child={
                                        <div className='unst-src-itm'>
                                            <h4 title={a?.file_name} className='unst-src-itm-heading fontInterMedium text-with-ellipsis'>
                                                {a?.file_name}
                                            </h4>
                                            <div className='unst-src-itm-file text-with-ellipsis'>
                                                <span className=''>
                                                    {documentFileTypeIcon(
                                                        a?.file_type?.toLowerCase() ?? _.last(a?.file_name?.split('.'))?.toLowerCase() ?? 'unknown', 
                                                        documentFileTypeColor(a?.file_type?.toLowerCase() ?? _.last(a?.file_name?.split('.'))?.toLowerCase()), 
                                                        '1.5rem', '1.5rem'
                                                    )}
                                                </span>
                                                <span className='fontSizeLabel'>{a?.file_name}</span>
                                            </div>
                                        </div>
                                    }
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
            </>,
            }
        ]
    }, [data])

    const RetryButtonComponent = React.useCallback(() => {
        return <>
            {
                data?.chat_failed
                ?
                <>
                    <span className='chat-retry-btn'>
                        <Buttons
                            props={{
                                buttonText: '',
                                tooltipPlacement: 'left',
                                tooltip: 'Retry',
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setQuestValue(data?.question)
                                    retryQuery(data?.question)
                                },
                                ImgSrc: () => <ICON_REFRESH />,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                            }}
                        />
                    </span>
                    <span className='chat-failed-text fontInterSemiBold'>
                        {/* <span className='mr-1'>
                            <ICON_ALERT width='9' height='9' color='currentColor'/>
                        </span> */}
                        {
                            data?.canceled ? 'Canceled!' : 'Failed!'
                        }
                    </span>
                </>
                :   ''
            }
        </>
    }, [data])

    const SenderAvatarComponent = React.useCallback(() => {
        return <>
            <span className='chat-user-sender-avt'>
                <Tooltip 
                    title={
                        data?.chat_failed
                        ?
                        `${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`
                        :
                        `${data?.created_by_user?.first_name} ${data?.created_by_user?.last_name}`
                    } 
                    placement="topLeft">
                    <Avatar
                        size={'default'}
                        className='text-uppercase'
                        style={{
                            backgroundColor: stringToColor(
                                data?.chat_failed
                                ?
                                `${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`
                                :
                                `${data?.created_by_user?.first_name} ${data?.created_by_user?.last_name}`
                                ),
                        }}
                    >
                        {
                            data?.chat_failed
                            ?
                            <>
                                {userDetailsRedux?.first_name?.charAt(0)}
                                {userDetailsRedux?.last_name?.charAt(0)}
                            </>
                            :
                            <>
                                {data?.created_by_user?.first_name?.charAt(0)}
                                {data?.created_by_user?.last_name?.charAt(0)}
                            </>
                        }
                    </Avatar>
                </Tooltip>
            </span>
        </>
    }, [data, userDetailsRedux])

    const citationObj = React.useMemo(() => {
        let element = data?.content_asset_ids
        let obj = element?.reduce((prev, curr) => ({...prev, [curr?.citation_id]: {...curr}}), {})
        
        return obj
    }, [data])

    return (
        <div
            id={`conversation-${data?.id}`}
            className={`cht-cont-box position-relative mx-auto`}
        >

            <div className={`chat-usr-sender-box position-relative mb-3 ${userDetailsRedux?.email === data?.created_by_user?.email || data?.chat_failed ? '' : 'left'}`}>
                <div className={`chat-user-ask`}>
                    <span className={`chat-tail ${userDetailsRedux?.email === data?.created_by_user?.email || data?.chat_failed ? 'right' : 'left'}`}>
                        <ICON_CHAT_TAIL/>
                    </span>

                    {data?.question}
                    <RetryButtonComponent/>
                </div>
                <SenderAvatarComponent/>
            </div>


            <label 
                id={`conversation-${data?.id}-ts`}
                className={`ts-top position-relative`}>
                
                <span className='chat-usr-sysreply'>
                    <ICON_CLARISTA/>
                </span>
                <span className='ts ml-1'>
                    {
                        `${readableDate}, ${getJourneyTime(data?.created_on)} (${chatTime})`
                    }
                </span>
            </label>
            <div className='talk-chat-ai-head' style={{maxWidth: '100%'}}>
                <span className='chat-hover-action-btn'>
                    <Buttons
                        props={{
                            buttonText: '',
                            tooltipPlacement: 'top',
                            tooltip: 'Delete',
                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon error-red bg-transparent border-0",
                            buttonEvent: (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setDeleteModal(true)
                            },
                            ImgSrc: () => <ICON_DELETE />,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                        }}
                    />
                </span>
                <div className='unst-chat-content-body'>
                    <div className='talk-chat-ai-quote-reply border-0 px-0'>
                        <MarkdownText 
                            markdown={data?.response ?? ""} 
                            citationObj={citationObj}
                        />
                    </div>
                    <div>
                        {
                            !assetDetails?.length ? ''
                            :
                            <Collapse 
                                className='unst-source-collapse'
                                defaultActiveKey={['1']} 
                                ghost 
                                items={assetDetails ?? []} 
                            />
                        }
                    </div>
                </div>
            </div>

            <DeleteModal
                open={deleteModal}
                setOpen={setDeleteModal}
                body={getDeleteMessage({title: `Conversation`})}
                deleteBtnDisabled={isDeleting}
                heading='Delete Conversation'
                onConfirm={handleDelete}
                loading={isDeleting}
            />
            {/* <div 
                style={{
                    maxWidth: '100%', 
                    marginRight: 'auto', 
                    transition: '.3s',
                }}
                className={`talk-chat-content talk-data`}
                onClick={() => {
                }}>
                
            </div> */}
        </div>
    )
})

export default UnstructuredChatContent