import React from 'react'
import { ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN, ICON_CHECK, ICON_DELETE, ICON_EDITBOX, ICON_LOADING_CIRCLE, ICON_PLUS, ICON_USER } from '../../../Common/newIconSource'
import { Literals } from '../../common/literals'
import Buttons from '../../../Common/button/Buttons'
import { Checkbox, Input, Popover, Select, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getDataDomainList, setDataDomainList, setUserSelectedDomain } from '../../../../store/actions'
import { useLocation, useNavigate } from 'react-router-dom'
import { NoDataComponent, getQueryParams, getReloadErrorTemplate } from '../../common/helperFunctions'
import CommonModal from '../../../Common/CommonModal/CommonModal'
import { bulkDeleteVocabulary, createPersona, deletePersona, getPersona, updatePersona } from '../../../../store/modules/common/GenAi/GenAiReducer'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton'
import useHideSidebar from '../../../../customHooks/useHideSidebar'
import TraceDomainList from './TraceDomainList'
import SearchInput from '../../../Common/search/Search'
import Highlighter from 'react-highlight-words'

const TracePersona = ({
    parentDomain = '',
    setParentDomain = () => {}
}) => {

    const history = useNavigate()

    const dispatch = useDispatch()
    
    // const location = useLocation()

    // const {dm: domainId} = getQueryParams(location.search)

    const [activeDomain, setActiveDomain] = React.useState(parentDomain ?? null)
    const [openModal, setOpenModal] = React.useState(false)
    const [personaTitle, setPersonaTitle] = React.useState('')
    const [personaDesc, setPersonaDesc] = React.useState('')
    const [createLoading, setCreateLoading] = React.useState(false)
    const [updating, setUpdating] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [bulkDeleteOpen, setBulkDeleteOpen] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [data, setData] = React.useState([])
    const [empty, setEmpty] = React.useState(false)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [loadMore, setLoadMore] = React.useState(false)
    const [pageNo, setPageNo] = React.useState(1)
    const [bulkDeleting, setBulkDeleting] = React.useState(false)
    const [searchData, setSearchData] = React.useState('')

    const listElementRef = React.useRef({})
    const selectedDataRef = React.useRef(null)
    const selectedBulkRef = React.useRef(null)
    const searchRef = React.useRef('')

    useHideSidebar(true)

    React.useEffect(() => {
        setActiveDomain(parentDomain)
    }, [parentDomain])

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }

        if(searchData && searchData?.trim() !== "") searchRef.current = searchData
        else searchRef.current = ''

        if(activeDomain && activeDomain !== "") {
            fetchPersona(true)
        }
    }, [activeDomain, searchData])

    const fetchPersona = (reset = false) => {
        if(reset) {
            setLoading(true)
        }

        setError(undefined)
        setStopLazyload(false)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 10

        dispatch(getPersona(pageNumber, pageLimit, activeDomain, searchRef.current, false))
        .then(res => {
            let dat = res?.data
            if(dat) {
                setEmpty(dat?.results?.length === 0)
                if(reset) {
                    setData([...dat?.results])
                }
                else {
                    setData(prev => [...prev, ...dat?.results])
                }
            }
            if (dat?.has_next === false) {
                setStopLazyload(true)
                setLoadMore(false)
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }
            setLoading(false)
        })
        .catch(err => {
            console.error({err})
            setError(err?.message)
            setLoading(false)
            setLoadMore(false)
        })
    }

    const handleCreatePersona = () => {
        setCreateLoading(true)

        let payload = {
            data_domain: activeDomain,
            type: 'PERSONA',
            name: personaTitle,
            value: personaDesc
        }

        if(personaDesc?.trim() === "" || personaDesc?.trim() === "") {
            setCreateLoading(false)
            if(personaTitle?.trim() === "") {
                emitToastNotification('error', 'Please provide title')
            }
    
            if(personaDesc?.trim() === "") {
                emitToastNotification('error', 'Please provide description')
            }
            return
        }


        dispatch(createPersona(payload, false))
        .then(res => {
            setPersonaTitle('')
            setPersonaDesc('')

            if(empty) setEmpty(false)
            
            setData(prev => [{...res?.data}, ...prev])
            
            setOpenModal(false)
            setCreateLoading(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setCreateLoading(false)
        })
    }

    const handleUpdatePersona = (pData) => {
        setUpdating(true)

        let payload = {
            data_domain: activeDomain,
            name: personaTitle,
            value: personaDesc
        }

        dispatch(updatePersona(pData?.id, payload, false))
        .then(res => {
            setData(prev => prev?.map(p => (p?.id === pData?.id ? {...res?.data} : {...p})))
            setPersonaTitle('')
            setPersonaDesc('')
            setOpenModal(false)
            setUpdating(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setUpdating(false)
        })
    }

    const handleDeletePersona = (pData) => {
        setDeleting(true)

        dispatch(deletePersona(pData?.id))
        .then(res => {
            setData(prev => {
                let list = prev?.filter(p => p?.id !== pData?.id)
                setEmpty(list?.length === 0)
                return list
            })
            setPersonaTitle('')
            setPersonaDesc('')
            setDeleteOpen(false)
            setDeleting(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setDeleting(false)
        })
    }

    const onScrollToBottom = (paginate) => {
        if (!stopLazyload && !loading) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchPersona()
            }
        }
    }

    const userSelectedDomain = (d_id, d_name) => {
        dispatch(setUserSelectedDomain({ data_domain_id: d_id, data_domain_name: d_name }, false))
        .catch(err => console.error(err?.message))
    }

    const onChange = (key) => {
        setParentDomain(key)
    };


    const bulkDelete = (ids) => {
        setBulkDeleting(true)
        dispatch(bulkDeleteVocabulary({ids}))
        .then(() => {
            setBulkDeleting(false)
            setPageNo(1)
            fetchPersona(true)
            setBulkDeleteOpen(false)
        })
        .catch(() => {
            setBulkDeleting(false)
        })
    }

    return (
    <div className='trace-persona-wrap'>
        <div className='trace-persona-heading pb-2'>
            <div className='persona-head'>
                 {/* <div>
                   <div className='d-flex align-items-center color-primary sel-domain'>
                    {
                        loading
                        ?
                        <CustomSkeleton height='1.5rem' width='6.25rem' />
                        :
                        <TraceDomainList
                            key={'dmListPersona'}
                            setParentDomain={setParentDomain}
                            activeDomain={activeDomain}
                            userSelectedDomain={userSelectedDomain}
                        />
                    }
                        <div className='vertical-separator' style={{height: '1.5rem', width: '0.125rem'}}></div>
                    </div> */}






                    {/* <Select
                        placeholder="Select Domain"
                        showSearch={true}
                        optionFilterProp="children"
                        loading={loadingDomains}
                        value={
                            domainError    ? 'Error!' :
                            loadingDomains ? 'Loading...' :
                            domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.value ?? 'Select Domain'
                        }
                        onChange={onChange}
                        filterOption={(input, option) => {
                                const name = domainOptions?.find(d => d?.value === option?.value)?.Name ?? ''
                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                        }
                        filterSort={(optionA, optionB) => {
                                const nmA = domainOptions?.find(d => d?.value === optionA?.value)?.Name ?? ''
                                const nmB = domainOptions?.find(d => d?.value === optionB?.value)?.Name ?? ''
                                return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                            }
                        }
                        >
                        {
                            domainOptions?.map((ele, index) => (
                                <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                    <div title={ele?.Name} className='align-items-center d-flex px-1'>
                                        <span className='mr-1'><ICON_CATALOG_DOMAIN width='18' height='18' /></span>
                                        <span className='fontSizeHeading ml-2 text-with-ellipsis text-uppercase'>{ele.Name}</span>
                                    </div>
                                </Select.Option>
                            ))
                        }
                    </Select>
                </div> */}
                <div className='d-flex align-items-center'>
                    <label className='mb-0 fontSizeLargeHeading fontInterSemiBold'>Manage Persona</label>
                </div>
                
                <div className='d-flex align-items-center justify-content-end'>
                    
                    <div className='trace-persona-search-wrap' style={{width: '14rem'}}>
                        <SearchInput
                            searchData={searchData}
                            setSearchData={setSearchData}
                            autoFocus={false}
                        />
                    </div>
                    <div className='vertical-separator' style={{height: '1.5rem', width: '0.125rem'}}></div>
                    {
                        data?.length > 0
                        ?
                        <div className='d-flex'>
                            <Tooltip 
                                title={data?.every(d => d?.checked) ? 'Unselect All' : 'Select All'}
                            >
                                <Checkbox 
                                    className='mr-2'
                                    checked={data?.every(d => d?.checked)}
                                    onChange={e => {
                                        let chk = e?.target?.checked
                                        setData(prev => {
                                            return prev?.map(v => ({...v, checked: chk}))
                                        })
                                    }}
                                    disabled={bulkDeleting}
                                />
                            </Tooltip>
                        </div>
                        :   ''
                    }
                    {
                        data?.some(d => d?.checked)
                        ?
                        <Buttons
                            props={{
                                buttonText: bulkDeleting ? 'Deleting...' : `Delete (${data?.filter(d => d?.checked)?.length})`,
                                tooltip: "",
                                buttonClassName: `custom-btn-outline error-red custom-btn btn-with-text mr-2 border`,
                                buttonWrapperClass: 'd-flex justify-content-end',
                                buttonEvent: () => {
                                    selectedBulkRef.current = data?.filter(d => d?.checked)?.map(c => c?.id)
                                    setBulkDeleteOpen(true)
                                },
                                ImgSrc: () => bulkDeleting ? <ICON_LOADING_CIRCLE/> : <ICON_DELETE/>,
                                isDisable: bulkDeleting,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                        :   ''
                    }
                    <Buttons
                        props={{
                            buttonText: "Create",
                            tooltip: "",
                            buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                            buttonWrapperClass: 'd-flex justify-content-end',
                            buttonEvent: () => {
                                selectedDataRef.current = null
                                setOpenModal(true)
                            },
                            ImgSrc: () => <ICON_PLUS/>,
                            isDisable: bulkDeleting,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                        }}
                    />
                </div>
            </div>
        </div>
        <div className='trace-persona-body position-relative' 
            ref={listElementRef} 
            onScroll={
                ({currentTarget}) => {
                    onScrollToBottom(currentTarget.scrollTop + 10 >=
                        currentTarget.scrollHeight - currentTarget.clientHeight)
                }
            }
            >
            {
                error ? getReloadErrorTemplate({
                    errorMessage: error, 
                    onReload: () => {
                        setPageNo(1)
                        fetchPersona(true)
                    }
                })
                :

                loading ? <ClaristaLoader/>
                :

                empty ? <NoDataComponent logo={<ICON_USER/>} message={'No Persona Found'} />
                :
                <div>
                    {
                        data?.map((d, i) => (
                            <React.Fragment key={`${d?.id}-persona-${i}`}>
                                <div className='py-3'>
                                    <div className='d-flex align-items-start justify-content-between' style={{gap: 15}}>
                                        <div>
                                            <label className='fontInterSemiBold fontSizeLargeHeading'>
                                                <Highlighter
                                                    searchWords={[searchData?.toLocaleLowerCase()]}
                                                    autoEscape={true}
                                                    textToHighlight={d?.name}
                                                >
                                                    {d?.name}
                                                </Highlighter>
                                            </label>
                                        </div>
                                        <div>
                                            <div className='d-flex align-items-center'>
                                                <Checkbox 
                                                    className='mr-2'
                                                    checked={!!d?.checked}
                                                    onChange={e => {
                                                        let chk = e?.target?.checked
                                                        setData(prev => {
                                                            return prev?.map(v => ({...v, checked: v?.id === d?.id ? chk : !!v?.checked}))
                                                        })
                                                    }}
                                                    disabled={bulkDeleting}
                                                />
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        tooltip: "",
                                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0`,
                                                        buttonWrapperClass: 'd-flex justify-content-end',
                                                        buttonEvent: () => {
                                                            selectedDataRef.current = {...d}
                                                            setPersonaTitle(d?.name)
                                                            setPersonaDesc(d?.value)
                                                            setOpenModal(true)
                                                        },
                                                        ImgSrc: () => <ICON_EDITBOX/>,
                                                        isDisable: bulkDeleting,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                                <Buttons
                                                    props={{
                                                        buttonText: "",
                                                        tooltip: "",
                                                        buttonClassName: `custom-btn-outline error-red custom-btn btn-with-icon ml-2 border-0`,
                                                        buttonWrapperClass: 'd-flex justify-content-end',
                                                        buttonEvent: () => {
                                                            selectedDataRef.current = {...d}
                                                            setDeleteOpen(true)
                                                        },
                                                        ImgSrc: () => <ICON_DELETE/>,
                                                        isDisable: bulkDeleting,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label className='mb-0 fontSizeLargeHeading' style={{whiteSpace: 'pre-line'}}>
                                            <Highlighter
                                                searchWords={[searchData?.toLocaleLowerCase()]}
                                                autoEscape={true}
                                                textToHighlight={d?.value}
                                            >
                                                {d?.value}
                                            </Highlighter>
                                        </label>
                                    </div>
                                </div>
                                <div className="septor my-2"></div>
                            </React.Fragment>
                        ))
                    }
                </div>
            }

        </div>

        <CommonModal
            open={openModal}
            setOpen={setOpenModal}
            header='Create Persona'
            size='md'
            body={<>
                <div>
                    <div className='mb-3'>
                        <label className='label'>Title</label>
                        <div>
                            <Input
                                value={personaTitle}
                                onChange={e => {
                                    setPersonaTitle(e?.target?.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className='mb-3'>
                        <label className='label'>Description</label>
                        <div>
                            <Input.TextArea
                                value={personaDesc}
                                onChange={e => {
                                    setPersonaDesc(e?.target?.value)
                                }}
                                style={{minHeight: 100, lineHeight: 1.4, resize: 'none'}}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        {
                            selectedDataRef.current === null
                            ?
                            <Buttons
                                props={{
                                    buttonText: createLoading ? "Creating..." : "Submit",
                                    tooltip: "",
                                    buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                    buttonEvent: () => {
                                        handleCreatePersona()
                                    },
                                    ImgSrc: () => createLoading ? <ICON_LOADING_CIRCLE/> : <ICON_CHECK/>,
                                    isDisable: createLoading || personaTitle?.trim() === "" || personaDesc?.trim() === "",
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                            :
                            <Buttons
                                props={{
                                    buttonText: updating ? "Updating..." : "Update",
                                    tooltip: "",
                                    buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                    buttonEvent: () => {
                                        handleUpdatePersona(selectedDataRef.current)
                                    },
                                    ImgSrc: () => updating ? <ICON_LOADING_CIRCLE/> : <ICON_CHECK/>,
                                    isDisable: updating,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                }}
                            />
                        }
                    </div>
                </div>
            </>}
            onClose={() => {
                setPersonaTitle('')
                setPersonaDesc('')
            }}
            hideFooter={true}
        />
        <DeleteModal
            heading='Delete Persona'
            loading={deleting}
            deleteBtnDisabled={deleting}
            open={deleteOpen}
            setOpen={setDeleteOpen}
            onConfirm={() => {
                handleDeletePersona(selectedDataRef.current)
            }}
        />
        <DeleteModal
            heading='Delete Selected Persona(s)'
            loading={bulkDeleting}
            deleteBtnDisabled={bulkDeleting}
            open={bulkDeleteOpen}
            setOpen={setBulkDeleteOpen}
            onConfirm={() => {
                bulkDelete(selectedBulkRef.current)
            }}
        />
        {
            !loadMore ? ''
            :
            <div className='trace-loading-more-wrap w-100'>
                <div className='trace-loading-more'>
                    <span className='mr-2'>
                        <ICON_LOADING_CIRCLE height='20' width='20' color='currentColor'/>
                    </span>
                    Loading More
                </div>
            </div>
        }
    </div>
    )
}

export default TracePersona