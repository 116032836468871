
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector, useStore } from "react-redux";
import { useParams } from "react-router";
import { setSidebarVisibilty, setUserActiveDomain, setUserActiveDomainLoader } from "../../../../store/actions";

import {
  EditFlowsAPI,
  RetriveExecutionFlows,
  getFlowDetails,
  setFlowsPermissions,
  setTransformerMaxId,
  setTransformerNames,
  showFlowsLogsLowerSection,
  updateFlowsBasicDetails,
} from "../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import { SET_TRANSFORMER_PROGRESS } from "../../../../store/modules/flows/flowsTypes";
import "../Newflow.scss";
import Header from "./Header";
import _, { cloneDeep } from 'lodash'
import RightSideUpperContent from "./RightSideUpperContent";
import { generateHtmlTitle, permissions } from "../../common/helperFunctions";
import { checkIsEditorFlow } from "../transformers/commonFunctions/FlowsHelperFunction";
import { updateFlowsElements } from "../../../../store/modules/flows/flowsActions";
import { Drawer } from "antd";
import LogsLowerFlowsSection from "./LogsLowerFlowsSection";
import JupyterNotebook from "../transformers/script/JupyterNotebook";
import LoadingComponent from "../../common/loadingComponent";
import NoResultsFound from "./NoResultsFound";
import LandingpageFirstHeader from "../../common/LandingpageFirstHeader";
import { getGlobalConnectionCache } from "../../../../store/modules/UserManagement/connection/connectionActions";


const flowsPermissions = permissions.flows;

const CreateFlows = (props) => {
  const {

  } = props;

  const {
    showTransformDetails,
  } = props;


  const [isViewMode, setisViewMode] = useState({}); //flows detail response with permission 
  const [showerror, setshowerror] = useState(false); //flows detail response failed then show no result found
  const [flowData, setflowData] = useState({}); //flows detail response with permission 
  const store = useStore();


  const saveFlowClick = useSelector(
    (state) => state.Lab.saveFlowClick
  );
  const showJupyterNoteBook = useSelector(
    (state) => state.Lab.showJupyterNoteBook
  );
  const showDatabricksNotebook = useSelector(
    (state) => state.Lab.showDatabricksNotebook
  );
  const showLogsLowerSection = useSelector(
    (state) => state.Lab.showLogsLowerSection
  );

  const { flowId } = useParams();
  let RetriveInterval = useRef([]);


  useEffect(() => {
    if (props && props?.flowName) {
      let title = generateHtmlTitle(props.flowName.toUpperCase(), 'Flows')
      document.title = title
    }
  }, [props])



  useEffect(() => {
    let removeEditorAccess = false;
    store.dispatch(setSidebarVisibilty(false));
    store.dispatch(getGlobalConnectionCache());
    store.dispatch(EditFlowsAPI({ ApiType: "GET", flow_Id: flowId })).then((res) => {
      setisViewMode(false)
      removeEditorAccess = false
    }).catch((err) => {
      if (err && err?.message && err?.message?.includes('The flow is already opened by another user')) { /// if this error occure then only access will be removed from my end
        setisViewMode(true);
        removeEditorAccess = true;
      }
    })
    // store.dispatch(setUserActiveDomain(null))
    store.dispatch(setUserActiveDomainLoader(true))
    store.dispatch(getFlowDetails(flowId)).then((resp) => {
      // console.log(removeEditorAccess);

      if (resp && resp.data) {
        setshowerror(false)
        store.dispatch(setUserActiveDomainLoader(false))
        let tempResponseData = resp.data;

        if(tempResponseData?.pod_detail && tempResponseData?.pod_detail[0]?.data_domain_id) {
          store.dispatch(setUserActiveDomain(
            {
              id: tempResponseData?.pod_detail[0]?.data_domain_id,
              name: tempResponseData?.pod_detail[0]?.data_domain_name,
            }
          ))
        }
        else {
          store.dispatch(
              setUserActiveDomain(
                  store?.getState()?.UserManagement?.DataDomain?.userSelectedDomain
              )
          )
        }

        if (tempResponseData?.permission && tempResponseData?.permission?.length) {

          let newPermissions = cloneDeep(tempResponseData?.permission);
          if (removeEditorAccess) {
            newPermissions = newPermissions.filter(item => item !== 'editor_datadomain');

          }

          store.dispatch(setFlowsPermissions(newPermissions));
          let isTrue = newPermissions?.findIndex(v => v === flowsPermissions.editor) === -1
          setisViewMode(isTrue);

        }
        setflowData(tempResponseData);
        let obj = {
          name: tempResponseData.name,
          description: tempResponseData.description,
          id: tempResponseData.id,
          bookmark: tempResponseData.bookmark,
        };
        store.dispatch(updateFlowsBasicDetails(obj));

        if (tempResponseData?.flow_redux) {
          store.dispatch(updateFlowsElements(tempResponseData?.flow_redux?.flowsElements))
          store.dispatch(setTransformerMaxId(tempResponseData?.flow_redux?.maxTransformerId))
          let flowsElements = tempResponseData?.flow_redux?.flowsElements;
          if (flowsElements?.nodes.length) {
            let transformerNameList = {}
            flowsElements?.nodes.forEach(
              (item) => {
                transformerNameList[item.id] = item.transformerName;

              }
            );
            store.dispatch(setTransformerNames(transformerNameList))

          }
        }

      }
    }).catch((err) => {
      store.dispatch(setSidebarVisibilty(true));
      setshowerror(true);
    })


    return () => {
      store.dispatch(setSidebarVisibilty(true));
      store.dispatch(EditFlowsAPI({ ApiType: "DELETE", flow_Id: flowId }));

    };
  }, [flowId]);



  useEffect(() => {
    let apiPayload = {
      page: "",
      limit: "",
      execution_id: "",
      flow_Id: flowId,
    };
    let currentState = "";

    store.dispatch(RetriveExecutionFlows(apiPayload)).then((res) => {
      if (res?.data?.length) {
        let data = res.data.map((ele) => {
          ele.state = ele.state ? ele.state?.toLowerCase() : "";
          return ele;
        });
        store.dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data });
        let ids = res?.data
          .map((ele) => Number(ele.crnt_transformer))
          .filter((item) => Number(item));
        if (ids?.length) {
          let maxId = store.getState().Lab.maxTransformerId;
          ids = ids.sort((a, b) => a - b);

          if (Number(ids[ids?.length - 1] > maxId)) {
            store.dispatch(setTransformerMaxId(Number(ids[ids?.length - 1])));
          }
        }
      }



      let id = setInterval(() => {

        callLatestStatusApiNew()
      }, 5000);
      RetriveInterval.current = [...RetriveInterval.current, id]

    });



    return () => {

      clearTimeout(RetriveInterval.current)

    };
  }, []);

  const clearAllTimeout = () => {
    localStorage.removeItem('openConfigModal')

    // RetriveInterval.current.forEach(clearTimeout)
    RetriveInterval.current.forEach(clearInterval)
    RetriveInterval.current = []
  }



  const callLatestStatusApiNew = async () => {
    let isConfigureModalOpen = localStorage.getItem('openConfigModal');

    let apiPayload = {
      page: "",
      limit: "",
      execution_id: "",
      flow_Id: flowId,
    };

    if (!window.location.pathname.toLowerCase().includes('/flows')
    ) {
      clearAllTimeout()
      // clearTimeout(RetriveInterval.current);

      return;
    }

    try {
      let res = await store.dispatch(RetriveExecutionFlows(apiPayload))

      if (res?.data?.length) {
        let data = res.data.map((ele) => {
          ele.state = ele.state ? ele.state?.toLowerCase() : "";
          return ele;
        });
        if (data?.length && data[0]?.flow_id === flowId) {
          store.dispatch({ type: SET_TRANSFORMER_PROGRESS, payload: data });
        }
      }

      // let id = setTimeout(() => {
      //   callLatestStatusApiNew();
      // }, 5000);
      // RetriveInterval.current = [...RetriveInterval.current, id]
    } catch (error) {

      // let id = setTimeout(() => {
      //   callLatestStatusApiNew();
      // }, 5000);
      // RetriveInterval.current = [...RetriveInterval.current, id]


    }



  }



  return (
    !showerror ? <>
      <div className="New-flows-section">
        {!showTransformDetails && !showJupyterNoteBook ? <Header

          showJupyterNoteBook={showJupyterNoteBook}
          showDatabricksNotebook={showDatabricksNotebook}
          isViewMode={isViewMode}
          isEditPermission={!isViewMode}

        /> : null}
        {showJupyterNoteBook ? <JupyterNotebook /> :
          // <JupyterNotebook />
          // ) : showDatabricksNotebook ? <DatabricksNotebook /> :
          (
            <div className="New-flows-main-canvas">
              <RightSideUpperContent

                isViewMode={isViewMode}


              />
              {showLogsLowerSection ? <Drawer
                title="Logs"
                placement={'bottom'}
                rootClassName="LogsDrawer"
                height={"440px"}
                closable={false}
                onClose={() => store.dispatch(showFlowsLogsLowerSection(!store.getState().Lab.showLogsLowerSection))}
                open={showLogsLowerSection}

              >
                <LogsLowerFlowsSection flowId={flowId} showLogs={showLogsLowerSection} />
              </Drawer> : null}
            </div>

          )}
      </div>

      {props.loadingStatus ? <LoadingComponent /> : null}
    </> : <> <LandingpageFirstHeader title='Flows'></LandingpageFirstHeader>
      <NoResultsFound height="100vh" /></>
  );
};

const mapStateToProps = (state) => {
  return {
    flowName: state.Lab.name,
    loadingStatus: state.LoadingReducer.loadingStatus,
    flowId: state.Lab.id,
    showTransformDetails: state.Lab.showTransformDetails,
    isSidebarVisible: state.Layout.isSidebarVisible,
  };
};
export default connect(mapStateToProps)(CreateFlows);
