import React from 'react'
import { ICON_CLOSE, ICON_FEEDBACK, ICON_SQLFILE, ICON_SYNONYM, ICON_USER } from '../../../Common/newIconSource'
import { ConfigProvider, Tabs } from 'antd'
import { getQueryParams, getReloadErrorTemplate, pxToRem, remToPx } from '../../common/helperFunctions'
import { useLocation, useNavigate } from 'react-router-dom'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import '../genAi.scss'
import TalkDataTrace from './TalkDataTrace'
import TracePersona from './TracePersona'
import TraceSynonym from './TraceSynonym'
import { Literals } from '../../common/literals'
import Buttons from '../../../Common/button/Buttons'
import { useDispatch } from 'react-redux'
import { getUserSelectedDomain, setSidebarActiveRoute, setUserSelectedDomain } from '../../../../store/actions'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import TraceFormula from './TraceFormula'
import _ from 'lodash'

const TalkDataTraceIndex = () => {

    const history = useNavigate()

    const location = useLocation()

    const dispatch = useDispatch()

    const {dm: domainId} = getQueryParams(location.search)

    const [activeTabKey, setActiveTabKey] = React.useState('persona')
    const [activeDomain, setActiveDomain] = React.useState('')
    const [isDomainFailed, setDomainFailed] = React.useState(false)
    const [isDomainLoading, setDomainLoading] = React.useState(false)
    const [domainError, setDomainError] = React.useState(undefined)

    React.useEffect(() => {
        getActiveDomain()

        if (location?.hash) {
            let hashParam = location.hash?.replace('#', '')
            if(['feedback', 'persona', 'synonym', 'formula']?.includes(hashParam)) {
                setActiveTabKey(hashParam)
            }
            else {
                setActiveTabKey('persona')
            }
        }
    }, [location])

    React.useEffect(() => {
        dispatch(setSidebarActiveRoute(window.location.pathname))
    }, [])

    const getActiveDomain = () => {
        if(location?.state?.domain) {
            let domain = location?.state?.domain
            let domain_id = '', domain_name = null

            if(_.isObject(domain)) {
                domain_id = domain?.id?.toString()
                domain_name = domain?.name
            }
            else {
                domain_id = domain?.toString()
            }
            setActiveDomain(domain_id)
            dispatch(setUserSelectedDomain({ data_domain_id: domain_id, data_domain_name: domain_name }, false))
            .catch(err => console.error(err?.message))
            return
        }

        dispatch(getUserSelectedDomain())
        .then(res => {
            const domain = res?.data?.domain ?? undefined
            if (domain && domain?.id) {
                setActiveDomain(domain?.id?.toString())
            }
            else if (domain) {
                setActiveDomain(domain?.id ?? 0)
            }
        })
        .catch(err => {
            setDomainFailed(true)
            console.error(err?.message)
        })
    }

    const tabItems = React.useMemo(() => {
        let tabs = [
                {
                    key: 'persona',
                    label: <span>
                        <ICON_USER color='currentColor'/> Persona
                    </span>,
                    children: activeTabKey !== 'persona' ? <></>
                    : <>
                        <TracePersona parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
                {
                    key: 'synonym',
                    label: <span>
                        <ICON_SYNONYM color='currentColor'/> Synonym
                    </span>,
                    children: activeTabKey !== 'synonym' ? <></>
                    : <>
                        <TraceSynonym parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
                {
                    key: 'formula',
                    label: <span>
                        <ICON_SQLFILE color='currentColor'/> Formula
                    </span>,
                    children: activeTabKey !== 'formula' ? <></>
                    : <>
                        <TraceFormula parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
                {
                    key: 'feedback',
                    label: <span>
                        <ICON_FEEDBACK color='currentColor'/> Feedback
                    </span>,
                    children: activeTabKey !== 'feedback' ? <></>
                    : <>
                        <TalkDataTrace parentDomain={activeDomain} setParentDomain={setActiveDomain} />
                    </>,
                },
            ]

        return tabs

    }, [activeDomain, activeTabKey]) 

    const onTabChange = key => {
        setActiveTabKey(key)
        window.history.replaceState(null, null, `${window.location.pathname}#${key}`);
    }

    return (
        <React.Fragment>
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            inkBarColor: "#2C83C4",
                            itemSelectedColor: "#2C83C4",
                            // verticalItemMargin: "0 0 0 0",
                            // horizontalItemGutter: remToPx(pxToRem(16))
                        },
                    },
                }}
            >   
                <div className='talk-index-main trace-index-wrapper'>
                    <LandingpageFirstHeader 
                        title={'TALKdata'} 
                        isPulse={true}
                        activeDomain={domainId}
                        showFeedNotification={true}
                    />
                    <div className='trace-index-tab-wrap'>
                        {
                            isDomainLoading ? <ClaristaLoader/>
                            :
                            isDomainFailed ? getReloadErrorTemplate({
                                errorMessage: domainError,
                                onReload: () => getActiveDomain()
                            })
                            :
                            <Tabs 
                                className='trace-index-tab'
                                defaultActiveKey="1" 
                                activeKey={activeTabKey}
                                items={tabItems}
                                tabBarExtraContent={<>
                                    <Buttons
                                        props={{
                                            buttonText: "",
                                            tooltip: "",
                                            buttonClassName: `custom-btn-outline custom-btn btn-with-icon border-0`,
                                            buttonEvent: () => {
                                                if(location?.state?.fromPulsePage) {
                                                    history(Literals.links.CLARISTA_PULSE+"#pod")
                                                }
                                                else {
                                                    history(Literals.links.TALKDATA_PULSE)
                                                }
                                            },
                                            ImgSrc: () => <ICON_CLOSE/>,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                        }}
                                    />
                                </>}
                                onChange={onTabChange} 
                            />
                        }
                    </div>
                </div>
            </ConfigProvider>
        </React.Fragment>
    )
}

export default TalkDataTraceIndex