import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';

import store from './store';
import ErrorBoundry from './ErrorBoundry';
import { emitToastNotification } from './helpers/toast_helper';
import { Literals } from './components/modules/common/literals';
import { isUserLogin } from './store/userAuthentication/userActions';
import { SocketProvider } from './services/SocketContextProvider';



window.addEventListener('storage', (e) => {

  let user = JSON.parse(localStorage.getItem('user'))
  let splitParams = window.location.search?.split('?redirectFrom=')
  let url = null

  if (splitParams?.length === 2) {
    url = splitParams[1];
  }

  if (user && isUserLogin() && (window.location?.href?.includes('login') || window.location?.href?.includes('logout') || window.location?.href?.includes('forgot-password') || window.location?.href?.includes('verify-otp') || window.location?.href?.includes('signup'))) {
    if (url && url !== '/' && !url?.includes('/login') && !url?.includes('/logout')) {
      window.location.href = url
    } else {
      if (user?.userDetails?.designation === Literals.DATA_ANALYST) {

        window.location.href = Literals.links.CLARISTA_NAVIGATOR + '#business'

      } else if (user?.userDetails?.designation === Literals.DATA_SCIENTIST) {
        window.location.href = Literals.links.CLARISTA_LAB + '#Flows'

        // history(Literals.links.CLARISTA_LAB);
      } else if (user?.userDetails?.designation === Literals.DATA_CONSUMER) {
        window.location.href = Literals.links.DASHBOARD_PULSE

        // history(Literals.links.CLARISTA_PULSE);
      } else {
        window.location.href = Literals.links.CLARISTA_HOME

      }
    }
    // window.location.reload()
  } else if (!user && !isUserLogin() && !window.location?.href?.includes('login') && !window.location?.href?.includes('forgot-password') && !window.location?.href?.includes('verify-otp') && !window.location?.href?.includes('signup') && !window.location?.href?.includes('logout')) {

    // window.location.href = `${Literals.links.LOGIN}?redirectFrom=${window.location.pathname}${window?.location?.search}`

    window.location.href = `${Literals.links.LOGOUT}?redirectFrom=${window.location.pathname}${window?.location?.search}`

  }
})

const hasShownOnlineAlert = sessionStorage.getItem('hasShownOnlineAlert');
const hasShownOfflineAlert = sessionStorage.getItem('hasShownOfflineAlert');

if (!hasShownOnlineAlert) {
  window.addEventListener('online', () => {
    alert('Welcome, You are now online');
    sessionStorage.setItem('hasShownOnlineAlert', true);
  });
}

if (!hasShownOfflineAlert) {
  window.addEventListener('offline', () => {
    alert('You are offline, Please check the network');
    sessionStorage.setItem('hasShownOfflineAlert', true);
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      {emitToastNotification()}

      <ErrorBoundry>
        <SocketProvider>
          <App />
        </SocketProvider>
      </ErrorBoundry>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);


