import React from 'react'
import LandingpageFirstHeader from '../../common/LandingpageFirstHeader'
import TalkDataQuestionHeader from './TalkDataQuestionHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateHtmlTitle, getDateToEPOC, getMonthName, getQueryParams, getReloadErrorTemplate, NoDataComponent, stringToColor } from '../../common/helperFunctions'
import '../genAi.scss'
import './UnstructuredTalkData.scss'
import { ICON_CHAT, ICON_CHAT_TAIL, ICON_CLARISTA, ICON_LOADING_CIRCLE, ICON_TWO_PART_CIRCLE_BOLD } from '../../../Common/newIconSource'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import UnstructuredChatContent from './UnstructuredChatContent'
import moment from 'moment'
import { getUserSelectedDomain, setUserActiveDomain, setUserActiveDomainLoader } from '../../../../store/actions'
import { getChatConversation, getChatData, startChartConversation } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { v4 as uuidv4 } from "uuid";
import { Literals } from '../../common/literals'
import _, { isArray } from 'lodash'
import { Avatar } from 'antd'
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions'
import { useSocket } from '../../../../services/SocketContextProvider'
import MarkdownText from '../../../Common/MarkdownText'

const UnstructuredTalkDataIndex = ({
    isFloating = false,
    dataDomainId,
    extraPayload,
    uuidProp
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useNavigate()
    const {dm: domainId, chat: chatIdParam, uuid} = getQueryParams(location.search)
    const query = location?.state?.query ?? ''
    const podIdsLoc = location?.state?.podIds ?? []
    
    const [data, setData]                 = React.useState([])
    const [empty, setEmpty]               = React.useState(false);
    const [pageNo, setPageNo]             = React.useState(1)
    const [chatId, setChatId]             = React.useState(chatIdParam)
    const [podIds, setPodIds]             = React.useState(podIdsLoc)
    const [loading, setLoading]           = React.useState(false)
    const [errMsg, setErrorMsg]           = React.useState("");
    const [chatData, setChatData]         = React.useState({})
    const [loadMore, setLoadMore]         = React.useState(false)
    const [convError, setConvError]       = React.useState(undefined)
    const [activeDomain, setActiveDomain] = React.useState(isFloating ? dataDomainId : domainId ?? '')
    const [questValue, setQuestValue]     = React.useState(query)
    const [searchFocus, setSearchFocus]   = React.useState(false)
    const [chatError, setChatError]       = React.useState("")
    const [usersList, setUsersList]       = React.useState([])
    const [usersLoading, setUsersLoading] = React.useState(false)
    const [fetchingConv, setFetchingConv] = React.useState(false)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [queryChoices, setQueryChoices] = React.useState([])

    const [apiController, setApiController] = React.useState(null)
    const [isReady, setIsReady] = React.useState(true)
    const [loadingSteps, setLoadingSteps] = React.useState(false)
    const [chunkData, setChunkData] = React.useState("")

    const uuidRef = React.useRef(isFloating ? uuidProp : uuid)
    const headerRef = React.useRef({})
    const searchInputRef = React.useRef({})
    const chatScrollRef = React.useRef({})
    const chatWindowRef = React.useRef({})
    const chartRefMap = React.useRef({})
    const dataRefMap = React.useRef({})

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const SocketContext = useSocket(null)

    React.useEffect(() => {
        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'user_message') {

            const resp = SocketContext?.socketResponse
            
            let currentChat;
            if(resp?.chat_id?.toString()) {
                currentChat = resp

                setChunkData(prev => {
                    let newStr = currentChat?.blocks?.[0]?.markdown_block?.chunks?.join('') ?? ""
                    prev = prev + newStr
                    return prev
                })
                chatScrollRef?.current?.scrollTo(0, chatScrollRef?.current?.scrollHeight)
            }
        }
    }, [SocketContext, chatId])
    
    React.useEffect(() => {
        if(!domainId) {
            dispatch(getUserSelectedDomain())
            .then(res => {
                const domain = res?.data?.domain ?? undefined
                if (domain && domain?.id) {
                    setActiveDomain(domain?.id)
                    window.history.pushState(null, null, window?.location?.pathname + window?.location?.search + '?dm=' + domain?.id)
                }
                else {
                    setActiveDomain(domain?.id ?? 0)
                }
            })
            .catch(err => {
                console.error(err?.message)
            })
        }
    }, [domainId])

    React.useEffect(() => {
        if(chatId) fetchChatData()
        document.querySelector('#pulseTalkSearchInp').innerText = query
    }, [])

    React.useEffect(() => {
        
        if(isFloating) {
            uuidRef.current = uuidProp
        }
        
    }, [uuidProp, isFloating])

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }
        if(!isFloating) {
            getUsersList()
        }
        if(chatId && chatId !== '') {
            if(!isFloating) {
                setLoading(true)
                fetchConversations(true)
            }
        }
        else {
            startConversation()
        }
    }, [activeDomain])

    const startConversation = React.useCallback((retryQuest = undefined) => {
        let quest = retryQuest ?? questValue
        if(quest?.trim() === '') return

        if(!uuidRef?.current || uuidRef?.current === '') {
            uuidRef.current = uuidv4()
        }


        let payload = {
            uuid: uuidRef.current,
            data_domain: activeDomain,
            question: quest,
            unstructured: true
        }

        if(chatId) payload = {...payload, chat: chatId }

        let chatPodIds = podIds || podIdsLoc
        if(chatPodIds) {
            payload = {...payload, pod_ids: [...chatPodIds]}
        }

        if(isFloating) {
            payload = {...payload, ...extraPayload}
        }

        setErrorMsg(undefined)
        setConvError(undefined)
        setFetchingConv(true)
        setEmpty(false)

        setTimeout(() => {
            chatScrollRef?.current?.scrollTo(0, chatScrollRef?.current?.scrollHeight)
        }, 300)

        if(retryQuest) {
            setData(prev => {
                prev = prev?.filter(v => v?.chat_failed === undefined || !v?.chat_failed)
                return prev
            })
        }

        document.querySelector('#pulseTalkSearchInp').innerText = ' '

        const previousQueryChoices = queryChoices ?? []
        setQueryChoices([])
        setChunkData("")

        const controller = new AbortController();
        setApiController(controller)
        dispatch(startChartConversation(payload, true, controller))
        .then(res => {
            const dat = res?.data
            const chat_id = dat?.chat_detail?.id
            if(!isFloating) {
                if(window.location.pathname === Literals.links.PULSE_UNSTRUCTURED_TALK) {
                    if(chatId === undefined)
                        window.history.pushState(null, null, window?.location?.search + '&chat=' + chat_id)
                    if(uuid === undefined)
                        window.history.pushState(null, null, window?.location?.search + '&uuid=' + uuidRef.current)
                }
            }
            
            dataRefMap.current[dat?.id] = dat
            chartRefMap.current[dat?.id] = {
                ...dat?.chart_payload,
                type: dat?.selected_chart,
                possible_chart: dat?.possible_chart
            }
            
            setData(prev => {
                prev = [...prev, dat]
                prev = _.uniqBy(prev, 'id')
                return prev
            })

            setQueryChoices(dat?.response_choices ?? [])

            setTimeout(() => {
                chatScrollRef?.current?.scrollTo(0, chatScrollRef?.current?.scrollHeight)
            }, 300)

            setChatId(chat_id)
            setChatData(dat)
            setFetchingConv(false)

            setQuestValue("")

            if(["CLARIFICATION", "FAILED"]?.includes(res?.data?.state)) {
                document.querySelector('#pulseTalkSearchInp').innerText = ''
            }

            if(searchInputRef?.current?.focus) {
                setTimeout(() => {
                    searchInputRef?.current?.focus()
                }, 500)
            }

            setChunkData("")
        })
        .catch(err => {
            setData(prev => {
                prev = [...prev, {question: quest, chat_failed: true, canceled: err?.code === "ERR_CANCELED", created_on: new Date()}]
                prev = _.uniqBy(prev, 'id')
                return prev
            })
            setQueryChoices(previousQueryChoices)

            setQuestValue("")
            setChunkData("")

            setConvError(err?.message)
            setFetchingConv(false)
            setLoadingSteps(false)
            
            setApiController(null)

            document.querySelector('#pulseTalkSearchInp').innerText = ''

            if(searchInputRef?.current?.focus) {
                setTimeout(() => {
                    searchInputRef?.current?.focus()
                }, 500)
            }

        })
    }, [activeDomain, chatId, queryChoices, questValue, uuid])

    const fetchConversations = React.useCallback((reset = false, chat_id = undefined) => {
        // setData([...demoResp])


        if(!activeDomain || activeDomain === '' || chatError?.length > 0) {
            setLoading(false)
            return
        }

        if(isFloating && uuidProp !== uuidRef.current) {
            setLoadMore(false)
            setLoading(false)
            return
        }
       
        setStopLazyload(false)
        setErrorMsg(undefined)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 5

        dispatch(getChatConversation(
                uuidRef.current ?? '', 
                chat_id ?? chatId ?? null, 
                pageNumber, 
                pageLimit, 
                data?.length > 0
            )
        )
        .then(res => {
            const dat = res?.data

            if(dat) {
                if(dat?.results) {
                    let arr = []
                    setData(prev => {
                        if(reset) {
                            arr =  [...dat?.results]
                            return arr?.reverse()
                        }
                        arr = [...prev?.reverse(), ...dat?.results]
                        arr = _.uniqBy(arr, 'id')
                        return arr?.reverse()
                    })
                    if(chatId === undefined) {
                        if(window.location.pathname === Literals.links.PULSE_TALK) {
                            const new_chat_id = dat?.results?.[0]?.chat_detail?.id
                            if(!isFloating) {
                                window.history.pushState(null, null, window?.location?.search + '&chat=' + new_chat_id + '&uuid=' + dat?.results?.[0]?.uuid)
                            }
                            setChatId(new_chat_id)
                        }
                    }
                    
                    setTimeout(() => {
                        if(reset) {
                            chatScrollRef?.current?.scrollTo({top: chatScrollRef?.current?.scrollHeight, left: 0, behavior: 'instant' })
                        }
                        else if(document?.querySelector(`#conversation-${dat?.results?.[0]?.id}-ts`)) {
                            document?.querySelector(`#conversation-${dat?.results?.[0]?.id}-ts`)?.scrollIntoView({behavior: 'instant'})
                        }
                    }, 800)
                }
    
                if (dat?.has_next === false) {
                    setStopLazyload(true)
                    setLoadMore(false)
                }
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }

            setEmpty(dat?.results?.length === 0)
            setLoading(false)
        })
        .catch(err => {
            console.error({err})
            setErrorMsg(err?.message)
            setEmpty(false)
            setLoading(false)
            setLoadMore(false)
        })
    }, [activeDomain, chatError?.length, chatId, data?.length, pageNo])

    const fetchChatData = React.useCallback(() => {
        dispatch(setUserActiveDomainLoader(true))
        dispatch(getChatData(chatId, false))
        .then(res => {
            setChatData({chat_detail: res?.data})
            setPodIds(res?.data?.pod_ids ?? [])
            dispatch(setUserActiveDomainLoader(false))
            
            if(res?.data && res?.data?.data_domain) {
                setActiveDomain(res?.data?.data_domain)

                dispatch(setUserActiveDomain(
                    {
                        id: res?.data?.data_domain,
                        name: res?.data?.data_domain_name,
                    }
                ))
            }


            if(res?.data && res?.data?.name) {
                let title = generateHtmlTitle(res?.data?.name + ' | Unstructured | TALKdata', 'Pulse')
                document.title = title
            }
        })
        .catch(err => {
            let title = generateHtmlTitle('Unstructured | TALKdata', 'Pulse')
            document.title = title
            setChatError(err?.message)
            console.error({err})

            if(err?.status?.toString() === '404') {
                history('/page404')
            }
        })
    }, [chatId])

    const getUsersList = React.useCallback(() => {
        setUsersLoading(true)
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile?data_domain_id=" + domainId, payload: undefined, showLoader: false }))
        .then(res => {
            let list = res?.data?.map(ele => ({ name: `${ele?.first_name} ${ele?.last_name}`, value: ele?.id, email: ele?.email, data: {...ele} }))
            setUsersList([...list]);
            setUsersLoading(false)
        })
        .catch(err => {
            setUsersLoading(false)
            console.error(err)
        })
    }, []);
    
    const onScrollToTop = React.useCallback((paginate) => {
        if (!stopLazyload && !fetchingConv) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchConversations()
            }
        }
    }, [stopLazyload, fetchingConv, loadMore])

    const conversationArray = React.useMemo(() => {

        const getReadableData = (dat) => {
            let date = new Date(dat)
            return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
        }

        let obj = data?.reduce((prev, curr) => ({
            ...prev, 
            [getDateToEPOC(curr?.created_on)]: [...data?.filter(d => getReadableData(d?.created_on) === getReadableData(curr?.created_on))]
        }), {})

        return Object.keys(obj)?.map((c, idx) => (
            <React.Fragment key={`conv-fragment-${idx}`}>
                <div className='chat-date-strip fontInterSemiBold fontSizeLabel mx-auto my-4 py-2 text-center'>
                    <span>
                        {   
                            moment(c, "YYYY-MM-DD").calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: '[Last] dddd',
                                sameElse: `DD MMM YYYY`
                            })
                        }
                    </span>
                </div>
                {
                    obj[c]?.map((con, i) => (
                        <UnstructuredChatContent 
                            key={`conv-${i}`} 
                            index={i} 
                            data={con}
                            reload={() => {
                                setPageNo(1)
                                setLoadMore(true)
                                setLoading(true)
                                fetchConversations(true)
                            }}
                            usersList={usersList}
                            chartRefMap={chartRefMap.current}
                            dataRefMap={dataRefMap.current}
                            deleteFullChat={data?.length === 1}
                            fetchingConv={fetchingConv}
                            retryQuery={(qry) => {
                                startConversation(qry)
                            }}
                            setQuestValue={setQuestValue}
                            chatWindowRef={chatWindowRef}
                            setData={setData}
                            isFloating={isFloating}
                            refreshNotification={() => {
                                headerRef?.current?.refreshNotification()
                            }}
                            setLoadingSteps={
                                obj[c]?.length === (i + 1)
                                ?
                                setLoadingSteps
                                :
                                () => {}
                            }
                        />
                    ))
                }
            </React.Fragment>
        ))
    }, [data, usersList, fetchingConv])

    const displayUserName = React.useCallback(() => {
        let name = userDetailsRedux?.first_name
        if(userDetailsRedux?.last_name)
        name += " " + userDetailsRedux?.last_name
        return name ?? 'Unknown User'
    }, [userDetailsRedux])

    return (
        <div className={`unstruct-talkdata-container talk-index-main talkData-wrapper ${isFloating ? 'floating-talkdata-main-wrapper' : 'normal-talkdata-main-wrapper'}`}>
            {
                isFloating ? ''
                :
                <LandingpageFirstHeader title={'Unstructured TALKdata'} isPulse={true} ref={headerRef} />
            }
            <div className='pulse-talk-wrap'>
                <TalkDataQuestionHeader
                    activeDomain={activeDomain}
                    setActiveDomain={setActiveDomain}
                    setSearchFocus={setSearchFocus}
                    usersList={usersList}
                    usersLoading={usersLoading}
                    questValue={questValue}
                    setQuestValue={setQuestValue}
                    chatData={chatData}
                    query={
                        (() => {
                            if(!query || query?.trim() === '') 
                            return "Let's talk, ask me anything about data"
                            else return query
                        })()
                    }
                    onSend={() => {
                        setSearchFocus(false)
                        startConversation()
                    }}
                    fetchingConv={fetchingConv}
                    fetchingSteps={loadingSteps}
                    stopResponding={() => apiController.abort()}
                    convError={convError}
                    chatError={chatError}
                    ref={searchInputRef}
                    isUnstructured={true}
                />
                    <div 
                    ref={chatWindowRef} 
                    // style={{height: chatWindowHeight ?? undefined}}
                    className={`talk-conv-wrap mx-auto ${loadMore ? 'loading-more-chat' : ''}`}>
                    {
                        
                        loadMore
                        ?   <div className='py-2 d-flex align-items-center justify-content-center color-primary fontSizeHeading load-more-chat-box'>
                                <span className='mr-2'>
                                    <ICON_LOADING_CIRCLE height='14' width='14' color='currentColor' />
                                </span>
                                <span>
                                    Loading more..
                                </span>
                            </div>
                        :   ''
                    }
                    {
                        // convError
                        // ?   <ErrorComponent msg={convError}/>
                        // :   

                        loading || !isReady
                        ?   <ClaristaLoader height='200px' />
                        :

                        chatError?.length > 0
                        ?   getReloadErrorTemplate({ 
                                errorMessage: chatError,
                                hideReloadBtn: true
                            })
                        :
            
                        errMsg?.length > 0 && data?.length === 0 
                        ?   getReloadErrorTemplate({ 
                                errorMessage: errMsg, 
                                onReload: () => {
                                    setPageNo(1)
                                    setLoading(true)
                                    fetchConversations(true)
                                } 
                            })
                        :
            
                        empty && chatError?.length === 0
                        ?   <NoDataComponent 
                                logo={<ICON_CHAT width="60" height="60" />}
                                message="Start asking me questions" />
                        :

                        <div
                            ref={chatScrollRef}
                            className={`talkdata-conv-array-wrap ${isFloating && data?.length === 0 && !(fetchingConv) ? 'mb-auto' : ''} ${chunkData && chunkData !== "" ? 'h-100' : ''}`}
                            onScroll={({currentTarget}) => {
                                onScrollToTop(currentTarget?.scrollTop === 0)
                            }}
                        >
                            {
                                isFloating && data?.length === 0 && !fetchingConv
                                ?
                                <section className='px-3 w-75 mx-auto d-flex flex-column h-100 justify-content-center'>
                                    <div>
                                        <h4 className='cpilot-head-lg fontInterSemiBold text-capitalize'>
                                            Hello {displayUserName()}!
                                        </h4>
                                        <h4 className='cpilot-head-md fontInterSemiBold'>
                                            {
                                                extraPayload?.dashboard_id
                                                ?
                                                "I can help you find information about the data on your dashboard."
                                                :
                                                "I'm here to help you with analyzing your document data!"
                                            }
                                        </h4>
                                    </div>
                                    <div className='p-2'></div>
                                    <div className='pr-5 mb-2'>
                                        <div className='cpilot-simple-msg-card' style={{cursor: 'default'}}>
                                            <div className='align-items-center d-flex fontSizeHeading fontInterSemiBold mb-1'>
                                                {
                                                    extraPayload?.dashboard_id
                                                    ?
                                                    "Want to know more about your data?"
                                                    :
                                                    "Ready to unlock your document's secrets?"
                                                }
                                            </div>
                                            <p className='mb-0 fontSizeLabel text-muted'>
                                                {
                                                    extraPayload?.dashboard_id
                                                    ?
                                                    "What do you want to know? Just ask! I can answer questions about any data on your dashboard"
                                                    :
                                                    "What do you want to analyze? Ask me any question about your document data!"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </section>
                                :
                                isFloating && data?.length === 0 && fetchingConv ? ''
                                :
                                <div className='mb-0' style={data?.length === 0 ? {height: 'calc(100% - 204px'} : {}}></div>
                            }
                            {conversationArray}
                            {
                                !fetchingConv && data?.length !== 0 && queryChoices && queryChoices?.length > 0
                                ?
                                <div className='question-suggestion-stack'>
                                    {
                                        queryChoices && isArray(queryChoices) ? queryChoices?.map((question, i) => (
                                            <div 
                                                key={`suggestedQuest-${i}`}
                                                onClick={() => {
                                                    setQuestValue(question)
                                                    startConversation(question)
                                                }}
                                            >
                                                {question}
                                            </div>
                                        ))
                                        :   ''
                                    }
                                </div>
                                :   ''
                            }
                            {
                                fetchingConv
                                ?
                                <div className={`chat-usr-sender-box user-query position-relative mt-5 mb-3 mx-auto`}>
                                    <div className='chat-user-ask'>
                                        <span className={`chat-tail right`}>
                                            <ICON_CHAT_TAIL/>
                                        </span>
                                        {questValue}
                                    </div>
                
                                    <span className='chat-user-sender-avt'>
                                        <Avatar
                                            size={'default'}
                                            className='text-uppercase'
                                            style={{
                                                backgroundColor: stringToColor(`${userDetailsRedux?.first_name} ${userDetailsRedux?.last_name}`),
                                            }}
                                        >
                                            {userDetailsRedux?.first_name?.charAt(0)}
                                            {userDetailsRedux?.last_name?.charAt(0)}
                                        </Avatar>
                                    </span>
                                </div>
                                :   ''
                            }
                            {
                                fetchingConv
                                ?   <div className='replying-chat-box'>
                                        <span className='replying-avt'>
                                            <ICON_CLARISTA/>
                                        </span>
                                        
                                        {
                                            (chunkData && chunkData !== "") ? 
                                            <div className='unst-chat-content-body'>
                                                <div className='talk-chat-ai-quote-reply border-0 px-0 pb-4'>
                                                    <MarkdownText markdown={chunkData + `<span class='bx bx-flashing bxs-circle ml-1'></span>` ?? ""}/>
                                                    {/* <span className='bx bx-flashing bxs-circle'></span> */}
                                                </div>
                                            </div>
                                            :
                                            <div className='reply-wait-box'>
                                                <ICON_TWO_PART_CIRCLE_BOLD className='bx-spin' color='currentColor' />
                                                Analyzing
                                            </div>
                                        }

                                        {/* <span className='replying-avt-chatbox'>
                                            <span className={`chat-tail left`}>
                                                <ICON_CHAT_TAIL/>
                                            </span>
                                            <div style={{transform: 'scale(.7)', margin: '0 auto'}}>
                                                <div className='dot-typing'></div>
                                            </div>
                                        </span> */}
                                        {/* <span 
                                            ref={replyTimerRef}
                                            className='waiting-reply-tim fontInterSemiBold ml-2'>
                                                <span id='rep-sec'>00</span>.
                                                <span id='rep-mil' className='mr-1' style={{width: 10, overflow: 'hidden'}}>000</span> Sec
                                        </span> */}
                                        {/* <button
                                            className='cancel-chat-btn'
                                            onClick={() => {
                                                apiController.abort()
                                            }}>
                                            <SquareFill color='currentColor' />
                                        </button> */}
                                    </div>
                                :   ''
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default UnstructuredTalkDataIndex

const demoResp = [
    {
        "id": 6930,
        "uuid": "c440d96a-0348-4055-a972-4e9bbc128776",
        "name": "Count of Diabetes Patients by Blood Type",
        "description": "",
        "chat_detail": {
            "id": 2281,
            "name": "Count of Patients for Each Medical Condition",
            "top_question": []
        },
        "pod_ids": [
            75871
        ],
        "dashboard_id": null,
        "data_domain": 1477,
        "data_domain_name": "test11",
        "question": "Blood type give the count of diabetes patients",
        "query": "SELECT blood_type,\n       COUNT(name) AS diabetes_patient_count\nFROM test11.patient_healthcare_records\nWHERE LOWER(medical_condition) LIKE '%diabetes%'\nGROUP BY blood_type",
        "response": "The analysis reveals that the A+ blood type has the highest number of diabetes patients (1213), followed by B+ (1188) and AB+ (1173). This information can help healthcare providers understand the distribution of diabetes patients across different blood types, potentially guiding targeted healthcare strategies and resource allocation.",
        "state": "SUCCESS",
        "debug_step": [],
        "steps_history": [
            {
                "results": "| blood_type   |   diabetes_patient_count |\n|:-------------|-------------------------:|\n| AB+          |                     1173 |\n| B+           |                     1188 |\n| O+           |                     1151 |\n| AB-          |                     1139 |\n| A+           |                     1213 |\n| O-           |                     1122 |\n| A-           |                     1167 |\n| B-           |                     1151 |",
                "objective": "Determine the number of diabetes patients categorized by blood type.",
                "sql_query": "SELECT blood_type,\n       COUNT(name) AS diabetes_patient_count\nFROM test11.patient_healthcare_records\nWHERE LOWER(medical_condition) LIKE '%diabetes%'\nGROUP BY blood_type",
                "sql_modify": true,
                "step_title": "Count of Diabetes Patients by Blood Type",
                "data_summary": "The dataset contains information on diabetes patients categorized by blood type. The counts for each blood type are as follows: A+ (1213), B+ (1188), AB+ (1173), A- (1167), O+ (1151), B- (1151), AB- (1139), and O- (1122).",
                "analysis_and_insights": "The analysis reveals that the A+ blood type has the highest number of diabetes patients (1213), followed by B+ (1188) and AB+ (1173). This information can help healthcare providers understand the distribution of diabetes patients across different blood types, potentially guiding targeted healthcare strategies and resource allocation."
            }
        ],
        "chain_response": {
            "identify_tables": {
                "title": "Count of Diabetes Patients by Blood Type",
                "tables": [
                    "test11.patient_healthcare_records"
                ]
            },
            "persona_identification": {
                "summary": "The user's query involves analyzing patient healthcare records to determine the count of diabetes patients categorized by blood type. While the provided Data Information does not directly address blood type, it does include relevant details such as the categorization of diseases and the count of female diabetes patients per doctor. Additionally, the Verified Questions include inquiries related to diabetes and age groups, which may provide useful context for understanding the distribution of diabetes patients. The user's query can be addressed by grouping the patient records by blood type and filtering for those diagnosed with diabetes.",
                "verified_question": []
            }
        },
        "selected_chart": "funnel",
        "possible_chart": [
            "Column",
            "Line",
            "Bar",
            "Area",
            "Pie",
            "Funnel",
            "WordCloud"
        ],
        "pod_detail": [
            {
                "table_id": 75871,
                "table_name": "patient_healthcare_records",
                "data_domain_id": 1477,
                "domain_table": "test11.patient_healthcare_records",
                "data_domain_name": "test11"
            }
        ],
        "error_msg": null,
        "deleted": false,
        "created_by_user": {
            "id": 66,
            "username": "manish@clarista.io",
            "email": "manish@clarista.io",
            "first_name": "Manish",
            "last_name": "Prajapati"
        },
        "updated_by_user": {
            "id": 2,
            "username": "admin@clarista.co",
            "email": "admin@clarista.co",
            "first_name": "First",
            "last_name": "User"
        },
        "created_on": "2024-11-15T05:15:14.061976Z",
        "updated_on": "2024-11-18T10:24:19.821300Z",
        "permission": [
            "member_datadomain",
            "delete_datadomain",
            "editor_datadomain",
            "add_datadomain",
            "owner_datadomain",
            "view_datadomain",
            "viewer_datadomain",
            "change_datadomain"
        ],
        "tracked": false,
        "bookmark": false,
        "feedback": {}
    },
    {
        "id": 6927,
        "uuid": "c440d96a-0348-4055-a972-4e9bbc128776",
        "name": "Identify Top 3 Diseases with Most Patients",
        "description": "",
        "chat_detail": {
            "id": 2281,
            "name": "Count of Patients for Each Medical Condition",
            "top_question": []
        },
        "pod_ids": [
            75871
        ],
        "dashboard_id": null,
        "data_domain": 1477,
        "data_domain_name": "test11",
        "question": "list top 3 disease with most patients",
        "query": "SELECT medical_condition,\n       COUNT(name) AS patient_count\nFROM test11.patient_healthcare_records\nGROUP BY medical_condition\nORDER BY patient_count DESC\nLIMIT 3",
        "response": "The data indicates that Arthritis, Diabetes, and Hypertension are the most prevalent medical conditions among patients. This suggests a significant demand for healthcare services related to these conditions, highlighting potential areas for targeted healthcare initiatives, resource allocation, and patient education programs.",
        "state": "SUCCESS",
        "debug_step": [],
        "steps_history": [
            {
                "results": "| medical_condition   |   patient_count |\n|:--------------------|----------------:|\n| Arthritis           |            9308 |\n| Diabetes            |            9304 |\n| Hypertension        |            9245 |",
                "objective": "Determine the top 3 most common medical conditions recorded in the patient healthcare records and the number of patients associated with each condition.",
                "sql_query": "SELECT medical_condition,\n       COUNT(name) AS patient_count\nFROM test11.patient_healthcare_records\nGROUP BY medical_condition\nORDER BY patient_count DESC\nLIMIT 3",
                "sql_modify": true,
                "step_title": "Identify Top 3 Diseases with Most Patients",
                "data_summary": "Based on the available dataset, the top 3 most common medical conditions recorded in the patient healthcare records are as follows: Arthritis with 9,308 patients, Diabetes with 9,304 patients, and Hypertension with 9,245 patients.",
                "analysis_and_insights": "The data indicates that Arthritis, Diabetes, and Hypertension are the most prevalent medical conditions among patients. This suggests a significant demand for healthcare services related to these conditions, highlighting potential areas for targeted healthcare initiatives, resource allocation, and patient education programs."
            }
        ],
        "chain_response": {
            "identify_tables": {
                "title": "Identify Top 3 Diseases with Most Patients",
                "tables": [
                    "test11.patient_healthcare_records"
                ]
            },
            "persona_identification": {
                "summary": "To address the user's query about identifying the top 3 diseases with the most patients, the following data information and synonyms are relevant: 'Diseases Y-Axis' suggests that diseases should be displayed on the Y-axis in visualizations, which implies that diseases are a key focus in the data analysis. Additionally, the 'Name Uppercase' information indicates that names are provided in uppercase, except for doctor names, which may be relevant if the query involves displaying patient names. While the synonyms provided do not directly relate to the query, they could be useful if the conversation involved terms like 'ayurveda', 'body care', or 'skincare'. It's also important to consider generic personas such as units of measurement, currency preferences, or formatting guidelines, although they are not directly mentioned in the data information.",
                "verified_question": []
            }
        },
        "selected_chart": "Column",
        "possible_chart": [
            "Column",
            "Line",
            "Bar",
            "Area",
            "Pie",
            "Funnel",
            "WordCloud"
        ],
        "pod_detail": [
            {
                "table_id": 75871,
                "table_name": "patient_healthcare_records",
                "data_domain_id": 1477,
                "domain_table": "test11.patient_healthcare_records",
                "data_domain_name": "test11"
            }
        ],
        "error_msg": null,
        "deleted": false,
        "created_by_user": {
            "id": 66,
            "username": "manish@clarista.io",
            "email": "manish@clarista.io",
            "first_name": "Manish",
            "last_name": "Prajapati"
        },
        "updated_by_user": {
            "id": 2,
            "username": "admin@clarista.co",
            "email": "admin@clarista.co",
            "first_name": "First",
            "last_name": "User"
        },
        "created_on": "2024-11-14T12:24:04.181034Z",
        "updated_on": "2024-11-15T09:32:31.879327Z",
        "permission": [
            "member_datadomain",
            "delete_datadomain",
            "editor_datadomain",
            "add_datadomain",
            "owner_datadomain",
            "view_datadomain",
            "viewer_datadomain",
            "change_datadomain"
        ],
        "tracked": false,
        "bookmark": false,
        "feedback": {}
    },
    {
        "id": 6923,
        "uuid": "c440d96a-0348-4055-a972-4e9bbc128776",
        "name": "Identify the most common diseases from patient records",
        "description": "",
        "chat_detail": {
            "id": 2281,
            "name": "Count of Patients for Each Medical Condition",
            "top_question": []
        },
        "pod_ids": [
            75871
        ],
        "dashboard_id": null,
        "data_domain": 1477,
        "data_domain_name": "test11",
        "question": "List most common diseases",
        "query": "SELECT medical_condition,\n       COUNT(name) AS patient_count\nFROM test11.patient_healthcare_records\nGROUP BY medical_condition\nORDER BY patient_count DESC",
        "response": "The analysis reveals that Arthritis, Diabetes, and Hypertension are the top three most prevalent medical conditions among patients. This suggests a significant portion of healthcare resources may be directed towards managing these chronic conditions. Understanding the prevalence of these diseases can help in resource allocation, preventive measures, and tailored healthcare services to address these common health issues effectively.",
        "state": "SUCCESS",
        "debug_step": [],
        "steps_history": [
            {
                "results": "| medical_condition   |   patient_count |\n|:--------------------|----------------:|\n| Arthritis           |            9308 |\n| Diabetes            |            9304 |\n| Hypertension        |            9245 |\n| Obesity             |            9231 |\n| Cancer              |            9227 |\n| Asthma              |            9185 |",
                "objective": "Determine the most common diseases by counting the number of patients associated with each medical condition and identifying those with the highest counts.",
                "sql_query": "SELECT medical_condition,\n       COUNT(name) AS patient_count\nFROM test11.patient_healthcare_records\nGROUP BY medical_condition\nORDER BY patient_count DESC",
                "sql_modify": true,
                "step_title": "Identify the Most Common Diseases",
                "data_summary": "The dataset contains information on patient healthcare records, focusing on medical conditions. The most common diseases identified, based on patient count, are as follows: Arthritis (9308 patients), Diabetes (9304 patients), Hypertension (9245 patients), Obesity (9231 patients), Cancer (9227 patients), and Asthma (9185 patients).",
                "analysis_and_insights": "The analysis reveals that Arthritis, Diabetes, and Hypertension are the top three most prevalent medical conditions among patients. This suggests a significant portion of healthcare resources may be directed towards managing these chronic conditions. Understanding the prevalence of these diseases can help in resource allocation, preventive measures, and tailored healthcare services to address these common health issues effectively."
            }
        ],
        "chain_response": {
            "identify_tables": {
                "title": "Identify the most common diseases from patient records",
                "tables": [
                    "test11.patient_healthcare_records"
                ]
            },
            "persona_identification": {
                "summary": "To address the user's query about the most common diseases, the 'Diseases Y-Axis' data information can be relevant as it suggests that diseases should be displayed on the Y-axis in visualizations. This implies that diseases can be categorized and counted to determine their prevalence. Additionally, while not directly related to the user's question, the 'Name Uppercase' information indicates that names are provided in uppercase except for doctor names, which might be useful if the query involves any name-related data processing. No synonyms are directly applicable to this query. The summary includes considerations for categorizing diseases and handling name formatting, which are essential for creating a business-related SQL query.",
                "verified_question": []
            }
        },
        "selected_chart": "Column",
        "possible_chart": [
            "Column",
            "Line",
            "Bar",
            "Area",
            "Pie",
            "Funnel",
            "WordCloud"
        ],
        "pod_detail": [
            {
                "table_id": 75871,
                "table_name": "patient_healthcare_records",
                "data_domain_id": 1477,
                "domain_table": "test11.patient_healthcare_records",
                "data_domain_name": "test11"
            }
        ],
        "error_msg": null,
        "deleted": false,
        "created_by_user": {
            "id": 66,
            "username": "manish@clarista.io",
            "email": "manish@clarista.io",
            "first_name": "Manish",
            "last_name": "Prajapati"
        },
        "updated_by_user": {
            "id": 2,
            "username": "admin@clarista.co",
            "email": "admin@clarista.co",
            "first_name": "First",
            "last_name": "User"
        },
        "created_on": "2024-11-14T12:11:03.088327Z",
        "updated_on": "2024-11-18T10:12:49.552396Z",
        "permission": [
            "member_datadomain",
            "delete_datadomain",
            "editor_datadomain",
            "add_datadomain",
            "owner_datadomain",
            "view_datadomain",
            "viewer_datadomain",
            "change_datadomain"
        ],
        "tracked": false,
        "bookmark": false,
        "feedback": {}
    },
    {
        "id": 6922,
        "uuid": "c440d96a-0348-4055-a972-4e9bbc128776",
        "name": "Count of Patients for Each Medical Condition",
        "description": "",
        "chat_detail": {
            "id": 2281,
            "name": "Count of Patients for Each Medical Condition",
            "top_question": []
        },
        "pod_ids": [
            75871
        ],
        "dashboard_id": null,
        "data_domain": 1477,
        "data_domain_name": "test11",
        "question": "How many patients are there for each medical condition in the patient healthcare records?",
        "query": "SELECT medical_condition,\n       COUNT(name) AS patient_count\nFROM test11.patient_healthcare_records\nGROUP BY medical_condition",
        "response": "The analysis reveals that Arthritis and Diabetes have the highest number of patients, with 9308 and 9304 respectively, indicating these conditions are prevalent among the patient population. Hypertension and Obesity also have significant patient counts, suggesting a need for targeted healthcare strategies. Asthma has the lowest count among the listed conditions, but still represents a substantial patient group. These insights can guide resource allocation and healthcare planning to address the most common conditions effectively.",
        "state": "SUCCESS",
        "debug_step": [],
        "steps_history": [
            {
                "results": "| medical_condition   |   patient_count |\n|:--------------------|----------------:|\n| Hypertension        |            9245 |\n| Obesity             |            9231 |\n| Diabetes            |            9304 |\n| Cancer              |            9227 |\n| Arthritis           |            9308 |\n| Asthma              |            9185 |",
                "objective": "Calculate the number of patients for each medical condition in the patient healthcare records.",
                "sql_query": "SELECT medical_condition,\n       COUNT(name) AS patient_count\nFROM test11.patient_healthcare_records\nGROUP BY medical_condition",
                "sql_modify": true,
                "step_title": "Count Patients by Medical Condition",
                "data_summary": "The dataset contains patient healthcare records with counts for each medical condition. The key findings are: Hypertension (9245 patients), Obesity (9231 patients), Diabetes (9304 patients), Cancer (9227 patients), Arthritis (9308 patients), and Asthma (9185 patients).",
                "analysis_and_insights": "The analysis reveals that Arthritis and Diabetes have the highest number of patients, with 9308 and 9304 respectively, indicating these conditions are prevalent among the patient population. Hypertension and Obesity also have significant patient counts, suggesting a need for targeted healthcare strategies. Asthma has the lowest count among the listed conditions, but still represents a substantial patient group. These insights can guide resource allocation and healthcare planning to address the most common conditions effectively."
            }
        ],
        "chain_response": {
            "identify_tables": {
                "title": "Count of Patients for Each Medical Condition",
                "tables": [
                    "test11.patient_healthcare_records"
                ]
            },
            "persona_identification": {
                "summary": "To address the user's query about the number of patients for each medical condition in the patient healthcare records, the following Data Information and Synonyms can be useful: \n- 'Diseases Y-Axis': This suggests that all diseases should be displayed on the Y-axis in visualizations, indicating that medical conditions are a key focus in the dataset. \n- 'Name Uppercase': Although not directly related to counting patients, it provides a formatting guideline that names are in uppercase except for doctor names, which might be relevant if the query involves displaying patient names. \n- Synonyms: While there are no direct synonyms related to medical conditions, understanding that terms like 'ayurveda' can also be referred to as 'ayurvedic' might be useful if the dataset includes such terms. \n- Generic Information: Consideration of units of measurement, currency preferences, or formatting guidelines might be necessary if the query involves additional data processing or visualization.",
                "verified_question": []
            }
        },
        "selected_chart": "Column",
        "possible_chart": [
            "Column",
            "Line",
            "Bar",
            "Area",
            "Pie",
            "Funnel",
            "WordCloud"
        ],
        "pod_detail": [
            {
                "table_id": 75871,
                "table_name": "patient_healthcare_records",
                "data_domain_id": 1477,
                "domain_table": "test11.patient_healthcare_records",
                "data_domain_name": "test11"
            }
        ],
        "error_msg": null,
        "deleted": false,
        "created_by_user": {
            "id": 66,
            "username": "manish@clarista.io",
            "email": "manish@clarista.io",
            "first_name": "Manish",
            "last_name": "Prajapati"
        },
        "updated_by_user": {
            "id": 2,
            "username": "admin@clarista.co",
            "email": "admin@clarista.co",
            "first_name": "First",
            "last_name": "User"
        },
        "created_on": "2024-11-14T11:45:14.932444Z",
        "updated_on": "2024-11-18T10:12:49.441199Z",
        "permission": [
            "member_datadomain",
            "delete_datadomain",
            "editor_datadomain",
            "add_datadomain",
            "owner_datadomain",
            "view_datadomain",
            "viewer_datadomain",
            "change_datadomain"
        ],
        "tracked": false,
        "bookmark": false,
        "feedback": {}
    }
]