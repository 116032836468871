import React from 'react'
import { useDispatch } from 'react-redux'
import ClaristaLoader from '../claristaLoader/ClaristaLoader'
import { NoDataComponent, getDeleteMessage, getReloadErrorTemplate } from '../../modules/common/helperFunctions'
import './../../modules/dataCatalogue/landingPage/landingPage.scss'
import ConnectionContext from '../../modules/connections/landingPage/components/ConnectionContext'
import { ICON_DICTIONARY, ICON_IMPORT_FROM_DATABASE, ICON_MANUAL_CREATION, ICON_PLUS, ICON_REDIRECT, ICON_SETTINGS } from '../newIconSource'
import NoSearchResultFound from '../../modules/common/NoSearchResultFound'
import { deleteBussinessTerm, getClassificationData, getDictionaryList, getSettingsAdditionalDetailsList, getSettingsDataCategory } from '../../../store/modules/dataDictionary/action'
import DeleteModal from '../deleteModal/DeleteModal'
import { emitToastNotification } from '../../../helpers/toast_helper'
import Buttons from '../button/Buttons'
import { Tooltip } from 'antd'
import ConnectionModal from '../../modules/connections/landingPage/components/ConnectionModal'
import { Literals } from '../../modules/common/literals'
import { useNavigate } from 'react-router-dom'
import '../../modules/dataCatalogue/landingPage/components/CreateButton.scss'
import { DataDictionaryMiniListView } from '../../modules/dataDictionary/components/DataDictionaryMiniListVIew'
import DictionaryLandingPageFilter from '../dictionaryTableFilter/DictionaryLandingPageFilter'

const DictionaryGridIndex = ({
    searchTerm   = '',
    activeDomain = '',
    isDataLoading = false,
    setDataLoading = () => {}
}) => {
    
    const dispatch = useDispatch()
    const history  = useNavigate()

    const [loading, setLoading] = React.useState(false)
    const [error, setError]     = React.useState(undefined)
    const [empty, setEmpty]     = React.useState(false)
    const [data, setData]       = React.useState([])

    const [isButtonActive, setIsButtonActive]               = React.useState(false);
    const [isConnectionModalOpen, setIsConnectionModalOpen] = React.useState(false)

    const [apiCallTime, setApiCallTime]         = React.useState("");
    const [isReady, setIsReady]                 = React.useState(false)
    const [loadMore, setLoadMore]               = React.useState(false)
    const [dataCount, setDataCount]             = React.useState();
    const [stopLazyload, setStopLazyload]       = React.useState(false);
    const [pageNo, setPageNo]                   = React.useState(1)
    const [isSearching, setSearching]           = React.useState(false)
    const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
    const [filterList, setFilterList]           = React.useState({
        category: [], 
        classifiers: [], 
        status: [
            // {id: 0, name: 'PENDING', selected: false},
            {id: 1, name: 'ACTIVE', selected: true},
            {id: 2, name: 'ARCHIVED', selected: false}
        ], 
        addfields: [], 
        pii: false
    })
    const [dataCategoryList, setDataCategoryList]             = React.useState([])
    const [dataClassificationList, setDataClassificationList] = React.useState([])
    const [addFieldList, setAddFieldList]                     = React.useState([])
    
    const searchRef                = React.useRef('')
    const categoryRef              = React.useRef('')
    const classificationRef        = React.useRef('')
    const addfieldsRef             = React.useRef('')
    const currentBusinessTermIdRef = React.useRef({current: {}})
    const statusRef                = React.useRef('ACTIVE')
    const piiRef                   = React.useRef(false)

    const connectionContext = React.useContext(ConnectionContext)
    
    React.useEffect(() => {
        // getDataCategoryList()
        getDataClassificationList()
        getDataAddFieldsList()
    }, [])

    React.useEffect(() => {

        const el = document.querySelector("#main-lpg-wrap")
        if(data?.length > 0) {
            if(el) {
                el?.scrollTo(0, el?.scrollHeight)
                el.classList.add("stop-scroll")
            }
        }
        else {
            if(el) {
                el?.scrollTo(0, 0)
                el.classList.remove("stop-scroll")
            }
        }

        return () => {
            if(el) {
                el.classList.remove("stop-scroll")
            }
        }

    }, [data])

    React.useEffect(() => {
        if (pageNo !== 1) {

            setPageNo(1)
        }
        searchRef.current = searchTerm?.toLowerCase()
        setLoading(true)
        if(activeDomain !== '') getData(true)
        else setLoading(false)
        connectionContext.setFromConnectionRoute(true)

        return () => {
            connectionContext.setConnectionStatus(null)
        }
    }, [searchTerm, activeDomain])

    React.useEffect(() => {
        if(
               filterList?.category?.length === 0 
            || filterList?.classifiers?.length === 0
            || filterList?.addfields?.length === 0
            ) {
            setFilterList(
                {
                    category: dataCategoryList?.map(c => ({id: c?.id, name: c?.name?.toLowerCase(), selected: false})), 
                    classifiers: dataClassificationList?.map(c => ({id: c?.id, name: c?.name?.toLowerCase(), selected: false})),
                    addfields: addFieldList?.map(c => ({
                        id: c?.id, 
                        name: c?.name?.toLowerCase(), 
                        selected: false, 
                        default_value: c?.default_value,
                        options: c?.options,
                        type: c?.type,
                        value: c?.default_value,
                        selectedValues: c?.options?.map(o => ({value: o, selected: false}))
                    })),
                    status: filterList?.status,
                    pii: false
                }
            )
        }
    }, [
        dataCategoryList, 
        dataClassificationList,
        addFieldList
    ])
    
    const getData = (refresh = false) => {

        setDataLoading(true)

        setStopLazyload(false)
        let filterQuery = `&search_term=${searchRef?.current}`
        if(categoryRef?.current?.trim() !== '') {
            filterQuery += `&category_id=${categoryRef?.current}`
        }
        if(classificationRef?.current?.trim() !== '') {
            filterQuery += `&classification_id=${classificationRef?.current}`
        }
        if(addfieldsRef?.current?.trim() !== '') {
            filterQuery += `&${addfieldsRef?.current}`
        }
        if(statusRef?.current?.trim() !== '') {
            filterQuery += `&status=${statusRef?.current}`
        }
        if(piiRef?.current) {
            filterQuery += `&pii=${piiRef?.current}`
        }
        if(activeDomain && activeDomain !== '') {
            filterQuery += `&data_domain_id=${activeDomain}`
        }
        
        const startTime = new Date();
        const pageNumber = refresh ? 1 : pageNo
        dispatch(getDictionaryList(pageNumber, 100, filterQuery, false))
        .then(res => {

            const endTime = new Date();
            setApiCallTime(
                Math.abs(endTime - startTime) / 1000
            );
            if (res.data) {
                if (res.data.has_next === false) {
                    setStopLazyload(true)
                    setLoadMore(false)
                }
            }
            if ((!res.data?.length || res.data?.results?.length <= 0) && pageNo === 1) {
                setDataCount(0)
            }
            if (res.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
                setDataCount(res?.data?.count)
            }

            if (res.data.results.length === 0) {
                if (refresh) {
                    setData([])
                }
                else {
                    setEmpty(true)
                }
                setIsReady(true)
                setSearching(false)
            }
            else {
                if (refresh) {
                    setData([...res.data.results])
                    setIsReady(true)
                    setSearching(false)

                }
                else {
                    setData(prev => [...prev, ...res.data.results])
                }
            }

            setLoading(false)

            setDataLoading(false)
        })
        .catch(err => {
            setEmpty(false)
            setLoading(false)
            setError(err?.message)

            setIsReady(true)
            setSearching(false)

            setDataLoading(false)
        })
    }

    const getDataCategoryList = () => {
        dispatch(getSettingsDataCategory(false))
            .then((res) => {
                let temp = res.data.map((item) =>
                ({
                    ...item,
                    Name: item.name,
                    value: item.name
                }))
                setDataCategoryList([...temp]);
            })
    }

    const getDataClassificationList = () => {
        dispatch(getClassificationData(false))
            .then((res) => {
                let temp = res.data.map((item) =>
                ({
                    ...item,
                    Name: item.name,
                    value: item.name,
                }))
                setDataClassificationList([...temp]);
            })
    }

    const getDataAddFieldsList = () => {
        dispatch(getSettingsAdditionalDetailsList(false))
            .then((res) => {
                let temp = res.data.map((item) =>
                ({
                    ...item,
                    Name: item.name,
                    value: item.name,
                }))
                setAddFieldList([...temp]);
            })
    }

    const onPaginate = (paginate) => {
        if (!stopLazyload) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                getData()
            }
        }
    }

    const onApplyFilter = (filterObj) => {
        const { category, classifiers, status, pii, addfields } = filterObj
        categoryRef.current = category?.filter(c => c?.selected)?.map(v => v?.id)?.join(',') 
        classificationRef.current = classifiers?.filter(c => c?.selected)?.map(v => v?.id)?.join(',')
        statusRef.current = status?.filter(c => c?.selected)?.map(v => v?.name?.toUpperCase())?.join(',')
        piiRef.current = pii

        addfieldsRef.current = addfields?.filter(a => a?.selected).map(a => a?.type?.toLowerCase() !== "dropdown" 
        ? `${a?.name}=${a?.value}` 
        : `${a?.name}=${a?.selectedValues?.filter(s => s?.selected)?.map(v => v?.value)
            ?.join(',')}`)
        ?.join('&')
        
        setLoading(true)
        getData(true) 
    }

    const onResetFilter = () => {
        categoryRef.current = ''
        classificationRef.current = ''
        addfieldsRef.current = ''

        statusRef.current = ''
        piiRef.current = false
        
        setLoading(true)
        getData(true) 
    }

    const openDeleteDialog = (id) => {

        currentBusinessTermIdRef.current = id
        setOpenDeletePopup(true);
    };

    const deleteBusinessTerm = (id) => {
        setLoading(true)
        dispatch(deleteBussinessTerm(id))
        .then(() => {
            getData(true)
            setOpenDeletePopup(false)
        })
        .catch(() => {
            // emitToastNotification('error', 'Failed to delete')
            setLoading(false)
        })
    }

    const onReload = () => {
        setError(undefined)
        onResetFilter()
    }

    return (
        <div className='new-dict-lpg position-relative' style={{height: data?.length === 0 ? '10rem' : 'calc(100vh - 15rem)'}}>
            {
                loading ? <ClaristaLoader height='200px'/>
                :
                error ? getReloadErrorTemplate({errorMessage: error, onReload})
                :
                empty ? <NoDataComponent logo={<ICON_DICTIONARY/>} message={"Click on (+) to Create Display Name"} />
                :
                <>
                    {
                        dataCount !== undefined
                            ? <div className='data-dict-count'>
                                <span className='position-absolute d-flex align-items-center' style={{top: '-0.125rem', left: '-4.875rem'}}>
                                    <DictionaryLandingPageFilter 
                                        filterList={filterList} 
                                        setFilterList={setFilterList} 
                                        showApplyButton={true} 
                                        onApply={onApplyFilter}
                                        onReset={onResetFilter}
                                        reload={
                                            {
                                                category: getDataCategoryList,
                                                classifiers: getDataClassificationList
                                            }
                                        }
                                    />
                                    <Buttons
                                            props={{
                                                tooltip: "Dictionary Settings",
                                                tooltipPlacement: "top",
                                                buttonText: "",
                                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon ml-1`,
                                                buttonEvent: () => { history(Literals.links.DATA_DICTIONARY) },
                                                ImgSrc: () => <ICON_SETTINGS />,
                                                isDisable: false,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                            }}
                                        />
                                </span>
                                <p className='mb-0'>{`${dataCount} results (${apiCallTime} seconds) `}</p>
                            </div>
                            : ''
                    }
                    {
                        searchTerm?.length > 0 && data?.length === 0 
                        ? 
                        <div className='h-100'>
                            <NoSearchResultFound 
                                className='d-flex flex-column justify-content-center align-items-center' 
                                style={{height: '370px'}} 
                            />
                        </div>
                        :
                        <DataDictionaryMiniListView
                            listData={data}
                            openDeleteDialog={openDeleteDialog}
                            searchData={searchTerm}
                            onScrollToBottom={onPaginate}
                            loadMore={loadMore}
                            activeDomain={activeDomain}
                        />
                    }

                    {/* <DataDictionaryGridView
                        searchData={searchTerm}
                        data={data}
                        loadMore={loadMore}
                        onScrollToBottom={onPaginate}
                        fromRoute='new-landing'
                        openDeleteDialog={openDeleteDialog}
                    /> */}
                </>    
            }

            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                body={getDeleteMessage({
                    title: 'Display Name'
                })}
                onConfirm={() => deleteBusinessTerm(currentBusinessTermIdRef?.current)}
                extraContent={<>
                    <div className='mt-3 bg-light rounded p-2 shadow-sm border'>
                        <div className='fontSizeHeading fontInterSemiBold label-color'>
                            Related POD's
                        </div>
                        <hr className='my-2'/>
                        <div style={{maxHeight: 168, overflowY: 'auto'}}>
                            {
                                data?.find(d => d?.id?.toString() === currentBusinessTermIdRef?.current?.toString())
                                ?.col_term?.length === 0
                                ?
                                <div className='mb-1 py-1 fontSizeLabel text-uppercase text-center text-muted'>
                                    <span>No POD's Found</span>
                                </div>
                                :
                                data?.find(d => d?.id?.toString() === currentBusinessTermIdRef?.current?.toString())
                                ?.col_term
                                ?.map(c => (
                                    <div key={`rel-pod-${c?.table_id}`} 
                                        className='mb-1 py-1 fontSizeLabel text-uppercase d-flex align-items-center'>
                                        <span>{c?.table_name}</span>
                                        <span 
                                            className='ml-1 color-primary cursor-pointer' 
                                            title='Click to open'
                                            onClick={() => {
                                                window.open(Literals?.links?.DATA_CATALOG_POD_DETAILS + c?.table_id)
                                            }}
                                            >
                                            <ICON_REDIRECT height='12' width='12' color='currentColor' />
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>}
            />

            <div className="landingPage data-dict">
                <div className="options-wrapper">
                    <Buttons
                        props={{
                            buttonText: "",
                            buttonClassName: `custom-btn-primary custom-btn btn-with-text fixed-button addbutton_plusicon ${isButtonActive ? "rotate-icon" : ""
                                }`,
                            buttonEvent: () => {
                                setIsButtonActive((isButtonActive) => !isButtonActive);
                            },
                            tooltip: "Add",
                            tooltipPlacement: "left",
                            ImgSrc: () => <ICON_PLUS />,
                            isDisable: false,
                            buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT,
                        }}
                    />
                    <ul
                        className={`add-options-list ${isButtonActive ? "active-list" : ""}`}
                    >
                        <Tooltip placement="left" title="Import from Database">
                            <li onClick={() => history(Literals.links.DATA_DICTIONARY_IMPORT_DB)}>
                            {/* <li onClick={() => setIsConnectionModalOpen(true)}> */}
                                <ICON_IMPORT_FROM_DATABASE height='24' width='24' />
                            </li>
                        </Tooltip>
                        <Tooltip placement="left" title="Manual Creation">
                            <li onClick={() => history(Literals.links.DATA_DICTIONARY_MANUAL_CREATION)}>
                                <ICON_MANUAL_CREATION height='24' width='24' />
                            </li>
                        </Tooltip>
                    </ul>
                </div>
            </div>
            <ConnectionModal
                isModalOpen={isConnectionModalOpen}
                onClose={() => setIsConnectionModalOpen(false)}
                isRedirect={true}
            />
        </div>
    )
}

export default DictionaryGridIndex