import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import LoadingComponent from "../../common/loadingComponent";
import UserPageCardView from "../users/components/UserPageCardView";
import UserPageListView from "./components/UserPageListView";
import {
  getUserGroupList,
  setUsersListRefresh,
} from "../../../../store/modules/UserManagement/users/usersActions";
import Buttons from "../../../Common/button/Buttons";
import { Literals } from "../../common/literals";
import { PLUS } from "../../../Common/iconSource";
import CreateNewUserModal from "./components/CreateNewUserModal";
import _ from "lodash";
import { ErrorComponent } from "../../common/helperFunctions";
import NoResultsFound from "../../flows/createFlows/NoResultsFound";
import NoSearchResultFound from "../../common/NoSearchResultFound";

const intialFieldsState = {
  firstName: "",
  lastName: "",
  email: "",
  designation: "",
  isSuperUser: false,
  id: "",
};

const filterUserType = {
  normal: 'Normal User',
  super: 'Super User',
  active: 'Active User',
  inactive: 'Inactive User'
}

const Users = (props) => {
  const loadingStatus = useSelector(
    (state) => state.LoadingReducer.loadingStatus
  );
  const { selectedFilters, filterList, setSelectedFilters, setParentUsersList } = props;
  const [usersList, setUsersList] = useState([]);
  const [dataClone, setDataClone] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [isEmpty, setIsEmpty] = useState(undefined);
  const refresh = useSelector(
    (state) => state.UserManagement.Users.usersListRefresh
  );
  const isSuperUser = useSelector(state => state?.UserReducer?.user?.userDetails?.is_superuser)
  const userFilter = useSelector((state) => state.App)



  // useEffect(()=>{
  //   setUsersList(users);
  // },[users])
  const [userDataFields, setUserDataFields] = useState(intialFieldsState);

  const [viewMode, setViewMode] = useState("create");
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  const dispatch = useDispatch();
  const store = useStore();

  React.useEffect(() => {
    let data = [...usersList]
    
    if (filterList?.user_types?.some(u => u?.selected)) {
      data = data?.filter(d => {
        let activeTypes = filterList?.user_types?.filter(f => f?.selected)?.map(n => n?.name)
        /**
         *  nu: Normal User
         *  su: Super User
         *  au: Active User
         *  iu: inactive User
         */
        let nu = false, su = false, au = false, iu = false

        if (activeTypes?.includes(filterUserType.normal)) {
          nu = !d?.is_superuser
        }

        if (activeTypes?.includes(filterUserType.super)) {
          su = d?.is_superuser
        }

        if (activeTypes?.includes(filterUserType.active)) {
          au = d?.is_verified
        }

        if (activeTypes?.includes(filterUserType.inactive)) {
          iu = !d?.is_verified
        }

        if (activeTypes?.includes(filterUserType?.normal) || activeTypes?.includes(filterUserType.super)) {
          if(au && iu) {
            return [nu, su]?.some(b => b)
          }
          return [au, iu]?.some(b => b) || [nu, su]?.some(b => b)
        }

        return [au, iu]?.some(b => b)
      })
    }
    if (filterList?.persona?.some(u => u?.selected)) {
      data = data?.filter(d => filterList?.persona?.filter(u => u?.selected)?.map(n => n?.name?.toLowerCase())?.includes(d?.designation?.toLowerCase() ?? ''))
    }
    setDataClone([...data])
  }, [filterList, usersList])

  const openCreateUserModalHandler = () => {
    setIsCreateUserModalOpen(true);
  };

  const closeCreateUserModalHandler = () => {
    setIsCreateUserModalOpen(false);
    setUserDataFields(intialFieldsState);
    setViewMode("create");
  };

  const onInputChangeHandler = (field, value) => {

    if(['firstName', 'lastName'].includes(field)) {
      value = value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      });
    }

    setUserDataFields((fields) => {
      return { ...fields, [field]: value };
    });
  };
  const getFormattedData = (allData) => {

    let designation = []
    let favourites = []




    if (selectedFilters.length > 0) {
      selectedFilters.forEach(val => {
        if (val['Designation']) {
          designation = val['Designation'].map(val => val.name) || []
        }
        if (val['emptyheader']) {
          favourites = val['emptyheader'].map(val => val.name) || []
        }
      })
    }

    let categories = [];
    let designationName = {}
    allData.forEach((el, key) => {
      if (el?.designation) {
        designationName[el?.designation] = el.designation;

      }
    });
    Object.keys(designationName).forEach((name, key) => categories.push({ id: key, checked: designation.includes(name), name: name, category: name }))

    const obj2 = {
      header: 'Designation',
      selectAll: false,
      list: categories
    }
    const obj = {
      header: '',
      list: [{ id: 1, checked: false, name: 'Super User' }]
    }
    return [obj, obj2]
  }
  const onEditUserHandler = (userDetails) => {
    setUserDataFields({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.emailAddress,
      designation: userDetails.designation,
      isSuperUser: userDetails.superuser === "Yes",
      id: userDetails.key,
    });

    setViewMode("edit");
    openCreateUserModalHandler();
  };

  const getUsersList = async () => {
    setIsEmpty(false)
    let response;
    try {
      response = await store.dispatch(
        getUserGroupList({ method: "GET", endPoint: "group_manager/user/" })
      );
      if (response.status === "success") {
        setUsersList(response.data);
        setParentUsersList(response.data);
        setDataClone(response.data)
        setIsEmpty(response?.data?.length === 0)
      }
    } catch (error) {
      setErrMsg(error.message)
    }
  };

  // const filterData = (responseData = []) => {
  //   let designation = []
  //   let superuser = []
  //   if (selectedFilters.length > 0) {
  //     selectedFilters.forEach(val => {
  //       if (val['Designation']) {
  //         designation = val['Designation'].map(val => val.name) || []
  //       }
  //       if (val['emptyheader']) {
  //         superuser = val['emptyheader'].map(val => val.name) || []
  //       }
  //     })
  //   }

  //   let oldObj = responseData && responseData.length ? [...responseData] : [...dataClone]

  //   oldObj.forEach(val => {

  //     if (superuser.length) {
  //       oldObj = oldObj.filter(a => a.is_superuser)
  //     }
  //     if (designation.length) {
  //       oldObj = oldObj.filter(a => {
  //         return designation.includes(a.designation)
  //       })
  //     }

  //   })
  //   if (selectedFilters.length) {
  //     return (oldObj)
  //   }
  //   //setAlertData(oldObj)
  //   else {
  //     return (responseData && responseData.length ? [...responseData] : [...dataClone])
  //   }
  //   //setAlertData(dataClone)

  // }
  useEffect(() => {
    getUsersList();
  }, []);
  
  useEffect(() => {
    if (refresh) {
      dispatch(setUsersListRefresh(false));
      getUsersList();
    }
  }, [refresh]);

  const layOutReturn = () => {
    if (errMsg.length) {
      return <ErrorComponent msg={errMsg} />
    }
    return props.layout === "grid" ? (
      <div>
        {
          dataClone?.length === 0 
          && 
          (filterList?.user_types?.some(u => u?.selected) || filterList?.persona?.some(u => u?.selected)) 
          ?
          <NoSearchResultFound/>
          :
          <UserPageCardView
            usersList={dataClone}
            getUsersList={getUsersList}
            searchData={props.setSearchData}
            onEditUser={onEditUserHandler}
            isEmpty={isEmpty}
          />
        }
      </div>
    ) : (
          dataClone?.length === 0 
          && 
          (filterList?.user_types?.some(u => u?.selected) || filterList?.persona?.some(u => u?.selected)) 
          ?
          <NoSearchResultFound/>
          :
          <UserPageListView
            usersList={dataClone}
            getUsersList={getUsersList}
            searchData={props.setSearchData}
            onEditUser={onEditUserHandler}
            isEmpty={isEmpty}
          />
    );
  };

  return (
    <div>
      {layOutReturn()}

      <Buttons
        props={{
          tooltip: "Create User",
          buttonText: "",
          buttonClassName:
            "custom-btn-primary custom-btn fixed-button",
          buttonEvent: () => {
            openCreateUserModalHandler();
          },
          ImgSrc: () => <PLUS />,
          isDisable: !isSuperUser,
          buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
        }}
      />

      <CreateNewUserModal
        isModalOpen={isCreateUserModalOpen}
        closeModal={closeCreateUserModalHandler}
        viewMode={viewMode}
        userData={userDataFields}
        onChange={onInputChangeHandler}
      />

      {loadingStatus ? <LoadingComponent /> : null}
    </div>
  );
};
export default Users;
