import React from 'react'
import ChatGridView from './ChatGridView'
import '../genAi.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getChatList } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { ContentViewGridTable } from '../../../Common/contentGridTable/ContentViewGridTable'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import { NoDataComponent, getReloadErrorTemplate, pxToRem, remToPx } from '../../common/helperFunctions'
import NoSearchResultFound from '../../common/NoSearchResultFound'
import { ICON_CHAT, ICON_SUPPORT } from '../../../Common/newIconSource'
import { getUserGroupList } from '../../../../store/modules/UserManagement/userGroup/UMActions'
import { Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Literals } from '../../common/literals'

const ChatGridViewIndex = ({
    activeDomain = '',
    searchTerm = '',
    showFavourite = false,
    fullHeight = false,
    isUnstructured = false
}) => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const [data, setData]                 = React.useState([])
    const [empty, setEmpty]               = React.useState(false);
    const [pageNo, setPageNo]             = React.useState(1)
    const [loading, setLoading]           = React.useState(false)
    const [errMsg, setErrorMsg]           = React.useState("");
    const [loadMore, setLoadMore]         = React.useState(false)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [usersLoading, setUsersLoading] = React.useState([])    
    const [usersList, setUsersList]       = React.useState([])
    const [shortWindow, setShortWindow]   = React.useState(false)

    const searchRef = React.useRef('')

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)
    // For Future Use
    // React.useEffect(() => {
    //     getUsersList()
    // }, [])

    React.useEffect(() => {
        const el = document.querySelector('.pulse-landing-wrap')
        if(el) {
            setShortWindow(el.offsetWidth <= 1300) 
        }
    }, [])

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }
        searchRef.current = searchTerm?.toLowerCase()
        setLoading(true)
        fetchChatList(true)
    }, [activeDomain, searchTerm, showFavourite])

    const fetchChatList = (reset = false) => {
        if(!activeDomain || activeDomain === '') {
            setLoading(false)
            return
        }

        setStopLazyload(false)
        setErrorMsg(undefined)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 25
        const search     = searchRef?.current?.trim() ?? ''

        dispatch(getChatList(
            activeDomain, 
            search, 
            showFavourite ? true : '', 
            isUnstructured,
            pageNumber, 
            pageLimit, 
            data?.length > 0
        ))
        .then(res => {
            const dat = res?.data

            if(dat) {
                if(dat?.results) {
                    setData(prev => reset ? [...dat?.results] : [...prev, ...dat?.results])
                }
    
                if (dat?.has_next === false) {
                    setStopLazyload(true)
                    setLoadMore(false)
                }
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }

            setEmpty(dat?.results?.length === 0)
            setLoading(false)
        })
        .catch(err => {
            console.error({err})
            setErrorMsg(err?.message)
            setEmpty(false)
            setLoading(false)
            setLoadMore(false)
        })
    }

    /***LOCALLY FILTERING OF DATA***/
    // const chatData = React.useMemo(() => {
    //     const FILTER_COLUMNS = ["name", "description", "updated_by_user"]
    //     const clone = [...data]
    //     let dat = clone?.filter(item => {
    //         return FILTER_COLUMNS?.some(key => {
    //             let data = item[key]
    //             if (key === 'updated_by_user') {
    //                 data = item[key]?.email
    //             }
    //             return data?.toLowerCase()?.includes(searchTerm.toLowerCase())
    //         })
    //     })

    //     if(showFavourite) {
    //         return dat?.filter(c => c?.bookmark)
    //     }

    //     return dat
    // }, [data, showFavourite, searchTerm])

    const getUsersList = () => {
        setUsersLoading(true)
        dispatch(getUserGroupList({ method: "GET", endPoint: "profile/", payload: undefined, showLoader: false }))
        .then(res => {
            let list = res?.data?.map(ele => ({ name: `${ele?.first_name} ${ele?.last_name}`, value: ele?.id, email: ele?.email }))
            setUsersList([...list]);
            setUsersLoading(false)
        })
        .catch(err => {
            setUsersLoading(false)
            console.error(err)
        })
    };

    const onScrollToBottom = (paginate) => {
        if (!stopLazyload) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchChatList()
            }
        }
    }

    return (
        <div className={`chat-landing p-0 ${shortWindow ? 'short' : 'long'} ${fullHeight ? 'full-height' : ''}`}>
        {/* <div className='chat-gridview-responsive new-box-style p-0'> */}
        {
            loading 
            ?   <ClaristaLoader height='200px' />
            :

            errMsg?.length > 0 && data?.length === 0 
            ?   getReloadErrorTemplate({ 
                    errorMessage: errMsg, 
                    onReload: () => {
                        setLoading(true)
                        fetchChatList(true)
                    } 
                })
            :

            empty && !showFavourite
            ?   <NoDataComponent 
                    logo={<ICON_CHAT width="60" height="60" />}
                    message="No chat(s) found, Please select another domain" />
            :

            ((searchTerm?.length > 0 || showFavourite) && data?.length === 0) 
            ?   <NoSearchResultFound />
            :

            <ContentViewGridTable
                key={`chat-grd-idx-${showFavourite}`}
                colsNumber={
                    (() => {
                        const wd = window?.innerWidth
                        if(wd > 1920) return 8
                        if(wd > 1440) return 4
                        return shortWindow ? 3 : 4
                    })()
                }
                data={
                    data?.map((item, index) => (
                        <ChatGridView
                            key={`chat-${index}`}
                            data={item}
                            activeDomain={activeDomain}
                            reload={() => {
                                setPageNo(1)
                                setLoading(true)
                                fetchChatList(true)
                            }}
                            searchData={searchTerm}
                            showFavourite={showFavourite}
                            setData={setData}
                            isUnstructured={isUnstructured}
                        />
                    ))
                }
                rowHeight={remToPx(pxToRem(150))}
                onScrollToBottom={onScrollToBottom}
                loadMore={loadMore}
            />
        }
            {/* {
                datadomainList && 
                datadomainList?.find(d => d?.data_domain_id === Number(activeDomain))?.permission?.includes('editor_datadomain')
                ?
                    <div className='talk-trace-float-btn'
                        onClick={() => {
                            history(Literals.links.PULSE_TALK_TRACE)
                        }}
                    >
                        <ICON_SUPPORT color='currentColor'/>
                        Clarify
                    </div>
                :   ''
            } */}
        </div>
    )
}

export default ChatGridViewIndex