import { Checkbox, Empty, Popover, Tooltip } from 'antd';
import React from 'react'
import { ICON_ARROW_RIGHT, ICON_CATALOG, ICON_EYEOPEN, ICON_FILE } from '../../newIconSource';
import SearchInput from '../../search/Search';
import ClaristaLoader from '../../claristaLoader/ClaristaLoader';
import { getReloadErrorTemplate } from '../../../modules/common/helperFunctions';
import Buttons from '../../button/Buttons';
import { Literals } from '../../../modules/common/literals';

const PulseSourceDropdown = ({
    sourceOpen = false,
    setSourceOpen = () => {},
    activeSource,
    SOURCES,
    sourceContentSearch,
    setSourceContentSearch = () => {},
    sourceContentLoading,
    sourceContentError,
    sourceContentEmpty,
    fetchSourcePopOverContent,
    sourcePopoverList,
    sourceContentAll,
    onContentCheckAll,
    onContentCheck,
    handlePreview
}) => {
    return (
        <>
            <Popover
                open={sourceOpen}
                onOpenChange={open => setSourceOpen(open)}
                placement='bottomLeft'
                arrow={false}
                trigger={'hover'}
                title={<></>}
                content={
                    <div className='source-pop-wrap'>
                        <div className='py-2 d-flex align-items-center justify-content-between source-pop-head'>
                            <div className='d-flex align-items-center text-dark'>
                                <span className='mr-1 d-flex'>
                                    {
                                        activeSource === SOURCES.pod
                                        ?
                                        <ICON_CATALOG height='1rem' width='1rem' color='currentColor'/>
                                        :
                                        <ICON_FILE height='1rem' width='1rem' color='currentColor'/>
                                    }
                                </span>
                                <label className='mb-0 fontSizeHeading fontInterMedium'>
                                    {   
                                        activeSource === SOURCES.pod ? "PODs" : "Documents"
                                    }
                                </label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <SearchInput
                                    searchData={sourceContentSearch}
                                    setSearchData={setSourceContentSearch}
                                />
                            </div>
                        </div>
                        <div className='source-pop-body py-2'>
                            {
                                sourceContentLoading ? <ClaristaLoader/>
                                :   sourceContentError ? getReloadErrorTemplate({errorMessage: sourceContentError, onReload: () => fetchSourcePopOverContent()})
                                    :   sourceContentEmpty || (sourcePopoverList?.length === 0 && sourceContentSearch !== "") ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={sourceContentSearch !== "" ? 'No Data Found' :  `NO ${activeSource?.toUpperCase()} FOUND`} />
                                        :
                                        <div className='source-content-grid'>
                                            {
                                                sourceContentSearch === ""
                                                ?
                                                <div>
                                                    <div className='d-flex align-items-start'>
                                                        <div className='mr-2'>
                                                            <Checkbox
                                                                checked={sourceContentAll}
                                                                onChange={(e) => onContentCheckAll(e)}
                                                            />
                                                        </div>
                                                        <div className='d-flex flex-column' style={{width: 'calc(100% - 2.5rem)'}}>
                                                            <div className='mb-1 fontSizeLabel text-dark fontInterMedium text-with-ellipsis text-uppercase' style={{}}>{`ALL ${activeSource === SOURCES.pod ? "PODS" : activeSource?.toUpperCase()}`}</div>
                                                            <div className='text-black-50 fontSizeLabel custom-ellipsis two-line-ellipsis'>
                                                                {`List of all published ${activeSource?.toUpperCase()}.`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :   ""
                                            }
                                            {
                                                sourcePopoverList?.map((itm, idx) => (
                                                    <React.Fragment key={`${idx}-source-cont`}>
                                                        <div>
                                                            <div className='d-flex align-items-start position-relative source-tile-wrap'>
                                                                <div className='mr-2'>
                                                                    <Checkbox
                                                                        checked={itm?.checked}
                                                                        onChange={(e) => onContentCheck(e, itm?.id)}
                                                                    />
                                                                </div>
                                                                <div className='d-flex flex-column' style={{width: 'calc(100% - 2.5rem)'}}>
                                                                    <Tooltip title={itm?.title?.toUpperCase()} placement='topLeft' mouseEnterDelay={0.6}>
                                                                        <div className='mb-1 fontSizeLabel text-dark fontInterMedium text-with-ellipsis text-uppercase' style={{}}>{itm?.title}</div>
                                                                    </Tooltip>
                                                                    <Tooltip title={itm?.description ?? ""} placement='topLeft' mouseEnterDelay={0.6}>
                                                                        <div className='text-black-50 fontSizeLabel custom-ellipsis two-line-ellipsis'>
                                                                            {!itm?.description || itm?.description === "" ? <em>No Description Provided</em> : itm?.description}
                                                                        </div>
                                                                    </Tooltip>
                                                                    <Buttons
                                                                        props={{
                                                                            tooltip: 'Preview',
                                                                            tooltipPlacement: 'right',
                                                                            buttonClassName: "custom-btn custom-btn-outline btn-with-icon bg-transparent border-0 source-preview-btn",
                                                                            buttonEvent: (e) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                                handlePreview(itm)
                                                                            },
                                                                            ImgSrc: () => <ICON_EYEOPEN width='20' height='20' color='#333333'/>,
                                                                            isDisable: false,
                                                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div>
                            }
                        </div>
                    </div>
                }
            >
                <label className='d-flex align-items-center fontSizeHeading mb-0 px-1 py-1'>
                    Source
                    <span className='ml-2 bx-rotate-90 d-flex'><ICON_ARROW_RIGHT height='.6rem' width='.6rem' color='currentColor' /></span>
                </label>
            </Popover>
        </>
    )
}

export default PulseSourceDropdown