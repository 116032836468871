import { Checkbox, Collapse, Popover, Tooltip } from 'antd'
import React from 'react'
import { ICON_ALERT, ICON_ARROW_RIGHT, ICON_FUNCTION, ICON_LINE_ARROW_RIGHT } from '../../../Common/newIconSource'
import { ICON_AI_MAGIC } from './TalkDataIndex'

const FilterCalculations = ({
    dimensions = [],
    metrics = [],
    columns = [],
    data = {},
    setDimensions = () => {},
    setMetrics = () => {},
    setColumns = () => {},
    isEditable = false,
    retryQuery = () => {},
    fetchingConv = false,
}) => {

    // React.useEffect(() => {
    //     const askQuestionInputElement = document?.querySelector('.pulse-search-container')
    //     if(isEditable) {
    //         if(askQuestionInputElement) {
    //             askQuestionInputElement?.classList?.add('d-none')
    //         }
    //     }
    //     else {
    //         if(askQuestionInputElement) {
    //             askQuestionInputElement?.classList?.remove('d-none')
    //         }
    //     }
    // }, [isEditable])

    const handleDimensionColValueCheck = React.useCallback((dimension, colObject, colValue, checked) => {
        let colName = colObject?.column_name
        let colValues = colObject?.column_value

        colValues = colValues?.map(c => (c?.value === colValue ? {...c, checked} : {...c}))
        
        setDimensions(prev => {
            prev = prev?.map(dim => ( dim?.user_value === dimension?.user_value ? {...dim, columns: dim?.columns?.map(d => (d?.column_name === colName ? {...d, column_value: colValues} : {...d}))} : {...dim}))
            return [...prev]
        })
    
    }, [dimensions])

    const handleColumnsValueCheck = React.useCallback((columns, colObject, colValue, checked) => {

        setColumns(prev => {
            prev = prev?.map(itm => ( itm?.user_value === columns?.user_value ? {...itm, columns: itm?.columns?.map(d => (d?.value === colValue ? {...d, checked} : {...d}))} : {...itm}))
            return [...prev]
        })
    
    }, [columns])

    const handleColumnsSelectAllCol = React.useCallback((colObject, checked) => {
        let userValue = colObject?.user_value

        setColumns(prev => {
            prev = prev?.map(itm => ( itm?.user_value === userValue ? {...itm, columns: itm?.columns?.map(d => ({...d, checked}))} : {...itm}))
            return [...prev]
        })
    
    }, [columns])

    const handleDimensionSelectAllCol = React.useCallback((colObject, colName, checked) => {
        let userValue = colObject?.user_value

        setDimensions(prev => {
            prev = prev?.map(dim => ( dim?.user_value === userValue ? {...dim, columns: dim?.columns?.map(d => ({...d, column_value: d?.column_name === colName ? d?.column_value?.map(c => ({...c, checked})) : d?.column_value}))} : {...dim}))
            return [...prev]
        })
    
    }, [dimensions])

    const Metrics = () => {
        return <>
            <div className='filter-calc-metrics-grid mb-4' style={{display: !metrics || metrics?.length === 0 ? 'none' : undefined}}>
                {
                    metrics?.filter(m => m?.columns?.length)?.map((m, i) => (
                        <React.Fragment key={`metric-${m?.user_value}-${i}`}> 
                            <div>
                                <div className='metric-body-wrap'>
                                    <div className='filter-calc-metric-header mb-2 d-flex justify-content-between'>
                                        <label title={m?.user_value} className='fontSizeLabel text-color-grey text-uppercase mb-0 fontInterSemiBold custom-ellipsis two-line-ellipsis'>
                                            {m?.user_value}
                                        </label>
                                        <div className='d-flex position-relative align-items-start' style={{top: '-0.1875rem'}}>
                                            {
                                                m?.ai_interpreted
                                                ?   <span className='ai-magic-ic ml-1 d-flex align-items-center'>
                                                        <ICON_AI_MAGIC/>
                                                    </span>
                                                :   ""
                                            }

                                            <Popover
                                                trigger={'hover'}
                                                placement='right'
                                                arrow={true}
                                                title={
                                                    <div className='px-3 py-1 fontSizeLabel fontInterMedium'>Formula</div>
                                                }
                                                content={
                                                    <div className='fontSizeLabel text-color-grey px-3 py-2 border-top' style={{minWidth: '10rem', maxWidth: '17rem', wordWrap: "break-word"}}>
                                                        {m?.formula}
                                                    </div>
                                                }
                                            >
                                                <>
                                                    <button className='custom-btn-outline custom-btn btn-with-icon bg-transparent border-0 ml-1'>
                                                        <ICON_FUNCTION/>
                                                    </button>
                                                </>
                                            </Popover>
                                        </div>
                                    </div>
                                    <div className='filter-calc-metric-body'>
                                        <div className='d-flex align-items-start' style={{}}>
                                            {/* <div className='d-flex mr-2 mb-auto' style={{paddingTop: '2px'}}><ICON_COLUMN height='1rem' width='1rem' /></div> */}
                                            <div className='d-flex align-items-start flex-wrap' style={{gap: '.25rem'}}>
                                                {
                                                    m?.columns?.map((c, idx) => (
                                                        <React.Fragment
                                                            key={`${m?.user_value}-${c}-${idx}`} 
                                                        >
                                                            <Tooltip title={c} placement='topLeft'>
                                                                <span 
                                                                    // title={c}
                                                                    className='metric-cols fontSizeLabel px-1 py-1 rounded'
                                                                    style={{background: '#f0f0f0', lineHeight: 1}}
                                                                >
                                                                    {c}
                                                                </span>
                                                            </Tooltip>
                                                            {/* {
                                                                idx !== (m?.columns?.length - 1)
                                                                ?   <div className='vertical-separator mx-2' style={{height: '.8rem'}}></div>
                                                                :   ''
                                                            } */}
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        {/* <div className='mt-2 metric-body-foot'>
                                            <Popover
                                                trigger={'click'}
                                                placement='bottomLeft'
                                                arrow={false}
                                                title={
                                                    <div className='px-3 py-1 fontSizeLabel fontInterMedium'>Formula</div>
                                                }
                                                content={
                                                    <div className='fontSizeLabel text-color-grey px-3 py-2 border-top' style={{minWidth: '10rem', maxWidth: '17rem'}}>
                                                        {m?.formula}
                                                    </div>
                                                }
                                            >
                                                <>
                                                    <button className='custom-btn-outline custom-btn btn-with-text border'>
                                                        Show Formula
                                                    </button>
                                                </>
                                            </Popover>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
        </>
    }

    const dimensionsView = React.useMemo(() => {
        if(data?.state === "CLARIFICATION") {
            let arr = [...dimensions]
            
            let res = []

            arr?.forEach(el => {
                if(el?.score?.toString() !== "100" && el?.columns?.length === 1) {
                    let c = el?.columns?.filter(c => c?.column_value?.length > 1)
                    if(c?.length > 0) {
                        res?.push({...el, columns: c})
                    }
                }
                else if(el?.score?.toString() !== "100" && el?.columns?.length > 1) {
                    res?.push({...el, columns: el?.columns?.filter(c => c?.column_value?.length > 0)})
                }
            })
            
            // res = res?.map(d => ({...d, columns: d?.columns?.filter(c => c?.column_value?.length > 1)}))
            res = res?.filter(d => d?.columns?.length > 0)

            // arr = arr?.map(d => ({...d, columns: d?.columns?.filter(c => c?.column_value?.length > 1)}))
            // arr = arr?.filter(d => d?.columns?.length > 0)
            // return arr

            return res
        }
        return dimensions
    }, [dimensions, data])

    const columnsView = React.useMemo(() => {
        if(data?.state === "CLARIFICATION") {
            let arr = [...columns]
            arr = arr?.filter(d => d?.columns?.length > 1)
            return arr
        }
        return columns
    }, [columns, data])

    const submitBtnDisabled = React.useMemo(() => {
        return !(dimensions?.some(d => d?.columns?.some(c => c?.column_value?.some(v => v?.checked))) || columns?.some(d => d?.columns?.some(c => c?.checked)))
                || fetchingConv
    }, [dimensions, columns, fetchingConv])

    if(!dimensions && !metrics) return <></>
    return (
        <div id={`respFilterWrap-${data?.id}`} className='resp-filter-calculations-wrap mb-2'>
            {
                isEditable
                ?
                <section className='submit-filter-wrap'>
                    <div className='submit-filter-content'>
                        <span className='submit-filter-info d-flex align-items-center'>
                            {
                                !fetchingConv && submitBtnDisabled ?
                                <span className='d-flex mr-2'>
                                    <ICON_ALERT color='currentColor' height='1rem' width='1rem' />
                                </span>
                                :   ''
                            }
                            {
                                fetchingConv ? "Please wait while we are getting the results for you"
                                : submitBtnDisabled ? "Select values from filter to proceed" : "Click to update with current selections"
                            }
                        </span>
                        <button 
                            className='submit-filter-btn' 
                            disabled={submitBtnDisabled}
                            onClick={(e) => {
                                e?.stopPropagation()

                                /**
                                 * Filtering Dimensions: 
                                 *      If columns[] contains multiple column_value[] then send only checked values,
                                 *      and if columns[] contains only one object and with one column_value[] 
                                 *      then send that to API, also if only one columns value is there with
                                 *      multiple column_value[] then send only checked values.
                                 */
                                let dim = dimensions?.filter(d => d?.score?.toString() !== "100").map(d => (
                                    {
                                        ...d, 
                                        columns: 
                                            d?.columns?.length > 1 
                                            ? d?.columns?.map(c => ({...c, column_value: c?.column_value?.filter(c => c?.checked)?.map(v => v?.value)})) 
                                            : d?.columns?.map(c => ({...c, column_value: c?.column_value?.length === 1 ? c?.column_value?.map(v => v?.value) : c?.column_value?.filter(v => v?.checked)?.map(c => c?.value)}))
                                    }
                                ))?.filter(d => d?.columns?.length > 0)

                                /**
                                 * Score === 100 in Dimensions
                                 */
                                let scoreDim = dimensions?.filter(d => d?.score?.toString() === "100")?.map(d => ({...d, columns: d?.columns?.map(c => ({...c, column_value: c?.column_value?.map(v => v?.value)})) }))
                                
                                let resultDim = [...dim, ...scoreDim]
                                /**
                                 * Filtering the empty Arrays[] in dim[] and column_value[]
                                 */
                                let filteredDim = resultDim?.map(d => ({...d, columns: d?.columns?.filter(c => c?.column_value?.length > 0)}))?.filter(d => d?.columns?.length > 0)
                                // console.log({filteredDim})
                                // return
                                let cols = columns?.map(d => ({...d, columns: d?.columns?.length > 1 ? d?.columns?.filter(c => c?.checked )?.map(c => c?.value) : d?.columns?.map(c => c?.value)}))?.filter(d => d?.columns?.length > 0)
                                let met = [...metrics]
                                // console.log({dimensions: filteredDim, metrics: met, columns: cols, question_nlq: data?.chain_response?.filters?.question_nlq ?? ""})
                                // return
                                let stringifyJson = JSON.stringify({dimensions: filteredDim, metrics: met, columns: cols, question_nlq: data?.chain_response?.filters?.question_nlq ?? ""})
                                retryQuery(stringifyJson, data?.question)
                            }}
                        >
                            See Results
                            <span className='d-flex'>
                                <ICON_LINE_ARROW_RIGHT color='currentColor'/>
                            </span>
                        </button>
                    </div>
                </section>
                :   ''
            }
            <Collapse 
                key={`filter-catalog-grid-collapse-${data?.id}-${data?.state}`}
                className='chat-steps-collapse'
                collapsible={'header'}
                ghost={true}
                expandIconPosition='right'
                defaultActiveKey={1}
                items={[
                    {
                        key: 1,
                        label: <> Filters & Calculations</>,
                        showArrow: true,
                        children: <>
                            <div className='filter-calc-wrap'>
                                <div className='filter-calc-dimensions-grid mb-4' style={{display: !dimensions || dimensionsView?.length === 0 ? 'none' : undefined}}>
                                    {
                                        dimensionsView?.map((d, i) => (
                                            <React.Fragment key={`dimension-${d?.user_value}-${i}`}>
                                                <div>
                                                    <label className='fontSizeLabel text-color-grey fontInterSemiBold text-uppercase'>
                                                        {d?.user_value}
                                                    </label>
                                                    <div className={`filter-calc-dimensions-values`}>
                                                        {
                                                            d?.columns?.map((col, index) => (
                                                                <React.Fragment key={`dimensionCols${d?.user_value}-${col?.column_name}-${index}`}>
                                                                    <Popover
                                                                        key={`dimensionCols${d?.user_value}-${col?.column_name}-${index}-pop`}
                                                                        placement='bottomLeft'
                                                                        arrow={false}
                                                                        title={<></>}
                                                                        content={
                                                                            <>
                                                                                <div className='dim-col-value-popwrap d-flex flex-column' style={{minWidth: '8rem'}}>
                                                                                    {
                                                                                        !isEditable ? ''
                                                                                        :
                                                                                        <div className='col-val-item d-flex align-items-center px-2 py-2'>
                                                                                            <Checkbox 
                                                                                                className='mr-2' 
                                                                                                checked={col?.column_value?.every(c => c?.checked)}
                                                                                                onChange={e => {
                                                                                                    handleDimensionSelectAllCol(d, col?.column_name, e?.target?.checked)
                                                                                                }}
                                                                                            />
                                                                                            <span className='justify-content-start bg-transparent fontSizeLabel'>
                                                                                                { col?.column_value?.every(c => c?.checked) ? 'Unselect All' : 'Select All' }
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        col?.column_value
                                                                                        ?.map((c, idx) => (
                                                                                            <React.Fragment key={`${d?.user_value}-${c}-${idx}`}>
                                                                                                <div className='col-val-item d-flex align-items-center px-2 py-2'>
                                                                                                    {
                                                                                                        !isEditable ? ''
                                                                                                        :
                                                                                                        <Checkbox 
                                                                                                            className='mr-2' 
                                                                                                            checked={c?.checked}
                                                                                                            onChange={e => {
                                                                                                                handleDimensionColValueCheck(d, col, c?.value, e?.target?.checked)
                                                                                                            }}
                                                                                                        />
                                                                                                    }
                                                                                                    <span title={c?.value} className='justify-content-start bg-transparent fontSizeLabel'>
                                                                                                        {c?.value}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <span className={`fontSizeLabel ${ isEditable && col?.column_value?.some(c => c?.checked) ? 'active' : ''}`}>
                                                                            <span title={col?.column_name} className='dim-col-name text-with-ellipsis'>
                                                                                {col?.column_name}
                                                                            </span>
                                                                            <span className='dim-col-name-arrow ml-1'>
                                                                                <ICON_ARROW_RIGHT color='currentColor'/>
                                                                            </span>
                                                                        </span>
                                                                    </Popover>
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                                <div className='keyword-section filter-calc-dimensions-grid mb-4' 
                                // style={data?.state === "SUCCESS" || !columns || columns?.length === 0 ? {display: 'none'} : {}}
                                style={!columns || columnsView?.length === 0 ? {display: 'none'} : {}}
                                >
                                    {
                                        columnsView?.map((d, i) => (
                                            <React.Fragment key={`dimension-${d?.user_value}-${i}`}>
                                                <div>
                                                    <label className='fontSizeLabel text-color-grey fontInterSemiBold text-uppercase'>
                                                        {d?.user_value}
                                                    </label>
                                                    <div className={`filter-calc-dimensions-values`}>
                                                        {
                                                            <Popover
                                                                key={`columnsCols${d?.user_value}-pop`}
                                                                placement='bottomLeft'
                                                                arrow={false}
                                                                title={<></>}
                                                                content={
                                                                    <>
                                                                        <div className='dim-col-value-popwrap d-flex flex-column' style={{minWidth: '8rem'}}>
                                                                            {
                                                                                !isEditable ? ''
                                                                                :
                                                                                <div className='col-val-item d-flex align-items-center px-2 py-2'>
                                                                                    <Checkbox 
                                                                                        className='mr-2' 
                                                                                        checked={d?.columns?.every(c => c?.checked)}
                                                                                        onChange={e => {
                                                                                            handleColumnsSelectAllCol(d, e?.target?.checked)
                                                                                        }}
                                                                                    />
                                                                                    <span className='justify-content-start bg-transparent fontSizeLabel'>
                                                                                        { d?.columns?.every(c => c?.checked) ? 'Unselect All' : 'Select All' }
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                d?.columns?.map((col, idx) => (
                                                                                    <React.Fragment key={`${d?.user_value}-${col?.value}-${idx}`}>
                                                                                        <div className='col-val-item d-flex align-items-center px-2 py-2'>
                                                                                            {
                                                                                                !isEditable ? ''
                                                                                                :
                                                                                                <Checkbox 
                                                                                                    className='mr-2' 
                                                                                                    checked={col?.checked}
                                                                                                    onChange={e => {
                                                                                                        handleColumnsValueCheck(d, col, col?.value, e?.target?.checked)
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            <span 
                                                                                                title={col?.value}
                                                                                                className='justify-content-start bg-transparent fontSizeLabel'
                                                                                                style={{wordBreak: "break-all"}}
                                                                                            >
                                                                                                {col?.value}
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            >
                                                                <span className={`fontSizeLabel ${ (isEditable) && d?.columns?.some(c => c?.checked) ? 'active' : ''}`}>
                                                                    <span
                                                                        className='dim-col-name text-with-ellipsis'
                                                                    >
                                                                        {
                                                                            d?.columns?.filter(c => c?.checked)?.length === 0
                                                                            ? isEditable ? "Select Columns" : "Columns"
                                                                            : `${d?.columns?.filter(c => c?.checked)?.length} Column(s) Selected`
                                                                        }
                                                                    </span>
                                                                    <span className='dim-col-name-arrow ml-1'>
                                                                        <ICON_ARROW_RIGHT color='currentColor'/>
                                                                    </span>
                                                                </span>
                                                            </Popover>
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                                {
                                    data?.state === "CLARIFICATION" ? ''
                                    :
                                    <Metrics/>
                                }
                            </div>
                        </>
                    }
                ]}
            />
        </div>
    )
}

export default React.memo(FilterCalculations)