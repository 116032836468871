import { emitToastNotification } from "../../../../helpers/toast_helper";
import { fetchData } from "../../../../services/apiService";
import { callAzure, callAzureMultiPart, callS3, commitBlockList } from "../createPOD/createPODActions";
const BASE_URL = window._env_.REACT_APP_API_BASE_URL;

export const getContentRepo = (id, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}content_repo/${id}/`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const createContentRepo = (payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}content_repo/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            if (showToast) {
              emitToastNotification('success', resp?.message);
            }
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const updateContentRepo = (repoId, payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("PUT", `${BASE_URL}content_repo/${repoId}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            if (showToast) {
              emitToastNotification('success', resp?.message);
            }
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const deleteContentRepo = (repoId, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}content_repo/${repoId}/`))
        .then((resp) => {
          if (resp.status === "success") {
            if (showToast) {
              emitToastNotification('success', resp?.message);
            }
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const initiateFileUpload = (repoId, payload, showToast = true) => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}content_repo/${repoId}/initiate_upload/`, payload))
        .then(async (resp) => {
          resolve(resp)
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const unstructuredFileUpload = (data, file, source, showToast = true, setUploadProgress = () => {}) => {

  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
          if (source === "azure_adls") {
              // let data = resp.data;
              const state = getState();
              let blockIds = []
                  
              await callAzureMultiPart(
                  data.response, 
                  file, 
                  file.name, 
                  data.account_name, 
                  data.container_name, 
                  data.path, 
                  data.pod, 
                  dispatch, 
                  state, 
                  blockIds,
                  setUploadProgress
              )
              await commitBlockList(data.response, "", file.name, data.account_name, data.container_name
                  , data.path, data.pod, dispatch, state, "", blockIds, false
                  , { fileContentType: data?.content_type } 
              )
      
              resolve();
      
          }
          else {  // s3
              let reader = new FileReader();
              let blobData;
              reader.readAsDataURL(file);
              reader.onload = (e) => {
      
                  let binary = atob(e?.target?.result?.split(",")[1]);
                  let array = [];
                  for (var i = 0; i < binary.length; i++) {
                  array.push(binary.charCodeAt(i));
                  }
                  blobData = new Blob([new Uint8Array(array)]);
                  // console.log(blobData)
                  if (source === "azure_adls") {
                      // let data = resp.data;
                      const state = getState();
                      resolve(callAzure(
                          data.response,
                          file,
                          file.name,
                          data.account_name,
                          data.container_name,
                          data.path,
                          data.pod,
                          dispatch,
                          state,
                          false
                      ))
                  }
                  else resolve(dispatch(callS3(data?.response, data?.content_type, blobData, file.name, false, setUploadProgress)));
              };
            }
        }
        catch(err) {
          if(showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err)
        }
      })
    }
};

export const getAssetFromConnection = (payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}content_repo/asset/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const processAsset = (asset_id, payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}content_repo/asset/${asset_id}/process/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const processContentRepo = (repoId, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}content_repo/${repoId}/process/`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const updateAssetStatus = (asset_id, payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("POST", `${BASE_URL}content_repo/asset/${asset_id}/status/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchAssetStatus = (asset_id, showToast = true, controller) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}content_repo/asset/${asset_id}/status/`, null, null, null, controller))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const updateContentAsset = (asset_id, payload, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("PUT", `${BASE_URL}content_repo/asset/${asset_id}/`, payload))
        .then((resp) => {
          if (resp.status === "success") {
            if (showToast) {
              emitToastNotification('success', resp?.message);
            }
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const retrieveAssetContent = (asset_id, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}content_repo/asset/${asset_id}/`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const fetchRepoStatus = (repo_id, showToast = true, controller) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}content_repo/${repo_id}/status/`, null, null, null, controller))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getRepositoryData = (repo_id, showToast = true, controller) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}content_repo/asset/?repository_id=${repo_id}&limit=100`, null, null, null, controller))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const getRepositoryList = (domain_id, showToast = true, controller) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("GET", `${BASE_URL}content_repo/?data_domain_id=${domain_id}&limit=1000`, null, null, null, controller))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const deleteAsset = (asset_id, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchData("DELETE", `${BASE_URL}content_repo/asset/${asset_id}/`))
        .then((resp) => {
          if (resp.status === "success") {
            resolve(resp);
          } 
        })
        .catch((err) => {
          if (showToast) {
            emitToastNotification('error', err?.message);
          }
          reject(err);
        });
    });
  };
};

export const assignTagToDocument = (payload, repoId, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('POST', `${BASE_URL}content_repo/${repoId}/tag/`, payload)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const toggleBookmarkToDocument = (repoId, bookmark, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData(!bookmark ? 'GET' : 'DELETE', `${BASE_URL}content_repo/${repoId}/bookmark/`)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const unassignTagToDocument = (id, repoId, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('DELETE', `${BASE_URL}content_repo/${repoId}/tag/?tag=${id}`)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};

export const getDomainDocuments = (dataDomain, showToast = true) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchData('GET', `${BASE_URL}content_repo/?fetch_assets=true&fetch_asset_status=PROCESSED&data_domain_id=${dataDomain}&limit=1000`)
      )
        .then((resp) => {
          if (showToast) emitToastNotification("success", resp.message);

          resolve(resp);
        })
        .catch((err) => {
          if (showToast) emitToastNotification("error", err.message);

          reject(err);
        });
    });
  };
};