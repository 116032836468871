import React from 'react'
import LandingpageFirstHeader from '../../../modules/common/LandingpageFirstHeader'
import './pulseLandingPage.scss'
import '../../../../../src/assets/scss/media/pulse_media.scss'
import PulseCompanyLogo from './PulseCompanyLogo'
import PulseSearchBox from './PulseSearchBox'
import { getCatalogListing, getQuickUnstructuredPreview, getUserSelectedDomain, setSidebarActiveRoute, setUserSelectedDomain } from '../../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Literals } from '../../../modules/common/literals'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { getOpenAiStatus, setUpdatedUserData, setUser } from '../../../../store/userAuthentication/userActions'
import { ICON_CATALOG, ICON_CLOCK, ICON_DASHBOARD, ICON_FILE, ICON_TRACK, ICON_USER } from '../../newIconSource'
import { Tooltip } from 'antd'
import { getDomainDocuments } from '../../../../store/modules/dataCatalogue/unstructured/unstructuredActions'
import { getTopQuestions } from '../../../../store/modules/common/GenAi/GenAiReducer'
import { v4 as uuidv4 } from 'uuid';
import { getPreviewTable } from '../../../../store/modules/dataCatalogue/preview/previewActions'
import { fetchData } from '../../../../services/apiService'
import _ from 'lodash'
import PulseFilePreviewModal from './PulseFilePreviewModal'
import PulseSourceDropdown from './PulseSourceDropdown'
import Buttons from '../../button/Buttons'

export const SOURCES = {
    pod: 'pod',
    documents: 'documents'
}

const failedDocMessage = 'Failed to load document'

const NoDocumentMessage = "No Document(s) Found To Preview"

const NewPulseLandingPage = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const history = useNavigate()

    const { userSelectedDomain } = useSelector(state => state.UserManagement.DataDomain)

    const [searchTerm, setSearchTerm] = React.useState('') // for data search
    const [questValue, setQuestValue] = React.useState('')
    const [activeDomain, setActiveDomain] = React.useState('')
    const [isDomainFailed, setDomainFailed] = React.useState(false)
    const [noDomainAccess, setNoDomainAccess] = React.useState(false)
    const [topQuestions, setTopQuestions] = React.useState([])
    const [previewOpen, setPreviewOpen] = React.useState(false)
    const [previewData, setPreviewData] = React.useState({})
    const [previewLoading, setPreviewLoading] = React.useState(false)
    const [previewError, setPreviewError] = React.useState(undefined)
    const [previewController, setPreviewController] = React.useState(null)
    const [uniqUUID, setUniqUUID] = React.useState('')
    const [podDetails, setPodDetails] = React.useState({})
    const [previewUrl, setPreviewUrl] = React.useState(null) // for document preview
    const [currentDocument, setCurrentDocument] = React.useState({}) // for document preview

    /**
     * States of Source Popover Content
     */
    const [activeSource, setActiveSource] = React.useState(SOURCES.pod)
    const [sourceOpen, setSourceOpen] = React.useState(false)
    const [sourceContentLoading, setSourceContentLoading] = React.useState(false)
    const [sourceContentEmpty, setSourceContentEmpty] = React.useState(false)
    const [sourceContentError, setSourceContentError] = React.useState(undefined)
    const [sourceContent, setSourceContent] = React.useState([])
    const [sourceContentSearch, setSourceContentSearch] = React.useState("")
    const [sourceContentAll, setSourceContentAll] = React.useState(true)

    let userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)
    let user = useSelector((state) => state.UserReducer?.user)

    // const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    const podDataRef = React.useRef({})

    // const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    React.useEffect(() => {
        dispatch(setSidebarActiveRoute('/clarista-pulse'))
    }, [])

    React.useEffect(() => {
        if (location?.hash) {
            let hashParam = location.hash?.replace('#', '')
            if (hashParam) {
                setActiveSource([SOURCES.pod, SOURCES.documents]?.includes(hashParam) ? hashParam : SOURCES.pod)
            }
        }
        else {
            setActiveSource(SOURCES.pod)
        }
    }, [location])

    React.useEffect(() => {
        return () => {
            if(previewController && previewController?.abort) {
                previewController?.abort()
            }
        }
    }, [previewController])

    React.useEffect(() => {
        if(!!userSelectedDomain && userSelectedDomain?.name) {
            setActiveDomain(userSelectedDomain?.id)
        }
    }, [userSelectedDomain])

    React.useEffect(() => {
        const locationDomainId = location?.state?.domainId
        if (locationDomainId) {
            setActiveDomain(locationDomainId)
            dispatch(setUserSelectedDomain({ data_domain_id: locationDomainId }, false))
                .catch(err => console.error(err?.message))
        }
        else {
            dispatch(getUserSelectedDomain())
                .then(res => {
                    const domain = res?.data?.domain ?? undefined
                    if (domain && domain?.id) {
                        setActiveDomain(domain?.id)
                    }
                    else if (domain) {
                        setActiveDomain(domain?.id ?? 0)
                    }
                    else {
                        setNoDomainAccess(true)
                    }
                })
                .catch(err => {
                    setDomainFailed(true)
                    console.error(err?.message)
                })
    
                dispatch(getOpenAiStatus())
                .then(res => {
                    let openai_data = res?.data
                    let details = {...user}
                    details.userDetails = {...user.userDetails, openAi: openai_data}
                    dispatch(setUser(details))
                    setUpdatedUserData({...details.userDetails, openAi: openai_data})
                })
                .catch(err => {
                    console.error({err})
                    let details = {...user}
                    details.userDetails = {...user.userDetails, openAi: null}
                    dispatch(setUser(details))
                    setUpdatedUserData({...details.userDetails, openAi: null})
                })
        }
    }, [])

    React.useEffect(() => {
        const pulseSearchElement = document?.querySelector('#pulseTalkSearchInp')
        if(pulseSearchElement) {
            pulseSearchElement.focus()
        }
    }, [])

    React.useEffect(() => {
        if(activeDomain && activeDomain !== '') {
            fetchSourcePopOverContent()
            fetchTopQuestions()
        }
    }, [activeDomain, activeSource])

    const fetchSourcePopOverContent = React.useCallback(() => {
        if(activeSource === SOURCES.pod) {
            fetchPodSourceContent()
        }
        else if(activeSource === SOURCES.documents) {
            fetchDocumentSourceContent()
        }
    }, [activeSource, activeDomain, sourceContent])

    const fetchDocumentSourceContent = React.useCallback(() => {

        if(sourceContent?.length && sourceContent?.some(s => s?.data_domain_detail?.data_domain_id?.toString() === activeDomain?.toString())) {
            return
        }
        
        setSourceContentAll(true)
        setSourceContentSearch("")
        setSourceContentEmpty(false)
        setSourceContentError(undefined)
        setSourceContentLoading(true)
        dispatch(getDomainDocuments(activeDomain, false))
        .then(res => {
            const data = res?.data?.results ?? []
            setSourceContent(() => {
                let arr = data?.map(d => ({
                    title: d?.name,
                    description: d?.description,
                    checked: false,
                    ...d
                }))
                return arr ?? []
            })
            setSourceContentEmpty(data?.length === 0)
            setSourceContentLoading(false)
        })
        .catch((err) => {
            setSourceContentEmpty(false)
            setSourceContentError(err?.message)
            setSourceContentLoading(false)
        })
    }, [activeDomain, sourceContent])

    const fetchPodSourceContent = React.useCallback(() => {
        
        if(sourceContent?.length && sourceContent?.some(s => s?.data_domain_detail?.data_domain_id?.toString() === activeDomain?.toString())) {
            return
        }

        setSourceContentAll(true)
        setSourceContentSearch("")
        setSourceContentEmpty(false)
        setSourceContentError(undefined)
        setSourceContentLoading(true)
        dispatch(getCatalogListing(activeDomain, 'metadata', true, false))
        .then(res => {
            const data = res?.data ?? []
            setSourceContent(() => {
                let arr = data?.map(d => ({
                    id: d?.table_id,
                    title: d?.table_name,
                    description: d?.pod_description,
                    checked: false,
                    ...d
                }))
                return arr ?? []
            })
            setSourceContentEmpty(res?.data?.length === 0)
            setSourceContentLoading(false)
        })
        .catch((err) => {
            setSourceContentEmpty(false)
            setSourceContentError(err?.message)
            setSourceContentLoading(false)
        })
    }, [activeDomain, sourceContent])

    const fetchTopQuestions = () => {
        setTopQuestions([])
        dispatch(getTopQuestions(activeDomain, activeSource === SOURCES.documents))
        .then(res => {
            let list = res?.data            
            setTopQuestions([...list])
        })
        .catch(() => {

        })
    }

    const handleQuestion = React.useCallback(() => {

        if (questValue?.trim() === '') {
            return
        } 
        if(!activeDomain || activeDomain === '' || activeDomain === undefined || activeDomain === null) {
            emitToastNotification('info', 'Please Select Domain To Proceed')
            return
        }

        let podIds = sourceContent?.filter(s => s?.checked)?.map(s => s?.id) ?? []

        if(activeSource === SOURCES.pod) {
            history(Literals.links.PULSE_TALK + `?dm=${activeDomain}`, { state: { fromPulsePage: true, query: questValue, podIds } })
        }
        else if(activeSource === SOURCES.documents) {
            history(Literals.links.PULSE_UNSTRUCTURED_TALK + `?dm=${activeDomain}`, { state: { fromPulsePage: true, query: questValue, podIds } })
        }
    }, [activeDomain, questValue, activeSource, sourceContent])

    const displayUserName = React.useCallback(() => {
        let name = userDetailsRedux?.first_name
        if(userDetailsRedux?.last_name)
        name += " " + userDetailsRedux?.last_name
        return name ?? 'Unknown User'
    }, [userDetailsRedux])

    const fetchDocumentPreview = () => {

        const fileObj = podDataRef?.current
        
        if(!fileObj?.file_name) return

        setPreviewError(undefined)
        setPreviewLoading(true)
        const {prefix, file_name, connection} = fileObj
        dispatch(getQuickUnstructuredPreview(
            prefix,
            file_name,
            connection,
            true,
            false
        ))
        .then(res => {
            let url = ''
            if(typeof res?.data === 'object') {
                url = res?.data?.response
            }
            else url = res?.data 
            setPreviewUrl(url)

            dispatch(fetchData("GET", url))
            .then(() => {
                setPreviewError(undefined)
            })
            .catch((err) => {
                // console.log("ERROR::",err)
                if(err && _.isString(err)) {
                    setPreviewError(err)
                }
                else if(typeof err === 'object') {
                    if(err?.status === 403) {
                        setPreviewError(err?.data ?? err?.message ?? failedDocMessage)
                    }
                    else {
                        setPreviewError(err?.message ?? failedDocMessage)
                    }
                }
                else {
                    setPreviewError(failedDocMessage)
                }
            });
        })
        .catch((err) => {
            setPreviewError(err?.message ?? 'Failed to load')
            setPreviewLoading(false)
        })
    }

    const fetchPreview = React.useCallback(() => {
        if(previewController && previewController?.abort) {
            previewController?.abort()
        }

        const controller = new AbortController()
        setPreviewController(controller)

        let details = podDataRef?.current
        setPreviewLoading(true)
        setPreviewError(undefined)
        let uuid = uuidv4()
        setUniqUUID(uuid)
        let payload = {
            uuid: uuid,
            table_name: details?.table_name,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: [details?.table_id]
        }
        dispatch(getPreviewTable(payload, false, true, false, false, false, controller))
        .then((res) => {
            setPreviewData(res?.data?.result??{})
            setPreviewLoading(false)
            setPreviewError(undefined)
        })
        .catch((err) => {
            setPreviewController(null)
            setPreviewError(err?.message ?? 'Error!')
            setPreviewLoading(false)
        })
    }, [podDetails])

    const onPreview = () => {
        if(activeSource === SOURCES.pod) {
            fetchPreview()
        }
        else {
            fetchDocumentPreview()
        }
    }

    const PulseInputTab = React.useCallback(() => {
        const opts = [
            {title: "PODs", key: SOURCES.pod, icon: <ICON_CATALOG color='currentColor' height='.9rem' width='.9rem' />},
            {title: "Documents", key: SOURCES.documents, icon: <ICON_FILE color='currentColor' height='.9rem' width='.9rem' />},
        ]
        const onClick = (key) => {
            if(activeSource !== key) {
                setActiveSource(key)
                window.history.replaceState({}, null, `${window.location.pathname}#${key}`)
                setSourceContentSearch("")
                setSourceContent([])
            }
        }
        return <div className='pod-doc-tab'>
            {
                opts?.map(({title, icon, key}) => (
                    <React.Fragment key={`option-${key}`}>
                        <div className={`${activeSource === key ? 'active' : ''}`} onClick={() => onClick(key)}>
                            <span className='mr-1 d-flex'>{icon}</span>
                            <span className='mb-0 fontSizeHeading'>{title}</span>
                        </div>
                    </React.Fragment>
                ))
            }
        </div>
    }, [activeSource])

    const sourcePopoverList = React.useMemo(() => {
        let list = [...sourceContent]?.filter(s => sourceContentAll ? true : s?.checked)

        setSourceContentAll(!list?.some(itm => itm?.checked))
        
        if(list?.length && sourceContentSearch !== "") {
            list = list?.filter(item => item?.title?.toLowerCase()?.includes(sourceContentSearch?.toLowerCase()))
        }

        return list
    }, [sourceContent, sourceContentSearch, sourceContentAll])

    const onContentCheck = (e, id) => {
        const checked = e?.target?.checked
        setSourceContent(prev => {
            prev = prev?.map(s => (s?.id === id ? {...s, checked} : {...s}))
            return [...prev]
        })
    }

    const onContentCheckAll = (e) => {
        const checked = e?.target?.checked
        setSourceContentAll(checked)
        if(checked) {
            setSourceContent(prev => {
                prev = prev?.map(s => ({...s, checked: false}))
                return [...prev]
            })
        }
    }

    const handlePreview = (itm, activeFile = null) => {
        
        if(activeSource === SOURCES.pod) {
            podDataRef.current = itm
            setPodDetails(itm)
            setSourceOpen(false)
            setPreviewOpen(true)
            onPreview()
        }
        else {
            if(itm?.assets?.length === 0) {
                setPreviewError(NoDocumentMessage)
            }
            let data = {}
            if(activeFile) {
                data = itm?.data?.assets?.find(a => a?.id === activeFile) ?? {}
                data = {...data, table_id: itm?.table_id, table_name: itm?.table_name, pod_description: itm?.pod_description, assets: itm?.data?.assets, data: itm?.data}
            }
            else {
                data = itm?.assets[0]
                setCurrentDocument(data)
            }
            
            if(!activeFile) {
                data = {table_id: itm?.id, table_name: itm?.title, pod_description: itm?.description,...data, data: {...itm}}
            }
            
            podDataRef.current = data
            setPodDetails(data ?? {})
            setSourceOpen(false)
            setPreviewOpen(true)
            onPreview()
        }
    }
    

    const ActiveElements = React.useCallback(() => {
        let data = []
        if(sourceContentAll) {
            data = sourceContent
        }
        else {
            data = sourceContent?.filter(s => s?.checked)
        }
        const visibleData = data?.length >= 2 ? data?.slice(0, 2) : data
        const restData = data?.length > 2 ? data?.slice(2) : []
        return <>
            <div className='d-flex align-items-center' style={{gap: '.25rem'}}>
                {
                    visibleData?.map(cont => (
                        <React.Fragment key={`activeEl-${cont?.title}`}>
                            <label className='mb-0 fontSizeLabel text-uppercase d-flex align-items-center bg-white rounded-pill px-2 py-1'>
                                <span className='mr-1 d-flex'>
                                    {
                                        activeSource === SOURCES.pod
                                        ? <ICON_CATALOG height='.8rem' width='.8rem' color='currentColor' />
                                        : <ICON_FILE height='.8rem' width='.8rem' color='currentColor' />
                                    }
                                </span>
                                <span title={cont?.title?.toUpperCase()} className='text-with-ellipsis' style={{maxWidth: '8rem'}}>
                                    {cont?.title}
                                </span>
                            </label>
                        </React.Fragment>
                    ))
                }
                {
                    restData?.length 
                    ?   
                        <Tooltip title={restData?.map(t => t?.title).join(', ')?.toUpperCase()}>
                            <label className='mb-0 fontSizeLabel d-flex align-items-center bg-white rounded-pill px-2 py-1'>
                            +{restData?.length}
                            </label> 
                        </Tooltip>
                    :   ''
                }
            </div>
        </>
    }, [sourceContent, sourceContentAll])

    const handleDocMovement = (moveToPrevious = false) => {
        let currentIndex = sourceContent?.findIndex(s => s?.id === podDetails?.table_id)
        let newIndex = 0
        if(moveToPrevious) {
            newIndex = currentIndex - 1
        }
        else {
            newIndex = currentIndex + 1
        }
        if(sourceContent[newIndex]) {
            if(previewController && previewController?.abort) {
                previewController?.abort()
            }
            const data = sourceContent[newIndex]
            const defaultFile = data?.assets[0] ?? {}
            if(data?.assets?.length === 0) {
                setPreviewError(NoDocumentMessage)
            }
            const newData = {table_id: data?.id, table_name: data?.name, pod_description: data?.description, data: data, ...defaultFile}
            podDataRef.current = newData
            setPodDetails(newData)
            setCurrentDocument(data?.assets[0] ?? {})
            onPreview()
        }
    }

    const handlePodMovement = (moveToPrevious = false) => {
        let currentIndex = sourceContent?.findIndex(s => s?.id === podDetails?.id)
        let newIndex = 0
        if(moveToPrevious) {
            newIndex = currentIndex - 1
        }
        else {
            newIndex = currentIndex + 1
        }
        if(sourceContent[newIndex]) {
            if(previewController && previewController?.abort) {
                previewController?.abort()
            }
            podDataRef.current = sourceContent[newIndex] ?? {}
            setPodDetails(sourceContent[newIndex] ?? {})
            onPreview()
        }
    }

    const handleNextPod = () => {
        if(activeSource === SOURCES?.pod) {
            handlePodMovement()
        }
        else {
            handleDocMovement()
        }
    }

    const handlePreviousPod = () => {
        if(activeSource === SOURCES?.pod) {
            handlePodMovement(true)
        }
        else {
            handleDocMovement(true)
        }
    }

    const onPreviewModalClose = () => {
        if(previewController && previewController?.abort) {
            previewController?.abort()
        }
        podDataRef.current = {}
        setPodDetails({})
        setPreviewUrl(null)
        setCurrentDocument({})
    }

    const onAskPreviewData = () => {
        const item = sourcePopoverList?.[0] ?? null
        if(item) {
            handlePreview(item)
        }
    }

    const nextPodBtnVisibility = React.useMemo(() => {
        let currentIndex = 0
        if(activeSource === SOURCES?.documents) {
            currentIndex = sourceContent?.findIndex(s => s?.id === podDetails?.table_id)
        }
        else {
            currentIndex = sourceContent?.findIndex(s => s?.id === podDetails?.id)
        }
        return ((currentIndex + 1) === sourceContent?.length) || previewLoading
    }, [podDetails, sourceContent, previewLoading])

    const prevPodBtnVisibility = React.useMemo(() => {
        let currentIndex = 0
        if(activeSource === SOURCES?.documents) {
            currentIndex = sourceContent?.findIndex(s => s?.id === podDetails?.table_id)
        }
        else {
            currentIndex = sourceContent?.findIndex(s => s?.id === podDetails?.id)
        }
        return currentIndex === 0 || previewLoading
    }, [podDetails, sourceContent, previewLoading])

    const menuCard = React.useMemo(() => {
        const historyCard = {
            key: 'historyCard',
            title: 'History',
            description: 'Access your history of questions, discussions, and insights. Review past interactions, queries, findings  and analyses.',
            icon: <ICON_CLOCK height='1.8rem' width='1.8rem' color='#D0AE27' />,
            onClick: () => {
                if(activeSource === SOURCES.pod) {
                    history(Literals.links.TALKDATA_PULSE)
                }
                else if(activeSource === SOURCES.documents) {
                    history(Literals.links.PULSE_LANDING_UNSTRUCTURED)
                }
            }
        }
        if(activeSource === SOURCES?.pod) {
            return [
                {
                    key: 'dashboardCard',
                    title: 'Dashboard',
                    description: 'Access dashboards with valuable data. Monitor trends, track progress, and analyze key performance indicators.',
                    icon: <ICON_DASHBOARD height='1.8rem' width='1.8rem' color='#2C83C4' />,
                    onClick: () => {
                        history(Literals.links.DASHBOARD_PULSE)
                    }
                },
                {
                    key: 'contextCard',
                    title: 'Context',
                    description: 'Get data to understand preferences and trends. Use insights to improve decision-making and optimize strategies.',
                    icon: <ICON_USER height='1.8rem' width='1.8rem' color='#971BF6' />,
                    onClick: () => {
                        history(Literals.links.PULSE_TALK_TRACE+"#persona", { state: { fromPulsePage: true } })
                    }
                },
                historyCard
            ]
        }
        return [
            {
                key: 'pdfCard',
                title: 'PDF Upload',
                description: 'Get instant answers by uploading your PDF to any question. Explore your documents with interactive Q&A.',
                icon: <ICON_FILE height='1.8rem' width='1.8rem' color='#2C83C4' />,
                onClick: () => {}
            },
            historyCard
        ]
    }, [activeSource])

    return (
        <div className='talk-index-main'>
            <LandingpageFirstHeader 
                title={'Pulse'} 
                isPulse={true} 
                activeDomain={activeDomain}
                showFeedNotification={true}
            />
            <div className='pulse-landing-wrap'>
                <PulseFilePreviewModal
                    nextPodBtnVisibility={nextPodBtnVisibility}
                    prevPodBtnVisibility={prevPodBtnVisibility}
                    SOURCES={SOURCES}
                    activeSource={activeSource}
                    currentDocument={currentDocument}
                    handleNextPod={handleNextPod}
                    handlePreview={handlePreview}
                    handlePreviousPod={handlePreviousPod}
                    onPreview={onPreview}
                    onPreviewModalClose={onPreviewModalClose}
                    podDataRef={podDataRef}
                    podDetails={podDetails}
                    previewData={previewData}
                    previewError={previewError}
                    previewLoading={previewLoading}
                    previewOpen={previewOpen}
                    previewUrl={previewUrl}
                    setCurrentDocument={setCurrentDocument}
                    setPreviewLoading={setPreviewLoading}
                    setPreviewOpen={setPreviewOpen}
                    setUniqUUID={setUniqUUID}
                    uniqUUID={uniqUUID}
                />
                <section className='pulse-hero-sec'>
                    <div className='pulse-legend'>
                        <h2 className='mb-2'>Hello, <span className='fontInterSemiBold text-capitalize'>{`${displayUserName()}`}!</span></h2>
                        <p className='mb-4'>
                            With Pulse find what you're looking for quickly and accurately.
                        </p>
                        <div>
                            <div className='pulse-input-outerwrap'>
                                <div className='pulse-input-opt-wrap'>
                                    <div>
                                        <PulseInputTab/>
                                    </div>
                                    <div>
                                        <PulseSearchBox
                                            activeDomain={activeDomain}
                                            setActiveDomain={setActiveDomain}
                                            isDomainFailed={isDomainFailed}
                                            setDomainFailed={setDomainFailed}
                                            searchValue={questValue}
                                            setSearchValue={setQuestValue}
                                            onSearch={handleQuestion}
                                            onEnterCallback={handleQuestion}
                                            hideDomain={true}
                                            isPulseLandingPage={true}
                                            useTextLimit={true}
                                            source={activeSource}
                                        />
                                    </div>
                                </div>
                                <div className='pulse-input-foot'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <PulseSourceDropdown
                                                SOURCES={SOURCES}
                                                activeSource={activeSource}
                                                handlePreview={handlePreview}
                                                fetchSourcePopOverContent={fetchSourcePopOverContent}
                                                onContentCheck={onContentCheck}
                                                onContentCheckAll={onContentCheckAll}
                                                sourceContentAll={sourceContentAll}
                                                sourceContentEmpty={sourceContentEmpty}
                                                sourceContentError={sourceContentError}
                                                sourceContentLoading={sourceContentLoading}
                                                sourceContentSearch={sourceContentSearch}
                                                sourcePopoverList={sourcePopoverList}
                                                setSourceContentSearch={setSourceContentSearch}
                                                setSourceOpen={setSourceOpen}
                                                sourceOpen={sourceOpen}
                                            />
                                            <div className='vertical-separator' style={{height: '1rem'}}></div>
                                            <ActiveElements/>
                                        </div>
                                        <div>
                                            {
                                                sourcePopoverList?.length === 0 ? ""
                                                :
                                                <label className='mb-0 text-dark fontSizeHeading'>
                                                    Not sure what to ask?
                                                    <span className='underline-button ml-1'
                                                        onClick={() => {
                                                            onAskPreviewData()
                                                        }}>
                                                        <span>Preview Data</span>
                                                    </span>
                                                </label>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-end w-100' style={{height: '8rem'}}>
                            <PulseCompanyLogo 
                                activeDomain={activeDomain}
                            />
                        </div>
                    </div>
                </section>
                <section className='bg-white new-pulse-lower-wrap'>
                    <div className='new-pulse-content-wrap'>
                        <div>
                            <div className='mb-4 pb-2'>
                                <h4 className='fontInterSemiBold mb-1 text-color-grey'>Tap to Access</h4>
                                <p className='mb-0 fontSizeHeading text-black-50'>
                                    Tap to view and manage your dashboard, context and history.
                                </p>
                            </div>
                            <div>
                                <div className='new-pulse-contcard-grid'>
                                    {
                                        menuCard?.map(card => (
                                            <React.Fragment key={card?.key}>
                                                <div className='d-flex flex-column' 
                                                    style={{gap: '1rem'}}
                                                    onClick={() => {
                                                        card?.onClick()
                                                    }}
                                                    >
                                                    <div>{card?.icon}</div>
                                                    <div>
                                                        <div className='fontSizeLargeHeading fontInterSemiBold text-dark mb-2'>{card?.title}</div>
                                                        <div className='fontSizeHeading text-color-grey'>{card?.description}</div>
                                                    </div>
                                                    {/* <div>
                                                        <Buttons
                                                            props={{
                                                                buttonText: "Explore",
                                                                buttonClassName: "custom-btn custom-btn-outline btn-with-text border text-black-50 btn-large",
                                                                buttonEvent: (e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    card?.onClick()
                                                                },
                                                                ImgSrc: () => <></>,
                                                                isDisable: false,
                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                            }}
                                                        />
                                                    </div> */}
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-end'>
                            {
                                topQuestions?.length 
                                ?
                                <div className='top-quest-wrap'>
                                    <div className='d-flex align-items-center top-quest-head'>
                                        <span className='d-flex mr-2'>
                                            <ICON_TRACK height='1rem' width='1rem' color='currentColor' />
                                        </span>
                                        <span className='fontSizeHeading fontInterMedium'>
                                            Trending Questions
                                        </span>
                                    </div>
                                    <div className='top-quest-body'>
                                        {
                                            topQuestions?.map((q, i) => (
                                                <div key={`quest-${i}`} className='d-flex align-items-start'
                                                    onClick={() => {
                                                        history(
                                                            (q?.unstructured ? Literals.links.PULSE_UNSTRUCTURED_TALK : Literals.links.PULSE_TALK) + '?dm=' + activeDomain + "&uuid=" + uuidv4(), { state: { fromPulsePage: true, query: q?.question } }
                                                        )
                                                    }}
                                                >
                                                    <span className='d-flex mr-2 bx bxs-circle fontSizeExtraSmall text-black-50 mt-1 pt-1'></span>
                                                    <span className='mb-0 text-color-grey fontSizeHeading'>
                                                        {q?.question}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                :   ''
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default NewPulseLandingPage