import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useStore } from "react-redux";
import { useParams } from "react-router";
import { checkOutcomeColumnPresent, getInputTransformerDetails, orderRetainCheck } from "../commonFunctions/FlowsHelperFunction";
import _, { cloneDeep } from "lodash";
import { getConfirmationModal, getObjectLength, mergeArrayByProperty, NoDataComponent, showSimpleLoading } from "../../../common/helperFunctions";
import { SCRIPTDEFAULTVIEW } from "../commonFunctions/ScriptDefaultInfography";
import DndSchemaList from "../commonFunctions/dndMultiColumnsVirtualList/DndSchemaList";
import { HIGHPRIORITY, LOWPRIORITY, MEDIUMPRIORITY, RESETFILTER } from "../../../../Common/iconSource";
import { Literals } from "../../../common/literals";
import Buttons from "../../../../Common/button/Buttons";
import SearchInput from "../../../../Common/search/Search";
import DndAlertList from "../commonFunctions/dndMultiColumnsVirtualList/DndAlertList";
import { Checkbox, Radio, Select, Switch, Tooltip } from "antd";
import { getAlertAssignee } from "../../../../../store/modules/alert/createAlert/createAlertAction";
import MultiSelection from "../../../../Common/dropDown/MultiSelection";
import { getUserGroupList } from "../../../../../store/modules/UserManagement/userGroup/UMActions";
import { getAlertAssigneeList } from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";
import NoResultsFound from "../../../flows/createFlows/NoResultsFound";


const AlertTransformerIndex = ({
    flowsElements,
    setshowOverlay,
    currentTransformer,
    updatedFlowRedux,
    isExecutePermission,
    setupdatedFlowRedux,
    setisrunDisabled = () => { },
    setshowCloseWarning,
    ...props
}) => {
    const [columnList, setcolumnList] = useState([]);
    const plainOptions = ['Data Quality', 'Business Alert'];
    const [reOrderRender, setreOrderRender] = useState(true)
    const [openWarningModal, setopenWarningModal] = useState(false)
    const [showResetColumns, setshowResetColumns] = useState(false)
    const [showLoader, setshowLoader] = useState(false);
    const [originalColumnList, setoriginalColumnList] = useState([]);
    const [searchText, setsearchText] = useState('')
    const [Purpose, setPurpose] = useState('Data Quality');
    const [priority, setPriority] = useState('Low');
    const [assignee, setassignee] = useState([])
    const [message, setMessage] = useState('')
    const [notifySubject, setnotifySubject] = useState(null)
    const [isAttachFile, setisAttachFile] = useState(false)

    const [selectedAssignee, setselectedAssignee] = useState([])

    let currentAssigneeList = useRef([])
    const store = useStore();
    const { flowId } = useParams();

    const dispatch = useDispatch();

    const [toggleDefaultView, setToggleDefaultView] = useState(false);



    const columnListRef = useRef([])

    const getAssigneeId = () => {
        let userid = []
        assignee.forEach((ele) => {
            if (selectedAssignee.includes(ele.value)) {
                userid.push(ele.key)
            }
        })

        return userid
    }



    useEffect(() => {
        if (columnList && columnList.length) {
            if (!notifySubject) {

                setisrunDisabled(true)
            }
            columnListRef.current = columnList;

            let cloneOriginalColList = cloneDeep(originalColumnList);

            cloneOriginalColList.forEach((col) => {
                let checkedIndex = columnList.findIndex(item => item?.id === col.id);
                if (checkedIndex > -1) {
                    col.checked = columnList[checkedIndex]['checked'];

                }

            })
            setoriginalColumnList(cloneOriginalColList);
            // return () => {
            let currentFlowsElements = cloneDeep(store.getState().Lab.flowsElements)
            let nodes = currentFlowsElements?.nodes?.map(item => {
                if (item.id === currentTransformer.id) {
                    let list = columnListRef.current.filter(item => item?.checked);

                    // if (list?.length) {
                    item.content.orderList = columnListRef.current;
                    item.content.purpose = Purpose;
                    item.content.priority = priority;
                    item.content.assignee = selectedAssignee//getAssigneeId();
                    item.content.selectedAssignee = selectedAssignee;
                    item.content.subject = notifySubject;
                    item.content.description = message
                    item.content.attach_file = isAttachFile

                    item.content.appliedFilterCriteria = list?.length ? list : [];
                    // }

                }
                return item
            })
            // console.log(nodes,columnList)
            let finalFlowsElements = cloneDeep(flowsElements)
            finalFlowsElements['nodes'] = nodes;
            setupdatedFlowRedux(finalFlowsElements)
            // store.dispatch(updateFlowsElements(finalFlowsElements))
            // }

        }

    }, [columnList, Purpose, priority, selectedAssignee, notifySubject, message, isAttachFile])



    const Selectall = (checked) => {
        if (checked) {
            let temp = columnList.map((ele) => {
                ele.checked = true;
                return ele;
            });

            setshowCloseWarning(true)

            setcolumnList([...temp]);

        } else {
            let temp = columnList.map((ele) => {
                ele.checked = false;
                return ele;
            });
            setshowCloseWarning(true)

            setcolumnList([...temp]);
        }
    };

    const getDataType = (actualDataType = '', index) => {

        if (actualDataType.includes('(')) {
            return actualDataType.split('(')[0]
        } else {
            return actualDataType
        }

    }
    const getDataTypeFormat = (actualDataType = '', index) => {

        if (actualDataType.includes('(')) {
            let splitStr = actualDataType.split('(');

            return splitStr[1]?.split(')')[0];
        } else {
            return ''
        }

    }

    const createColumnsList = (target = [], source = [], prop) => {

        let newColumnList = [];

        if (!target?.length) {
            source.forEach(sourceElement => {
                sourceElement['showError'] = false
                sourceElement['dataType'] = getDataType(sourceElement?.Type)
                sourceElement['format'] = getDataTypeFormat(sourceElement?.Type)
                sourceElement['text'] = sourceElement.text
                newColumnList.push(sourceElement);
            })
            return newColumnList;
        } else {

            let elementFound = iselementFound();
            let noneFindElement = isnoneFindElement();


            if (elementFound?.length && noneFindElement?.length) {
                newColumnList = [...elementFound, ...noneFindElement]
                setshowResetColumns(true);
                setisrunDisabled(true)
            }
            else if (elementFound?.length) {
                newColumnList = [...elementFound]

            } else if (noneFindElement?.length) {
                newColumnList = [...noneFindElement]
                setshowResetColumns(true);
                setisrunDisabled(true)


            } else {
                setshowResetColumns(false)
                setisrunDisabled(false)



            }
            let cloneSource = cloneDeep(source)

            const getChecked = (column) => {
                if (elementFound?.length) {
                    return elementFound.find(item => item?.Name === column?.Name)?.checked
                } else if (target?.length) {

                    return target.find(item => item?.Name === column?.Name)?.checked
                }
            }
            cloneSource = cloneSource.map(sourceElement => {

                sourceElement['showError'] = false;
                sourceElement['checked'] = getChecked(sourceElement) ?? false;

                sourceElement['dataType'] = getDataType(sourceElement?.Type)
                sourceElement['format'] = getDataTypeFormat(sourceElement?.Type)
                sourceElement['text'] = sourceElement?.text

                return sourceElement;
            })


            let mergeList = (mergeArrayByProperty(cloneSource, newColumnList, 'Name', true))  //----------------------------this will return unique column remove duplicates from source columns i.e if duplicate found it will take newColumnList column
            let newcurrentTransformer = {}

            if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

                newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
            } else {
                newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

            }

            let orderList = newcurrentTransformer?.content?.orderList;
            return orderRetainCheck(orderList, mergeList);
        }

        function isnoneFindElement() {

            if (target?.length) {
                let targetElement = cloneDeep(target)
                source.forEach(sourceElement => {
                    targetElement = targetElement.filter((item) => {

                        if (item['Name'] !== sourceElement['Name']) {
                            item['showError'] = true;
                            item['checked'] = true;
                            item['text'] = sourceElement['text']

                            return item;
                        }
                        // else if (item['dataType'] && item['dataType'] !== sourceElement['Type']) {
                        //     item['dataType'] = getDataType(sourceElement?.Type)
                        //     return item;
                        // }
                    })
                })
                return targetElement
            }

        }

        function iselementFound() {

            if (target?.length) {
                let targetElement = cloneDeep(target)
                let foundEleList = []
                source.forEach(sourceElement => {
                    targetElement.forEach((item) => {

                        if (item['Name'] === sourceElement['Name']) {
                            let obj = cloneDeep(sourceElement)
                            obj['showError'] = false;
                            obj['dataType'] = getDataType(sourceElement?.Type)
                            obj['checked'] = true;
                            obj['text'] = item['text']

                            foundEleList.push(obj)
                        }
                    })
                })
                return foundEleList


            }

        }



    }


    const getUsersList = async (index) => {
        let response;
        try {
            response = await store.dispatch(
                // getUserGroupList({ method: "GET", endPoint: "group_manager/user/", payload: {}, showLoader: false })
                getAlertAssigneeList(flowId)
            );
            if (response.status === "success") {
                let children = []
                let user = response.data;
                user.forEach((ele) => {

                    children.push({ key: ele.id, Name: ele.email, Type: "", value: ele.email })

                })

                setassignee([...children]);
            }
        } catch (error) { }
    };
    useEffect(() => {
        setshowLoader(true)
        const getColumn = async () => {

            let InputTransformer = dispatch(getInputTransformerDetails());

            let inputColumns = [];

            if (InputTransformer && InputTransformer?.length) {

                inputColumns = await dispatch(checkOutcomeColumnPresent(InputTransformer[0]));

            }
            let newcurrentTransformer = {}

            if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

                newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
            } else {
                newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

            }


            if (InputTransformer && InputTransformer?.length && inputColumns?.length) {
                getUsersList();

                let column = []

                column = createColumnsList(newcurrentTransformer?.content?.appliedFilterCriteria, inputColumns, 'Name')

                if (newcurrentTransformer?.content?.purpose && newcurrentTransformer?.content.priority) {
                    setPurpose(newcurrentTransformer?.content?.purpose);
                    setPriority(newcurrentTransformer?.content?.priority)
                }

                if (newcurrentTransformer?.content?.selectedAssignee) {
                    setselectedAssignee(newcurrentTransformer?.content?.selectedAssignee)
                }
                if (newcurrentTransformer?.content?.subject) {
                    setnotifySubject(newcurrentTransformer?.content?.subject)
                }
                if (newcurrentTransformer?.content?.description) {
                    setMessage(newcurrentTransformer?.content?.description)
                }
                if (newcurrentTransformer?.content?.attach_file) {
                    setisAttachFile(newcurrentTransformer?.content?.attach_file)
                }
                if (column?.length) {

                    setcolumnList([...column])
                    setoriginalColumnList([...column])
                    setshowLoader(false)
                    setToggleDefaultView(false);
                    setshowOverlay(false)

                } else {

                    setcolumnList([])
                    setshowOverlay(true)
                    setshowLoader(false)


                }



            } else if (!InputTransformer?.length) {
                setToggleDefaultView(true);
                setshowLoader(false)


            } else if (!inputColumns?.length) {
                setshowOverlay(true);
                setshowLoader(false)

            }
        }
        getColumn();

    }, []);


    const currentConditionTypeRef = useRef(null)
    const onResetEvent = () => {

        currentConditionTypeRef.current = {
            message: 'Columns that are not found in input source that will be removed',
            onConfirm: () => {
                setreOrderRender(false)
                let cloneCols = cloneDeep(columnList);
                cloneCols = cloneCols.filter((item) => !item.showError);

                if (cloneCols?.length) {

                    setcolumnList([...cloneCols]);
                    setoriginalColumnList([...cloneCols])
                }
                setopenWarningModal(false);
                setshowResetColumns(false);
                setisrunDisabled(false)

                setTimeout(() => {
                    setreOrderRender(true)
                }, 50);
                return true
            },
            onCancel: () => {
                setopenWarningModal(false);
                return false
            }
        }
        setopenWarningModal(true)
    }
    const getActualData = (columnListClone) => {

        let clone = cloneDeep(columnListClone);
        // console.log(clone,originalColumnList)
        if (searchText !== '' && clone?.length) {
            return clone?.filter((item) => item.Name?.toLowerCase().includes(searchText?.toLowerCase()))
        } else {

            return originalColumnList;
        }

    }


    // const onChecked = (text, type) => {

    //     let tempOutcomeColumns = [...columnList]

    //     if (tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]?.checked) {
    //         setallChecked(false)

    //         tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]['checked'] = false
    //     }
    //     else {
    //         tempOutcomeColumns.filter(item => (item.Type === type && item.Name === text))[0]['checked'] = true
    //     }



    //     setcolumnList([...tempOutcomeColumns])
    // }

    // const selectall = (checked) => {

    //     if (checked) {
    //         setallChecked(true)
    //         let tempOutcomeColumns = [...columnList]
    //         tempOutcomeColumns.forEach(item => item.checked = true)
    //         setcolumnList([...tempOutcomeColumns])
    //     } else {
    //         setallChecked(false)
    //         let tempOutcomeColumns = [...columnList]
    //         tempOutcomeColumns.forEach(item => item.checked = false)
    //         setcolumnList([...tempOutcomeColumns])

    //     }
    // }

    const isAllSelected = () => { //return true
        if (getActualData(columnList)?.length) {

            return getActualData(columnList).every((ele) => ele?.checked === true)
        } else {
            return false
        }
    }

    return (
        <>
            {
                getConfirmationModal({
                    open: openWarningModal,
                    setOpen: setopenWarningModal,
                    body: currentConditionTypeRef?.current?.message,
                    onConfirm: currentConditionTypeRef?.current?.onConfirm,
                    onCancel: currentConditionTypeRef?.current?.onCancel
                })
            }
            {!showLoader ? <div className="transformer-details-maindiv pt-2">
                {showResetColumns ? (
                    <Buttons
                        props={{
                            tooltip: 'Reset Columns',
                            buttonText: "Reset Columns",
                            buttonClassName: "custom-btn-outline btn-outline reset-col-btn custom-btn btn-with-text ml-2 mr-2",
                            buttonEvent: onResetEvent,
                            toggleBtnValue: "",
                            ImgSrc: () => <RESETFILTER />,
                            isDisable: !isExecutePermission,
                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            toggleBtnOption: null,
                        }}
                    />
                ) : null}

                <>
                    {!toggleDefaultView ? (
                        <div className="d-flex alert-main-div h-100">
                            <div className="col-8 custom-dashed-border-right">
                                <p className="section-caption">
                                    Alert Details
                                </p>
                                <div className="d-flex custom-dashed-border-bottom pl-1" style={{ height: "5.9375rem" }}>
                                    <div className="col-4 m-0 p-0">
                                        <label className="paragraph">
                                            Purpose
                                        </label>
                                        <Radio.Group disabled={!isExecutePermission} options={plainOptions} onChange={(e) => setPurpose(e.target.value)} value={Purpose} />
                                    </div>
                                    <div className="col-4 ml-2">
                                        <label className="paragraph">
                                            Priority
                                        </label>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div onClick={() => setPriority('Low')} className={`cursor-pointer ${!isExecutePermission ? 'priority-disable' : ''}  ${priority === 'Low' ? 'priority-active' : 'priority-outer-div'}`}>
                                                <LOWPRIORITY /> <span className="ml-2"> Low</span>
                                            </div>
                                            <div onClick={() => setPriority('Medium')} className={`cursor-pointer  ${!isExecutePermission ? 'priority-disable' : ''}  ml-2 ${priority === 'Medium' ? 'priority-active' : 'priority-outer-div'}`}>
                                                <MEDIUMPRIORITY /> <span className="ml-2">Medium</span>
                                            </div>
                                            <div onClick={() => setPriority('High')} className={` cursor-pointer  ${!isExecutePermission ? 'priority-disable' : ''}  ml-2 ${priority === 'High' ? 'priority-active' : 'priority-outer-div'}`}>
                                                <HIGHPRIORITY /><span className="ml-2"> High</span>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <p className="section-caption">
                                    Notification
                                </p>
                                <div className="d-flex  pl-1">
                                    <div className="col-4 pl-0 ml-0">
                                        <label className="paragraph">
                                            Subject <sup className="astrik">*</sup>
                                        </label>
                                        <input type="text" placeholder='Please Enter Subject ' onChange={(e) => { setnotifySubject(e.target.value); 
                                            if(!showResetColumns){
                                                setisrunDisabled(false)
                                            }
                                         }} value={notifySubject} disabled={!isExecutePermission} className={"form-control"} />

                                    </div>
                                    <div className="col-4 ">
                                        <label className="paragraph">
                                            Description <span style={{ fontSize: "10px", fontStyle: "italic", }}>{"(optional)"}</span>
                                        </label>
                                        <input type="text" placeholder='Please check the alert for more details' onChange={(e) => setMessage(e.target.value)} value={message} disabled={!isExecutePermission} className={"form-control"} />

                                    </div>
                                    <div className="col-4">
                                        <label className="paragraph">
                                            Assignee
                                        </label>
                                        {/* <MultiSelection props={{
                                            label: "", placeholder: "Select assignee", className: "custom-select-dropdown", dropDownValue: selectedAssignee, onClickEvent: (value) => {
                                                setselectedAssignee(value ? [...value] : [])
                                            }, optionList: assignee, children: [], isDisable: !isExecutePermission
                                        }} /> */}
                                        <div className="custom-multi-select custom-select-dropdown IN-Select">
                                            <Select
                                                mode="tags"
                                                maxTagPlaceholder={(omittedValues) => (
                                                    <Tooltip
                                                        overlayStyle={{
                                                            pointerEvents: 'none',
                                                        }}
                                                        title={omittedValues.map(({ value, Name }) => value).join(', ')}>
                                                        <span>More...</span>
                                                    </Tooltip>
                                                )}
                                                style={{
                                                    width: '100%',
                                                }}
                                                showSearch={true}
                                                allowClear={true}
                                                placeholder="Select assignee"
                                                className="custom-select-dropdown"
                                                value={selectedAssignee}
                                                maxTagCount={'responsive'}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    setselectedAssignee(value ? [...value] : [])
                                                }}
                                                options={assignee}
                                                filterSort={
                                                    (optionA, optionB) =>
                                                        optionA?.value?.toString()?.toLowerCase()?.localeCompare(optionB?.value?.toString()?.toLowerCase())
                                                }
                                                disabled={!isExecutePermission}
                                            /></div>
                                    </div>
                                </div>
                                <div className="d-flex mt-4 align-items-center">
                                    <Switch
                                        className={`custom-switch-btn mr-2 `}
                                        checkedChildren="Yes" unCheckedChildren="No"
                                        checked={isAttachFile}
                                        onChange={setisAttachFile}
                                        disabled={!isExecutePermission}
                                    />
                                    <span className="paragraph">Attach alert data as an email attachment.</span>
                                </div>


                            </div>
                            <div className="col-4">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        {/* 
                                        <p className="section-caption">
                                            Input Columns
                                        </p> */}
                                        <div className='d-flex align-items-center pt-2 pl-1 ml-1'><Checkbox checked={isAllSelected()} disabled={window.location.href.includes("viewFlows") ? true : false} onChange={(e) => Selectall(e.target.checked)}></Checkbox>  <p className=" ml-3 section-caption">
                                            Input Columns
                                        </p></div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <SearchInput searchData={searchText} setSearchData={setsearchText} />
                                    </div>

                                </div>

                                {getActualData(columnList).length ? <DndAlertList
                                    reOrderRender={reOrderRender} setreOrderRender={setreOrderRender}

                                    actualData={getActualData(columnList)}
                                    originalColumnList={originalColumnList}
                                    stopDraggingRow={
                                        searchText !== '' || !isExecutePermission ? true : false
                                    }
                                    isExecutePermission={isExecutePermission}
                                    setupdatedcolumnList={setoriginalColumnList}
                                    setshowCloseWarning={
                                        setshowCloseWarning

                                    }

                                    setNewColumnList={setcolumnList}
                                    Selectall={Selectall}
                                /> : !getActualData(columnList).length && searchText !== '' ? <div className="d-flex align-items-center justify-content-center label h-100"><NoResultsFound imgheight="120" imgwidth="120"></NoResultsFound></div> : null}

                            </div>
                        </div>

                    ) : (
                        <div className="source-not-found">
                            <SCRIPTDEFAULTVIEW />
                        </div>
                    )}
                </>

            </div > :
                showSimpleLoading('Please wait loading transformer details...')
            }




        </>
    );
};
const mapStateToProps = (state) => {
    return {
        flowsElements: state.Lab.flowsElements,
        currentTransformer: state.Lab.currentTransformer,
    };
};
export default connect(mapStateToProps)(AlertTransformerIndex);
