import React from 'react'
import CommonModal from '../../CommonModal/CommonModal';
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT, ICON_CATALOG, ICON_FILE, ICON_REDIRECT } from '../../newIconSource';
import Buttons from '../../button/Buttons';
import { Literals } from '../../../modules/common/literals';
import ClaristaLoader from '../../claristaLoader/ClaristaLoader';
import { getReloadErrorTemplate, humanFileSize } from '../../../modules/common/helperFunctions';
import DataGridTable from '../../dataGridTable/DataGridTable';

const PulseFilePreviewModal = ({
    previewOpen = false,
    setPreviewOpen = () => {},
    setPreviewLoading = () => {},
    onPreviewModalClose = () => {},
    activeSource = 'pod',
    SOURCES = {},
    podDataRef = {},
    prevPodBtnVisibility,
    nextPodBtnVisibility,
    handlePreviousPod = () => {},
    handleNextPod = () => {},
    previewLoading = false,
    previewError = undefined,
    onPreview = () => {},
    currentDocument = {},
    uniqUUID = '',
    setUniqUUID = () => {},
    previewData = {},
    podDetails = {},
    setCurrentDocument = () => {},
    handlePreview = () => {},
    previewUrl = null
}) => {

  return (
    <CommonModal
        className='pulse-preview-modal'
        open={previewOpen}
        setOpen={setPreviewOpen}
        onClose={onPreviewModalClose}
        size='xl'
        header={'Preview Data'}
        body={
            <>
                <div className='d-flex align-items-center justify-content-between mt-n2'>
                    <div className='w-75'>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2 d-flex'>
                                {
                                    activeSource === SOURCES.pod ? <ICON_CATALOG height='1.5rem' width='1.5rem' /> : <ICON_FILE height='1.5rem' width='1.5rem' />
                                }
                            </div>
                            <div>
                                <label className='mb-0 text-uppercase fontInterMedium fontSizeHeading'>{podDataRef?.current?.table_name}</label>
                                <p className='mb-0 text-color-grey fontSizeLabel'>{podDataRef?.current?.pod_description}</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end' style={{gap: '.5rem'}}>
                        {
                            previewLoading || previewError || !previewUrl || previewUrl === "" ? ''
                            :
                            <Buttons
                                props={{
                                    buttonWrapperClass: '',
                                    buttonText: '',
                                    tooltip: 'Open document in new tab',
                                    tooltipPlacement: 'left',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon border rounded-circle p-3 bg-light",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        window.open(previewUrl)
                                    },
                                    ImgSrc: () => <ICON_REDIRECT />,
                                    isDisable: previewLoading || previewError || !previewUrl || previewUrl === "",
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        }
                        {
                            prevPodBtnVisibility ? ''
                            :
                            <Buttons
                                props={{
                                    buttonWrapperClass: '',
                                    buttonText: '',
                                    tooltip: 'Previous POD',
                                    tooltipPlacement: 'left',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon border rounded-circle p-3 bg-light",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handlePreviousPod()
                                    },
                                    ImgSrc: () => <ICON_ARROW_LEFT />,
                                    isDisable: prevPodBtnVisibility,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        }
                        {
                            nextPodBtnVisibility ? ''
                            :
                            <Buttons
                                props={{
                                    buttonWrapperClass: '',
                                    buttonText: '',
                                    tooltip: 'Next POD',
                                    tooltipPlacement: 'right',
                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon border rounded-circle p-3 bg-light",
                                    buttonEvent: (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleNextPod()
                                    },
                                    ImgSrc: () => <ICON_ARROW_RIGHT />,
                                    isDisable: nextPodBtnVisibility,
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }}
                            />
                        }
                    </div>
                </div>
                <div className='pulse-quick-preview-wrap p-0 mt-2 custom-virtual-data-table' style={{height: '32rem', maxHeight: '70vh'}}>
                    
                    {
                        activeSource === SOURCES.pod
                        ?
                        <>
                            {
                                    previewLoading
                                    ?   <ClaristaLoader isCancel={true}/>
                                    :   previewError 
                                        ?   getReloadErrorTemplate({
                                                errorMessage: previewError,
                                                onReload: () => onPreview()
                                            })
                                        :
                                        <DataGridTable
                                            resp={previewData}
                                            requestedTableId={podDetails?.table_id}
                                            CurrentPODName={()=> {return podDetails?.table_name}}
                                            CurrentDomainName={()=> {return podDetails?.data_domain_name}}
                                            uniqUUID={uniqUUID}
                                            setUniqUUID={setUniqUUID}
                                            isSmallTable={true}
                                            gridHeight={'100%'}
                                        />
                            }
                        </>
                        :
                        <div className='new-pulse-source-file-viewer h-100'>
                            <div className='d-flex flex-column h-100' style={{gap: '0.5rem', overflowY: 'auto', overflowX: 'hidden'}}>
                                {
                                    podDataRef?.current?.data?.assets?.length === 0
                                    ?
                                    <div className='px-3 py-2 text-black-50 fontSizeSmall'>No Documents Found</div>
                                    :
                                    podDataRef?.current?.data?.assets?.map(a => (
                                        <div key={`asset-${a?.id}`} 
                                            className={`px-3 py-2 border rounded file-prev-itm ${currentDocument?.id === a?.id ? 'active' : ''} ${previewLoading ? 'disabled' : ''}`}
                                            onClick={() => {
                                                if(currentDocument?.id === a?.id || previewLoading) {
                                                    return
                                                }
                                                setCurrentDocument(a)
                                                handlePreview(podDataRef?.current, a?.id)
                                            }}
                                        >
                                            <div className='fontInterMedium fontSizeLabel mb-1'>
                                                {a?.file_name ?? 'Unknown'}
                                            </div>
                                            <div className='fontSizeExtraSmall text-black-50'>
                                                {humanFileSize(a?.file_size ?? 0)}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='h-100 pl-2'>
                                <div className='bg-dark h-100 position-relative' style={{borderRadius: '1rem', overflow: 'hidden'}}>
                                    <div className='d-block bg-light h-100 w-100 position-absolute' style={{top: 0, left: 0, zIndex: previewLoading || previewError ? 0 : -1}}>
                                        {
                                            previewLoading ? <ClaristaLoader/>
                                            :
                                            previewError
                                            ?
                                            <>
                                                <div className='d-flex flex-column align-items-center h-100 justify-content-center'>
                                                    {getReloadErrorTemplate({
                                                        errorMessage: previewError,
                                                        hideReloadBtn: true,
                                                        wrapClass: 'w-100'
                                                    })}
                                                </div>
                                            </>
                                            :   ''
                                        }
                                    </div>
                                    <iframe
                                        title={previewUrl}
                                        src={previewUrl}
                                        style={{
                                            height: 'calc(100% - 10px)', 
                                            width: '100%', 
                                            border: 'solid 1px #dcdcdc', 
                                            borderRadius: '6px'
                                        }}
                                        onLoad={() => setPreviewLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        }
        hideFooter={true}
    />
  )
}

export default PulseFilePreviewModal