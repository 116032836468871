import React, { useEffect, useState } from 'react'
import LandingpageFirstHeader from '../../modules/common/LandingpageFirstHeader'
import Legend from './components/Legend'
import { LandingContentTabs } from './components/LandingContentTabs'
import LandingDomainTabs from './components/LandingDomainTabs'
import './mainLandingPage.scss'
import { Modal } from 'reactstrap'
import LandingPageTag from '../../modules/dataCatalogue/landingPage/components/LandingPageTag'
import { ICON_FILTER, ICON_GROUP, ICON_TAG, ICON_TAG_GROUP, ICON_TAG_LIST } from '../newIconSource'
import { Literals } from '../../modules/common/literals'
import Buttons from '../button/Buttons'
import CatalogFilter from '../CommonModal/catalogFilter/CatalogFilter'
import { FILLEDSTAR, FILTER, RESETFILTER, STAR } from '../iconSource'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, Tag, Dropdown } from 'antd'
import { getUserSelectedDomain, setUserSelectedDomain } from '../../../store/actions'

import { useLocation } from 'react-router-dom'
const { CheckableTag } = Tag;

const placeholderMap = {
    pod: 'Search pod name, description, tags, user email...',
    business: 'Search display names...',
    document: 'Search documents...',
    flows: 'Search flow name, description, user email...',
    alert: 'Search alert name, description, user email...',
    scheduler: 'Search scheduler name, description, user email...',
    dashboard: 'Search dashboards...',
}

const MainLandingPage = ({
    page = 'navigator',
    IndexComponent = () => <></>,
    tabsMenuList = [],
    defaultTabKey = '',
    searchPlaceholder = 'Search here...'
}) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const prevActiveDomain = location?.state?.domain ?? null
    const fromDictionary = location?.state?.fromDict ?? false

    const [searchTerm, setSearchTerm] = useState('')
    const [activeTabKey, setActiveTabKey] = useState(defaultTabKey)
    const [activeDomain, setActiveDomain] = useState('')
    const [isDomainFailed, setDomainFailed] = useState(false)
    const [isDataLoading, setDataLoading] = useState(false)
    const [optionOpen, setOptionOpen] = useState(false)
    const [openTags, setOpenTags] = React.useState(false)
    const [groupByTags, setGroupByTags] = React.useState(true)
    const [showPublished, setshowPublished] = useState(false)
    const [showFavourite, setshowFavourite] = useState(false)
    const [selectedSchedulerFilter, setselectedSchedulerFilter] = useState('')
    const [filterList, setFilterList] = React.useState({ connections: [], tags: [], types: [] })

    const { userSelectedDomain } = useSelector(state => state.UserManagement.DataDomain)

    const moduleRef = React.useRef({})

    useEffect(() => {
        if (defaultTabKey) {
            setActiveTabKey(defaultTabKey)
        }
    }, [defaultTabKey])

    React.useEffect(() => {
        if(!!userSelectedDomain && userSelectedDomain?.name) {
            setActiveDomain(prev => {
                if(prev !== '')
                return userSelectedDomain?.id
            })
        }
    }, [userSelectedDomain])

    useEffect(() => {
        const locationDomainId = location?.state?.domainId
        if (locationDomainId) {
            setActiveDomain(locationDomainId)
            dispatch(setUserSelectedDomain({ data_domain_id: locationDomainId }, false))
                .catch(err => console.error(err?.message))
        }
        else {
            dispatch(getUserSelectedDomain())
                .then(res => {
                    const domain = res?.data?.domain ?? undefined

                    if(fromDictionary && (prevActiveDomain === 0)) {
                        setActiveDomain(0)
                    }
                    else if (domain && domain?.id) {
                        setActiveDomain(domain?.id)
                    }
                    else {
                        setActiveDomain(domain?.id ?? 0)
                    }
                })
                .catch(err => {
                    setDomainFailed(true)
                    console.error(err?.message)
                })
        }
    }, [])

    const handleScroll = (e) => {
        // let el = document.getElementById('landing-search-lg')
        let el2 = document.querySelector('.rel-dom-tb-wrap')
        let wrap = document.querySelector('.clsta-land-legend')
        let title = document.querySelector('.leg-title-cont')
        const thisTop = parseInt(e.target.scrollTop)
        
        if (thisTop > 230) {
            wrap.classList.add('leg-shrink')
            el2.classList.add('stay')
            title.classList.add('fade')
            return
        }
        else if (thisTop < 130) {
            wrap.classList.remove('leg-shrink')
            el2.classList.remove('stay')
            title.classList.remove('fade')
        }
    }

    const optionList = [
        {
            label: <div className={`d-flex align-items-center dbox-opt-itm dbox-opt-itm-reset justify-content-between custom-border-bottom`}>
                <b> Filters </b>
                <span className='cursor-pointer  mb-2' onClick={() => {
                    setselectedSchedulerFilter('')
                }}><RESETFILTER /></span>
                
            </div>,
            key: '3',
            disabled: false
        },
        {
            label: <span className={`dbox-opt-itm ${selectedSchedulerFilter === 'active' ? 'dbox-opt-itm-active' : ''}`} onClick={() => setselectedSchedulerFilter('active')}>
                Active
            </span>,
            key: '1',
            disabled: false
        },
        {
            label: <span className={`dbox-opt-itm ${selectedSchedulerFilter === 'inactive' ? 'dbox-opt-itm-active' : ''}`} onClick={() => setselectedSchedulerFilter('inactive')}>
                Inactive
            </span>,
            key: '2',
            disabled: false
        },


    ]

    const onTagsChangeCallback = () => {
        if(moduleRef?.current && moduleRef?.current?.reload) {
            moduleRef?.current?.reload(activeDomain)
        }
    }

    return (
        <div>
            <LandingpageFirstHeader title={page} />
            <div id='main-lpg-wrap' onScroll={handleScroll} className={`main-lpg-id-wrap ${page}`}>
                <Legend
                    page={page}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchPlaceholder={placeholderMap[activeTabKey] ?? searchPlaceholder}
                    activeTabKey={activeTabKey}
                />
                <div className='main-lpg-content'>
                    <div className={`rel-dom-tb-wrap`}>
                        <h4 className='fontInterSemiBold h6 text-dark relevant-list-head'>Relevant list for you</h4>
                        <div className='d-flex align-items-center justify-content-between'>
                            <LandingContentTabs
                                setSearchTerm={setSearchTerm}
                                tabItems={tabsMenuList}
                                activeTabKey={activeTabKey}
                                setActiveTabKey={setActiveTabKey}
                            />
                            <div className='d-flex align-items-center justify-content-end' style={{zIndex: '1'}}>
                            {/* <div className='ex-comp-wrap'> */}
                                {
                                    true ? <div></div>
                                    :
                                    <LandingDomainTabs
                                        activeDomain={activeDomain}
                                        setActiveDomain={setActiveDomain}
                                        isDomainFailed={isDomainFailed}
                                        setDomainFailed={setDomainFailed}
                                        activeTabKey={activeTabKey}
                                        isDataLoading={isDataLoading}
                                    />
                                }
                                <div className='d-flex justify-content-end catalog-extra-btns'>
                                    {
                                        activeTabKey === 'scheduler'

                                            ? <Dropdown
                                                destroyPopupOnHide={true}
                                                menu={{
                                                    items: optionList,
                                                    onClick: (e) => e.domEvent.stopPropagation()
                                                }}
                                                arrow={true}
                                                trigger={["hover"]}
                                                placement="bottomRight"
                                                onOpenChange={(bool) => setOptionOpen(bool)}
                                                overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                                            >

                                                <button
                                                    className={` ml-2
                                                    ${optionOpen ? "custom-btn-primary" : "custom-btn-outline"} 
                                                    custom-btn btn-with-icon border-0 ${optionOpen || selectedSchedulerFilter ? 'rdg-filter-active' : ''}`
                                                    }

                                                >
                                                    <FILTER />
                                                </button>
                                            </Dropdown> : null
                                    }

                                    {
                                        activeTabKey === 'pod' &&
                                        <>
                                            <Tooltip placement='bottom' title={"Show Published Pod"}>
                                                <CheckableTag
                                                    key={'tag'}
                                                    checked={showPublished}
                                                    color={showPublished ? '#d3d3d3' : '#2c8348'}
                                                    onChange={(checked) => setshowPublished(checked)}
                                                >
                                                    {'Published'}
                                                </CheckableTag>
                                            </Tooltip>
                                            <CatalogFilter
                                                id="landingPodFilter"
                                                filterList={filterList}
                                                setFilterList={setFilterList}
                                                showApplyButton={false}
                                            />
                                        </>
                                    }

                                    {
                                        ['pod', 'document', 'flows', 'scheduler']?.includes(activeTabKey)
                                            ?
                                            <>
                                                <Buttons
                                                    props={{
                                                        buttonId: 'tagLiBtn',
                                                        tooltip: "Tags",
                                                        tooltipPlacement: "top",
                                                        buttonText: "",
                                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon ml-2`,
                                                        buttonEvent: () => { setOpenTags(true) },
                                                        ImgSrc: () => <ICON_TAG />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                                <Modal
                                                    size="md"
                                                    isOpen={openTags}
                                                    className="custom-modal"
                                                    centered={true}
                                                >
                                                    <LandingPageTag
                                                        onClose={() => setOpenTags(false)}
                                                        domainId={activeDomain}
                                                        onTagsChangeCallback={onTagsChangeCallback}
                                                    />
                                                </Modal>
                                                <Buttons
                                                    props={{
                                                        tooltip: !groupByTags ? "Group By Tags" : "Ungroup",
                                                        tooltipPlacement: "top",
                                                        buttonText: "",
                                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon ml-2
                                                        ${groupByTags ? 'rdg-filter-active' : ''}
                                                    `,
                                                        buttonEvent: () => {
                                                            setGroupByTags(!groupByTags)
                                                        },
                                                        ImgSrc: () => <ICON_TAG_GROUP />,
                                                        isDisable: false,
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                            </>
                                            : ''
                                    }
                                    {
                                        // activeTabKey !== 'scheduler' &&
                                        !['dashboard', 'business']?.includes(activeTabKey)
                                        // activeTabKey !== 'dashboard'
                                        //     && activeTabKey !== 'business'
                                            ? <Buttons
                                                props={{
                                                    buttonId: 'landingFavBtn',
                                                    tooltip: showFavourite ? "Hide Favourite" : "Show Favourite",
                                                    tooltipPlacement: "top",
                                                    buttonText: "",
                                                    buttonClassName: `custom-btn-outline custom-btn btn-with-icon ml-2 ${showFavourite ? 'active' : ''}`,
                                                    buttonEvent: () => { setshowFavourite(!showFavourite) },
                                                    ImgSrc: () => showFavourite ? <FILLEDSTAR /> : <STAR />,
                                                    isDisable: false,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                }}
                                            /> : null
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lpg-comp-wrap'>

                        {<IndexComponent
                            activeDomain={activeDomain}
                            searchTerm={searchTerm}
                            activeTabKey={activeTabKey}
                            showFavourite={showFavourite}
                            filterList={filterList}
                            setFilterList={setFilterList}
                            showPublished={showPublished}
                            setDataLoading={setDataLoading}
                            isDataLoading={isDataLoading}
                            groupByTags={groupByTags}
                            selectedSchedulerFilter={selectedSchedulerFilter}
                            componentRef={moduleRef}
                        />}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainLandingPage