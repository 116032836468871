import React, { useEffect, useState } from 'react'
import MultiSelection from '../../../Common/dropDown/MultiSelection';
import { Checkbox, Select, Tooltip } from 'antd';
import { SetSchedulerPayload, getSchedulerAssignee } from '../../../../store/modules/scheduler/action';
import { useSelector, useStore } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getObjectLength } from '../../common/helperFunctions';
import { emitToastNotification } from '../../../../helpers/toast_helper';

function SchedulerNotification({ flowId, isExecutePermission, dashboardId, refreshUserList }) {
    const store = useStore()
    const plainOptions = ['ON SUCCESS', 'ON FAILED'];
    const [assignee, setassignee] = useState([])
    const schedulerPayload = useSelector(state => state.Scheduler.schedulerPayload)


    const getUsersList = async (index) => {
        let response;
        try {
            response = await store.dispatch(

                getSchedulerAssignee(schedulerPayload['task_type']?.toLowerCase() === 'flow' ? flowId : undefined, schedulerPayload['task_type']?.toLowerCase() === 'dashboard' ? dashboardId : undefined)
            );
            if (response.status === "success") {
                let children = []
                let user = response.data;
                user.forEach((ele) => {

                    children.push({ key: ele.id, Name: ele.email, Type: "", value: ele.email })

                })

                setassignee([...children]);
            }
        } catch (error) { }
    };
    useEffect(() => {
        getUsersList()
    }, [refreshUserList])



    const validateEmails = (emails, pattern) => {
        let wrongEmailFound = false;
        emails.forEach(email => {
            if (!pattern.test(email)) {
                wrongEmailFound = true;
                emitToastNotification('warning', `${email} is not a valid email address.`)
            }
        });

        return wrongEmailFound ? false : true
    };



    const getAssigneeId = (assigneeList) => {

        let userid = []
        assignee.forEach((ele) => {
            if (assigneeList.includes(ele.value)) {
                userid.push(ele.key)
            }
        })

        return userid
    }

    const getSelectedAssignee = () => {
        let schedulerPayload = store.getState().Scheduler.schedulerPayload;
        let assigneeList = [];

        let userid = []
        if (schedulerPayload['recipients'] && getObjectLength(schedulerPayload['recipients']) && schedulerPayload['recipients']['non_user_email']?.length) {
            assigneeList = [...new Set(schedulerPayload['recipients']['non_user_email'])];


            // assignee.forEach((ele) => {
            //     if (assigneeList.includes(ele.key)) {
            //         userid.push(ele.value)
            //     }
            // })
        }

        return assigneeList
        // return userid
    }

    const getSelectedPurpose = () => {
        let schedulerPayload = store.getState().Scheduler.schedulerPayload;
        let list = [];
        if (schedulerPayload['recipients'] && getObjectLength(schedulerPayload['recipients']) && schedulerPayload['recipients']['status']?.length) {

            plainOptions.forEach((ele) => {
                if (schedulerPayload['recipients']['status']?.includes(ele?.replace('ON ', ''))) {
                    list.push(ele)
                }
            })
        }
        if (list?.length) {
            return list
        } else {
            setpayload(['ON SUCCESS'], 'checkvalue')
            return ['ON SUCCESS']
        }

    }

    const setpayload = (value, type) => {

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const newPayload = cloneDeep(schedulerPayload);

        if (type === 'checkvalue') {
            // setPurpose(value)
            if (newPayload?.recipients) {

                newPayload['recipients']['status'] = value?.map((item) => item?.replace('ON ', ''));
            }
        } else {
            // newPayload['recipients']['user'] = value// getAssigneeId(value);
            let isAllMailsValid = validateEmails(value, emailPattern);
            if (isAllMailsValid) {
                newPayload['recipients']['non_user_email'] = value// getAssigneeId(value);
            }
        }

        store.dispatch(SetSchedulerPayload(newPayload))



    }

    return (
        <div className="w-100">
            <div className="">

                <label className="label">
                    Assignee
                </label>
                <div className="custom-multi-select custom-select-dropdown IN-Select">
                    <Select
                        mode="tags"
                        maxTagPlaceholder={(omittedValues) => (
                            <Tooltip
                                overlayStyle={{
                                    pointerEvents: 'none',
                                }}
                                title={omittedValues.map(({ value, Name }) => value).join(', ')}>
                                <span>More...</span>
                            </Tooltip>
                        )}
                        style={{
                            width: '100%',
                        }}
                        showSearch={true}
                        allowClear={true}
                        placeholder="Select assignee"
                        className="custom-select-dropdown"
                        value={getSelectedAssignee()}
                        maxTagCount={'responsive'}
                        onChange={(value) => {
                            setpayload(value, 'assignee')
                        }}
                        options={assignee}
                        filterSort={
                            (optionA, optionB) =>
                                optionA?.value?.toString()?.toLowerCase()?.localeCompare(optionB?.value?.toString()?.toLowerCase())
                        }
                        disabled={!isExecutePermission || window.location?.href?.includes('scheduler/view')}
                    /></div>
            </div>


            <div className="pt-3 pl-1">
                <Checkbox.Group disabled={!isExecutePermission || window.location?.href?.includes('scheduler/view')} options={plainOptions} onChange={(checkedValues) => setpayload(checkedValues, 'checkvalue')} value={getSelectedPurpose()} />
            </div>


        </div>
    )
}

export default SchedulerNotification