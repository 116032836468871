import React, { Component } from "react";

import { connect, useSelector } from "react-redux";
import { WithRouterHOC } from "../../services/WithRouterHOC";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

// Layout Related Components
import NewSidebar from "../Common/Sidebar/NewSidebar";
import { useLocation } from "react-router-dom";
import DomainTopStrip from "../modules/common/DomainTopStrip";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  componentDidMount() {
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }

    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }
  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };
  render() {
    const { isSidebarVisible, isSidebarCollapsed, activeRoute } = this.props;
    const searchParams = new URLSearchParams(document.location.search);
    const history_id = searchParams.get("history_id");

    return (
      <React.Fragment>
        <div id="layout-wrapper" className={history_id ? "older-version" : ""}>
          {/* <Sidebar theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile} /> */}
          <NewSidebar activeRoute={activeRoute} />
          <TopDomainComponent activeRoute={activeRoute} />
          <div
            className={`main-content
              ${
                "expand-content"
              // !isSidebarVisible ? "expand-content" : ""
              }
            `}
          >
            {this.props.children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(WithRouterHOC(Layout));


const TopDomainComponent = React.memo(({activeRoute}) => {
  const location = useLocation()
  const userSelectedDomain = useSelector(state => state.UserManagement.DataDomain.userSelectedDomain)
  const datadomainList = useSelector(state => state.UserManagement.DataDomain.datadomainList)
  const [isLightHeader, setIsLightHeader] = React.useState(false)

  React.useEffect(() => {
    const isLight = (() => {
      let el = document?.querySelector('#firstHeader')
      let flowHeader = document?.querySelector('.flow-header')
      
      if(el) {
        return el.classList.value.split(' ')?.includes('light-theme')
      }
      else if(flowHeader) return true
      return false
    })()
    
    setIsLightHeader(isLight)
  }, [activeRoute, location])

  const TopDomain = React.useCallback(() => {

    return <DomainTopStrip 
      isLight={isLightHeader} 
      userSelectedDomain={userSelectedDomain} 
      datadomainList={datadomainList}
      activeRoute={activeRoute} 
    />
  }, [activeRoute, datadomainList, isLightHeader, userSelectedDomain])

  return <>
    <TopDomain/>
  </>
})