import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Space, Switch, Tooltip } from 'antd'


import { EDIT, NEWSTOPICON, PENDINGICON, PIN, PINNEW, PLAY, RUNICON, STOPICON, STOPOCTAGON, TERMINATEDICON, UNPINNEW } from '../../Common/iconSource'
import { ICON_CALENDAR, ICON_CLOCK, ICON_EDIT, ICON_INFO, ICON_INFO_FILLED, ICON_SAVE, ICON_SCHEDULER } from '../../Common/newIconSource'
import { LandingSimpleTable } from '../../Common/landingSimpleTable/LandingSimpleTable'
import moment from 'moment'
import { dateformater, getConfirmationModal, getObjectLength, pxToRem, remToPx } from '../common/helperFunctions'
import { useStore } from 'react-redux'
import { deleteScheduleClusterAction, saveQuantity } from '../../../store/modules/cluster/Cluster'
import { cloneDeep } from 'lodash'


export const CoreTableList = ({
    clusterList,
    setClusterList,
    showStopped,
    stopCluster,
    startCluster,
    getClusterListApi,
    scheduleCluster,
    pinCluster,
    isEditPermission
}) => {


    const [data, setData] = useState([])
    const [optionOpen, setOptionOpen] = useState({});
    const [editId, setEditId] = useState(null)
    const [showWarningPopup, setshowWarningPopup] = useState(false);

    const store = useStore();

    const currentQuantity = useRef(null)
    const saveModalOpen = useRef(false)

    const dynamic = px => remToPx(pxToRem(px))

    const getoptionList = (item) => {
        return [
            {
                label: <span className={`dbox-opt-itm ${!isEditPermission || item?.state?.toLowerCase() === 'running' ? 'disabled' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!isEditPermission || item?.state?.toLowerCase() === 'running') {
                            return;
                        } else {
                            startCluster(item)
                            setOptionOpen((prev) => {
                                prev[item.id] = false;
                                return { ...prev }
                            })
                        }

                    }}>
                    <PLAY />
                    Start
                </span>,
                key: '1',
                disabled: !isEditPermission || item?.state?.toLowerCase() === 'running'

            },

            {
                label: <span className={`dbox-opt-itm  ${!isEditPermission || item?.state?.toLowerCase() === 'stopped' ? 'disabled' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!isEditPermission || item?.state?.toLowerCase() === 'stopped') {
                            return;
                        } else {
                            stopCluster(item);
                            setOptionOpen((prev) => {
                                prev[item.id] = false;
                                return { ...prev }
                            })
                        }


                    }}>
                    <NEWSTOPICON />
                    Stop
                </span>,
                key: '2',
                disabled: !isEditPermission || item?.state?.toLowerCase() === 'stopped'
            },
            // {
            //     label: <span className={`dbox-opt-itm  ${!isEditPermission ? 'disabled' : ''}`}
            //         onClick={(e) => {
            //             e.stopPropagation();
            //             e.preventDefault();
            //             pinCluster(item)
            //         }}>
            //         <PIN color='#000' />

            //        {item.pinned?' Unpin Cluster':'Pin Cluster'}
            //     </span>,
            //     key: '3',
            //     disabled: !isEditPermission
            // },
            {
                label: <span className={`dbox-opt-itm  ${!isEditPermission ? 'disabled' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        scheduleCluster(item)
                    }}>
                    <ICON_CALENDAR color='#000' />
                    Schedule
                </span>,
                key: '3',
                disabled: !isEditPermission
            },
        ]
    }
    const listTemplate = {

        state: (stateType) => {
            switch (stateType?.toLowerCase()) {
                case 'stopped':
                    return <>
                        <div className='d-flex align-items-center justify-content-center al'>
                            <STOPICON />
                            <label className='text-with-ellipsis text-uppercase ml-2 mb-0 text-stop'>{stateType}</label>
                        </div>
                    </>

                case 'running':

                    return <>
                        <div className='d-flex align-items-center justify-content-center'>
                            {/* <i className='bx bx-spin'> */}
                            <RUNICON />
                            {/* </i> */}
                            <label className='text-with-ellipsis text-uppercase ml-2 mb-0 text-run'>{stateType}</label>
                        </div>
                    </>
                case 'terminated':

                    return <>
                        <div className='d-flex align-items-center justify-content-center'>
                            <TERMINATEDICON />
                            <label className='text-with-ellipsis text-uppercase ml-2 mb-0 text-terminate'>{stateType}</label>
                        </div>
                    </>
                case 'pending':

                    return <>
                        <div className='d-flex align-items-center justify-content-center'>
                            <PENDINGICON />
                            <label className='text-with-ellipsis text-uppercase ml-2 mb-0 text-pending'>{stateType}</label>
                        </div>
                    </>
                default:
                    return <>
                        <div className='d-flex align-items-center'>
                            <PENDINGICON />
                            <label className='text-with-ellipsis text-uppercase ml-2 mb-0 table-content-color'><b>{stateType}</b></label>
                        </div>
                    </>
            }

        },


        size: (text = '') => {
            return <>
                <div className='table-content-color position-relative '>
                    <Tooltip placement='topLeft' title={text}> {text ?? <div className='ml-4 gray-color-NA'>N/A</div>}</Tooltip>

                </div>
            </>
        },
        capacity: (text = '') => {
            return <>
                <div className='table-content-color position-relative '>
                    <Tooltip placement='topLeft' title={text}> {text ?? <div className='ml-4 gray-color-NA'>N/A</div>}</Tooltip>

                </div>
            </>
        },
        quantity: (quantity, index, onQuantityChange, disabled) => {
            return <>
                {/* onBlur={(e) => { e.preventDefault(); setEditId(null); getClusterListApi(showStopped)}} */}
                <div className='table-content-color ml-1  d-flex align-items-center justify-content-start' onBlur={(e) => {
                    e.preventDefault();
                    if (!saveModalOpen.current) {
                        setEditId(null);
                        getClusterListApi(showStopped);
                        saveModalOpen.current = false
                    }
                }}>
                    {index === editId ? <input className='input-bottom-border form-control custom-input ml-1 col-2' maxLength={1} max={9} autoFocus={true} type='number' value={quantity} min={1} step={1} onChange={(e) => {
                        let val = e?.target?.value
                        if (val.match(/^[0-9]+$/) || val === "") {
                            if (val?.length <= 1) {
                                currentQuantity.current = val;
                                onQuantityChange(val, index);

                            }
                        }
                    }} /> : <div className='mr-4'>
                        {quantity}
                    </div>}

                    <div className='cursor-pointer ml-2'>
                        {index !== editId ? <div onClick={() => setEditId(index)} > <EDIT height={dynamic(14)?.toString()} width={dynamic(14)?.toString()} color='#2c83c4'></EDIT></div>
                            :
                            <div onMouseDown={(e) => {

                                e.stopPropagation();
                                e.preventDefault();
                                saveModalOpen.current = true
                                currentQuantity.current = quantity;
                                setshowWarningPopup(true)

                            }}> <ICON_SAVE height={dynamic(15)?.toString()} width={dynamic(15)?.toString()} color='#2c83c4'></ICON_SAVE></div>}
                    </div>
                </div>
            </>
        },



        action: (optionOpen, setOptionOpen, item) => {

            return <Space direction="vertical">
                <Space wrap>


                    <Dropdown
                        destroyPopupOnHide={true}
                        menu={{
                            items: getoptionList(item),
                            onClick: (e) => e.stopPropagation()
                        }}
                        arrow={{ pointAtCenter: true }}
                        autoAdjustOverflow={true}
                        trigger={['click']}
                        open={optionOpen[item.id]}
                        placement="bottomRight"
                        onOpenChange={(bool) => setOptionOpen((prev) => {
                            prev[item.id] = bool;

                            return { ...prev }
                        })}
                        overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                    >

                        <span onClick={e => {
                            e.stopPropagation(); setOptionOpen((prev) => {
                                let obj = { ...prev }
                                obj[item.id] = true;
                                return { ...obj, ...prev }
                            })
                        }}
                            className={`dbox-opts ml-1 mr-3 ${optionOpen[item.id] ? 'active' : ''}`}><i style={{ fontSize: "1rem", marginTop: "0.9375rem" }} className="bx bx-dots-vertical-rounded cursor-pointer"></i></span>
                    </Dropdown>
                    <Tooltip placement='topRight' title="Default Cluster auto starts when activity is detected and are used by scheduler. Scheduler will not work if no default clusters are selected"><div onClick={() => pinCluster(item)} className={`cursor-pointer ${item.pinned ? 'active-tag' : 'default-tag'}`}>

                        <div className='mr-1'>Default</div>

                        <ICON_INFO_FILLED height={dynamic(12)?.toString()} width={dynamic(12)?.toString()} color={item.pinned ? '#fff' : '#868E96'}></ICON_INFO_FILLED>

                    </div> </Tooltip>
                    {item?.schedule_config && getObjectLength(item?.schedule_config) ? <div className='ml-3 cursor-pointer cluster-schedule-toggle'>
                        <Tooltip title={item.is_scheduled ? 'Scheduler' : 'Scheduler'}>
                            <Switch
                                className='custom-switch-btn'
                                checkedChildren="On" unCheckedChildren="Off"
                                size='small'
                                defaultChecked={item.is_scheduled}
                                checked={item.is_scheduled}
                                // checked={checked}
                                onChange={(chk) => {
                                    if (chk) {
                                        store.dispatch(deleteScheduleClusterAction(item?.id, 'GET', {})).then((resp) => {
                                            getClusterListApi(showStopped) //added showStopped status
                                        })

                                    } else {
                                        store.dispatch(deleteScheduleClusterAction(item?.id, 'DELETE', {})).then((resp) => {
                                            getClusterListApi(showStopped) //added showStopped status
                                        })
                                    }
                                }

                                }
                                disabled={false}
                            />
                            {/* {
                                item.is_scheduled ? <span className="active" onClick={() => {

                                    store.dispatch(deleteScheduleClusterAction(item?.id, 'DELETE', {})).then((resp) => {
                                        getClusterListApi(showStopped) //added showStopped status
                                    })


                                }}> <ICON_SCHEDULER width={dynamic(18)?.toString()} height={dynamic(18)?.toString()} color='#2c83c4' /></span> : <span onClick={() => {
                                    store.dispatch(deleteScheduleClusterAction(item?.id, 'GET', {})).then((resp) => {
                                        getClusterListApi(showStopped) //added showStopped status
                                    })

                                }}> <ICON_SCHEDULER width={dynamic(18)?.toString()} height={dynamic(18)?.toString()} color='#333' /></span>
                            } */}
                        </Tooltip>
                    </div> : null}


                </Space>
            </Space>

        }
    }

    const columns = [
        {
            name: <label className='mt-0 dict-color-dark label'>Size</label>,
            key: "size",
            sortable: false,
            resizable: false,
            // width: 200
        },
        {
            name: <label className='mt-0 dict-color-dark label'>Capacity</label>,
            key: "capacity",
            sortable: false,
            resizable: false,
            // width: 200
            // width: 220
        },
        {
            name: <label className='mt-0 dict-color-dark label'>Quantity <span className='ml-2'> <Tooltip title="Only 1 to 9 number of quantity is allowed."> <ICON_INFO_FILLED width={dynamic(12)?.toString()} height={dynamic(12)?.toString()} color='#868E96'></ICON_INFO_FILLED></Tooltip></span> </label>,
            key: "quantity",
            sortable: false,
            resizable: false,
            // width: 200
        },
        {
            name: <label className='mt-0 dict-color-dark label'>State</label>,
            key: "state",
            sortable: false,
            resizable: false,
            // width: 'auto'
            // width: 150
        },
        {
            name: <label className='mt-0 dict-color-dark label'>Actions</label>,
            key: "action",
            sortable: false,
            resizable: false,
            // width: 'auto'
            // width: 150
        },
    ]

    const onQuantityChange = (value, id) => {
        let clone = cloneDeep(clusterList);
        let newClusterList = []
        if (id) {
            newClusterList = clone?.map((item) => {
                if (item.id === id) {
                    item.quantity = value;
                }
                return item
            })
            if (newClusterList?.length) {

                setClusterList(newClusterList)
            }
        }
    }

    useEffect(() => {
        if (clusterList) {  //clusterList

            let temp = clusterList.map((item) => {
                return {
                    id: item.id,
                    size: listTemplate.size(item.size),
                    capacity: listTemplate.capacity(item.capacity),
                    quantity: listTemplate.quantity(item.quantity, item.id, onQuantityChange),
                    state: <div className='clust-status'>{listTemplate.state(item.state)}</div>,
                    action: listTemplate.action(optionOpen, setOptionOpen, item),



                }


            })
            setData([...temp])

        }

    }, [clusterList, optionOpen, editId])


    return (
        <div className='px-2'>


            {data.length
                ? <LandingSimpleTable
                    cols={columns}
                    // onRowClick={onRowClick}
                    autoWidth={true}
                    removeBorders={true}
                    rowsData={data}
                    tableHeight={"calc(100vh - 70vh)"}
                    showCursor={false}
                />
                : <div className='p-3 text-center no-cluster-text text-black-50  no-datasrc-found'>No Clusters Found</div>}


            {getConfirmationModal({
                open: showWarningPopup,
                setOpen: setshowWarningPopup,
                onConfirm: () => {
                    saveModalOpen.current = false;
                    store.dispatch(saveQuantity('POST', { 'quantity': currentQuantity.current }, editId)).then((resp) => {
                        getClusterListApi(showStopped) //added showStopped status
                        setEditId(null);


                    }).catch(e => {
                        saveModalOpen.current = false

                    })
                },
                onCancel: () => {
                    setEditId(null); getClusterListApi(showStopped);
                    saveModalOpen.current = false

                },
                heading: 'Warning',
                hideButton: false,
                body: <div>Are you sure, you want to apply <b>{currentQuantity.current}</b>  this quantity?</div>
            })}
        </div>
    )
}



