import { Dropdown, Popover, Tooltip } from "antd";
import React, { useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from "reactstrap";

import "toastr/build/toastr.min.css";

import _ from "lodash";
import { ICON_CLOSE, ICON_DELETE, ICON_FILE, ICON_LOADING_CIRCLE, ICON_MINUS, ICON_PLUS, ICON_REDIRECT, ICON_TAG, ICON_USER } from "../../../../../Common/newIconSource.js";
import DeleteModal from "../../../../../Common/deleteModal/DeleteModal.js";
import { getRelativeTime, permissions } from "../../../../common/helperFunctions.js";
import { FILLEDSTAR, STAR, UPDATED_BY } from "../../../../../Common/iconSource.js";
import Buttons from "../../../../../Common/button/Buttons.js";
import { tags } from "../../../landingPage/landingPageTagHelper.js";
import { Literals } from "../../../../common/literals.js";
import '../unstructuredPodCreation.scss'
import { removePOD } from "../../../../../../store/actions.js";
import { assignTagToDocument, deleteContentRepo, toggleBookmarkToDocument, unassignTagToDocument } from "../../../../../../store/modules/dataCatalogue/unstructured/unstructuredActions.js";

const catalogPermissions = permissions.dataCatalog

const LandingPageCard = ({
  details,
  refreshData = () => { },
  index = '',
  setData = () => { },
  groupByTags = false
}) => {
    const history = useNavigate();
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [visible, setVisible] = useState(false);

    const dispatch = useDispatch()
    const tagList = useSelector(state => state.DataCatalogue?.LandingPage?.tagList);
    const [tagMenu, setTagMenu] = useState([]);
    const [showPopUp,] = useState(false)
    const [showTagPop, setShowTagPop] = useState(false)
    const [optionOpen, setOptionOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [bookmark, setBookmark] = useState(false)
    const [bookmarkLoading, setBookmarkLoading] = useState(false)
    const [tagLoader, setTagLoader] = useState(false)
    
    const checkIsEditor = () => {
        const isRoleEditor = !!details?.permission?.find(v => v === catalogPermissions.editor)
        if (isRoleEditor) return true
        return undefined
    }

    const isDeletePermission = checkIsEditor() ?? !!details?.permission?.find(v => v === catalogPermissions.delete)

    React.useEffect(() => {
        if (details) {
            setBookmark(details?.bookmark)
        }
    }, [details])

    const addRemoveBookmark = (e) => {
        e.stopPropagation();
        e.preventDefault();

        try {
        setBookmarkLoading(true)
        dispatch(toggleBookmarkToDocument(details?.id, details?.bookmark)).then(
            res => {
                if (res.status === 'success') {
                    setBookmark(!bookmark)
                    setBookmarkLoading(false)
                    setData(prev => prev?.map(v => ({ ...v, bookmark: v?.id === details?.id ? !bookmark : v?.bookmark })))
                }
            }
            ).catch(err => {
                setBookmarkLoading(false)
            })
        }
        catch (e) {
            setBookmarkLoading(false)
        }
    }

    const tagColor = React.useCallback((value) => {
        var res = value.split("#");
        let colorname = res[0];
        let color = '';
        for (let x in tags) {
        if (tags[x].id === colorname) {
            color = tags[x].color;
            break;
        }
        }
        return color + '95';
    }, [])

    const deleteRepo = React.useCallback(() => {
        setLoading(true)
        dispatch(deleteContentRepo(details?.id)).then(
        res => {
            if (res.status === 'success') {
                setOpenDeletePopup(false);
                refreshData()
                setLoading(false)
            }
        })
        .catch(() => setLoading(false))
    }, [])

    const openDeleteDialog = () => {
        if (!isDeletePermission) return
        setOpenDeletePopup(true);
    };

    const addRemoveTagToPOD = React.useCallback((tag, remove = false) => {
        const { id: tag_id } = tag

        setTagLoader(true)
        let requestBody = {
            "tag": [tag_id]
        }
        if (!remove) {
            dispatch(assignTagToDocument(requestBody, details?.id)).then(
                res => {
                    if (res.status === 'success') {
                        setTagLoader(false)
                        setData(prev => prev?.map(v => ({ ...v, tag: v?.id === details?.id ? [...v?.tag, tag] : v?.tag })))
                        
                    }
                }
            )
            .catch(() => {
                setTagLoader(false)
            })
        }
        else {
            dispatch(unassignTagToDocument(tag_id, details?.id)).then(
                res => {
                if (res.status === 'success') {
                    setTagLoader(false)
                    setData(prev => prev?.map(v => ({ ...v, tag: v?.id === details?.id ? v?.tag?.filter(t => t?.id !== tag_id) : v?.tag })))
                    // refreshData()
                }
                }
            )
            .catch(() => {
                setTagLoader(false)
            })
        }
    }, [details])

    const redirectTo = React.useCallback((id) => {
        history(Literals.links.UNSTRUCTURED_DOCUMENT_CREATION_VIEW + id)
    }, [])

    React.useEffect(() => {
        if (showPopUp) {
            setTagSubMenu([...details?.tag])
        }
    }, [showPopUp, details, tagList])

    React.useEffect(() => {
        if (!optionOpen) {
            setShowTagPop(false)
            setVisible(false)
        }
    }, [optionOpen])

    React.useEffect(() => {
        if (visible) {
            setShowTagPop(false)
        }
    }, [visible])

    const setTagSubMenu = React.useCallback((tags) => {
        let podTag = tags.map(t => t?.name)
        let returnList = [];
        let availableTags = _.sortBy([...tagList], [function (o) { return o?.name; }])

        availableTags?.length && availableTags.forEach((tag, x) => {
            if (!podTag.includes(tag?.name)) {
                returnList.push(
                    <div onClick={(e) => { e.stopPropagation() }} key={x} className={`${x === availableTags.length - 1 ? '' : 'custom-border-bottom'} d-flex justify-content-between align-items-center padding-top-1 padding-bottom-1`}>
                        <div className="d-flex align-items-center">
                            <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
                            <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
                            </Tooltip>
                        </div>
                        <Buttons
                            props={{
                                buttonText: '',
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2 ml-2",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    setShowTagPop(false);
                                    addRemoveTagToPOD(tag)
                                },
                                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE /> : <ICON_PLUS />,
                                isDisable: tagLoader,
                                buttonType: Literals.BTN_TERTIARY
                            }}
                        />
                    </div>
                )
            } else {
                returnList.push(
                    <div onClick={(e) => { e.stopPropagation() }} key={x} className='d-flex justify-content-between custom-border-bottom align-items-center padding-top-1 padding-bottom-1'>
                        <div className="d-flex align-items-center">
                            <i className="bx bxs-circle" style={{ color: tagColor(tag?.color) }}></i>
                            <Tooltip title={tag?.name?.toUpperCase()} placement="topLeft">
                                <p className="paragraph m-0 ml-2">{tag?.name?.toUpperCase()}</p>
                            </Tooltip>
                        </div>
                        <Buttons
                            props={{
                                buttonText: '',
                                buttonClassName: "danger-btn custom-btn btn-with-icon mr-2 ml-2 border-0",
                                buttonEvent: (e) => {
                                    e.stopPropagation();
                                    setShowTagPop(false);
                                    addRemoveTagToPOD(tag, true)
                                },
                                ImgSrc: () => tagLoader ? <ICON_LOADING_CIRCLE /> : <ICON_MINUS />,
                                isDisable: tagLoader,
                                buttonType: Literals.BTN_TERTIARY
                            }}
                        />
                    </div>
                )
            }
        })
        if (returnList.length) {
            setTagMenu([...returnList])
        } else {
            setTagMenu([])
        }
    } , [addRemoveTagToPOD, tagColor, tagList, tagLoader])

    const handleVisibleChange = (visible) => {
        setShowTagPop(visible)
        if (visible) {
            setTagSubMenu(details?.tag)
        }
    }

    const tagPopover = {
        header: () => {
            return <>
                <div className="d-flex justify-content-between custom-border-bottom padding-2">
                    <p className="section-heading m-0">Tags</p>
                    <div className="d-flex">
                        <Tooltip title='Close' placement="bottom">
                            <button className="custom-btn-outline custom-btn btn-with-icon"
                                onClick={function (e) {
                                    e.stopPropagation()
                                    setShowTagPop(false)
                                }}
                            >
                                <ICON_CLOSE />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                <div className="padding-2 card-tags-auto-overflow tag-popover-body" style={{ maxHeight: '12.5rem', overflowY: 'auto' }}>
                    {
                        tagMenu.length
                            ? tagMenu.map((val, i) => <span key={`tag-pop-itm-${i}`}>{val}</span>)
                            : <div className="py-1 text-center text-black-50">No Tag Found</div>
                    }
                </div>
            </>
        }
    }

    const trimmedDescription = React.useMemo(() => {
        return details?.description?.length > 86 ?
            details?.description?.substring(0, 86 - 3) + "..." :
            details?.description ?? "";
    }, [details])

    const optionItems = React.useCallback(() => {
        let optionList = [
            {
                label: <span className={`dbox-opt-itm`}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.open(Literals.links.UNSTRUCTURED_DOCUMENT_CREATION_VIEW + details?.id)
                    }}>
                    <ICON_REDIRECT />
                    Open In New Tab
                </span>,
                key: '0',
                disabled: false
            },
            {
                label: <Popover placement="right"
                    title={tagPopover.header}
                    content={tagPopover.body}
                    trigger="click"
                    autoAdjustOverflow={true}
                    open={showTagPop}
                    onOpenChange={handleVisibleChange}
                    destroyTooltipOnHide={true}
                >
                    <span className="dbox-opt-itm"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowTagPop(true)
                            setTagSubMenu(details?.tag)
                        }}>
                        <ICON_TAG />
                        Tags
                    </span>
                </Popover>,
                key: '1',
            },
            {
                label: <span className={`dbox-opt-itm danger ${!isDeletePermission ? 'disabled' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openDeleteDialog(details)
                    }}>
                    <ICON_DELETE />
                    Delete
                </span>,
                key: '4',
                disabled: !isDeletePermission
            }
        ]
        return optionList
    }, [showTagPop, isDeletePermission, details])

    const onCardClick = React.useCallback((e) => { 
        e.preventDefault(); 
        e.stopPropagation(); 
        redirectTo(details?.id) 
    }, [details])

    return (
        <div key={`${details?.id}-${details?.name}`} className={`h-100 position-relative unstructured-document-card`}>
            <Card id={`podCrd${index}`} onClick={onCardClick}>
                <CardBody>
                    <div className='data-view-dbox' onClick={() => { }}>
                        <div className='_src_img'>
                            <ICON_FILE height="1.8rem" width="1.8rem"/>
                        </div>
                        <div className='desc-box'>
                            <div className="d-flex align-items-start">
                                <Tooltip placement='topLeft' title={details?.name?.toUpperCase()}>
                                    <label style={{ width: 'calc(100% - 3.375rem)' }} className='paragraph mt-1 title-txt font-w-600 text-uppercase text-with-ellipsis'>
                                        {`${details.name}`}
                                    </label>
                                </Tooltip>
                                <Buttons
                                    props={{
                                        buttonText: '',
                                        tooltip: bookmark
                                            ? Literals.REMOVE_FAVOURITES
                                            : Literals.ADD_FAVOURITES,
                                        buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                                        buttonEvent: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            addRemoveBookmark(e)
                                        },
                                        ImgSrc: () => bookmarkLoading ? <ICON_LOADING_CIRCLE /> : bookmark ? <FILLEDSTAR /> : <STAR />,
                                        isDisable: bookmarkLoading,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                    }}
                                />

                                <Dropdown
                                    menu={{
                                        items: optionItems(),
                                        onClick: (e) => e.domEvent.stopPropagation()
                                    }}
                                    trigger={["hover"]}
                                    placement="bottomRight"
                                    onOpenChange={(bool) => setOptionOpen(bool)}
                                    overlayStyle={{ border: 'solid 1px #e4e4e4' }}
                                    destroyPopupOnHide={true}
                                >
                                    <span onClick={e => e.stopPropagation()} className={`dbox-opts ${optionOpen ? 'active' : ''}`}><i className="bx bx-dots-vertical-rounded"></i></span>
                                </Dropdown>
                            </div>
                            <div>
                                <Tooltip placement='topLeft' title={details.description}>
                                    <p className='paragraph text-black-50 small desc-txt custom-ellipsis two-line-ellipsis'>
                                        {
                                            details.description?.length > 0 ? details.description
                                            : "No description has been given yet."
                                        }
                                    </p>
                                </Tooltip>
                            </div>
                            <ul className="landingCard-profile-list">
                                <li className="landingCard-profile-listitem">
                                    <UPDATED_BY width="14" height="14" />
                                    <span className="label label-gray ml-1">{'Last Updated'} </span>
                                    <Tooltip placement="topLeft" title={getRelativeTime(details?.updated_on)}>
                                        <span className="paragraph">
                                            {getRelativeTime(details?.updated_on)}
                                        </span>
                                    </Tooltip>
                                </li>
                                <li className="landingCard-profile-listitem">
                                    <ICON_USER width="14" height="14" />
                                    <span className="label label-gray ml-1">{Literals.UPDATED_BY} </span>
                                    <Tooltip placement="topLeft" title={details?.updated_by_user?.email}>
                                        <span className="paragraph">
                                            {details?.updated_by_user?.email}
                                        </span>
                                    </Tooltip>
                                </li>
                            </ul>
                            <div className="round-tags-container position-relative"
                                style={{
                                    width: 'calc(100% + 3rem)',
                                    left: '-2.6875rem'
                                }}
                            >
                                {

                                    <span className={`position-absolute w-100`} style={{ top: '-0.125rem', display: 'grid', gridTemplateColumns: details?.is_unstructured ? '6.25rem auto 6.25rem' : 'auto 6.25rem' }}>
                                        {/* right: 0,  */}
                                        {
                                            details?.is_unstructured
                                                ?
                                                <label
                                                    className={'publish-tag-pod unstructured-tag fontInterSemiBold w-fit-content'}
                                                    style={{
                                                        color: '#222222',
                                                        borderColor: "#BFE4FF",
                                                        // visibility: details?.is_unstructured ? 'visible' : 'hidden'
                                                    }}
                                                >
                                                    {'Unstructured'}
                                                </label>
                                                : ''
                                        }
                                        <div className="d-flex align-items-center">
                                            {
                                                !groupByTags &&
                                                details?.tag?.map((v, i) => {
                                                    let limit = 1
                                                    if (!details?.is_unstructured) limit = 2
                                                    return <React.Fragment key={`${details.id}-${details.name}-tbl-tgs-${i}`}>
                                                        {
                                                            i < limit
                                                                ?
                                                                <div className={`${v?.color} round-tags-item mb-0`}>
                                                                    <span>{<ICON_TAG />}</span>
                                                                    <Tooltip placement="top" title={v?.name?.toUpperCase()}>
                                                                        <span className="label text-uppercase">{v?.name}</span>
                                                                    </Tooltip>
                                                                </div>
                                                                : ''
                                                        }
                                                        {
                                                            i === limit
                                                                ?
                                                                <div className={`${v?.color} round-tags-item mb-0`}>
                                                                    {/* <span>{<TAG />}</span> */}
                                                                    <Tooltip placement="top" title={details?.tag?.map(t => t?.name?.toUpperCase()).slice(limit).join(',')}>
                                                                        <span className="fontSizeExtraSmall fontInterSemiBold">+{details?.tag?.length - limit}</span>
                                                                    </Tooltip>
                                                                </div>
                                                                : ''
                                                        }
                                                    </React.Fragment>
                                                })
                                            }
                                        </div>
                                        {
                                            details?.published
                                                ?
                                                <label
                                                    className={'publish-tag-pod ml-auto w-fit-content'}
                                                    style={{
                                                        borderColor: '#2c83c4',
                                                    }}
                                                >
                                                    {'Published'}
                                                </label>
                                                : ''
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>


            <DeleteModal
                open={openDeletePopup}
                setOpen={setOpenDeletePopup}
                onConfirm={deleteRepo}
                loading={loading}
            />
        </div>
    );
};
export default LandingPageCard;