import { Tabs } from 'antd';
import React from 'react'
import { ICON_BAG, ICON_CATALOG } from '../../newIconSource';
import './landingContentTabs.scss'

const demo = [
    {
        key: '1',
        label: <span> <ICON_CATALOG /> POD </span>
    },
    {
        key: '2',
        label: <span> <ICON_BAG /> Business Glossary </span>
    }
]

export const LandingContentTabs = ({
    tabItems = demo,
    activeTabKey = '',
    setSearchTerm = () => { },
    setActiveTabKey = () => { }
}) => {

 
    const items = React.useMemo(() => [...tabItems], [tabItems])
    let stateObj = { id: "100" };
    
    if(activeTabKey){
        window.history.replaceState(stateObj,
            "new page", `${window.location.pathname}#${activeTabKey}`);
    }
   


    const onChange = (key) => {
        setSearchTerm('')
        let stateObj = { id: "100" };
        window.history.replaceState(stateObj,
                    "new page", `${window.location.pathname}#${key}`);
        setActiveTabKey(key)
    };

    return (
        <div>
            <Tabs
                id='landingContentTab'
                className='lpg-content-tab'
                items={items}
                // defaultActiveKey={activeTabKey}
                
                activeKey={activeTabKey}
                onChange={onChange} />
        </div>
    )
}
