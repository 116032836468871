import { Drawer, Input, Popover, Progress, Select, Tooltip, message } from 'antd'
import './pulseSearchBox.scss'
import React from 'react'
import { ICON_ATTACHMENT, ICON_CATALOG, ICON_CATALOG_DOMAIN, ICON_CLOSE, ICON_DELETE, ICON_FILE, ICON_LAYOUT, ICON_MIC_FILLED, ICON_MIC_MUTE, ICON_PLANE_FILLED, ICON_PLUS, ICON_STOP_FILLED } from '../../newIconSource'
import { useDispatch, useSelector } from 'react-redux'
import { getCatalogListing, getDataDomainList, setDataDomainList, setUserSelectedDomain } from '../../../../store/actions'
import { getReloadErrorTemplate, pxToRem, remToPx, showOneLineError } from '../../../modules/common/helperFunctions'
import LeftSidePanelToggler from '../../leftSidePanelToggler/LeftSidePanelToggler'
import SearchInput from '../../search/Search'
import ClaristaLoader from '../../claristaLoader/ClaristaLoader'
import { LandingSimpleTableInner } from '../../landingSimpleTable/LandingSimpleTable'
import { Literals } from '../../../modules/common/literals'
import Buttons from '../../button/Buttons'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import TalkDataSuggestBar from '../../../modules/genAi/components/TalkDataSuggestBar'
import { useNavigate } from 'react-router-dom'
import { emitToastNotification } from '../../../../helpers/toast_helper'
import { getDomainDocuments } from '../../../../store/modules/dataCatalogue/unstructured/unstructuredActions'
import { SOURCES as PULSE_SOURCES } from './NewPulseLandingPage'

const PulseSearchBox = React.forwardRef(({
    activeTabKey,
    activeDomain,
    setActiveDomain = () => {},
    setDomainFailed = () => {},
    isDomainFailed = false,
    setFocus = () => {},
    searchValue = '',
    setSearchValue = () => {},
    onSearch = () => {},
    disableDomain = false,
    onEnterCallback = () => {},
    disableInput = false,
    hideSearchBar = false,
    hideDomain = false,
    searchPlaceholder = 'Start typing your question here...',
    setParentDomainList = () => {},
    stopResponding = () => {},
    fetchingConversation = false,
    isUnstructured = false,
    searchBoxLimit = 128,
    useTextLimit = false,
    isPulseLandingPage = false,
    source = isUnstructured ? PULSE_SOURCES?.documents : PULSE_SOURCES?.pod, // either pod or documents
    // suggestionData = [],
    // onSuggestBarClick = () => {},
    // suggestVisibility = false,
    // showSuggestion = false
}, ref) => {
    const dispatch = useDispatch()
    const history = useNavigate()

    const [loadingDomains, setLoadingDomains] = React.useState(false)
    const [domainError, setDomainError] = React.useState(undefined)
    const [domains, setDomains] = React.useState([])
    const [openColDrawer, setOpenColDrawer] = React.useState(false)
    const [colsData, setColsData] = React.useState([])
    const [colsLoading, setColsLoading] = React.useState(false)
    const [colsError, setColsError] = React.useState(undefined)
    const [colsSearchData, setColsSearchData] = React.useState('')
    const [colsEmpty, setColsEmpty] = React.useState(false)
    const [drawerVisible, setDrawerVisible] = React.useState(false)
    const [visibleListObj, setVisibleListObj] = React.useState({})
    const [drawerData, setDrawerData] = React.useState([])

    const autoGrowInpRef = React.useRef({})

    const userDetailsRedux = useSelector((state) => state.UserReducer?.user?.userDetails)

    const noOpenAiAccess = userDetailsRedux?.openAi === undefined || userDetailsRedux?.openAi === null || userDetailsRedux?.openAi?.is_active === false

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    React.useImperativeHandle(ref, () => {
        return {
            focus: () => {
                autoGrowInpRef?.current?.focus()
            }
        }
    }, [])

    React.useEffect(() => {
        if (!listening && transcript !== "") {
            autoGrowInpRef.current.innerText = transcript
            setSearchValue(transcript);

            if(autoGrowInpRef?.current?.focus) {
                autoGrowInpRef?.current?.focus()
            }
            
            message.info({
                content: "Now Press Enter!"
            })
            message.config({
                top: 50,
                duration: 1
            })

            resetTranscript();
          // onSearchHandle(transcript);
        }
        if(listening) {
            autoGrowInpRef.current.innerText = "Speak Now, I'm Listening..."
        }
        else {
            autoGrowInpRef.current.innerText = transcript
        }
    }, [listening])

    React.useEffect(() => {

        if(autoGrowInpRef?.current?.focus) {
            autoGrowInpRef?.current?.focus()
        }

        if(!hideSearchBar) {
            
            setDomainError(undefined)
            setLoadingDomains(true)
            dispatch(getDataDomainList(false, false))
            .then(
                res => {
                    if(res?.data?.length) {
                        setDomains([...res?.data])
                        setParentDomainList([...res?.data])
                    }
                    if(activeDomain === null || activeDomain === 0 || activeDomain === isNaN) {
                        setActiveDomain(res?.data?.[0]?.data_domain_id)
                    }
                    dispatch(setDataDomainList(res.data))
                    setLoadingDomains(false)
                }
            )
            .catch((err) => {
                setLoadingDomains(false)
                setDomainError(err?.message)
            })
        }
    }, [])

    React.useEffect(() => {
        if(!openColDrawer) {
            setColsData([])
            return
        }
        if(activeDomain && activeDomain !== '') {
            setTimeout(() => {
                fetchColsOfPods()
            }, 1000)
        }
    }, [activeDomain, openColDrawer])

    React.useEffect(() => {
        if (isDomainFailed && domains && domains?.length) {
            setActiveDomain(domains?.[0]?.data_domain_id)
        }
    }, [isDomainFailed, domains])

    const domainOptions = React.useMemo(() => {
        let list = domains?.map((item) => {
            return {
                key: item.data_domain_id,
                value: item.data_domain_id,
                Name: item?.data_domain_name?.replaceAll("_"," ")?.toUpperCase(),
                domain: item?.data_domain_name,
            }
        })
        return list
    }, [domains, activeTabKey])

    const generateData = (_data = colsData, allColsVisibleObj = visibleListObj ?? {}) => {
        const clone = [..._data]?.map(c => (
                {
                    ...c,
                    column_detail: source === PULSE_SOURCES?.pod ? c?.column_detail?.filter(v => v?.published && v?.column_name?.toLowerCase()?.includes(colsSearchData?.toLowerCase())) : undefined,
                    assets: source === PULSE_SOURCES?.documents ? c?.assets?.filter(v => v?.file_name?.toLowerCase()?.includes(colsSearchData?.toLowerCase())) : undefined
                }
            ))
        let arr = [], valuesWidth = '14.125rem', visibleLimit = 5
        clone.forEach((v, idx) => {
            const el = clone[idx]
            const _values = source === PULSE_SOURCES?.pod ? (el?.column_detail ?? []) : source === PULSE_SOURCES?.documents ? (el?.assets ?? []) : []
            
            const _visibleValues = _values?.length > visibleLimit && ([undefined, false]?.includes(allColsVisibleObj[el?.title])) ? _values.slice(0, visibleLimit) : _values
            const _moreCols = !(!!allColsVisibleObj[el?.title])

            arr = [
                ...arr,
                { 
                    isHeading: true,
                    showingAll: _values?.length > visibleLimit ? false : true,
                    data: { id: el?.id, title: el?.title },
                    element: <div 
                            style={{opacity: _values?.length === 0 ? '0.5' : '1'}}
                            className='fontSizeHeading fontInterSemiBold d-flex align-items-center pulse-srch-pod-li-draw-ic'
                        >
                            {
                                source === PULSE_SOURCES?.pod
                                ?
                                <ICON_CATALOG width='1rem' height='1rem' />
                                :
                                <ICON_FILE width='1rem' height='1rem' />
                            }
                            <span className='p-1'></span>
                            <Tooltip title={el?.title?.toUpperCase()} placement='right'>
                                <label className='mb-0 text-uppercase text-with-ellipsis' style={{width: '12.5rem', cursor: 'pointer'}}>
                                    {el?.title}
                                </label>
                            </Tooltip>
                        </div>
                },
                ...[
                    ..._visibleValues?.map(c => {
                        const valueName = source === PULSE_SOURCES?.pod ? c?.column_name : source === PULSE_SOURCES?.documents ? c?.file_name : ""
                        return (
                            {
                                isHeading: false,
                                data: {
                                    ...c, 
                                    id: el?.id, 
                                    title: el?.title,
                                    data_domain_detail: el?.data_domain_detail
                                },
                                element: <Tooltip title={valueName} placement='right'>
                                    <div className='fontSizeHeading pl-4 text-with-ellipsis' style={{width: valuesWidth}}>
                                        {valueName}
                                    </div>
                                </Tooltip>
                            }
                        )
                    })
                ]
            ]
            
            if(_values?.length <= visibleLimit) {
                allColsVisibleObj[el?.title] = true
            }
            else if(_moreCols && colsSearchData?.trim() === "") {
                arr = [
                    ...arr,
                    {
                        isHeading: false,
                        isMoreButton: true,
                        data: {
                            id: el?.id, 
                            title: el?.title,
                            data_domain_detail: el?.data_domain_detail
                        },
                        element: <div className='fontSizeHeading pl-4 color-primary fontInterMedium' style={{width: valuesWidth}}>
                                Show More...
                            </div>
                    }
                ]
            }
            else if (colsSearchData?.trim() === "") {
                allColsVisibleObj[el?.title] = true
                arr = [
                    ...arr,
                    {
                        isLessButton: true,
                        data: {
                            id: el?.id, 
                            title: el?.title,
                            data_domain_detail: el?.data_domain_detail
                        },
                        element: <div className='fontSizeHeading pl-4 color-primary fontInterMedium' style={{width: valuesWidth}}>
                                Show Less...
                            </div>
                    }
                ]
            }
        })
        setVisibleListObj({...allColsVisibleObj})
        return arr ?? []
    } 

    React.useEffect(() => {
        setDrawerData(() => generateData(colsData))
    }, [colsData, colsSearchData])
    
    const fetchColsOfPods = () => {
        setVisibleListObj({})
        setColsEmpty(false)
        setColsError(undefined)
        setColsLoading(true)
        dispatch(
            source === PULSE_SOURCES?.pod 
            ?   getCatalogListing(activeDomain, 'metadata', true, false)
            :   getDomainDocuments(activeDomain, false)
        )
        .then(res => {
            let data = []

            if(source === PULSE_SOURCES?.pod) {
                data = res?.data ?? []
                data = data?.map(d => ({
                    id: d?.table_id,
                    title: d?.table_name,
                    ...d
                }))
            }
            else if(source === PULSE_SOURCES?.documents) {
                data = res?.data?.results ?? []
                data = data?.map(d => ({
                    id: d?.id,
                    title: d?.name,
                    ...d
                }))
            }

            setColsData(data ?? [])
            setColsEmpty(data?.length === 0)
            setColsLoading(false)
        })
        .catch((err) => {
            setColsEmpty(false)
            setColsError(err?.message)
            setColsLoading(false)
        })
    }

    const onColsRowClick = (record) => {
        if(window.screen.availWidth <= 640) {
            return
        }
        if(record?.isHeading) {
            if(record?.data?.id && source === PULSE_SOURCES?.pod) {
                window.open(Literals?.links?.DATA_CATALOG_POD_DETAILS + record?.data?.id)
            }
            else if(record?.data?.id && source === PULSE_SOURCES?.documents) {
                window.open(Literals?.links?.UNSTRUCTURED_DOCUMENT_CREATION_VIEW + record?.data?.id)
            }
        }
        else if(record?.isMoreButton || record?.isLessButton) { // For Expanding and Collapsing the Values of the drawer
            let prev = visibleListObj
            prev[record?.data?.title ?? 'undefined'] = record?.isMoreButton ?? !record?.isLessButton
            setDrawerData(() => generateData(colsData, prev))
        }
        else {
            if(record?.data?.data_dict && source === PULSE_SOURCES?.pod) {
                window.open(Literals?.links?.DATA_DICTIONARY_VIEW + record?.data?.data_dict)
            }
        }
    }

    const variableRowHeight = (record) => {
        if(record?.row?.isHeading) return remToPx(pxToRem(34))
        return remToPx(pxToRem(28))
    }

    const onChange = (key) => {
        key = parseInt(key)
        
        setActiveDomain(key)

        setDomainFailed(false)
        
        dispatch(setUserSelectedDomain({ data_domain_id: key === 0 ? null : key }, false))
        .catch(err => console.error(err?.message))
    };

    const checkTextLimit = React.useMemo(() => {
        return useTextLimit && searchValue?.length > searchBoxLimit
    }, [searchBoxLimit, searchValue, useTextLimit]) 

    const onEnterPress = (e) => {
        e.preventDefault()
        if(checkTextLimit) {
            emitToastNotification('info', `Phrase limit exceeds ${searchBoxLimit} characters, try reducing the phrase`)
            return
        }
        onEnterCallback()
    }

    const isSearchDisabled = React.useMemo(() => {
        return listening || disableInput || domainOptions?.length === 0 || noOpenAiAccess
    }, [listening, disableInput, domainOptions])

    const showShadow = () => {
        let hght = autoGrowInpRef.current?.clientHeight
        return hght && hght > 40
    }

    const PhraseLimitTag = React.useCallback(({style = {}, text = "PHRASE LIMIT EXCEEDS"}) => {
        return <span className='phrase-limit-tag text-uppercase px-1 py-1 rounded fontSizeExtraSmall' style={{lineHeight: 1,...style}}>{text}</span>
    }, [])

    const PhraseLimit = React.useCallback(({className = ''}) => {
        return <div className={`fontSizeExtraSmall fontInterMedium text-color-grey ${className}`} style={{minWidth: '3.5rem'}}>
                    <span className={checkTextLimit ? 'text-danger' : ''}>{searchValue?.length}</span> / {searchBoxLimit}
                </div>
    }, [checkTextLimit, searchBoxLimit, searchValue])

    return (
        <>
            <div className='pulse-search-container'>
                <div className={`pulse-search-comp ${showShadow() ? 'show-pulse-exp-eff' : ''} ${hideSearchBar ? 'p-0 border-0' : ''}`}
                    style={drawerVisible ? {zIndex: 'auto'} : {}}
                >
                    {
                        hideSearchBar ? ''
                        :
                        <>
                            {
                                hideDomain ? ''
                                :
                                <>
                                    <Select
                                        disabled={true ?? disableDomain}
                                        placeholder="Select Domain"
                                        showSearch={true}
                                        optionFilterProp="children"
                                        loading={loadingDomains}
                                        value={
                                            domainError    ? 'Error!' :
                                            loadingDomains ? 'Loading...' :
                                            domainOptions?.find(d => d?.key?.toString() === activeDomain?.toString())?.value ?? 'Select Domain'
                                        }
                                        onChange={onChange}
                                        filterOption={(input, option) => {
                                                const name = domainOptions?.find(d => d?.value === option?.value)?.Name ?? ''
                                                return name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                            }
                                        }
                                        filterSort={(optionA, optionB) => {
                                                const nmA = domainOptions?.find(d => d?.value === optionA?.value)?.Name ?? ''
                                                const nmB = domainOptions?.find(d => d?.value === optionB?.value)?.Name ?? ''
                                                return nmA?.toString()?.toLowerCase()?.localeCompare(nmB?.toString()?.toLowerCase())
                                            }
                                        }
                                        >
                                        {
                                            domainOptions?.map((ele, index) => (
                                                <Select.Option key={index} value={ele.value ? ele.value : ele.Name}>
                                                    <div title={ele?.Name} className='align-items-center d-flex px-1'>
                                                        <span className='mr-1 pulse-search-dm-ic'><ICON_CATALOG_DOMAIN width='18' height='18' /></span>
                                                        <span className='fontSizeHeading ml-2 text-with-ellipsis text-uppercase'>{ele.Name}</span>
                                                    </div>
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                    <span className='vertical-separator'></span>
                                </>
                            }
                            <form onSubmit={onEnterPress} className={`w-100 position-relative ${hideDomain ? 'pl-2' : ''}`} style={{top: 0}}>
                                <div 
                                    style={{width: checkTextLimit ? isPulseLandingPage ? 'calc(100% - 5rem)' : 'calc(100% - 8rem)' : undefined}}
                                    className={`pls-input pulse-autogrow-inp ${isSearchDisabled ? 'disabled' : ''}`}
                                    contentEditable={!isSearchDisabled}
                                    id='pulseTalkSearchInp'
                                    ref={autoGrowInpRef}
                                    data-placeholder={
                                        noOpenAiAccess 
                                        ? 'You do not have access to OpenAI' 
                                        : searchPlaceholder
                                    }
                                    onFocus={() => {
                                        setFocus(true)
                                    }}
                                    onBlur={() => {
                                            if(searchValue?.trim() === '') {
                                                autoGrowInpRef.current.innerHTML = ''
                                                autoGrowInpRef.current.innerText = ''
                                                setSearchValue('')
                                            }
                                            setTimeout(() => {
                                                setFocus(false)
                                            }, 1000)
                                        }
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key?.toLowerCase() === 'enter' && e.shiftKey) {
                                            const selection = window.getSelection();
                                            const range = selection.getRangeAt(0);
                                            const textNode = document.createTextNode('\n'); // Create a text node with a newline character
                                            range.insertNode(textNode);
                                            range.setStartAfter(textNode);
                                            range.setEndAfter(textNode);
                                            selection.removeAllRanges();
                                            selection.addRange(range);
                    
                                        }
                                        else if(e?.key?.toLowerCase() === 'enter' && !e.shiftKey) {
                                            if(!isSearchDisabled && searchValue?.trim() !== '')
                                            onEnterPress(e)
                                        }
                                    }}
                                    onInput={(e) => {
                                        e.preventDefault()
                                        if(!isSearchDisabled) {
                                            let val = e?.target?.innerText
                                            setSearchValue(val)
                                            // if(!val.match(/\n/g)) {
                                            //     setSearchValue(val)
                                            // }
                                            // else {
                                            //     setSearchValue(searchValue)
                                            //     autoGrowInpRef.current.innerText = searchValue
                                            // }
                                        }
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault()
                                        let text = e.clipboardData.getData("text/plain");
                                        /**
                                         * Removing line breaks
                                         */
                                        // text = text.replace(/(\r\n|\n|\r)/gm,"") // uncomment to use
                                        document.execCommand('insertText', false, text)

                                        /**
                                         * In case execCommand is removed by Javscript in new version
                                         * use this below code
                                         */
                                        /* text = text.replace(/(\r\n|\n|\r)/gm,"")
                                        const selection = window.getSelection();
                                        if (!selection.rangeCount) return;
                                        selection.deleteFromDocument();
                                        selection.getRangeAt(0).insertNode(document.createTextNode(text));
                                        setSearchValue(selection?.anchorNode?.innerText)
                                        selection.collapseToEnd() */
                                    }}
                                >
                                </div>
                                {/* <Input
                                    id='pulseTalkSearchInp'
                                    placeholder='Start typing your question here...'
                                    onFocus={() => setFocus(true)}
                                    onBlur={() => 
                                        setTimeout(() => {
                                            setFocus(false)
                                        }, 1000)
                                    }
                                    value={listening ? "Speak Now, I'm Listening..." : searchValue}
                                    onChange={(e) => setSearchValue(e?.target?.value)}
                                    disabled={listening || disableInput}
                                /> */}
                                {/* {
                                    showSuggestion
                                    ?
                                    <TalkDataSuggestBar
                                        data={suggestionData}
                                        onClick={onSuggestBarClick}
                                        visibility={suggestVisibility}
                                    />
                                    :   ''
                                } */}
                                {
                                    !isPulseLandingPage && checkTextLimit
                                    ? <PhraseLimitTag text="REPHRASE TO FIT" style={{position: 'absolute', top: '50%', right: '1rem', transform: 'translateY(-50%)', lineHeight: 1, zIndex: 5}}/>
                                    : ''
                                }
                            </form>
                            {
                                !isPulseLandingPage && useTextLimit && !fetchingConversation ?
                                    <PhraseLimit/>
                                :   ''
                            }

                            {
                                /**
                                 * For Pulse Landing Page Phrase Limit
                                 */
                                isPulseLandingPage && useTextLimit && !fetchingConversation
                                ?
                                <div className='d-flex align-items-center position-absolute' style={{lineHeight: 1, height: '1.1rem', zIndex: 5, bottom: '.35rem', right: 0}}>
                                    {
                                        checkTextLimit ? <PhraseLimitTag style={{lineHeight: 1, zIndex: 5}} text={"REFRAME TO FIT"}/> : ""
                                    }
                                    <PhraseLimit
                                        className='ml-1'
                                    />
                                </div>
                                :   ''
                            }

                            {
                                isUnstructured
                                ?
                                <>
                                    {/* <Popover
                                        placement='top'
                                        title={<></>}
                                        content={
                                            <>
                                                <div className='uns-talk-attch-cont px-2 py-2 d-flex flex-column' style={{width: '12rem', gap: '0.625rem', background: '#fbfbfb'}}>
                                                    <div className='border rounded px-2 py-1 bg-white shadow-sm'>
                                                        <p className='mb-0 fontSizeLabel fontInterSemiBold'>
                                                            document(1).pdf
                                                        </p>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <Progress 
                                                                percent={100} 
                                                                size="small" 
                                                                className='m-0'
                                                            />
                                                            <span className='d-flex text-danger'>
                                                                <ICON_DELETE color='currentColor' height='.8rem' width='.8rem' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='border rounded px-2 py-1 bg-white shadow-sm'>
                                                        <p className='mb-0 fontSizeLabel fontInterSemiBold text-black-50'>
                                                            document(2).pdf
                                                        </p>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <Progress 
                                                                percent={50} 
                                                                size="small" 
                                                                className='m-0'
                                                            />
                                                            <span className='d-flex text-danger'>
                                                                <ICON_STOP_FILLED color='currentColor' height='.8rem' width='.8rem' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center rounded px-2 py-1'
                                                        style={{border: '2px dashed #dee2e6'}}
                                                    >
                                                        <Buttons
                                                            props={{
                                                                buttonText: 'Add File',
                                                                tooltip: '',
                                                                buttonClassName: "custom-btn-outline custom-btn btn-with-text w-100 border-0 bg-transparent",
                                                                buttonEvent: (e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                },
                                                                ImgSrc: () => <ICON_PLUS width='20' height='20' color='#333333'/>,
                                                                isDisable: false,
                                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    >
                                        <span className='d-flex align-items-center px-1 pulse-act-btn'>
                                            <Buttons
                                                props={{
                                                    buttonText: '',
                                                    tooltip: '',
                                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent",
                                                    buttonEvent: (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    },
                                                    ImgSrc: () => <ICON_ATTACHMENT width='20' height='20' color='#333333'/>,
                                                    isDisable: disableInput || domainOptions?.length === 0 || noOpenAiAccess,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                }}
                                            />
                                        </span>
                                    </Popover> */}
                                    {/* <span className='d-flex align-items-center px-1 pulse-act-btn'>
                                        <Buttons
                                            props={{
                                                buttonText: '',
                                                tooltip: '',
                                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent",
                                                buttonEvent: (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                },
                                                ImgSrc: () => <ICON_LAYOUT width='20' height='20' color='#333333'/>,
                                                isDisable: disableInput || domainOptions?.length === 0 || noOpenAiAccess,
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                            }}
                                        />
                                    </span> */}
                                </>
                                :   ''
                            }
                            {
                                browserSupportsSpeechRecognition && !fetchingConversation
                                ?
                                <span className='d-flex align-items-center pr-1 pl-2 pulse-act-btn'>
                                    <Buttons
                                        props={{
                                            buttonText: '',
                                            tooltip: '',
                                            buttonClassName: `${listening ? 'bx-flashing text-danger' : ''} custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent`,
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                
                                                if(!listening) {
                                                    SpeechRecognition.startListening()
                                                }
                                                else {
                                                    setSearchValue("")
                                                    autoGrowInpRef.current.innerText = ""
                                                    SpeechRecognition.stopListening()
                                                }
                                            },
                                            ImgSrc: () => 
                                                listening
                                                ?
                                                <ICON_MIC_MUTE width='20' height='20' color='#dc3545' />
                                                :
                                                <ICON_MIC_FILLED width='20' height='20' color='#333333' />,
                                            isDisable: disableInput || domainOptions?.length === 0 || noOpenAiAccess || checkTextLimit,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                    {/* <ICON_MIC_FILLED width='20' height='20' color='#33333370' /> */}
                                </span>
                                :   ''
                            }
                            {
                                // isUnstructured
                                // ?
                                // <></>
                                // :
                                <>
                                    {
                                        fetchingConversation ? ''
                                        :
                                        <span className='d-flex align-items-center px-1 pulse-act-btn'>
                                            <Buttons
                                                props={{
                                                    buttonText: '',
                                                    tooltip: '',
                                                    buttonClassName: "custom-btn-outline custom-btn btn-with-icon btn-large bg-transparent",
                                                    buttonEvent: (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        onSearch()
                                                    },
                                                    ImgSrc: () => <ICON_PLANE_FILLED width='20' height='20' color='#333333'/>,
                                                    isDisable: disableInput || domainOptions?.length === 0 || noOpenAiAccess || checkTextLimit,
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                                }}
                                            />
                                            {/* <ICON_PLANE_FILLED width='20' height='20' color='#33333370' /> */}
                                        </span>
                                    }
                                </>
                            }
                            {
                                !fetchingConversation ? ''
                                :
                                <span className='d-flex align-items-center px-1 pulse-act-btn'>
                                    <Buttons
                                        props={{
                                            buttonId: 'customChatStop',
                                            buttonText: '',
                                            tooltip: '',
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon bg-transparent",
                                            buttonEvent: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                stopResponding()
                                            },
                                            ImgSrc: () => <ICON_STOP_FILLED width='20' height='20' color='#333333'/>,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }}
                                    />
                                </span>
                            }
                        </>
                    }
                </div>
                {
                    domainError ? showOneLineError(`Domain Error: ${domainError}`) : ''
                }
                <Drawer
                    title={
                        <>
                            <div className='pod-col-drawer-srch'>
                                <SearchInput
                                    placeholder={source === PULSE_SOURCES?.pod ? 'Search columns...' : source === PULSE_SOURCES?.documents ? 'Search documents...' : undefined}
                                    searchData={colsSearchData}
                                    setSearchData={setColsSearchData}
                                />
                            </div>
                        </>
                    }
                    placement={'left'}
                    closable={false}
                    
                    onClose={() => {
                        setColsSearchData('')
                        setOpenColDrawer(false)
                    }}
                    open={openColDrawer}
                    rootClassName={`pulse-col-draw ${window?.location?.href && window?.location?.href?.includes('/home') ? 'home-page' : ''}`}       
                    width={'15.625rem'}       
                    destroyOnClose={true}
                    afterOpenChange={(visibility) => {
                        setDrawerVisible(visibility)
                    }}
                >
                    {
                        colsError ? getReloadErrorTemplate({
                            errorMessage: colsError,
                            onReload: () => fetchColsOfPods()
                        })
                        :   colsLoading ? <ClaristaLoader/>
                            :
                            <div className='h-100 col-li-panel'>
                                {
                                    colsEmpty ? <div className='p-3 text-center text-black-50'>No Pods Published</div> 
                                    :
                                    drawerData?.length
                                        ? <LandingSimpleTableInner
                                            cols={columns}
                                            onRowClick={onColsRowClick}
                                            rowsData={drawerData}
                                            tableHeight={'100%'}
                                            headerRowHeight={0}
                                            rowHeight={variableRowHeight}
                                        />
                                        : ''
                                }
                            </div>
                    }
                </Drawer>
            </div>
            {
                /**
                 * In Case Want Arrow Button Toggler Use <LeftSidePanelToggler/>
                 */
                true 
                ?
                    !hideSearchBar && domainOptions?.length === 0 ? ""
                    :
                    <div className={`pulse-col-strip ${source === PULSE_SOURCES.documents ? 'docs' : ''}`} 
                        onClick={() => {
                            if(!openColDrawer) {
                                setDrawerVisible(true)
                            }
                            setOpenColDrawer(!openColDrawer)
                        }}
                        >
                        {
                            openColDrawer ? source === PULSE_SOURCES.documents ? 'Hide Documents' : 'Hide Columns' : source === PULSE_SOURCES.documents ? 'Show Documents' : 'Show Columns'
                        }
                    </div>
                :
                <LeftSidePanelToggler
                    customTooltip='Published Table(s)'
                    open={openColDrawer}
                    setOpen={setOpenColDrawer}
                />
                /**
                 * End of above comment
                 */
            }
        </>
    )
})

const columns = [
    {
        name: '',
        key: "element",
        sortable: false,
        resizable: false,
    }
]

export default PulseSearchBox