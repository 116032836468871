import Markdown from 'markdown-to-jsx'
import React from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash';
import { documentFileTypeColor, documentFileTypeIcon, getObjectLength } from '../modules/common/helperFunctions';
import { Popover } from 'antd';
import UnstructuredFileName from '../modules/dataCatalogue/podCreation/components/UnstructuredFileName';

const ChartComponent = ({
    option = {}
}) => {
    
    const getChart = () => {

        let parsed;

        try {
            parsed = JSON?.parse(option)
        }
        catch(error) {
            console.error('Invalid JSON:', error);
            parsed = {}
        }

        try {
            if(_.isObject(parsed) && getObjectLength(parsed))
            return <ReactEcharts
                option={parsed}
                style={{ width: '100%', height: '16rem' }}
                opts={{ renderer: 'canvas' }}
            />
            return ''
        }
        catch(err) {
            console.log("MD Chart ERROR::",{err, option})
            return ''
        }
    }

    return <div className='md-chart'>
        {getChart()}
    </div>

}

const Footnote = ({ children, citationObj }) => {
    const footnoteId = children[0];
    
    const content = citationObj[footnoteId];
    const fileType = content?.source?.file_type ?? _.last(content?.source?.file_name?.split('.'))?.toLowerCase()

    const [open, setOpen] = React.useState(false)
    
    const citationRef = React.useRef(null)

    React.useEffect(() => {
        const el = citationRef?.current

        const preventClick = (e) => { e?.preventDefault() }

        if(el && el?.parentElement) {
            el.parentElement.addEventListener('click', preventClick)
        }

        return () => {
            if(el && el?.parentElement) {
                el.parentElement.removeEventListener('click', preventClick)
            }
        }
    }, [])

    return (
        <Popover 
            open={open}
            onOpenChange={bool => {
                setOpen(bool)
            }}
            title={<>
                <div className='px-3 pt-3 pb-2 fontInterSemiBold text-dark fontSizeLabel'>
                    {/* <span className='mr-2'>
                        {documentFileTypeIcon(fileType ?? 'unknown', documentFileTypeColor(fileType), '1rem', '1rem')}
                    </span>
                    {content?.source?.file_name} */}
                    {
                        content?.source?.connection_name
                        ?
                        <UnstructuredFileName
                            key={`${footnoteId}-${content?.citation_id}-${content?.source?.file_name}`}
                            connectionName={content?.source?.connection_name}
                            dataObj={{document_name: content?.source?.file_name, document_type: fileType, prefix: content?.source?.prefix, connection_name: content?.source?.connection_name}}
                            // smallRowHeight
                            openFileOnClick={true}
                            openFileOnClickCallback={() => {
                                setOpen(false)
                            }}
                            parentOpen={open}
                            jumpToPage={content?.source?.page_index_start}
                        />
                        :   ""
                    }
                </div>
            </>}
            content={
                <>
                    <div className='px-3 pb-3 text-color-grey fontInterMedium fontSizeHeading' style={{maxWidth: '18rem'}}>
                        <p className='mb-0 md-citation-content'>
                            {content?.text}
                        </p>
                    </div>
                </>
            } 
            placement='top' 
            arrow={false} 
        >
            <sup ref={citationRef} className="md-citation fontInterSemiBold">
                {children}
            </sup>
        </Popover>
    )
};

const MarkdownText = ({
    markdown = '',
    citationObj = {}
}) => {

    return <Markdown
        options={{
            overrides: {
                ChartComponent: ChartComponent,
                sup: {
                    component: ({children}) => <Footnote children={children} citationObj={citationObj} />,
                },
            }
        }}
    >
        {markdown ?? ''}
    </Markdown>
}

export default MarkdownText