import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import { useStore } from 'react-redux'
import { useNavigate } from "react-router-dom";

import Buttons from '../../Common/button/Buttons';
import { CLOSE, SAVE } from '../../Common/iconSource';
import { Literals } from '../common/literals';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { scheduleClusterAction } from '../../../store/modules/cluster/Cluster';
import MultiSelection from '../../Common/dropDown/MultiSelection';
import moment from 'moment-timezone';
import TimeZoneDropdown from '../scheduler/components/TimeZoneDropdown';
import { emitToastNotification } from '../../../helpers/toast_helper';

const ScheduleCluster = ({ openModal, setOpenModal, getClusterListApi, showStopped, selectedScheduleItem, setselectedScheduleItem }) => {

    const history = useNavigate();
    const [dayselection, setdayselection] = useState([])
    const [start_time, setstart_time] = useState(null)
    const [end_time, setend_time] = useState(null);
    const [validUntil, setvalidUntil] = useState(selectedScheduleItem?.scheduled_until);
    const [isDisable, setisDisable] = useState(false);
    const [is_active, setis_active] = useState(selectedScheduleItem?.is_scheduled);
    const [selectedTimeZone, setselectedTimeZone] = useState(moment.tz.guess())

    const store = useStore()

    useEffect(() => {
        return () => {
            setdayselection([]);
            setstart_time(null)
            setend_time(null)
            setvalidUntil(null)
        }
    }, [])

    useEffect(() => {
        setdayselection(selectedScheduleItem?.schedule_config.start_days);
        setstart_time(selectedScheduleItem?.schedule_config?.start_time)
        setend_time(selectedScheduleItem?.schedule_config?.end_time)
        setvalidUntil(selectedScheduleItem?.scheduled_until)
        setis_active(selectedScheduleItem?.is_scheduled)

    }, [selectedScheduleItem])




    let daysList = [
        { key: 1, Name: "Monday", Type: "", value: "MON" },
        { key: 2, Name: "Tuesday", Type: "", value: "TUE" },
        { key: 3, Name: "Wednesday", Type: "", value: "WED" },
        { key: 4, Name: "Thursday", Type: "", value: "THU" },
        { key: 5, Name: "Friday", Type: "", value: "FRI" },
        { key: 6, Name: "Saturday", Type: "", value: "SAT" },
        { key: '0', Name: "Sunday", Type: "", value: "SUN" }
    ];


    const validationCheck = () => {
        if (!dayselection?.length) {
            emitToastNotification('error', 'Please select days to schedule cluster');
            return false;
        } else if (!start_time && !end_time) {
            emitToastNotification('error', 'Please select either start time or end time to schedule cluster')
            return false;

        } else {
            return true;
        }

    }
    const CreateCluster = () => {
        if (validationCheck()) {
            let payload = {
                "days": dayselection,
                "start_days": dayselection,  //utcstartDays,
                "end_days": dayselection, //utcendDays,
                "start_time": start_time && start_time !== "" ? start_time : null,//newUTCTimeandDays?.startUtc ?? null,
                "end_time": end_time && end_time !== "" ? end_time : null,//newUTCTimeandDays?.endUtc ?? null,
                "scheduled_until": null,
                "cron_timezone": selectedTimeZone,
                is_scheduled: is_active
            }
            store.dispatch(scheduleClusterAction(selectedScheduleItem?.id, payload)).then((resp) => {
                setisDisable(false)
                setOpenModal(false);
                getClusterListApi(showStopped)
            }).catch(e => setisDisable(false))
        }




    }




    return (
        <Modal size="lg" isOpen={openModal} className="custom-modal" centered={true}>
            <ModalHeader>

                <div className="d-flex align-items-center">

                    <h2 className="section-heading">{'Schedule Cluster'}</h2>

                </div>
                <div className="tab-menu right-flow-header">
                    {/* <Tooltip title={is_active ? 'Scheduled' : 'Unscheduled'}>
                        <Switch
                            className={`custom-switch-btn mr-3`}
                            checkedChildren="Yes" unCheckedChildren="No"
                            checked={is_active}
                            onChange={(e) => { setis_active(e) }}
                            disabled={false}
                        />
                    </Tooltip> */}
                    <Buttons props={{
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {

                            setdayselection([]);
                            setstart_time(null)
                            setend_time(null)
                            setvalidUntil(null);
                            setselectedScheduleItem()

                            setOpenModal(false)

                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_TERTIARY
                    }} />
                </div>
            </ModalHeader>
            <ModalBody >

                <div className='d-flex align-items-center justify-content-center w-100'>
                    <div className='col-6'>

                        <MultiSelection props={{
                            label: "Select Days ", placeholder: "", dropDownValue: dayselection, onClickEvent: (value) => {
                                setdayselection(value)
                            }, optionList: daysList, isSortOptionDisable: true, isDisable: false
                        }} />

                    </div>
                    <div className='col-6'>
                        <TimeZoneDropdown fromRoute='cluster' selectedTimeZone={selectedTimeZone} setselectedTimeZone={setselectedTimeZone} />

                    </div>
                    {/* <div className='col-6 '>
                        <label className="label"> Scheduled Until  </label>
                        <DatePicker
                            picker="date"
                            placeholder="Please select date"
                            className={
                                !validUntil
                                    ? "custom-input-field "
                                    : "custom-input-field"
                            }
                            disabledDate={(current) => current && (current < moment().endOf('day'))}
                            value={validUntil ? dayjs(validUntil, "YYYY-MM-DD") : null}
                            format="YYYY-MM-DD"
                            onChange={(e, val) => {

                                //let value=e.target.value
                                let value = val;
                                setvalidUntil(value)
                            }}
                        />
                    </div> */}

                </div>
                <div className='d-flex align-items-center mt-3 justify-content-center w-100'>
                    <div className='col-6 '>
                        {/* <span className='ml-1' style={{ fontStyle: "italic", fontSize: "9px" }}>{'(UTC hh:mm)Time Zone'}</span> */}
                        <label className="label"> Start Time  </label>
                        <TimePicker picker="time"
                            placeholder="Please select start time"
                            className={
                                "custom-input-field w-100"
                            }
                            minuteStep={15}

                            value={start_time ? dayjs(start_time, 'HH:mm') : null}
                            format={"HH:mm"}
                            changeOnScroll
                            needConfirm={false}
                            disabled={false}

                            onChange={(e, val) => {
                                setstart_time(val)

                            }}
                        ></TimePicker>
                    </div>
                    <div className='col-6'>
                        {/* <span className='ml-1' style={{ fontStyle: "italic", fontSize: "9px" }}>{'(UTC hh:mm)Time Zone'}</span>  */}
                        <label className="label"> End Time </label>
                        {/* <TimePicker picker="time"
                            placeholder="Please select end time"
                            className={
                                "custom-input-field w-100"
                            }
                            minuteStep={15} 
                            
                            value={end_time ? dayjs(end_time, 'HH:mm') : null}
                            format={"HH:mm"}
                            disabled={false}
                            // disabledTime={(current) => {console.log(current)}}
                            onChange={(e, val) => {
                                setend_time(val)

                            }}
                        ></TimePicker> */}
                        <TimePicker
                            picker="time"
                            placeholder="Please select end time"
                            className="custom-input-field w-100"
                            minuteStep={15}
                            value={end_time ? dayjs(end_time, 'HH:mm') : null}
                            format={"HH:mm"}
                            changeOnScroll

                            needConfirm={false}
                            disabled={false}
                            onChange={(e, val) => {
                                setend_time(val)

                            }}
                        />
                    </div>


                </div>


            </ModalBody>
            <ModalFooter>

                <Buttons props={{ buttonText: 'Save', buttonClassName: "custom-btn-primary custom-btn text-center  btn-with-text", buttonEvent: () => { CreateCluster() }, ImgSrc: () => <SAVE />, isDisable: isDisable, buttonType: Literals.BTN_PRIMARY_WITH_TEXT }} />
            </ModalFooter>
        </Modal>
    )
}

export default ScheduleCluster

{/* <span className='ml-1' style={{ fontStyle: "italic", fontSize: "9px" }}>{'(UTC)Time Zone'}</span>  */ }
