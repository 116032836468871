import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Tooltip, Checkbox } from "antd";
import { useStore, useSelector, useDispatch } from "react-redux";
import { getLandingPageData, openModel } from "../../store/actions";
import { connect } from "react-redux";
import LoadingComponent from "../modules/common/loadingComponent";
import {
  getRelativeTime, permissions, showSimpleLoading,

} from "../modules/common/helperFunctions";

import Accordian from "./accordion/Accordian";
import { ACCORDIONDOWNARROW, CLOSE, FILTER, PODICON } from "./iconSource";

import PodTaglist from "../modules/analytics/components/PodTaglist";

import Filter from "./filter/Filter";
import SearchInput from "./search/Search";
import Buttons from "./button/Buttons";
import { Literals } from "../modules/common/literals";
import Highlighter from "react-highlight-words";
import { LandingSimpleTable } from "./landingSimpleTable/LandingSimpleTable";
import NoDataRecordFound from "../modules/common/NoDataRecordFound";
import NoResultsFound from "../modules/flows/createFlows/NoResultsFound";
import SourceTypeImage from "../modules/common/sourceTypeImage";
import { setSelectedPodList } from "../../store/modules/common/podSelectionModalAction";
import NoSearchResultFound from "../modules/common/NoSearchResultFound";
import { ICON_CATALOG_DOMAIN, ICON_CHECK } from "./newIconSource";
import ClaristaLoader from "./claristaLoader/ClaristaLoader";
import { useLayoutEffect } from "react";
import { openFilter } from "../../store/modules/UserManagement/users/usersActions";

const PODSelection = ({
  openModal,
  onClose,
  onRowSelection,
  removeSelectedPods,
  isMultiSelection = true,
  save,
  podId,
  fromRoute,
  ...props
}) => {
  const store = useStore();
  const dispatch = useDispatch();
  // const data = useSelector(
  //   (state) => state.DataCatalogue.LandingPage.landingPageData
  // );
  const [data, setData] = useState([])
  const [searchData, setSearchData] = useState("");

  const [filterList, setfilterList] = useState([
    {
      header: "Data Domain",
      list: [],
    },
  ]);


  const [listData, setlistData] = useState([]);
  const [accordionList, setaccordionList] = useState([]);

  const [showFilter, setshowFilter] = useState(false);

  const [selectedPODsList, setselectedPODsList] = useState([]);
  const [singleSelectedPOD, setsingleSelectedPOD] = useState([]);
  const [selectedFilters, setselectedFilters] = useState([]);
  const [podListData, setPodListData] = useState([]);
  const [expandKeys, setexpandKeys] = useState([])
  const [selectedRowIndex, setselectedRowIndex] = useState()
  const [showNoRecordPage, setshowNoRecordPage] = useState(false)
  const [showLoadingMsg, setshowLoadingMsg] = useState(false)
  const filterStatus = useSelector((state) => {

    return state.UserManagement.Users.openFilter
  })
  const setshowFilterPopup = () => {
    dispatch(openModel('filter'))
    setshowFilter(true)
  };
  const pods = useSelector(
    (state) => state.CommonComponent.PodSelectionModalReducer.podList
  );
  const [tagList, settagList] = useState([]);

  useEffect(() => {

    if (pods && pods.length) {
      settagList(pods);
      setselectedPODsList(pods);
    } else {
      settagList([]);
      setselectedPODsList([]);
    }
  }, [pods]);

  useEffect(() => {
    if (podListData) {
      setData([...podListData])
    }
  }, [podListData])

  const removeTags = (id) => {
    setselectedPODsList((selectedPODsList) => {
      const newList = [...selectedPODsList];
      const index = newList.findIndex((ele) => ele.podId === id);

      if (index !== -1) {
        newList.splice(index, 1);
      }

      settagList(newList);
      return newList;
    });
  };
  const sourcetyprfn = (dataname) => {
    return <SourceTypeImage dataName={dataname} align="center" />
  };


  const checkedSelectedRow = (record, index) => {


    if (data && data?.length) {
      const allPodIds = tagList.map((tag) => tag.podId);

      if (!allPodIds.includes(record.key)) {
        let findRow = data.find((ele) => ele.table_id.toString() === record.key.toString())

        if (findRow && Object.keys(findRow).length) {

          const newPod = {
            podId: findRow.table_id,
            podName: findRow.table_name,
            notification: true,
            pod_name: findRow.table_name,
            description: findRow.pod_description,
            source: findRow.source_type,
            dataDomain: findRow.data_domain_detail.data_domain_name,
            databaseName: findRow.data_domain_detail.data_domain_name,
          };


          setselectedPODsList([...selectedPODsList, newPod]);

          settagList([...tagList, newPod]);

        }
      } else {
        setselectedPODsList((selectedPODsList) => {
          const newList = [...selectedPODsList];
          const index = newList.findIndex((ele) => ele.podId === record.key);

          if (index !== -1) {
            newList.splice(index, 1);
          }

          settagList(newList);
          return newList;
        });
      }
    }

  }
  const onRowClick = (record, index) => {

    let findRow = data.find((ele) => ele.table_id.toString() === record.key.toString())
    if (findRow && Object.keys(findRow).length) {

      const newPod = {
        key: record.key,
        podId: findRow.table_id,
        podName: findRow.table_name,
        notification: true,
        pod_name: findRow.table_name,
        description: findRow.pod_description,
        source: findRow.source_type,

        dataDomain: findRow.data_domain_detail.data_domain_name,
        databaseName: findRow.data_domain_detail.data_domain_name,
      };

      setselectedPODsList([newPod])

      onRowSelection(newPod, index);

    }

  }

  const getPodTableData = async () => {
    let response;
    let payload = { action: "list_pod" };
    try {
      response = await store.dispatch(getLandingPageData(true, true, true, undefined, true));
      if (response?.status === "success") {
        let data = response?.data
        // data = data?.filter(el => !!el?.permission?.find(v => v === permissions.dataCatalog.editor))
        setPodListData([...data])
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (openModal) {
      getPodTableData();
      setshowLoadingMsg(false)
    }
  }, [openModal]);

  const checkIsSelectedPod = (key, tagList) => {
    const allPodIds = tagList.map((tag) => tag.podId);
    return allPodIds.includes(key);
  };

  const podNameFunction = (row, tagList) => {
    let isChecked = false;
    if (tagList && tagList.length) {
      isChecked = checkIsSelectedPod(row.table_id, tagList);
    }



    return (
      <>
        {isMultiSelection ? (
          <div className="d-flex align-items-center mt-2">
            <Checkbox
              key={row.table_id}
              checked={isChecked}

              className="pr-2"

            ></Checkbox>{" "}
            <span className="flexible-search-wrap"><Tooltip placement="topLeft" title={row.table_name?.toUpperCase()}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.table_name.toUpperCase()} /></Tooltip></span>
          </div>
        ) : (
          <> <span className="flexible-search-wrap"><Tooltip placement="topLeft" title={row.table_name.toUpperCase()}>< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.table_name.toUpperCase()} /></Tooltip></span></>
        )}
      </>
    );
  };
  const createDomainGroup = (data, tagList) => {
    let TableData = [];
    if (data && data.length) {

      if (fromRoute === 'crawler') {
        data = data.filter((item) => (item?.source_type && (item?.source_type?.toLocaleLowerCase() === 's3' || item?.source_type?.toLocaleLowerCase() === 'azure_adls')))
      }
      data.forEach((row) => {
        if (TableData[row?.data_domain_detail?.data_domain_name]) {
          TableData[row?.data_domain_detail?.data_domain_name].push({
            key: row.table_id,
            allData: row,
            podName: podNameFunction(row, tagList),
            description: <Tooltip className="flexible-search-wrap" placement="topLeft" title={row.pod_description ? row.pod_description : ''}> < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.pod_description ? row.pod_description : ''} /></Tooltip>,
            sources: sourcetyprfn(row.source_type),
            createdOn: <Tooltip placement="topLeft" title={getRelativeTime(row.created_on)}>{getRelativeTime(row.created_on)}</Tooltip>,
          });
        } else {
          // checked=
          TableData[row?.data_domain_detail?.data_domain_name] = [];
          TableData[row?.data_domain_detail?.data_domain_name].push({
            key: row.table_id,
            allData: row,
            podName: podNameFunction(row, tagList),
            description: <Tooltip className="flexible-search-wrap" placement="topLeft" title={row.pod_description ? row.pod_description : ''}> < Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={row.pod_description ? row.pod_description : ''} /></Tooltip>,
            sources: sourcetyprfn(row.source_type),
            createdOn: <Tooltip placement="topLeft" title={getRelativeTime(row.created_on)}>{getRelativeTime(row.created_on)}</Tooltip>,
          });
        }
      });
    }

    return TableData;
  };


  const createAccordion = (TableData, tagList) => {

    let temp = [];

    Object.keys(TableData).forEach((ele, i) => {
      temp.push({
        header: (
          <Fragment>
            <ICON_CATALOG_DOMAIN height="20" width="20" /> <span className="ml-2">< Highlighter searchWords={[searchData?.toLocaleLowerCase()]} autoEscape={true} textToHighlight={ele} /></span>
          </Fragment>
        ),
        key: i,
        innerContent: () => (
          TableData[ele].length ? <>
            {!showLoadingMsg ? showSimpleLoading('Please wait loading pod list...') : null}
            <LandingSimpleTable
              isTableIDPreset={setshowLoadingMsg}
              cols={columns}
              autoWidth={false}
              rowsData={TableData[ele]}
              selectedRowIndex={selectedRowIndex}
              onRowClick={(record, index) => { setselectedRowIndex(record.key); !isMultiSelection ? onRowClick(record, index) : checkedSelectedRow(record, index) }}
              getComparator={getComparator}
              tableHeight={(TableData[ele]?.length * 41) + 41}
            />
          </> : <ClaristaLoader />




        ),
      });

    });
    setexpandKeys(temp.map((ele) => ele.key))
    setaccordionList([...temp]);
   
  };


  useEffect(() => {
    let TableData = createDomainGroup(data, tagList);
    let filterlist = [];
    Object.keys(TableData).forEach((ele, i) => {
      filterlist.push({ id: i, checked: false, name: ele });
    });

    let tempFilterList = [...filterList];
    tempFilterList[0].list = filterlist;
    setfilterList([...tempFilterList]);

    setlistData([...TableData]);
    // setTimeout(() => {
    //   setshowLoadingMsg(true)

    // }, 1000);
    createAccordion(TableData);
  }, [data, tagList, showLoadingMsg]);

  const filterDataApply = () => {
    if (selectedFilters.length && selectedFilters[0]["Data Domain"]?.length) {
      let filterData = [];
      selectedFilters[0]["Data Domain"].forEach((ele) => {
        let item = data?.filter(
          (data) => data.data_domain_detail.data_domain_name?.toLowerCase() === ele?.name?.toLowerCase()
        );
        if (item?.length) {
          filterData = [...filterData, ...item];
        }
      });



      if (filterData && filterData?.length) {
        return filterData

      }
    } else {
      return data

    }
  }
  useEffect(() => {

    let filterData = filterDataApply()?.filter((data) => {
      return (
        data.table_name?.toLowerCase().includes(searchData?.toLowerCase()) ||
        data.pod_description?.toLowerCase().includes(searchData?.toLowerCase()) ||
        getRelativeTime(data.created_on)
          ?.toLowerCase()
          .includes(searchData?.toLowerCase()) ||
        data.data_domain_detail.data_domain_name?.toLowerCase().includes(searchData?.toLowerCase())
      );
    });

    if (filterData && filterData?.length) {
      let data = filterData
      let tableData = createDomainGroup(data, tagList);

      createAccordion(tableData);
      setshowNoRecordPage(false)

      setlistData([...tableData]);
    }
    else if (searchData !== '') {
      setshowNoRecordPage(true)
    }
  }, [searchData, selectedFilters, selectedRowIndex]);

  // useEffect(() => {
  //   if (selectedRowIndex) {
  //     let TableData = createDomainGroup(data, tagList);

  //     createAccordion(TableData);

  //   }
  // }, [selectedRowIndex])

  const columns = [
    {
      name: Literals.POD_NAME,
      dataIndex: "podName",
      key: "podName",
      className: "description single-line-ellipsis",
      width: "30%",
      resizable: false

    },
    {
      name: Literals.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      className: "description single-line-ellipsis",
      width: "40%",
      sortable: false,
      resizable: false
    },
    {
      name: <div className="text-center">{Literals.SOURCES}</div>,
      dataIndex: "sources",
      key: "sources",
      width: "10%",
      sortable: false,
      resizable: false
    },

    // {
    //   name: Literals.CREATED_ON,
    //   dataIndex: "createdOn",
    //   key: "createdOn",
    //   sorter: {
    //     compare: (a, b) => a?.createdOn?.props?.title !== undefined ? a?.createdOn?.props?.title?.localeCompare(b?.createdOn?.props?.title) : null

    //   },
    //   sortable: false,
    //   width: "20%",
    //   resizable: false
    // },

  ];

  // let columns = getVirtualTableColumn([
  //   {
  //     columnName: "POD Name",
  //     dataIndex: "podName",
  //     isSort: false,
  //     width: "30%",
  //   },
  //   {
  //     columnName: "Description",
  //     dataIndex: "description",
  //     isSort: false,
  //     width: "40%",
  //   },
  //   {
  //     columnName: "Sources",
  //     dataIndex: "sources",
  //     isSort: true,
  //     width: "10%",
  //   },
  //   {
  //     columnName: "Created On",
  //     dataIndex: "createdOn",
  //     width: "20%",
  //     isSort: true,
  //   },
  // ]);




  const getComparator = (sortColumn) => {
    switch (sortColumn) {
      case 'podName':
        return (a, b) => a?.allData?.table_name ? a?.allData?.table_name.localeCompare(b?.allData?.table_name) : null
      case 'createdOn':
        return (a, b) => a[sortColumn]?.props?.title !== undefined ? a[sortColumn]?.props?.title?.localeCompare(b[sortColumn]?.props?.title) : null
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }


  // useEffect(() => {

  // }, [selectedFilters]);

  const onSaveHandler = () => {
    // dispatch(setSelectedAnalysisPODList(selectedPODsList))


    save(selectedPODsList);
  };
  const getFiltersStatus = () => {
    let filters = [...selectedFilters],
      status = null
    if (filters.length === 0) status = false
    else if (filters.length > 0) {
      status = !filters.map(v => v[Object.keys(v)[0]].length > 0).every(v => v === false)
    }
    return status

  }
  return (
    <>
      <Modal
        size="lg"
        isOpen={openModal}
        className="custom-modal"
        centered={true}
      >
        <ModalHeader>
          <div className="d-flex align-items-center">
            <PODICON height="20" />
            <h2 className="section-heading">Select PODs</h2>
          </div>
          <div className="tab-menu align-items-center right-flow-header">
            <div className="">  <SearchInput searchData={searchData} setSearchData={setSearchData} /></div>

            <Buttons
              props={{
                buttonText: "",
                buttonClassName:
                  `${getFiltersStatus() ? 'rdg-filter-active' : ''}
                  custom-btn-outline custom-btn btn-with-icon mr-2 ml-2`,
                buttonEvent: setshowFilterPopup,
                ImgSrc: () => <FILTER />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />

            <Buttons
              props={{
                buttonText: "",
                buttonClassName: "custom-btn-outline custom-btn btn-with-icon",
                buttonEvent: () => {
                  onClose(false);
                  setshowFilter(false);
                },
                ImgSrc: () => <CLOSE />,
                isDisable: false,
                buttonType: Literals.BTN_TERTIARY,
              }}
            />
          </div>

          {showFilter ? (
            <Filter
              props={{
                filterList: filterList,
                selectedFilters: selectedFilters,
                showPopover: 'new',
                from: 'podselectionmodal',
                setselectedFilters: setselectedFilters,
                setshowFilter: setshowFilter
              }}
            />
          ) : null}
        </ModalHeader>
        <ModalBody>
          {isMultiSelection && (
            <div className="podtag-list">
              <PodTaglist tagList={tagList} removeTags={removeTags} />
            </div>
          )}

          {!props.loadingStatus ? <div className="landing-page-accordion">

            {accordionList && accordionList.length && !showNoRecordPage ? <Accordian
              props={{
                isDisable: false,
                accordionList: accordionList,
                Icon: () => <ACCORDIONDOWNARROW />,
                defaultExpandedPanel: [...expandKeys],
              }}
            /> : showNoRecordPage ? <NoSearchResultFound mainMsg={searchData === '' ? 'No POD List Found' : ''} subMsg={'Please try Search / Filter with another term'} /> : null}
          </div> : null}

          {/* <ClaristaLoader />} */}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="custom-btn m-0 custom-btn-primary btn-with-text"
            onClick={onSaveHandler}
            disabled={!selectedPODsList?.length}
          >
            <ICON_CHECK />
            {/* <i className="bx bx-check"></i> */}
            Done
          </button>
        </ModalFooter>
      </Modal>
      {props.loadingStatus ? <LoadingComponent /> : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingStatus: state.LoadingReducer.loadingStatus,
  };
};

export default connect(mapStateToProps)(PODSelection);
