import { Badge, Empty, Progress, Tabs } from 'antd'
import React from 'react'
import { Literals } from '../../modules/common/literals'
import Buttons from '../button/Buttons'
import { ICON_ALERT, ICON_ANALYZE, ICON_CLOSE, ICON_LOADING_CIRCLE, ICON_NOTIFICATION, ICON_VERIFIED } from '../newIconSource'
import UserDropdown from '../../modules/usageAndJobs/components/UserDropdown'
import DomainDropdown from '../../modules/usageAndJobs/components/DomainDropdown'
import { useDispatch } from 'react-redux'
import { getNotifications, markAllReadNotification, markReadNotification } from '../../../store/modules/common/Notifications/NotificationsReducer'
import ClaristaLoader from '../claristaLoader/ClaristaLoader'
import { getJourneyTime, getMonthName, getRelativeTime, getReloadErrorTemplate } from '../../modules/common/helperFunctions'
import { useNavigate } from 'react-router-dom'
import MarkdownText from '../MarkdownText'

const colorMap = {
    success: '#14BA1A',
    warning: '#DEAA24',
    danger: '#CB1010',
    grey: '#ADB5BD'
}

const modules = {
    alert: 'ALERT',
    chat: 'CONVERSATION',
    health: 'DATA_DICTIONARY'
}

const NotificationsContent = ({
    isPopover = false,
    onClose = () => {},
    notifyData = {},
    setParentActiveTab = () => {},
    parentActiveTab = 'all'
}) => {

    const dispatch = useDispatch()

    const [activeKey, setActiveKey] = React.useState(parentActiveTab)
    const [allData, setAllData] = React.useState([])
    const [alertData, setAlertData] = React.useState([])
    const [healthData, setHealthData] = React.useState([])
    const [chatData, setChatData] = React.useState([])
    const [alertLoading, setAlertLoading] = React.useState(false)
    const [healthLoading, setHealthLoading] = React.useState(false)
    const [chatLoading, setChatLoading] = React.useState(false)
    const [allLoading, setAllLoading] = React.useState(false)
    const [loadMore, setLoadMore] = React.useState(false)
    const [pageNo, setPageNo] = React.useState(1)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [markingAll, setMarkingAll] = React.useState(false)

    const domainIdRef = React.useRef('')

    React.useEffect(() => {
        switch (parentActiveTab) {
            case 'alert':
                fetch.alert(true)
                break
            case 'chat':
                fetch.chat(true)
                break
            case 'health':
                fetch.health(true)
                break
            default:
                fetch.all(true)
                break
        }
    }, [])

    const handleData = (reset, res, data, setData, setLoading) => {
        if(reset) {
            setData([...data])
        }
        else {
            setData(prev => [...prev, ...data])
        }
        
        if (res?.data?.has_next === false) {
            setStopLazyload(true)
            setLoadMore(false)
        }

        if (res?.status === "success") {
            setLoadMore(false)
            setPageNo(prev => prev + 1)
        }
        
        setLoading(false)
    }
    
    const fetch = {
        all: (reset) => {
            if(reset) {
                setAllLoading(true)
            }
            else {
                setLoadMore(true)
            }
            setError(undefined)
            setStopLazyload(false)

            const page = {
                limit: isPopover ? 5 : 15,
                number: reset ? 1 : pageNo
            }
            dispatch(getNotifications(undefined, page, false))
            .then(res => {
                const dat = res?.data?.results ?? []
                handleData(reset, res, dat, setAllData, setAllLoading)
            })
            .catch(err => {
                console.error({err})
                setError(err?.message)
                setAllLoading(false)
            })
        },
        alert: (reset) => {
            if(reset) {
                setAlertLoading(true)
            }
            else {
                setLoadMore(true)
            }
            setError(undefined)
            setStopLazyload(false)

            const page = {
                limit: isPopover ? 5 : 15,
                number: reset ? 1 : pageNo,
                domain: domainIdRef?.current === 'all' ? undefined : domainIdRef?.current
            }
            dispatch(getNotifications(modules.alert, page, false))
            .then(res => {
                const dat = res?.data?.results ?? []
                handleData(reset, res, dat, setAlertData, setAlertLoading)
            })
            .catch(err => {
                console.error({err})
                setError(err?.message)
                setAlertLoading(false)
            })
        },
        health: (reset) => {
            if(reset) {
                setHealthLoading(true)
            }
            else {
                setLoadMore(true)
            }
            setError(undefined)
            setStopLazyload(false)

            const page = {
                limit: isPopover ? 5 : 15,
                number: reset ? 1 : pageNo,
                domain: domainIdRef?.current === 'all' ? undefined : domainIdRef?.current
            }
            dispatch(getNotifications(modules.health, page, false))
            .then(res => {
                const dat = res?.data?.results ?? []
                handleData(reset, res, dat, setHealthData, setHealthLoading)
            })
            .catch(err => {
                console.error({err})
                setError(err?.message)
                setHealthLoading(false)
            })
        },
        chat: (reset) => {
            if(reset) {
                setChatLoading(true)
            }
            else {
                setLoadMore(true)
            }
            setError(undefined)
            setStopLazyload(false)

            const page = {
                limit: isPopover ? 5 : 15,
                number: reset ? 1 : pageNo,
                domain: domainIdRef?.current === 'all' ? undefined : domainIdRef?.current
            }
            dispatch(getNotifications(modules.chat, page, false))
            .then(res => {
                const dat = res?.data?.results ?? []
                handleData(reset, res, dat, setChatData, setChatLoading)
            })
            .catch(err => {
                console.error({err})
                setError(err?.message)
                setChatLoading(false)
            })
        }
    }

    const markAllRead = (module, setData) => {
        setMarkingAll(true)
        dispatch(markAllReadNotification(module))
        .then(() => {
            setData(prev => prev?.map(d => ({...d, is_read: true})))
            setMarkingAll(false)
        })
        .catch(() => {
            setMarkingAll(false)
        })
    }

    const NotificationBand = ({ module, data = [], setData = () => {}}) => {
        return <div className='px-3 py-1 d-flex align-items-center justify-content-between'>
            <div>
                <label className='mb-0 fontSizeExtraSmall text-black-50 fontInterSemiBold'>
                    NOTIFICATIONS
                </label>
            </div>
            {
                data?.length === 0 || data?.every(d => d?.is_read) ? ''
                :
                <div>
                    <span 
                        style={{fontSize: 9, color: "#989898", borderColor: '#989898'}}
                        className='underline-button fontInterSemiBold' 
                        onClick={() => {
                            if(markingAll) return
                            markAllRead(module, setData)
                            // markAllRead(data?.filter(d => !d?.is_read).map(d => d?.id), setData)
                        }}
                    >
                        {
                            markingAll ? 'Marking...' : 'Mark all as read'
                        }
                    </span>
                </div>
            }
        </div>
    }

    const EmptyScreen = () => {
        return <Empty 
            className='d-flex flex-column justify-content-center'
            style={{height: 156}} 
            image={Empty.PRESENTED_IMAGE_SIMPLE} 
            description={'No Notifications'} 
        />
    }

    const getIcon = (type, status = colorMap.grey) => {
        switch (type) {
            case modules.alert:
                return <ICON_ALERT color={status} />
            case modules.chat:
                return <ICON_VERIFIED color={status} />
            case modules.health:
                return <ICON_ANALYZE color={status} />
            default:
                return <ICON_NOTIFICATION color={status} />
        }

    }

    const isAtBottom = ({ currentTarget }) => {
        return (
            currentTarget.scrollTop + 10 >=
            currentTarget.scrollHeight - currentTarget.clientHeight
        );
    }

    const onScrollAllData = (event) => {
        const paginate = isAtBottom(event)

        const loading = (() => {
            switch (activeKey) {
                case 'all':     return allLoading
                case 'chat':    return chatLoading
                case 'health':  return healthLoading
                case 'alert':   return alertLoading
                default:        return false
            }
        })()

        if (!stopLazyload && !loading && !isPopover) {
            if(paginate && !loadMore) {
                setLoadMore(true)
                fetch[activeKey]()
            }
        }
    }

    const getAlertColor = (priority) => {
        switch (priority?.toLowerCase()) {
            case 'low':
                return colorMap.success
            case 'medium':
                return colorMap.warning
            case 'high':
                return colorMap.danger
            default:
                return colorMap.grey
        }
    }

    const getHealthColor = (percent) => {
        return colorMap.warning
        /* if (percent > 50)
            return colorMap.warning
        else if (percent < 50)
            return colorMap.danger
        else return colorMap.grey */
    }

    const tabItems = [
        {
            key: 'all',
            label: <>All 
                <Badge 
                    size='small' 
                    count={notifyData?.total_unread ?? ''} 
                    style={{marginLeft: 4}}
                />
            </>,
            children: <>
                <div 
                    className='h-100' 
                    style={{overflowY: 'auto'}} 
                    onScroll={onScrollAllData}
                >
                    {
                        allLoading ? <ClaristaLoader height='13.75rem' />
                        : error ? getReloadErrorTemplate({
                            errorMessage: error, 
                            onReload: () => {
                                setPageNo(1)
                                fetch.all(true)
                            }
                        })
                        : allData?.length === 0 ? <EmptyScreen/> 
                        :
                        allData?.map((data, index) => (
                            <React.Fragment key={`all-notiItem-${index}`}>
                                <NotificationBox
                                    icon={() => getIcon(
                                        data?.notification?.content_type,
                                        (() => {
                                            switch (data?.notification?.content_type) {
                                                case modules.alert:
                                                    return getAlertColor(data?.notification?.details?.priority)
                                                case modules.health:
                                                    return getHealthColor(data?.notification?.details?.health)
                                                case modules.chat:
                                                    return colorMap.warning
                                                default: return undefined
                                            }
                                        })()
                                    )}
                                    heading={data?.notification?.msg}
                                    timestamp={data?.notification?.dttm}
                                    data={data}
                                    setData={setAllData}
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
            </>
        },
        {
            key: 'alert',
            label: <>Alert
                <Badge 
                    size='small' 
                    count={
                        notifyData?.notification_unread?.find(c => c?.content_type === modules.alert)?.unread_count ?? ''
                    } 
                    style={{marginLeft: 4}}
                />
            </>,
            children: <>
                        {
                            isPopover ? ''
                            :
                            <>
                                <div className='clst-noti-filter px-3 py-2'>
                                    <label className='fontSizeExtraSmall label-color fontInterSemiBold mb-0'>Filter By</label>
                                    <div className='d-flex align-items-center'>
                                        {/* <div className='mr-2'>
                                            <div>
                                                <label className='mb-2 fontSizeExtraSmall text-black-50 fontInterSemiBold'>
                                                    User
                                                </label>
                                            </div>
                                            <UserDropdown/>
                                        </div> */}
                                        <div>
                                            <div className='d-flex'>
                                                <label className='mb-2 fontSizeExtraSmall text-black-50 fontInterSemiBold'>
                                                    Domain
                                                </label>
                                            </div>
                                            <DomainDropdown
                                                selectedDomain={domainIdRef?.current}
                                                setselectedDomain={(id) => {
                                                    domainIdRef.current = id
                                                    fetch.alert(true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <NotificationBand module={modules.alert} data={alertData} setData={setAlertData} />
                            </>
                        }
                        <div className='notification-items-wrap mod-alert'
                            onScroll={onScrollAllData}
                        >
                            {
                                alertLoading ? <ClaristaLoader height='13.75rem' />
                                : error ? getReloadErrorTemplate({
                                    errorMessage: error, 
                                    onReload: () => {
                                        setPageNo(1)
                                        fetch.alert(true)
                                    }
                                })
                                : alertData?.length === 0 ? <EmptyScreen/> 
                                : alertData?.map((data, index) => (
                                    <React.Fragment key={`alert-notiItem-${index}`}>
                                        <NotificationBox
                                            icon={() => getIcon(
                                                data?.notification?.content_type, 
                                                getAlertColor(data?.notification?.details?.priority)
                                            )}
                                            heading={data?.notification?.msg}
                                            timestamp={data?.notification?.dttm}
                                            data={data}
                                            setData={setAlertData}
                                        />
                                    </React.Fragment>
                                ))
                            }
                        </div>
            </>
        },
        {
            key: 'health',
            label: <>Health
                <Badge 
                    size='small' 
                    count={
                        notifyData?.notification_unread?.find(c => c?.content_type === modules.health)?.unread_count ?? ''
                    } 
                    style={{marginLeft: 4}}
                />
            </>,
            children: <>
                {
                    isPopover ? ''
                    :
                    <>
                        <div className='clst-noti-filter px-3 py-2'>
                            <label className='fontSizeExtraSmall label-color fontInterSemiBold mb-0'>Filter By</label>
                            <div className='d-flex align-items-center'>
                                {/* <div className='mr-2'>
                                    <div>
                                        <label className='mb-2 fontSizeExtraSmall text-black-50 fontInterSemiBold'>
                                            User
                                        </label>
                                    </div>
                                    <UserDropdown/>
                                </div> */}
                                <div>
                                    <div className='d-flex'>
                                        <label className='mb-2 fontSizeExtraSmall text-black-50 fontInterSemiBold'>
                                            Domain
                                        </label>
                                    </div>
                                    <DomainDropdown
                                        selectedDomain={domainIdRef?.current}
                                        setselectedDomain={(id) => {
                                            domainIdRef.current = id
                                            fetch.health(true)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <NotificationBand module={modules.health} data={healthData} setData={setHealthData} />
                    </>
                }
                <div className='notification-items-wrap mod-health'
                    onScroll={onScrollAllData}
                >
                    {
                        healthLoading ? <ClaristaLoader height='13.75rem' />
                        : error ? getReloadErrorTemplate({
                            errorMessage: error, 
                            onReload: () => {
                                setPageNo(1)
                                fetch.health(true)
                            }
                        })
                        : healthData?.length === 0 ? <EmptyScreen/> 
                        : healthData?.map((data, index) => (
                            <React.Fragment key={`alert-notiItem-${index}`}>
                                <NotificationBox
                                    icon={() => getIcon(
                                        data?.notification?.content_type,
                                        getHealthColor(data?.notification?.details?.health)
                                    )}
                                    heading={data?.notification?.msg}
                                    timestamp={data?.notification?.dttm}
                                    data={data}
                                    setData={setHealthData}
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
            </>
        },
        {
            key: 'chat',
            label: <>TALKdata
                <Badge 
                    size='small' 
                    count={
                        notifyData?.notification_unread?.find(c => c?.content_type === modules.chat)?.unread_count ?? ''
                    } 
                    style={{marginLeft: '0.25rem'}}
                />
            </>,
            children: <>
                {
                    isPopover ? '' : <NotificationBand module={modules.chat} data={chatData} setData={setChatData} />
                }
                <div
                    style={{overflowY: 'auto', height: isPopover ? '100%' : 'calc(100% - 2.5rem)'}} 
                    onScroll={onScrollAllData}
                >
                    {
                        chatLoading ? <ClaristaLoader height='13.75rem' />
                        : error ? getReloadErrorTemplate({
                            errorMessage: error, 
                            onReload: () => {
                                setPageNo(1)
                                fetch.chat(true)
                            }
                        })
                        : chatData?.length === 0 ? <EmptyScreen/> 
                        :
                        chatData?.map((data, index) => (
                            <React.Fragment key={`all-notiItem-${index}`}>
                                <NotificationBox
                                    icon={() => getIcon(data?.notification?.content_type, colorMap.warning)}
                                    heading={data?.notification?.msg}
                                    timestamp={data?.notification?.dttm}
                                    data={data}
                                    setData={setChatData}
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
            </>
        }
    ]

    const handleTabChange = (key) => {
        setActiveKey(key)
        setParentActiveTab(key)

        domainIdRef.current = ''

        setPageNo(1)
        setLoadMore(false)
        setStopLazyload(false)
        setMarkingAll(false)

        setAllData([])
        setChatData([])
        setHealthData([])
        setAlertData([])

        fetch[key](true)
    }

    return (
        <div className='clst-notification-content-wrap'>
            <Tabs
                className='clst-noti-tabs'
                defaultActiveKey="all"
                items={tabItems}
                activeKey={activeKey}
                onChange={handleTabChange}
                tabBarExtraContent={
                    isPopover ? <></>
                    :   <>
                        <Buttons
                            props={{
                                buttonText: "",
                                tooltip: "",
                                buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                buttonEvent: () => {
                                    onClose()
                                },
                                ImgSrc: () => <ICON_CLOSE/>,
                                isDisable: false,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </>
                }
            />
        </div>
    )
}

export default NotificationsContent


const NotificationBox = ({icon = () => <></>, heading = '', timestamp = '', data = {}, setData = () => {}}) => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const [notifyTime, setNotifyTime] = React.useState(getRelativeTime(timestamp))
    const [marking, setMarking] = React.useState(false)

    React.useEffect(() => {
        const chatTimeInterval = setInterval(() => {
            setNotifyTime(getRelativeTime(timestamp))
        }, 30000)

        return () => {
            clearInterval(chatTimeInterval)
        }
    }, [timestamp])

    const markAsRead = () => {
        setMarking(true)
        let payload = {
            id: [data?.id]
        }
        dispatch(markReadNotification(payload))
        .then(() => {
            setData(prev => {
                prev = prev?.map(d => d?.id === data?.id ? ({...d, is_read: true}) : ({...d}))
                return prev
            })
            setMarking(false)
        })
        .catch(() => {
            setMarking(false)
        })
    }


    const readableDate = React.useMemo(() => {
        let date = new Date(timestamp)
        return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
    }, [timestamp])

    return <div>
        <div className='clst-noti-box'
            onClick={() => {
                if(window.screen.availWidth <= 640)  return
                markAsRead()
                switch (data?.notification?.content_type) {
                    case modules.chat: {
                        history(Literals?.links?.PULSE_TALK_TRACE+"#feedback", { state: { domain: data?.notification?.data_domain_detail } })
                        break
                    }
                    case modules.health: {
                        history(Literals?.links?.DATA_DICTIONARY_VIEW + data?.notification?.content_id)
                        break
                    }
                    case modules.alert: {
                        history(`${Literals?.links?.FLOWS}/${data?.notification?.details?.flow_id}?transformer_id=${data?.notification?.details?.alert_id}`)
                        break
                    }
                    default: break
                }
            }}
        >
            <div>
                <div className='clst-noti-mod-icon'>
                    {icon()}
                </div>
            </div>
            <div>
                <p className='clst-noti-title'>
                    <MarkdownText markdown={heading} />
                </p>
                <div className='clst-noti-ts'>{`${readableDate}, ${getJourneyTime(timestamp)} (${notifyTime})`}</div>
                {
                    data?.is_read ? ''
                    :
                    <div>
                        <Buttons
                            props={{
                                buttonText: marking ? "Marking..." : "Mark as read",
                                tooltip: "",
                                buttonClassName: `bg-white border custom-btn-outline custom-btn btn-with-text cls-noti-mark-read-btn`,
                                buttonEvent: (e) => {
                                    e?.stopPropagation()
                                    markAsRead()
                                },
                                ImgSrc: () => marking ? <ICON_LOADING_CIRCLE/> : <></>,
                                isDisable: marking,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    </div>
                }
            </div>
            <div>
                {
                    !data?.is_read
                    ?
                    <div className='clst-noti-unread-dot'>
                        <i className='bx bxs-circle color-primary'></i>
                    </div>
                    :   ''
                }
            </div>
        </div>
    </div>
}