import React from 'react'
import TraceDomainList from './TraceDomainList'
import { CustomSkeleton } from '../../../Common/skeleton/CustomSkeleton'
import { useDispatch } from 'react-redux'
import { getCatalogListing, setUserSelectedDomain } from '../../../../store/actions'
import Buttons from '../../../Common/button/Buttons'
import { ICON_CALENDAR, ICON_DELETE, ICON_INFO_FILLED, ICON_LOADING_CIRCLE, ICON_PLUS, ICON_RESET, ICON_SAVE, ICON_SQLFILE } from '../../../Common/newIconSource'
import { Literals } from '../../common/literals'
import { getJourneyTime, getMonthName, getReloadErrorTemplate, NoDataComponent, permissions } from '../../common/helperFunctions'
import SearchInput from '../../../Common/search/Search'
import ClaristaLoader from '../../../Common/claristaLoader/ClaristaLoader'
import './traceFormula.scss'
import { Alert, Select, Tooltip } from 'antd'
import SqlEditor from '../../dataCatalogue/preview/components/SqlEditor'
import _ from 'lodash'
import { createFormula, deleteSynonym, getFormulas, getSynonyms, updateFormula } from '../../../../store/modules/common/GenAi/GenAiReducer'
import DeleteModal from '../../../Common/deleteModal/DeleteModal'
import { emitToastNotification } from '../../../../helpers/toast_helper'

const demo = [
    { id: 0, name: "Revenue Growth Rate, Revenue Growth", columns: ['top_revenue', 'revenue'], date: '27 Jan 2021, 10:30 PM' },
    { id: 1, name: "Sales Growth Rate, Top Sales Performance", columns: ['top_revenue'], date: '27 Jan 2021, 10:30 PM' },
    { id: 2, name: "EBITDA", columns: ['top_revenue', 'revenue'], date: '27 Jan 2021, 10:30 PM' },
    { id: 3, name: "Sales Growth Rate, Top Sales Performance", columns: ['top_revenue', 'revenue'], date: '27 Jan 2021, 10:30 PM' },
]

const TraceFormula = ({
    parentDomain,
    setParentDomain = () => {}
}) => {

    const dispatch = useDispatch()

    const [activeDomain, setActiveDomain] = React.useState(parentDomain ?? null)
    const [createLoading, setCreateLoading] = React.useState(false)
    const [updating, setUpdating] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [error, setError] = React.useState(undefined)
    const [data, setData] = React.useState([])
    const [empty, setEmpty] = React.useState(false)
    const [stopLazyload, setStopLazyload] = React.useState(false)
    const [loadMore, setLoadMore] = React.useState(false)
    const [pageNo, setPageNo] = React.useState(1)
    const [searchText, setSearchText] = React.useState('')
    const [searchLoader, setSearchLoader] = React.useState(false)
    const [columns, setColumns] = React.useState([])
    const [queryValue, setQueryValue] = React.useState("")
    const [formulaNames, setFormulaNames] = React.useState([])
    const [formulaColumns, setFormulaColumns] = React.useState([])
    const [formulaDescription, setFormulaDescription] = React.useState("")
    const selectedDataRef = React.useRef(null)
    const selectedBulkRef = React.useRef(null)
    const searchRef = React.useRef('')
    const searchInputRef = React.useRef(null)

    React.useEffect(() => {
        setActiveDomain(parentDomain)
    }, [parentDomain])

    React.useEffect(() => {
        if (pageNo !== 1) {
            setPageNo(1)
        }
        searchRef.current = searchText?.toLowerCase()
        if(activeDomain && activeDomain !== "") {
            fetchList(true)
        }
    }, [activeDomain, searchText])

    React.useEffect(() => {
        if(activeDomain && activeDomain !== '') {
            fetchColumns()
        }
    }, [activeDomain])

    React.useEffect(() => {
        if(!loading || !searchLoader || !loadMore) {
            searchInputRef?.current?.focus()
        }
    }, [searchLoader, loading, loadMore])

    const fetchColumns = () => {
        dispatch(getCatalogListing(activeDomain, 'metadata', true, false))
        .then(res => {
            const dat = res?.data
            const cols = dat?.map(d => d?.column_detail)?.flat()?.map(c => c?.column_name)
            setColumns(_.uniq(cols))
        })
        .catch(() => {})
    }

    const createNewFormula = () => {
        setCreateLoading(true)
        const payload = {
            type: "FORMULA",
            synonym: formulaNames,
            column_arg: formulaColumns,
            definition: queryValue,
            description: formulaDescription,
            data_domain: activeDomain
        }
        dispatch(createFormula(payload, true))
        .then(res => {
            fetchList(true, true)
            setCreateLoading(false)
        })
        .catch(() => {
            setCreateLoading(false)
        })
    }

    const updateExistingFormula = () => {
        setCreateLoading(true)
        const payload = {
            type: "FORMULA",
            synonym: formulaNames,
            column_arg: formulaColumns,
            definition: queryValue,
            description: formulaDescription,
            data_domain: activeDomain
        }
        dispatch(updateFormula(activeData?.id, payload, true))
        .then(res => {
            // fetchList(true, true)
            setData(prev => {
                let updVals = {
                    synonym: payload?.synonym,
                    column_arg: payload?.column_arg,
                    definition: payload?.definition,
                    description: payload?.description
                }
                prev = prev?.map(d => d?.id === activeData?.id ? ({...d, ...updVals}) : ({...d}))
                return prev
            })
            setCreateLoading(false)
        })
        .catch(() => {
            setCreateLoading(false)
        })
    }
    
    const fetchList = (reset = false, preventAutoSelect = false) => {
        if(reset) {
            if(searchRef.current?.trim() !== "") {
                setSearchLoader(true)
            }
            else {
                setLoading(true)
            }
        }

        setError(undefined)
        setStopLazyload(false)

        const pageNumber = reset ? 1 : pageNo
        const pageLimit  = 30
        const search = searchRef.current ?? ''

        dispatch(getFormulas(pageNumber, pageLimit, activeDomain, search, false))
        .then(res => {
            let dat = res?.data
            let result = dat?.results
            if(dat) {
                setEmpty(result?.length === 0)
                if(reset) {
                    let list = result
                    if(list?.length > 0 && !preventAutoSelect) {
                        list[0]['active'] = true
                    }
                    setData([...list])
                }
                else {
                    setData(prev => [...prev, ...result])
                }
            }
            if (dat?.has_next === false) {
                setStopLazyload(true)
                setLoadMore(false)
            }

            if (res?.status === "success") {
                setLoadMore(false)
                setPageNo(prev => prev + 1)
            }

            if(searchRef.current?.trim() !== "") {
                setSearchLoader(false)
            }
            else {
                setLoading(false)
            }
        })
        .catch(err => {
            console.error({err})
            setError(err?.message)
            if(searchRef.current?.trim() !== "") {
                setSearchLoader(false)
            }
            else {
                setLoading(false)
            }
            setLoadMore(false)
        })
    }

    const handleDelete = (sData) => {
        setDeleting(true)

        dispatch(deleteSynonym(sData?.id))
        .then(() => {
            setData(prev => {
                let list = prev?.filter(p => p?.id !== sData?.id)
                if(sData?.active === true && list?.length > 0) {
                    list[0]['active'] = true
                }
                setEmpty(list?.length === 0)
                return list
            })
            setDeleteOpen(false)
            setDeleting(false)
        })
        .catch(err => {
            console.error({err})
            emitToastNotification('error', err?.message)
            setDeleting(false)
        })
    }

    const onScrollToBottom = (paginate) => {
        if (!stopLazyload && !loading) {
            if (paginate && !loadMore) {
                setLoadMore(true)
                fetchList()
            }
        }
    }

    const checkIsEditor = (item) => {
        const isRoleEditor = !!item?.permission?.find(v => v === permissions.dataCatalog.editor)
        if (isRoleEditor) return true
        return undefined
    }

    const isEditPermission = (item) => {
        if(!item) return true
        return checkIsEditor(item) ?? !!item?.permission?.find(v => v === permissions.dataCatalog.edit)
    }

    const sqlEditorDetails = React.useMemo(() => {
        if(columns && columns?.length) {
            return columns?.map(c => ({
                name: c,
                description: '',
                type: 'Column',
                tableName: "",
                key: c
            }))
        }
        return []
    }, [columns])

    const columnOptions = React.useMemo(() => {
        if(columns && columns?.length) {
            return columns?.map(c => ({
                label: c,
                value: c
            }))
        }
        return []
    }, [columns])

    const userSelectedDomain = (d_id, d_name) => {
        dispatch(setUserSelectedDomain({ data_domain_id: d_id, data_domain_name: d_name}, false))
        .catch(err => console.error(err?.message))
    }

    const activeData = React?.useMemo(() => {
        let itm = data?.find(d => d?.active === true)
        setFormulaNames(itm?.synonym?.filter(s => s !== "") ?? [])
        setFormulaColumns(itm?.column_arg ?? [])
        setFormulaDescription(itm?.description ?? '')
        setQueryValue("")
        setTimeout(() => {
            setQueryValue(itm?.definition ?? "")
        }, 500)
        return itm
    }, [data])

    const showFormulaWarning = React.useMemo(() => {
        if(formulaColumns?.length > 0 && queryValue && queryValue?.trim() !== "") {
            let allColumnsPresent = !formulaColumns.every(column => {
                let regex = new RegExp(`\\b${column}\\b`);
                return regex.test(queryValue);
                // return queryValue?.includes(column)
            });
            return allColumnsPresent
        }
        return false
    }, [formulaColumns, queryValue])
    
    const disableSaveBtn = React.useMemo(() => {
        return showFormulaWarning || createLoading || formulaNames?.length === 0 || formulaColumns?.length === 0 || queryValue?.trim() === ""
    }, [createLoading, formulaColumns, formulaNames, queryValue, showFormulaWarning])

    const readableDate = (dt) => {
        let date = new Date(dt)
        return `${date?.getDate()} ${getMonthName.short[date.getMonth()]} ${date?.getFullYear()}`
    }

    const createButtonRestriction = () => {
        if(data?.length === 0) {
            return false
        }
        else {
            return !(data?.some(d => !!d?.permission?.find(p => p === permissions.dataCatalog.editor)))
        }
    }
    
    return (
        <div className='trace-formula-wrap'>
            <div className='d-flex align-items-center justify-content-between trace-formula-header'
                style={{ borderBottom: 'solid 1px #f0f0f0' }}
            >
                <div>
                    <div className='d-flex align-items-center color-primary sel-domain'>
                        {/* {
                            loading
                                ?
                                <CustomSkeleton height='1.5rem' width='6.25rem' />
                                :
                                <TraceDomainList
                                    key={'dmListSynonym'}
                                    setParentDomain={setParentDomain}
                                    activeDomain={activeDomain}
                                    userSelectedDomain={userSelectedDomain}
                                />
                        }
                        <div className='vertical-separator' style={{ height: '1.5rem', width: '0.125rem' }}></div> */}
                        <label className='mb-0 fontSizeLargeHeading fontInterSemiBold text-dark'>Manage Formula</label>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    {
                        !activeData?.id ? ""
                        :
                        <Buttons
                            props={{
                                buttonText: "Create",
                                tooltip: "",
                                buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                buttonWrapperClass: 'd-flex justify-content-end',
                                buttonEvent: () => {
                                    selectedDataRef.current = null
                                    if(activeData?.id) {
                                        setData(prev => prev?.map(d => ({...d, active: false})))
                                        setQueryValue("")
                                    }
                                },
                                ImgSrc: () => <ICON_PLUS />,
                                isDisable: createLoading || createButtonRestriction(),
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                    }
                </div>
            </div>
            {
                error ? getReloadErrorTemplate({
                    errorMessage: error, 
                    onReload: () => {
                        setPageNo(1)
                        fetchList(true)
                    }
                })
                :
                <div className='trace-formula-body'>
                    <div className='position-relative'>
                        <div className='pr-2 py-2'>
                            <SearchInput
                                searchData={searchText}
                                setSearchData={setSearchText}
                                useDelay={true}
                                isDisable={loading || searchLoader || (empty && searchText === "")}
                                ref={searchInputRef}
                            />
                        </div>
                        <div className='trace-formula-list'
                            onScroll={
                                ({currentTarget}) => {
                                    onScrollToBottom(currentTarget.scrollTop + 10 >=
                                        currentTarget.scrollHeight - currentTarget.clientHeight)
                                }
                            }
                        >
                            {
                                loading ? <ClaristaLoader/>
                                :
                                searchLoader
                                ?
                                <div>
                                    {
                                        [...Array(3)]?.map((s, i) => (
                                            <React.Fragment key={`searchLoad-${i}`}>
                                                <div className='mb-3 mr-2'>
                                                    <CustomSkeleton height='8rem' width={`100%`} />
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                                :
                                data?.length === 0
                                ?
                                <div className='py-3 text-center text-black-50 fontSizeHeading'>
                                    No Data Found
                                </div>
                                :
                                data
                                ?.map((s, i) => (
                                    <React.Fragment key={`${i}-formula`}>
                                        <div 
                                            className={`formula-item ${activeData?.id === s?.id ? 'active' : ''} mr-2`}
                                            onClick={(e) => {
                                                e?.stopPropagation()
                                                setData(prev => prev?.map(d => ({...d, active: d?.id === s?.id})))
                                            }}
                                        >
                                            <div className='d-flex'>
                                                <label className="mb-0 fontSizeHeading fontInterMedium text-dark text-with-ellipsis">
                                                    {s?.synonym?.length ? s?.synonym?.join(', ') : s?.name ?? "Undefined"}
                                                </label>
                                                <Buttons
                                                    props={{
                                                        tooltip: "Delete",
                                                        tooltipPlacement: "top",
                                                        buttonClassName: `border-0 bg-transparent error-red custom-btn btn-with-icon`,
                                                        buttonWrapperClass: 'formula-delete-btn d-flex justify-content-end ml-1',
                                                        buttonEvent: () => {
                                                            selectedDataRef.current = {...s}
                                                            setDeleteOpen(true)
                                                        },
                                                        ImgSrc: () => <ICON_DELETE />,
                                                        isDisable: createLoading || !isEditPermission(s),
                                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                    }}
                                                />
                                            </div>
                                            <div className='d-flex'>
                                                <label className="mb-0 fontSizeLabel text-color-grey custom-ellipsis two-line-ellipsis">
                                                    {
                                                        s?.description ? s?.description : <em>No Description Provided</em>
                                                    }
                                                </label>
                                            </div>
                                            <div className='d-flex align-items-center flex-wrap' style={{gap: '.25rem'}}>
                                                {
                                                    s?.column_arg?.length > 2
                                                    ?   
                                                        <>
                                                            {
                                                                s?.column_arg?.slice(0, 2).map((c, idx) => (
                                                                    <React.Fragment key={`col_${idx}${c}`} >
                                                                        <Tooltip placement='topLeft' title={c}>
                                                                            <label
                                                                                className='fontSizeLabel mb-0 formula-col-pill text-with-ellipsis'
                                                                            >
                                                                                {c}
                                                                            </label>
                                                                        </Tooltip>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                            <Tooltip placement='topLeft' title={s?.column_arg?.slice(2)?.join(', ')}>
                                                                <label
                                                                    className='fontSizeLabel mb-0 formula-col-pill text-with-ellipsis'
                                                                >
                                                                    +{s?.column_arg?.slice(2)?.length}
                                                                </label>
                                                            </Tooltip>
                                                        </>
                                                    :
                                                    s?.column_arg?.map((c, idx) => (
                                                        <label key={`col_${idx}${c}`} 
                                                            className='fontSizeLabel mb-0 formula-col-pill text-with-ellipsis'
                                                        >
                                                            {c}
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                            <div className='d-flex align-items-center text-black-50'>
                                                <span className='mr-1 d-flex'><ICON_CALENDAR height='.7rem' width='.7rem' color='currentColor' /></span>
                                                <span className='fontSizeExtraSmall'>{readableDate(s?.updated_on)}, {getJourneyTime(s?.updated_on)}</span>
                                            </div>
                                        </div>
                                        <div className='septor' style={{height: 1}}></div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        {
                            !loadMore ? ''
                            :
                            <div className='trace-loading-more-wrap w-100'>
                                <div className='trace-loading-more'>
                                    <span className='mr-2'>
                                        <ICON_LOADING_CIRCLE height='20' width='20' color='currentColor'/>
                                    </span>
                                    Loading More
                                </div>
                            </div>
                        }
                    </div>
                    <div className='px-3 py-2' style={{borderLeft: 'solid 1px #f0f0f0'}}>
                        {
                            // empty ? <NoDataComponent logo={<ICON_SQLFILE/>} message={'No Formula Found'} />
                            // :
                            <React.Fragment>
                                <div className='pb-2 d-flex align-items-center fontSizeLargeHeading fontInterSemiBold text-dark text-capitalize'>
                                    <label className='mb-0'>
                                        {
                                            activeData?.synonym?.[0] ?? "Create Formula"
                                        }
                                    </label>
                                    {
                                        activeData?.id
                                        ?
                                        <Tooltip placement='right' title={<><span className='fontInterSemiBold'>Description:</span> {activeData?.description}</>}>
                                            <span className='d-flex ml-1 text-color-grey'>
                                                <ICON_INFO_FILLED height='.8rem' width='.8rem' color='currentColor' />
                                            </span>
                                        </Tooltip>
                                        :   ''
                                    }
                                </div>
                                <div className='formula-meta-info-wrap'>
                                    <div>
                                        <label className='mb-1 label-color fontSizeLabel fontInterMedium'>Formula Name</label>
                                        <Select 
                                            mode="tags"
                                            className='formula-select'
                                            maxTagCount={'responsive'}
                                            placeholder="Select"
                                            tokenSeparators={[',']}
                                            allowClear={true}
                                            value={formulaNames}
                                            onChange={(value) => {
                                                let vals = value?.map(v => v?.trim()).filter(v => v && v !== "")
                                                setFormulaNames(vals)
                                            }}
                                            disabled={createLoading || !isEditPermission(activeData)}
                                            maxTagPlaceholder={(omittedValues) => (
                                                <Tooltip
                                                    styles={{
                                                        root: {
                                                            pointerEvents: 'none',
                                                        },
                                                    }}
                                                    title={omittedValues.map(({ label }) => label).join(', ')}
                                                >
                                                    <span>+{omittedValues?.length}</span>
                                                </Tooltip>
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label className='mb-1 label-color fontSizeLabel fontInterMedium'>Description</label>
                                        <input
                                            value={formulaDescription}
                                            onChange={(e) => {
                                                setFormulaDescription(e?.target?.value)
                                            }}
                                            className='custom-input-field'
                                            style={{height: '31.6px', borderRadius: '6px', borderColor: '#d9d9d9'}}
                                            placeholder='Formula description here...'
                                            disabled={createLoading || !isEditPermission(activeData)}
                                        />
                                    </div>
                                    <div>
                                        <label className='mb-1 label-color fontSizeLabel fontInterMedium'>Column Name</label>
                                        <Select 
                                            className='formula-select'
                                            options={columnOptions}
                                            maxTagCount={'responsive'}
                                            mode={'multiple'}
                                            allowClear={true}
                                            value={formulaColumns}
                                            onChange={(value) => {
                                                setFormulaColumns(value)
                                            }}
                                            placeholder="Select"
                                            maxTagPlaceholder={(omittedValues) => (
                                                <Tooltip
                                                    styles={{
                                                        root: {
                                                            pointerEvents: 'none',
                                                        },
                                                    }}
                                                    title={omittedValues.map(({ label }) => label).join(', ')}
                                                >
                                                    <span>+{omittedValues?.length}</span>
                                                </Tooltip>
                                            )}
                                            disabled={createLoading || !isEditPermission(activeData)}
                                        />
                                    </div>
                                </div>
                                <hr className='mx-n3' style={{borderColor: '#f0f0f0'}}/>
                                <div className='formula-editor-wrap'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <label className='mb-0 fontSizeHeading text-dark fontInterMedium'>Formula Editor</label>
                                        <div className='d-flex'>
                                            <Buttons
                                                props={{
                                                    buttonText: "Reset",
                                                    tooltip: "",
                                                    buttonClassName: `red-border error-red danger-btn custom-btn btn-with-text`,
                                                    buttonWrapperClass: 'd-flex justify-content-end',
                                                    buttonEvent: () => {
                                                        if(activeData?.id) {
                                                            setQueryValue(activeData?.definition)
                                                        }
                                                        else {
                                                            setQueryValue("")
                                                        }
                                                    },
                                                    ImgSrc: () => <ICON_RESET />,
                                                    isDisable: createLoading || !isEditPermission(activeData),
                                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='formula-editor-container py-3'>
                                        {
                                            isEditPermission(activeData)
                                            ?
                                            <SqlEditor
                                                height={'15rem'}
                                                previewQuery
                                                sqlTableDetails={sqlEditorDetails}
                                                QueryValue={queryValue}
                                                setQueryvalue={setQueryValue}
                                                onChangeSqlEditor={qry => {
                                                    setQueryValue(qry)
                                                }}
                                                disableMinimap={true}
                                                wrapClassName={'border rounded p-2'}
                                                // wordsToHighlight={formulaColumns ?? []}
                                            />
                                            :
                                            <SqlEditor
                                                height={'15rem'}
                                                previewQuery
                                                QueryValue={queryValue}
                                                readOnly={true}
                                                disableMinimap={true}
                                                wrapClassName={'border rounded p-2'}
                                                // wordsToHighlight={formulaColumns ?? []}
                                            />

                                        }
                                    </div>
                                    <div className='d-flex w-100 align-items-center justify-content-between'>
                                        <div>
                                            {
                                                !showFormulaWarning ? ''
                                                : 
                                                <Alert className='py-1 px-2 fontSizeLabel text-dark fontInterMedium' message="Please ensure to use the selected column(s) in formula definition" type="warning" showIcon />
                                            }
                                        </div>
                                        <Buttons
                                            props={{
                                                buttonText: createLoading ? activeData?.id ? "Updating..." : "Saving..." : activeData?.id ? "Update" : "Save",
                                                tooltip: "",
                                                buttonClassName: `custom-btn-primary custom-btn btn-with-text`,
                                                buttonWrapperClass: 'd-flex justify-content-end',
                                                buttonEvent: () => {
                                                    if(activeData?.id) {
                                                        updateExistingFormula()
                                                    }
                                                    else {
                                                        createNewFormula()
                                                    }
                                                },
                                                ImgSrc: () => createLoading ? <ICON_LOADING_CIRCLE/> : <ICON_SAVE />,
                                                isDisable: disableSaveBtn || !isEditPermission(activeData),
                                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                            }}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            }
            <DeleteModal
                heading='Delete Formula'
                loading={deleting}
                deleteBtnDisabled={deleting}
                open={deleteOpen}
                setOpen={setDeleteOpen}
                onConfirm={() => {
                    handleDelete(selectedDataRef.current)
                }}
            />
        </div>
  )
}

export default TraceFormula