import React, { useEffect, useRef, useState } from "react";
import { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import Editor, { useMonaco } from "@monaco-editor/react";
import useDebounce from "../../../../../customHooks/useDebounce";
import { getMicrosoftSQLKeywords } from "./SQLKeyword";
import "./sqlEditor.scss";
import { useSelector, useStore } from "react-redux";
import { saveSQlTrigger } from "../../../../../store/modules/dataCatalogue/preview/previewActions";
import _, { filter } from "lodash";
import { pxToRem, remToPx } from "../../../common/helperFunctions";
loader.config({ monaco });
function SqlEditor({
  id = '',
  readOnly = false,
  setQueryvalue = () => { },
  setQueryObj = () => { },
  fromRoute = "",
  QueryValue,
  sqlTableDetails,
  isQueryViewMode,
  height,
  ExecuteQueryClick = () => {},
  openSaveModalHandler,
  downloadQueryHandler,
  width,
  debounceTime,
  saveQuerytoState = "",
  wrapEnabled,
  showGutter = true,
  autoFocus = true,
  language = "sql",
  theme = "light",
  className = "",
  previewQuery,
  handleEmptyQuery = false,
  disableDebounce = false,
  wrapClassName = '',
  getInstantQuery = () => { },
  isChanged = false,
  setChanged = () => { },
  onChangeSqlEditor = () => { },
  disableMinimap = false,
  hideLineNumbers = false,
  hideFolding = false,
  editorFontSize = 14,
  editorPadding = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  configEditorOptions = {},
  parentEditorRef = {},
  getSelectedQueryObj = () => {},
  // wordsToHighlight = []
}) {
  const [query, setquery] = useState();
  const monacoRef = useRef(null);
  const editorRef = useRef(null);
  const saveSQlTriggerChange = useSelector(
    (state) => state.DataCatalogue.PreviewPage.saveSQlTrigger
  );

  const store = useStore();

  const debouncedSearchTerm = useDebounce(query, debounceTime || 3000);

  React.useEffect(() => {
    getInstantQuery(query)
  }, [query])

  /* React.useEffect(() => {
    if(wordsToHighlight && wordsToHighlight?.length) {
      const editor = editorRef?.current;
      console.log({editor, wordsToHighlight})
      if(editor) {
        const model = editor?.getModel();
        const decorations = wordsToHighlight?.flatMap(word => {
          const matches = model?.findMatches(word, false, false, false, null, false);
          return matches.map(match => ({
            range: match.range,
            options: {
              inlineClassName: 'custom-editor-word-highlight'
            }
          }));
        });
        console.log({decorations})
        editor.deltaDecorations([], decorations);
      }
    }
  }, [wordsToHighlight, QueryValue]) */

  // useEffect(() => {

  //   if (query) {

  //     setQueryvalue(query)
  //   }
  // }, [debouncedSearchTerm])

  useEffect(() => {

    if (saveSQlTriggerChange?.trigger) {
      if (query || fromRoute === "sql_transformer") {

        let queryToExecute = query
        
        const editor = editorRef.current
        if(editor) {
          const selectedText = editor.getModel().getValueInRange(editor.getSelection()) ?? ''
          
          /* console.log({
              selectedText,
              val: editor.getValue()
          }) */
  
          queryToExecute = selectedText?.trim() || editor.getValue()
        }

        setQueryvalue(editor?.getValue() || query);

        let selectedQuery = queryToExecute, wholeQuery = editor?.getValue() || query

        if(setQueryObj) {
          setQueryObj({selected: selectedQuery, whole: wholeQuery})
        }

        if (saveSQlTriggerChange?.trigger === "run_click" && ExecuteQueryClick) {
          ExecuteQueryClick(queryToExecute);
        } else if (saveSQlTriggerChange?.trigger === "download_click" && downloadQueryHandler) {
          downloadQueryHandler(wholeQuery);
        } else if (saveSQlTriggerChange?.trigger === "save_click"  && openSaveModalHandler) {
          openSaveModalHandler(wholeQuery);
        } else if (saveSQlTriggerChange?.trigger === "close_click") {
        }
        store.dispatch(saveSQlTrigger({ trigger: false }));
      }
    }
  }, [saveSQlTriggerChange]);
  useEffect(() => {
    if (disableDebounce) return

    if (query) {
      setQueryvalue(query);
    }
    if (handleEmptyQuery) {
      if (_.isEmpty(query)) setQueryvalue('')
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (QueryValue) {
      setquery(QueryValue);
    }
    if (!QueryValue) {
      setquery("");
    }
  }, [QueryValue]);

  const DEFAULT_SQL_KEYWORDS = getMicrosoftSQLKeywords();

  const getEditorAutoCompleteSuggestion = (range, tables, monaco, word) => {
    const suggestionsFromDefaultKeywords = DEFAULT_SQL_KEYWORDS.map((kw) => ({
      label: `${kw.toUpperCase()}`,
      kind: monaco.languages.CompletionItemKind.Keyword,
      detail: "Keyword",
      insertText: `${kw.toUpperCase()} `,
      range: range,
    }));

    const tableNameKeywords = tables.map((table) => {
      if (table.type === "Table") {
        return {
          label: table.name,
          kind: monaco.languages.CompletionItemKind.Folder,
          detail: "Table",
          insertText: table.name,
          range: range,
        };
      } else {
        return {
          label: table.name,
          kind: monaco.languages.CompletionItemKind.Field,
          detail: table.type,
          insertText: table.name,
          range: range,
        };
      }
    });

    const suggestions = {
      suggestions: [...suggestionsFromDefaultKeywords, ...tableNameKeywords],
    };
    // const filteredKeywords = suggestions?.suggestions.filter(keyword => keyword?.detail?.toLowerCase().includes(word.word?.toLowerCase()) || keyword?.label?.toLowerCase().includes(word.word?.toLowerCase()));
    // suggestions['suggestions'] = filteredKeywords;
   
    return suggestions;
  };

  function handleEditorWillMount(monaco) {
    // or make sure that it exists by other ways
    if (monaco) {
      monacoRef.current = monaco;
    }
  }

  function handleEditorDidMount(editor, monaco) {
    // here is another way to get monaco instance
    // you can also store it in `useRef` for further usage
    // monacoRef.current = monaco

    editorRef.current = editor
    parentEditorRef['current'] = editor
  }
  useEffect(() => {
    if (monacoRef.current) {
      if (isQueryViewMode) {
        import("monaco-themes/themes/Slush and Poppies.json").then((data) => {
          monacoRef.current.editor.defineTheme("monokai", data);
          monacoRef.current.editor.setTheme("monokai");
        });
      } else monacoRef.current.editor.setTheme(theme);
    }
  }, [isQueryViewMode, monacoRef.current]);
  useEffect(() => {
    let sqlTables = [];

    if (sqlTableDetails && sqlTableDetails.length) {
      sqlTables = [...sqlTableDetails];
    }

    let disposable = null;

    if (monacoRef.current) {
      // or make sure that it exists by other ways

      disposable = monacoRef.current?.languages.registerCompletionItemProvider(
        "sql",
        {
          provideCompletionItems: (model, position) => {

            const word = model.getWordUntilPosition(position);
            const range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn,
            };
            return getEditorAutoCompleteSuggestion(
              range,
              sqlTables,
              monacoRef.current,
              word
            );
          },
        }
      );
    }


    return () => {
      disposable?.dispose();
    };
  }, [sqlTableDetails, monacoRef.current]);

  const options = {
    readOnly: isQueryViewMode ?? readOnly,
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: "on",
    accessibilitySupport: "auto",
    autoIndent: true,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: "smooth",
    cursorSmoothCaretAnimation: "off",
    cursorSurroundingLines: 0,
    cursorStyle: "line-thin",
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: false,
    fixedOverflowWidgets: false,
    folding: !hideFolding,
    foldingStrategy: "auto",
    fontLigatures: false,
    formatOnPaste: true,
    formatOnType: true,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: true,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: "alt",
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 100,

    renderControlCharacters: false,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: "all",
    renderWhitespace: "none",
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    rulers: [],
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: true,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: true,
    showFoldingControls: "mouseover",
    smoothScrolling: false,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
    wordWrap: "on",
    wordWrapBreakAfterCharacters: "\t})]?|&,;",
    wordWrapBreakBeforeCharacters: "{([+",
    wordWrapBreakObtrusiveCharacters: ".",
    wordWrapColumn: 80,
    wordWrapMinified: true,
    wrappingIndent: "deepIndent",
    suggestLineHeight: 30,
    suggestFontSize: remToPx(0.87),

    blockComment: ["/*", "*/"],
    lineComment: "//",
    minimap: {
      enabled: !disableMinimap
    },
    lineNumbers: hideLineNumbers ? "off" : "on",
    fontSize: remToPx(pxToRem(editorFontSize)),
    padding: editorPadding,
    layoutInfo: {
      height: 'auto', // Set the desired height (in pixels)
    },
    ...configEditorOptions
  };

  function handleEditorValidation(markers) {
    // model markers
    markers.forEach((marker) => console.log("onValidate:", marker.message));
  }

  return (
    <div id={id} className={`COMMON_MONACO_CODEEDITOR ${wrapClassName}`}>
      {fromRoute === "" && fromRoute !== "usage" && !previewQuery ? (
        <div className="custom-sql-editor-note">
          <code className="d-block font-italic font-w-600 px-1 py-2">
            {readOnly
              ? "You are in read only mode"
              : "Shortcut keys for commenting line of code - [Ctrl + /]."}
          </code>
        </div>
      ) : null}
      <Editor
        language={language}
        theme={theme}
        onChange={(newValue) => {
          if (!isChanged) setChanged(true)
          setquery(newValue, false)
          onChangeSqlEditor(newValue)
        }}
        onValidate={handleEditorValidation}
        name="UNIQUE_ID_OF_DIV"
        className={className}
        value={query}
        width={width ? width : "100%"}
        height={height ? height : "200px"}
        options={options}
        beforeMount={handleEditorWillMount}
        onMount={handleEditorDidMount}
      />
    </div>
  );
}
export default SqlEditor;
