import React from 'react'
import { Drawer, notification, Popover } from 'antd'
import { ICON_BELL, ICON_CLOSE } from '../newIconSource'
import './notifications.scss'
import NotificationsContent from './NotificationsContent'
import Buttons from '../button/Buttons'
import { Literals } from '../../modules/common/literals'
import { useSocket } from '../../../services/SocketContextProvider'
import { useDispatch, useSelector } from 'react-redux'
import { markReadNotification, setUnreadNotifications } from '../../../store/modules/common/Notifications/NotificationsReducer'
import { useNavigate } from 'react-router-dom'
import MarkdownText from '../MarkdownText'

const modules = {
    alert: 'ALERT',
    chat: 'CONVERSATION',
    health: 'DATA_DICTIONARY'
}

const Notifications = React.forwardRef((props, ref) => {

    const dispatch = useDispatch()
    const history = useNavigate()

    const unreadNotifications = useSelector(state => state?.CommonComponent?.Notifications?.unreadNotifications)

    const [activeTab, setActiveTab] = React.useState('all')
    const [open, setOpen] = React.useState(false)
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [data, setData] = React.useState(
        (() => {
            if (unreadNotifications?.type) {
                return unreadNotifications
            }
            return {}
        })()
    )

    const SocketContext = useSocket(null);

    const [api, contextHolder] = notification.useNotification();

    React.useImperativeHandle(ref, () => ({

    }), [])

    const markAsRead = (id) => {
        let payload = {
            id: [id]
        }
        dispatch(markReadNotification(payload, false))
            .catch(() => { })
    }

    const openNotification = (msg, data) => {
        api.info({
            message: msg,
            //   message: `You have 1 new notification`,
            description: '',
            placement: 'topRight',
            className: 'app-noti-wrap',
            duration: 8, // in seconds
            onClick: () => {
                markAsRead(data?.id)
                switch (data?.notification?.content_type) {
                    case modules.chat: {
                        history(Literals?.links?.PULSE_TALK_TRACE+"#feedback", { state: { domain: data?.notification?.data_domain_detail } })
                        break
                    }
                    case modules.health: {
                        history(Literals?.links?.DATA_DICTIONARY_VIEW + data?.notification?.content_id)
                        break
                    }
                    case modules.alert: {
                        history(`${Literals?.links?.FLOWS}/${data?.notification?.details?.flow_id}?transformer_id=${data?.notification?.details?.alert_id}`)
                        break
                    }
                    default: break
                }
            }
        });
    };

    React.useEffect(() => {
        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'user_message') {
            // openNotification()
            setData(SocketContext?.socketResponse)
            dispatch(setUnreadNotifications(SocketContext?.socketResponse))
        }
        // else {

        //     SocketContext.checkConnectionState();
        // }
    }, [SocketContext])

    React.useEffect(() => {
        if (SocketContext?.socketResponse && SocketContext?.socketResponse?.type === 'user_message') {
            if (SocketContext?.socketResponse?.new_notification) {
                openNotification(
                    <div className='custom-noti-notice'>
                        <MarkdownText 
                            markdown={
                                SocketContext?.socketResponse?.new_notification?.notification?.msg ?? ''
                            } 
                        />
                    </div>,
                    SocketContext?.socketResponse?.new_notification
                )
                
                if(SocketContext?.setSocketResponse) {
                    SocketContext?.setSocketResponse({...SocketContext?.socketResponse, new_notification: null})
                }
            }
        }
    }, [SocketContext])

    React.useEffect(() => {
        if (openDrawer) {
            setOpen(false)
        }
    }, [openDrawer])

    const onCloseDrawer = () => {
        setOpenDrawer(false)
    }

    return (
        <div>
            {contextHolder}
            <Popover
                placement='bottomRight'
                arrow={false}
                trigger={'click'}
                open={open}
                onOpenChange={(bool) => {
                    if (openDrawer) {
                        setOpen(false)
                    }
                    else {
                        setOpen(bool)
                    }
                }}
                destroyTooltipOnHide
                title={
                    <>
                        <div className='d-flex fontInterSemiBold fontSizeHeading 
                            align-items-center justify-content-between py-2 px-3 text-black-50'>
                            Notifications
                            <div className='d-flex'>
                                <Buttons
                                    props={{
                                        buttonText: "",
                                        tooltip: "",
                                        buttonClassName: `custom-btn-outline custom-btn btn-with-icon`,
                                        buttonEvent: () => {
                                            setOpen(false)
                                        },
                                        ImgSrc: () => <ICON_CLOSE />,
                                        isDisable: false,
                                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
                content={
                    <>
                        <div>
                            <NotificationsContent
                                isPopover={true}
                                notifyData={data}
                                setParentActiveTab={setActiveTab}
                                parentActiveTab={activeTab}
                            />
                            <div className='clst-noti-pop-footer'>
                                <span
                                    className='underline-button fontInterSemiBold'
                                    onClick={() => {
                                        setOpenDrawer(true)
                                    }}
                                >See All</span>
                            </div>
                        </div>
                    </>
                }
                rootClassName='clst-notification-pop-wrap'
            >
                <div className='pulse-noti-btn mr-1'
                >
                    <ICON_BELL color="currentColor" />
                    {
                        data?.total_unread
                            ?
                            <div className='feed-noti-count-bd fontInterSemiBold'>{data?.total_unread}</div>
                            : ''
                    }
                </div>
            </Popover>

            <Drawer
                title=""
                onClose={onCloseDrawer}
                open={openDrawer}
                closeIcon={false}
                className='clst-noti-drawer-content'
                rootClassName={`clst-noti-drawer-wrap ${props?.isPulse ? 'pulse-page' : ''}`}
                destroyOnClose
            >
                <NotificationsContent
                    onClose={onCloseDrawer}
                    notifyData={data}
                    setParentActiveTab={setActiveTab}
                    parentActiveTab={activeTab}
                />
            </Drawer>
        </div>
    )
})

export default Notifications