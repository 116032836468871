import React, { useEffect, useState } from 'react'

import { Checkbox, Card, Popover, Tooltip } from "antd";
import { Literals } from '../../modules/common/literals';
import { ACCORDIONDOWNARROW, CLOSE, FILTER, RESETFILTER } from '../iconSource';
import Buttons from '../button/Buttons.js';
import { toTitleCase } from '../../modules/common/helperFunctions';
import { setFilter } from '../../../store/app/appAction'

import Accordian from '../accordion/Accordian';
import { useSelector, useDispatch } from 'react-redux';
import _, { filter } from 'lodash';
function Filter({ props }) {

    let { filterList, from = '', type, setselectedFilters, selectedFilters, setshowFilter = () => { }, showPopover = 'new' } = props

    const [FilterList, setFilterList] = useState([]);
    const [showPop, setShowPop] = useState(false);
    const dispatch = useDispatch();
    const openModel = useSelector(state => state.DataCatalogue?.LandingPage?.openModel)

    const [accordionList, setaccordionList] = useState([])
    const [defaultExpandedPanel, setdefaultExpandedPanel] = useState([])

    const [selectAll, setselectAll] = useState([])

    useEffect(() => {
        if (showPopover === 'new') {
            setShowPop(openModel)
        }


    }, [openModel, showPopover])



    const onHeaderChecked = (e, list, headerName, i) => {

        e.stopPropagation(); e.preventDefault()
        let temp = [...selectedFilters];
        let index = temp.findIndex((ele) => Object.keys(ele)[0] === headerName);

        if (e.target.checked) {
            if (index !== -1) {

                temp[index][headerName] = list
                temp[index]['selectAll'] = e.target.checked;
                setselectedFilters([...temp])

            } else {
                let obj = {}
                obj[headerName] = list
                setselectedFilters([...selectedFilters, obj])
            }

        } else {

            if (index !== -1) {
                temp.splice(index, 1)
            }
            setselectedFilters([...temp])
        }

        let templist = [...FilterList];
        let select = [...selectAll];
        select[i] = e.target.checked;

        setselectAll([...select])

        list.forEach((ele) => {
            ele.checked = e.target.checked;

        })
        templist['list'] = list;

        templist[i]['selectAll'] = e.target.checked
        setFilterList([...templist])




    }
    const isSelectAll = (list) => {
        return list.filter(item => item.checked)?.length === list.length
    }

    const onListItemSelect = (e, item, headerName, listItemIndex) => {
        let tempObj = {}

        item['checked'] = e?.target?.checked
        if (e?.target?.checked) {
            let temp = [...selectedFilters]
            let index = temp.findIndex((ele) => Object.keys(ele)[0] === headerName);
            if (index !== -1) {
                temp[index][headerName].push(item)
            } else {
                let obj = {}
                obj[headerName] = [item]
                temp.push(obj)
            }
            tempObj.selectedFilters = [...temp]

            setselectedFilters([...temp])
        } else {
            let temp = _.cloneDeep(selectedFilters)
            let index = temp.findIndex((ele) => Object.keys(ele)[0] === headerName);
            if (index !== -1) {
                // let i = temp[index][headerName].findIndex((ele) => ele.name === item.name)
                let arr = temp[index][headerName].filter(v => v.id !== item.id)
                temp[index][headerName] = [...arr]
                // temp[index][headerName].splice(i, 1)

            }

            tempObj.selectedFilters = [...temp]

            setselectedFilters([...temp])

        }





        let templist = [...filterList];
        if (headerName !== 'emptyheader') {
            let listitem = templist.find((ele) => ele.header === headerName)


            if (listitem && listitem?.list?.length && listitem?.list[listItemIndex]) {

                listitem.list[listItemIndex].checked = e.target.checked;
                // listitem['selectAll'] = isSelectAll(listitem.list)
                templist['list'] = listitem.list;

                tempObj.filterList = [...templist]

                if (templist && templist?.length) {
                    templist.forEach((item) => item.selectAll = isSelectAll(item.list))
                }
                setFilterList([...templist])
            }
        } else {
            let listitem = templist.find((ele) => ele.header === '')
            if (listitem && listitem.list.length && listitem.list[listItemIndex]) {
                listitem.list[listItemIndex].checked = e.target.checked;
                templist['list'] = listitem.list;
                tempObj.filterList = [...templist]

                setFilterList([...templist])
            }
        }

        dispatch(setFilter({ [type]: tempObj }))
    }

    const FilterListItem = (ele) => {
        if (ele && ele?.list?.length) {
            return ele?.list?.map((item, i) => <li key={item?.id} className="custom-filter-li py-1 pl-4">
                <Checkbox className='mx-2' onChange={(e) => { onListItemSelect(e, item, ele.header, i) }} value={item.checked} checked={item.checked} />
                {
                    item?.isTagList
                        ? <Tooltip title={item?.name} placement='topLeft'><span className="content-label text-with-ellipsis">{item?.name} </span></Tooltip>
                        : <Tooltip title={toTitleCase(item?.name?.replaceAll('_', ' '))} placement='topLeft'><span className="content-label text-with-ellipsis">{toTitleCase(item?.name?.replaceAll('_', ' '))}</span></Tooltip>
                }
            </li>)
        }
        else {
            return <li className="padding-2 pl-4 bg-light text-center text-black-50">
                No {ele?.header?.toLowerCase()} found
            </li>
        }

    }
    useEffect(() => {
        setFilterList([...filterList])
        let temp = []
        filterList.forEach((ele) => {
            temp.push(ele.selectAll)
        })
        setselectAll([...selectAll, ...temp])

        createAccordionList(filterList)

    }, [])

    useEffect(() => {

        createAccordionList(FilterList)
    }, [FilterList])

    const createAccordionList = (filterList) => {
        let expandPanel = []

        let list = []
        filterList.forEach((ele, i) => {
            if (ele.header !== '') {
                expandPanel.push(i)
                // value={selectAll[i]}
                list.push({
                    header: <div>
                        <Checkbox
                            className='mr-2'
                            checked={isSelectAll(filterList[i].list)}
                            onClick={e => e.stopPropagation()}
                            onChange={(e) => { e.stopPropagation(); onHeaderChecked(e, ele.list, ele.header, i) }}
                            disabled={_.isEmpty(ele?.list)}
                        />
                        {ele.header}
                    </div>,
                    key: i,
                    innerContent: () => <div className={`tags-acc-ls-wrap filter-li-itm-${ele?.header?.replaceAll(' ', '-')?.toLowerCase()}`}>{FilterListItem(ele)}</div>
                })
            }
        })
        setdefaultExpandedPanel([...expandPanel])

        setaccordionList([...list])
    }

    const resetFilter = () => {
        let tempObj = {}
        tempObj.selectedFilters = [];
        setselectedFilters([])

        let templist = [...FilterList];
        templist.forEach((ele) => {
            ele.selectAll = false
            ele.list.forEach((item) => item.checked = false)
        })
        setselectAll([])
        tempObj.filterList = templist;

        setFilterList([...templist])

        dispatch(setFilter({ [type]: tempObj }))

    }

    const filterPopover = {
        header: () => {
            return <>
                <div className="custom-filter-header d-flex justify-content-between padding-2" style={{ width: '340px' }}>
                    <p className="section-heading m-0">{Literals.FILTERS}</p>
                    <div className="d-flex">
                        <Buttons props={{ buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon mr-2", buttonEvent: () => { resetFilter() }, ImgSrc: () => <RESETFILTER />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                        <Buttons props={{ buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => { setshowFilter(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                    </div>
                </div>
            </>
        },
        body: () => {
            return <>
                {
                    FilterList.length ? FilterList.map((ele) => {
                        return ele.header === '' && ele.list.map((item, i) =>
                            <li key={item?.id} className="top-filter-list padding-2">
                                <Checkbox checked={item?.checked} className='mr-2' onChange={(e) => onListItemSelect(e, item, 'emptyheader', i)} />
                                <span className="paragraph">{toTitleCase(item?.name)}</span>
                            </li>)
                    }) : null
                }

                {
                    accordionList.length ? <div className="landing-page-accordion p-0"><Accordian props={{ isDisable: false, accordionList: accordionList, Icon: () => <ACCORDIONDOWNARROW />, defaultExpandedPanel: defaultExpandedPanel }} /></div> : null
                }
            </>
        }
    }

    const handleVisibleChange = (visible) => {
        // console.log(visible)
        if (showPopover === 'new') {
            setShowPop(visible)

            setshowFilter(visible)
        }
        //  else if (showPopover === 'old') {
        //     setShowPop(false)

        // }

    }

    return (
        <>
            {showPopover === 'new' ? <div className="custom-filter text-right" >
                <Popover placement="bottomRight"
                    title={filterPopover.header}
                    content={filterPopover.body}
                    trigger={from === 'podselectionmodal' ? undefined : "click"}
                    autoAdjustOverflow={true}
                    open={showPop}
                    onOpenChange={handleVisibleChange}
                >
                    {/* <button
                        onClick={(e) => {
                                e.stopPropagation();
                                setShowPop(true)
                            }
                    }
                    >
                    </button> */}
                </Popover>
            </div>

                : showPopover === 'old' ?

                    <div className="custom-filter old-custom-filter">
                        <Card>
                            <div className="custom-filter-header">
                                <p className="section-heading m-0">{Literals.FILTERS}</p>
                                <div className="d-flex">
                                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon mr-2", buttonEvent: () => { resetFilter() }, ImgSrc: () => <RESETFILTER />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                                    <Buttons props={{ buttonText: '', buttonClassName: "custom-btn custom-btn-outline btn-with-icon", buttonEvent: () => { setshowFilter(false) }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_SECONDARY }} />
                                </div>
                            </div>
                            {
                                FilterList.length ? FilterList.map((ele) => {
                                    return ele.header === '' && ele.list.map((item, i) =>
                                        <li key={item?.id} className="top-filter-list">
                                            <Checkbox checked={item?.checked} onChange={(e) => onListItemSelect(e, item, 'emptyheader', i)} />
                                            <span className="paragraph">{toTitleCase(item?.name)}</span>
                                        </li>)
                                }) : null
                            }

                            {
                                accordionList.length ? <div className="landing-page-accordion"><Accordian props={{ isDisable: false, accordionList: accordionList, Icon: () => <ACCORDIONDOWNARROW />, defaultExpandedPanel: defaultExpandedPanel }} /></div> : null
                            }

                        </Card>
                    </div> : null}
        </>
    )
}

export default Filter;