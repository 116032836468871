import React, { Fragment, useEffect, useState } from 'react';
import { Literals } from '../../../common/literals';
import "./dataDomainPage.scss";
import LandingpageFirstHeader from '../../../common/LandingpageFirstHeader';
import { useLocation, useParams } from 'react-router-dom';
import { useStore, useSelector, useDispatch } from "react-redux";
import { getDataDomainDetail } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import { getUserGroupList } from '../../../../../store/modules/UserManagement/userGroup/UMActions';
import LoadingComponent from "../../../common/loadingComponent";
import { generateHtmlTitle, getRelativeTime, permissions } from '../../../common/helperFunctions';
import { RolePrivilegeModal } from '../../../common/helperFunctions';
import { CLOSE, CREATED_BY, CREATED_ON, PLUS, SAVE, SMALLDATADOMAIN, SMALLGROUP, SMALLUSERS, WHITEFILTER, MINUS, USERSGROUP } from '../../../../Common/iconSource';
import Buttons from '../../../../Common/button/Buttons';
import {
    InfoCircleFilled
} from '@ant-design/icons';
import { Drawer, Empty, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import SmallerDetailsTile from '../../common/SmallerDetailsTile';
import { assignEntitlement } from '../../../../../store/modules/entitlements/entitlementAction';
import {
    setDataDomainId,
    setDataDomainDescription,
    setDataDomainName,
    fetchDataDomainDetails,
} from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions';
import { Tooltip } from 'antd';
import SearchInput from '../../../../Common/search/Search';
import AddUserGroupModel from './AddUserGroupModel';
import classnames from "classnames";
import "toastr/build/toastr.min.css";
import NoSearchResultFound from '../../../common/NoSearchResultFound';
import LandingPageTable from '../../../../Common/landingPageTable/LandingPageTable';
import ConnectionList from './ConnectionList';
import PodList from './PodList';
import { SET_ACTIVE_UM_TAB_NAME } from '../../../../../store/modules/UserManagement/userGroup/UMActionTypes';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ICON_CATALOG_DOMAIN, ICON_CLOSE, ICON_EDITBOX, ICON_SAVE } from '../../../../Common/newIconSource';
import UserList from '../../userGroup/components/UserList';
import ClaristaLoader from '../../../../Common/claristaLoader/ClaristaLoader';


const EditDatadomain = () => {
    const { Option } = Select;
    const dataDomainId = useSelector(state => state.UserManagement.DataDomain.dataDomainId);
    const dataDomainName = useSelector(state => state.UserManagement.DataDomain.dataDomainName)
    const dataDomainDescription = useSelector(state => state.UserManagement.DataDomain.dataDomainDescription)
    let { id } = useParams();
    const store = useStore();
    const dispatch = useDispatch();
    const location = useLocation()
    const history = useNavigate();

    const [entitlements, setEntitlements] = useState([]);
    const [entitlementId, setEntitlementId] = useState()
    const [isUserGroup, setisUserGroup] = useState(false);
    const [details, setDetails] = useState({});
    const [userGroups, setUserGroups] = useState([]);
    const [addUserGroupsList, setAddUserGroupsList] = useState([]);

    const loadingStatus = useSelector(state => state.LoadingReducer.loadingStatus);

    const [groupDetails, setGroupDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('')
    const [userGroupList, setUserGroupList] = useState([]);
    const [openUserGroupModal, setOpenUserGroupModal] = useState(false);
    const [selectedUGId, setSelectedUGId] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedUGType, setSelectedUGType] = useState("member");
    const [editDesc, setEditDesc] = useState(false);
    const [userList, setUserList] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const [isEditorPermission, setIsEditorPermission] = useState(false)
    const [isOwnerPermission, setIsOwnerPermission] = useState(false)

    const [editor, setEditor] = useState(false)

    const userGroupData = [];

    const isSuperUser = useSelector(state => state.UserReducer.user.userDetails.is_superuser)

    useEffect(() => {
        setTimeout(() => {
            let el = document.querySelector('.dataDomain-lower-part.usr-manage-usr-dataDomain')
            if (el) {
                el.scroll(0, 0)
            }
        }, 1000)
    }, [])

    useEffect(() => {
        dispatch({ type: SET_ACTIVE_UM_TAB_NAME, payload: Literals.DATA_DOMAIN })

        if (id) {
            setisUserGroup(false)


            getDataDomainDetails();
        }
        getAllUserGroups()
        // getEntitlementsListData();

    }, []);


    useEffect(() => {
        setUserGroupList([...groupDetails]);
    }, [groupDetails])

    // const getEntitlementsListData = () => {
    //     dispatch(getEntitlementList()).then(res => {

    //         setEntitlements(res.data);
    //     })

    // }
    const deleteRow = (userGroup) => {
        let tempGroup = [...userGroupList]
        let index = userGroupList.findIndex((ele) => {
            return ele.id === userGroup.key;
        })
        if (index > -1) {
            tempGroup.splice(index, 1);
            setUserGroupList([...tempGroup]);
            let groups = [...userGroups]
            groups.push({ id: userGroup.key, name: userGroup.name });
            setUserGroups([...groups]);
        }
    };



    const getAllUserGroups = async () => {
        let response;
        try {
            response = await store.dispatch(getUserGroupList(
                { method: "GET", endPoint: `group_manager/group/` }));
            if (response.status === "success") {
                setAddUserGroupsList(response.data)
            }
        } catch (error) { }
    }

    const assignEntitlementToUserGroup = (groupId, role, isEditor, isUpdate, fromDropdown) => {
        let payload = {


            "editor": isEditor,
            "group": groupId,
            "data_domain": dataDomainId,
            "view_permission": [],
            "owner": role === 'owner'

        }
        try {
            dispatch(assignEntitlement(payload, isUpdate)).then((res) => {
                // dispatch(assignEntitlement(payload, from === 'fromAddEditModal' ? false : true)).then((res) => {

                if (fromDropdown) {
                    setRole(isEditor)
                }
                getDataDomainDetails();
                // setOpenUserGroupModal(false);
            })
                .catch(() => {
                    if (fromDropdown) {
                        setRole(!isEditor)
                    }
                })
        }
        catch (err) {

        }
    }
    const addGroupToDomain = async (groupId, userType, isEditor, isUpdate, fromDropdown) => {
        if (!(isEditorPermission && isOwnerPermission)) {
            emitToastNotification('info', 'You do not have permission')
            return
        }

        let response
        let type;
        if (userType === 'owner') type = 'add_owner_group';
        if (userType === 'member') type = 'add_member_group';
        let payload = {
            ids: [groupId]
        }
        try {
            // response = await store.dispatch(fetchDataDomainDetails(
            //     { method: "POST", endPoint: `data_domain/${dataDomainId}/${type}`, payload }
            // ));
            // if (response.status === "success") {
            assignEntitlementToUserGroup(groupId, userType, isEditor, isUpdate, fromDropdown);
            // getDataDomainDetails();
            setOpenUserGroupModal(false);
            //}
        } catch (error) {
        }
    }

    const addUserGroup = () => {
        setOpenUserGroupModal(true);
    }

    const removeGroupFromDomain = async (userGroup) => {
        let response;
        let payload = {
            "group": userGroup.key,
            "data_domain": dataDomainId,
        }
        try {

            response = await store.dispatch(fetchDataDomainDetails(
                { method: "POST", endPoint: `entitlement/remove_onboard`, payload }
            ));
            if (response.status === "success") {
                deleteRow(userGroup)
            }
            // response = await store.dispatch(fetchDataDomainDetails(
            //     { method: "POST", endPoint: `data_domain/${dataDomainId}/remove_member_group`, payload }
            // ));

        } catch (error) { }
    }



    const getDataDomainDetails = async () => {
        let response;
        try {
            response = await store.dispatch(getDataDomainDetail(id));
            if (response.status === "success") {
                setDetails(response?.data);
                const isEditor = !!response?.data?.permission?.find(v => v === permissions.domain.editor)
                const isOwner = !!response?.data?.permission?.find(v => v === permissions.domain.owner)
                setIsEditorPermission(isEditor)
                setIsOwnerPermission(isOwner)
                dispatch(setDataDomainId(response?.data?.data_domain_id));
                dispatch(setDataDomainDescription(response?.data?.data_domain_description));
                dispatch(setDataDomainName(response?.data?.data_domain_name));
                if (response?.data?.group?.group_detail?.length) {
                    let groupList = response?.data?.group?.group_detail
                    let sortedlist = groupList.sort((a, b) => {
                        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
                            return -1;
                        }
                        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    setGroupDetails([...sortedlist])

                }

                let title = generateHtmlTitle(response?.data?.data_domain_name?.toUpperCase() + ' | Data Domain', 'User Management')
                document.title = title

            }
        } catch (error) {
            let title = generateHtmlTitle('Data Domain', 'User Management')
            document.title = title
        }
    };




    const domainDetails = {
        domainDetail: {
            icon: isUserGroup ? <SMALLGROUP /> : <ICON_CATALOG_DOMAIN height='16' width='16' />,
            name: details?.data_domain_name || details?.name,
            description: details?.data_domain_description || details?.description,
        },
        userGroup: {
            icon: isUserGroup ? <SMALLGROUP /> : <SMALLDATADOMAIN />,
            label: isUserGroup ? Literals.DOMAINS : Literals.USER_GROUPS,
            value: isUserGroup ? details?.data_domain?.count : details?.group?.count,
        },
        users: {
            icon: <SMALLUSERS />,
            label: Literals.USERS,
            value: isUserGroup ? details?.user?.count : details?.user,
        },
        createdBy: {
            icon: <CREATED_BY />,
            label: Literals.CREATED_BY,
            value: details?.created_by_user?.username,
        },
        updatedOn: {
            icon: <CREATED_ON height='18' />,
            label: Literals.UPDATED_ON,
            value: getRelativeTime(details.updated_on),
        },
        isUserGroup: isUserGroup
    }

    const editDataDomain = async () => {
        let response;
        let payload = {
            data_domain_description: dataDomainDescription,
        }
        try {
            response = await store.dispatch(fetchDataDomainDetails(
                { method: "PATCH", endPoint: `data_domain/${id}`, payload }));
            if (response.status === "success") {
                setEditDesc(false)
                emitToastNotification('success', response.message);
                getDataDomainDetails()
                // history(redirectBack());
            }
        } catch (error) { }
    }


    const columns = [
        {
            title: Literals.USERGROUPNAME,
            dataIndex: "name",
            key: "name",
            sorter: {
                compare: (a, b) => a?.name?.props?.children[1]?.props?.title !== undefined ? a?.name?.props?.children[1]?.props?.title.localeCompare(b?.name?.props?.children[1]?.props?.title) : null
            }
        },
        {
            title: Literals.DESCRIPTION,
            dataIndex: "description",
            key: "description",
            className: "description",
        },
        {
            title: Literals.UPDATED_ON,
            dataIndex: "updatedOn",
            key: "updatedOn",
        },
        {
            title: Literals.CREATED_BY,
            dataIndex: "createdBy",
            key: "createdBy",
            className: "single-line-ellipsis",
        }, {
            title: <div style={{ display: "flex", alignItems: "center" }}>{Literals.TYPE} <InfoCircleFilled style={{ color: "#2C83C4" }}
                onClick={() => {
                    setOpenModal(true);
                }}
                className="ml-2" /></div>,

            key: 'action',

            className: 'type-cell',
            render: (text, record) => (
                <div className="d-flex ">
                    <div className="domain-usr-grp-list select-grp-types">
                        <p className={classnames({ 'owner-style': record.type === 'owner' || record.type === 'leader' })} onClick={(e) => { e.stopPropagation(); addGroupToDomain(record.key, 'owner', record?.data?.editor, true) }}><span className='filter mr-1'><WHITEFILTER /></span>Owner</p>
                        <p className={classnames({ 'user-style': record.type === 'member' })} onClick={(e) => { e.stopPropagation();     addGroupToDomain(record.key, 'member', record?.data?.editor, true) }}><span className='filter mr-1'><WHITEFILTER /></span>Member</p>
                    </div>
                </div>
            ),

        },
        {
            title: Literals.ROLES,
            dataIndex: "entitlement",
            key: "entitlement",
            render: (text, record) => {
                return (
                    <div className='custom-select-dropdown'>
                        <Select
                            showSearch
                            className=''
                            placeholder="Search role name here..."
                            optionFilterProp="children"
                            value={record?.data?.editor}
                            onClick={e => e?.stopPropagation()}
                            onChange={(value) => {
                                addGroupToDomain(record.key, record.type, value, true, true)
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                            disabled={!(isEditorPermission && isOwnerPermission)}
                        >
                            {[{ name: 'Editor', id: true }, { name: 'Viewer', id: false }].map((option, index) => (
                                <Option key={index} value={option.id}>{option.name}</Option>
                            ))}
                        </Select>
                    </div>
                )
            }
        },
        {
            title: <div className='text-right'>Remove</div>,
            key: 'action',
            render: (text, record) => (
                <div className="d-flex justify-content-end">
                    <Buttons props={{
                        buttonText: '', 
                        buttonClassName: "custom-btn-outline ml-1 mr-1 custom-btn btn-with-icon error-red red-border", 
                        buttonEvent: (e) => {
                            e?.stopPropagation()
                            removeGroupFromDomain(record)
                        }, tooltip: 'Delete', ImgSrc: () => <MINUS />,
                        isDisable: !(isEditorPermission && isOwnerPermission),
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} />
                </div>
            ),

        }
    ];


    const filterData = userGroupList && userGroupList.filter((data) => {
        return (data?.name?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase()) ||
            data?.description?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase()) ||
            getRelativeTime(data?.created_on)?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase()) ||
            data?.created_by_user?.username?.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase()));
    })

    filterData && filterData.map((value) => {
        // let entitlement_name = value?.entitlement_detail[0]?.entitlement_name;
        // let entitlement_id = value?.entitlement_detail[0]?.entitlement_id;
        userGroupData.push(
            {
                key: value?.id,
                // entitlement_id,
                name: <div ><span className="icon-box-desc"><USERSGROUP color='#2c83c4' height='20' width='20' /></span><Tooltip placement="top" title={value?.name}><a onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + value.id) }} href={Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + value.id}>{value?.name.toUpperCase()}</a></Tooltip></div>,
                description: <Tooltip placement="top" title={value?.description}><div className='text-with-ellipsis'> {value?.description}</div></Tooltip>,
                updatedOn: <Tooltip placement="top" title={getRelativeTime(value?.created_on)}>{getRelativeTime(value?.created_on)}</Tooltip>,
                entitlement: '',
                // entitlement: <Tooltip placement="top" title={entitlement_name}>{entitlement_name}</Tooltip>,

                createdBy: <Tooltip placement="top" title={value?.created_by_user?.username}>{value?.created_by_user?.username}</Tooltip>,
                // updatedOn: <Tooltip placement="top" title={getRelativeTime(value?.updated_on)}>{getRelativeTime(value?.updated_on)}</Tooltip>,
                updatedBy: <Tooltip placement="top" title={value?.updated_by_user?.username}>{value?.updated_by_user?.username}</Tooltip>,
                domain: <Tooltip placement="top" title={value?.data_domain}>{value?.data_domain}</Tooltip>,
                type: value?.owner ? 'owner' : 'member',
                data: value
            }

        )
    })
    const getUserGroupDetails = async (id) => {
        setOpenDrawer(true)
        setLoadingUsers(true)
        let response;
        try {
            response = await store.dispatch(
                getUserGroupList({
                    method: "GET",
                    endPoint: `group_manager/group/${id}/`,
                    showLoader: !!0
                })
            );
            if (response.status === "success") {
                setLoadingUsers(false)
                setUserList(response?.data?.user?.user_detail);

            }
        } catch (error) {
            setLoadingUsers(false)
        }
    };

    const onRowClick = (record) => {
        setUserList([])
        getUserGroupDetails(record.key)
        // window.open(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + record.id);
        // history(Literals.links.USER_MANAGEMENT_USER_GROUP_DETAILS + record.key, { state: { tabName: Literals.USERGROUP, activeTab: Literals.USERGROUP } });
    };
    function renderUserGroupList() {
        if (!isUserGroup) {
            return (
                <Fragment>
                    {searchValue?.length > 0 && userGroupData?.length === 0 ?
                        <div className='mt-20'><NoSearchResultFound /></div> :
                        <LandingPageTable
                            props={{
                                columns: columns, dataSource: userGroupData, tableHeight: '', onRowSelection: onRowClick


                            }}
                        />}
                </Fragment>
            )
        }
    }

    const setRole = (editor) => {
        setEditor(editor)
    }

    function renderDataDomainLowerSection() {
        return (
            <div style={{ background: "#f9f9f9" }} className="dataDomain-lower-part usr-manage-usr-dataDomain">

                <div className="sub-header lower-subheader">
                    <div className="sub-header-left">
                        <div className="heading-content">
                            <span className="domain-icon"><USERSGROUP width='20' height='20' /></span>
                            <p>List Of User Groups</p>
                        </div>
                    </div>
                    <div className="sub-header-right">
                        <ul className="landingPage_navbar-nav-right">
                            <li className="landingPage_nav-item mr-2">
                                <SearchInput searchData={searchValue} setSearchData={setSearchValue} />
                            </li>
                            <li className="landingPage_nav-item mr-2">
                                <Buttons props={{
                                    tooltip: 'Add',
                                    buttonText: 'Add', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => {
                                        addUserGroup()
                                    }, ImgSrc: () => <PLUS />,
                                    isDisable: !(isEditorPermission && isOwnerPermission),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }} />
                            </li>
                        </ul>
                    </div>
                </div>
                {renderUserGroupList()}
                <ConnectionList id={id} />
                <PodList id={id} />
                <AddUserGroupModel
                    openUserGroupModal={openUserGroupModal}
                    setOpenUserGroupModal={setOpenUserGroupModal}
                    userGroups={addUserGroupsList}
                    entitlements={entitlements}
                    setEntitlementId={setEntitlementId}
                    entitlementId={entitlementId}
                    selectedUGType={selectedUGType}
                    setSelectedUGType={setSelectedUGType}
                    selectedUGId={selectedUGId}
                    setSelectedUGId={setSelectedUGId}
                    addGroupToDomain={addGroupToDomain}
                    dataDomainId={dataDomainId}
                    setRole={setRole}
                    isEditor={editor}
                />
            </div>
        )
    }




    function renderDetailsDisc() {
        const { domainDetail, userGroup, users, createdBy, updatedOn, isUserGroup } = domainDetails
        const { icon, name, description } = domainDetail;
        return (
            <Card className='border-0'>
                <CardHeader className='bg-white px-2 py-2'>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className='d-flex'>
                            <span className='d-flex mr-2'>
                                {icon}
                            </span>
                            <label className='paragraph mb-0 text-uppercase font-weight-bold'>
                                {dataDomainName}
                            </label>
                        </div>
                        <div className='d-flex'>
                            <Buttons
                                props={{
                                    // tooltip: editDesc ? 'Save' : 'Edit',
                                    buttonText: editDesc ? 'Save' : 'Edit',
                                    buttonClassName: "custom-btn-primary custom-btn btn-with-text",
                                    buttonEvent: () => {
                                        if (editDesc)
                                            editDataDomain()
                                        else setEditDesc(true)
                                    },
                                    ImgSrc: () => editDesc ? <ICON_SAVE /> : <ICON_EDITBOX />,
                                    isDisable: !(isSuperUser || isOwnerPermission),
                                    buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                }} />
                            {
                                editDesc
                                    ?
                                    <Buttons
                                        props={{
                                            tooltipPlacement: 'top',
                                            tooltip: 'Close Edit',
                                            buttonText: '',
                                            buttonClassName: "custom-btn-outline custom-btn btn-with-icon ml-2",
                                            buttonEvent: () => {
                                                setEditDesc(false)
                                                if (details?.data_domain_description !== dataDomainDescription) {
                                                    dispatch(setDataDomainDescription(details?.data_domain_description))
                                                }
                                            },
                                            ImgSrc: () => <ICON_CLOSE />,
                                            isDisable: false,
                                            buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                                        }} />
                                    : ''
                            }
                        </div>
                    </div>
                </CardHeader>
                <CardBody className='p-2'>
                    <p className="grey">{Literals.DESCRIPTION}</p>
                    <textarea
                        key={`${editDesc ? 'domain-desc-ed' : 'domain-desc-vi'}-${dataDomainDescription}`}
                        style={{ height: 50 }}
                        defaultValue={dataDomainDescription}
                        className="form-control custom-textarea-field"
                        onBlur={(e) => dispatch(setDataDomainDescription(e.target.value))}
                        readOnly={!editDesc}
                    >
                        {/* {dataDomainDescription} */}
                    </textarea>
                    {/* <input className="form-control custom-input-field" value={dataDomainDescription}
                        onChange={(e) => dispatch(setDataDomainDescription(e.target.value))} /> */}
                </CardBody>
            </Card>
        )
    }

    function renderDomainDetails() {
        const { domainDetail, userGroup, users, createdBy, updatedOn, isUserGroup } = domainDetails
        return (
            <div className="data-domain-details-upper-section">
                <div className="data-domain-details-grid">
                    <div className="">
                        <div className="upper-left">
                            {renderDetailsDisc()}
                        </div>
                    </div>
                    <div className="">

                        <div className="data-domain-details-insights h-100">
                            <div className="data-domain-details-header">
                                <span className="domain-details-title">
                                    {isUserGroup ? "User Group" : "Data Domain"} Details
                                </span>
                            </div>
                            <div className="data-domain-details-content usr-manage-usr-data-domain">
                                <SmallerDetailsTile object={userGroup} isUserGroup={isUserGroup} />
                                <SmallerDetailsTile object={users} isUserGroup={isUserGroup} />

                                <SmallerDetailsTile object={createdBy} isUserGroup={isUserGroup} />
                                <SmallerDetailsTile object={updatedOn} isUserGroup={isUserGroup} />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            // <div className="data-domain-details-upper-section">
            //     <div className="row">
            //         <div className="col-6">
            //             <div className="upper-left">
            //                 {renderDetailsDisc()}
            //             </div>
            //         </div>
            //         <div className="col-6">
            //             <div className="upper-right">
            //                 <div className="upper-right-header border-bottom">{isUserGroup ? 'User Group' : 'Data Domain'} Details</div>
            //                 <div className="row">
            //                     <div className="col-3">
            //                         <SmallerDetailsTile object={userGroup} isUserGroup={isUserGroup} />
            //                     </div>
            //                     <div className="col-3">
            //                         <SmallerDetailsTile object={users} isUserGroup={isUserGroup} />
            //                     </div>
            //                     <div className="col-3">
            //                         <SmallerDetailsTile object={createdBy} isUserGroup={isUserGroup} />
            //                     </div>

            //                     <div className={isUserGroup ? "col-4" : "col-3"}>
            //                         <SmallerDetailsTile object={updatedOn} isUserGroup={isUserGroup} />
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }
    const redirectBack = () => {

        return Literals?.links?.USER_MANAGEMENT + '#datadomain'

        // if (location?.state && location?.state?.tabName) {
        //     return { pathname: '/userManagement', state: { tabName: location?.state?.tabName } }
        // }
        // else {
        //     return { pathname: '/userManagement' }
        // }
    }

    function renderHeader() {
        return (
            <div className="sub-header">
                <div className="custom-breadcrumb">
                    <p>{isUserGroup ? 'User Groups' : 'Data Domains'}</p>
                </div>
                <div className="d-flex justify-content-end">
                    <Buttons props={{
                        tooltip: 'Close',
                        buttonText: '', buttonClassName: "custom-btn-outline custom-btn btn-with-icon", buttonEvent: () => {
                            history(redirectBack())
                        }, ImgSrc: () => <CLOSE />, isDisable: false, buttonType: Literals.BTN_PRIMARY_WITHOUT_TEXT
                    }} />
                    {/* <Buttons props={{
                        tooltip: 'Save',
                        buttonText: 'Save', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => {
                            editDataDomain()
                        }, ImgSrc: () => <SAVE />, 
                        isDisable: isUserGroup 
                                    ? !(isEditorPermission && isOwnerPermission) 
                                    :  !(isSuperUser || isOwnerPermission), 
                        buttonType: Literals.BTN_PRIMARY_WITH_TEXT
                    }} /> */}
                </div>

            </div>
        )
    }

    return (
        <div className="data-domain-detail">
            <LandingpageFirstHeader title={Literals.USER_MANAGEMENT_HEADING} flag={true} />
            {renderHeader()}
            {/* y-scroll */}
            <div className="usr-manage-usr-dataDomain">
                <div className="container-fluid" style={{ background: "#F9F9F9" }}>
                    <div className="row" >
                        <div className="col-12 mt-10">
                            {renderDomainDetails()}
                            {renderDataDomainLowerSection()}
                        </div>
                    </div>

                </div>
            </div>
            {loadingStatus ? <LoadingComponent /> : null}
            <RolePrivilegeModal openModal={openModal} setOpenModal={setOpenModal} />

            <Drawer
                rootClassName="jobs-drawer"
                placement={'right'}
                width={500}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                closeIcon={false}


            >
                <div className='domain-users-list h-100 w-100'>
                    <div className={`domain-usr-grp-usrlist data-domain-detail-right-side h-100 w-100 d-flex align-items-center justify-content-center`}>
                        {
                            loadingUsers 
                            ? <ClaristaLoader/>
                            : userList?.length === 0
                                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Users To Show"} className='my-0' />
                                : <UserList usersList={userList} />
                        }
                    </div>
                </div>


            </Drawer>

        </div>
    )
}

export default EditDatadomain;