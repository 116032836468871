import React from 'react'
import { useDispatch } from 'react-redux'
import { Literals } from './literals'
import { getUserSelectedDomain, setUserSelectedDomain } from '../../../store/actions'
import { ICON_ARROW_RIGHT, ICON_CATALOG_DOMAIN } from '../../Common/newIconSource'
import { Empty, Popover, Tooltip } from 'antd'
import SearchInput from '../../Common/search/Search'
import { getObjectLength } from './helperFunctions'
import { useLocation } from 'react-router-dom'

const EXCEPTION_PATHS = [
    Literals.links.FLOWS,
    Literals.links.SCHEDULER_VIEW,
    Literals.links.SCHEDULER_EDIT,
    Literals.links.PULSE_TALK,
    Literals.links.PULSE_TRACKER_DETAIL_VIEW,
    Literals.links.VIEW_DASHBOARD,
    Literals.links.EDIT_DASHBOARD
]

const POPUP_VISIBLE_PATHS = [
    Literals.links.HOME,
    Literals.links.CLARISTA_NAVIGATOR,
    Literals.links.CLARISTA_PULSE,
    Literals.links.CLARISTA_LAB,
    // Literals.links.PULSE_INTERNAL,
]

const DomainTopStrip = React.memo(({
    isLight = false,
    activeRoute = '',
    userSelectedDomain = {},
    datadomainList = []
}) => {

    const dispatch = useDispatch()
    const location = useLocation()

    // const { userSelectedDomain } = useSelector(state => state.UserManagement.DataDomain)
    // const { userSelectedDomainLoader } = useSelector(state => state.UserManagement.DataDomain) // For Future Use, Do not remove.
    // const { activeRoute } = useSelector(state => state.Layout)
    // const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)

    const [open, setOpen] = React.useState(false)
    const [searchData, setSearchData] = React.useState("")
    const [loadingObject, setLoadingObject] = React.useState({})

    const searchInputRef = React.useRef(null)

    React.useEffect(() => {
        const location = window?.location
        if(
            userSelectedDomain === null
            && !EXCEPTION_PATHS?.includes(location?.pathname)
        ) {
            dispatch(getUserSelectedDomain())
        }
    }, [])

    React.useEffect(() => {
        if(open && searchInputRef?.current) {
            searchInputRef?.current?.focus()
        }
    }, [open])

    const checkLinks = () => {
        const stLinks = Literals.links // storedLinks
        const links = [
            stLinks.CLUSTERS,
            stLinks.USER_MANAGEMENT,
            stLinks.USAGE,
            stLinks.CONNECTIONS
        ]
        const res = links?.includes(activeRoute)
        // const res = links?.includes('/'+window.location?.pathname)
        return !res
    }

    const PopoverTitle = React.useCallback(() => {
        return <>
            <div className='px-4 py-3 d-flex align-items-center justify-content-between'>
                <div className='fontSizeLabel text-color-grey' style={{fontWeight: '400'}}>
                    DOMAINS
                </div>
                <div>
                    <SearchInput
                        searchData={searchData}
                        setSearchData={setSearchData}
                        placeholder={"Search Domains..."}
                        autoFocus={true}
                        ref={searchInputRef}
                    />
                </div>
            </div>
        </>
    }, [])

    const changeDomain = React.useCallback((id) => {
        setLoadingObject({[id]: true})
        dispatch(setUserSelectedDomain({ data_domain_id: id}, false))
        .then(() => {
            setLoadingObject({})
        })
        .catch(() => {
            setLoadingObject({})
        })
    }, [loadingObject])

    const PopoverContent = () => {
        let list = [...datadomainList]?.map(d => ({...d, name: d?.data_domain_name?.replaceAll("_", " ")}))
        list = list?.filter(item => item?.name?.toLowerCase()?.includes(searchData?.toLowerCase()))
        // list = _.sortBy(list, [o => !(loadingObject[o?.data_domain_id] || userSelectedDomain?.id === o?.data_domain_id) ])

        if([null, undefined]?.includes(list)) return <></>
        
        if(list && list?.length === 0) return <>
            <div className='px-4 py-3'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={searchData !== "" ? "No Data Found" : "No Domains"} />
            </div>
        </>
        return <>
            <div className='px-4 pb-2 domain-strip-list-wrap'>
                <div className='domain-strip-dm-grid'>
                    {
                        list?.map(d => (
                            <div 
                                key={`${d?.data_domain_id}-domain-strip-el`} 
                                className={`d-flex align-items-center ${userSelectedDomain?.id === d?.data_domain_id ? 'active' : ''}`}
                                style={{opacity: getObjectLength(loadingObject) && !loadingObject[d?.data_domain_id] ? '.6' : '1', cursor: loadingObject[d?.id] ? 'not-allowed' : undefined}}
                                onClick={e => {
                                    e?.stopPropagation()
                                    if(getObjectLength(loadingObject)) {
                                        return
                                    }
                                    changeDomain(d?.data_domain_id)
                                }}
                            >
                                <div className='mr-2'>
                                    <ICON_CATALOG_DOMAIN height='1.1rem' width='1.1rem'/>
                                </div>
                                <div className='w-100'>
                                    <div className='text-uppercase fontSizeLabel fontInterSemiBold text-with-ellipsis'
                                        style={{width: 'calc(100% - 1rem)', color: '#0A3863'}}
                                        >
                                            <Tooltip title={d?.name?.toUpperCase()} >
                                                {d?.name}
                                            </Tooltip>
                                    </div>
                                    <div title={d?.data_domain_description} className={`fontSizeLabel ${loadingObject[d?.data_domain_id] ? 'text-success' : 'text-color-grey'} custom-ellipsis two-line-ellipsis`}
                                        /* style={{
                                            minHeight: '1.8125rem'
                                        }} */
                                        >
                                        {
                                            loadingObject[d?.data_domain_id] ? 'Switching Domain...'
                                            :
                                            !(!!d?.data_domain_description) ? <em>No Description</em> : d?.data_domain_description
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    }

    const visibility = React.useMemo(() =>  !!userSelectedDomain && userSelectedDomain?.name && checkLinks(), [activeRoute, userSelectedDomain])

    const popupVisibility = React.useMemo(() => POPUP_VISIBLE_PATHS?.includes(location?.pathname) && ![Literals.links.PULSE_TALK_TRACE]?.includes(location?.pathname) , [location])

    return <>
            <Popover
                rootClassName='domain-strip-pop'
                title={<PopoverTitle/>}
                content={<PopoverContent/>}
                open={open && popupVisibility}
                onOpenChange={bool => {
                    setOpen(bool)
                }}
                trigger={['click']}
                arrow={false}
                destroyTooltipOnHide={false}
            >
                <div
                    className={`domain-indicator-strip ${isLight ? 'dark' : ''} ${visibility ? '' : 'hide'} ${open ? 'expand' : ''}`}
                    style={{cursor: !popupVisibility ? 'default' : undefined}}    
                >
                    {userSelectedDomain?.name?.replaceAll("_", " ")}
                    {
                        popupVisibility
                        ?   <>
                                <div className='vertical-separator mx-1 position-relative' style={{height: '.5rem', top: '-.5px', opacity: isLight ? '.5' : '1'}}></div>
                                <span className='d-flex align-items-center expand-arrow'>
                                    <ICON_ARROW_RIGHT height='.8rem' width='.8rem' color='currentColor' />
                                </span>
                            </>
                        :   ""
                    }
                </div>
            </Popover>
    </>
}, (prevProp, nextProp) => {
    
    return  prevProp?.isLight !== nextProp?.isLight 
            || prevProp?.activeRoute !== nextProp?.activeRoute 
            || prevProp?.userSelectedDomain?.id !== nextProp?.userSelectedDomain?.id
            || prevProp?.datadomainList?.length !== nextProp?.datadomainList?.length
            || prevProp?.activeRoute !== window?.location?.pathname
})

export default DomainTopStrip