import React, { useEffect, useState } from 'react';
import Buttons from '../../../../Common/button/Buttons';
import { useSelector, useStore } from 'react-redux';
import _, { cloneDeep, isArray } from 'lodash';
import { Literals } from '../../../common/literals';
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT } from '../../../../Common/newIconSource';
import { getObjectLength } from '../../../common/helperFunctions';
import { setSchemaDirectoryTreeMap, setSchemaTreeMap } from '../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer';

const podStepsForTable = [
    { key: 0, title: 'Connection', active: true },
    { key: 1, title: 'Explorer', active: false },
    { key: 2, title: 'Schema', active: false },
    // { key: 3, title: 'Save Details', active: false },
]


function TargetStepsContainer({ flowsElements,
    setshowOverlay,
    currentTransformer,
    showResetColumns,
    NewFlowsRedux,
    setNewFlowsRedux,
    setisrunDisabled,
    setshowCloseWarning,
    setisdropTable,
    isdropTable,
    toggleValue,
    setToggleValue,
    isExecutePermission,
    setcurrentStepKey,
    ...props
}) {
    const [steps, setSteps] = useState(
        [...podStepsForTable]
    );


    const store = useStore();

    const firstStepKey = _.head(steps)?.key
    const currentStepKey = steps?.find(s => s?.active)?.key
    const lastStepKey = _.last(steps)?.key

    const selectedConn = useSelector((state) => state.LabTransformer.TargetTransformer.connectionDetails)

    const onStepChange = (stepKey) => {

        setcurrentStepKey(stepKey);
        if (isExecutePermission) {
            let cloneDeepFlowsElements = {}
            if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
                cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

            } else {
                cloneDeepFlowsElements = cloneDeep(flowsElements)

            }
            cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map((item) => {
                if (item.id === currentTransformer.id) {
                    item.content.isdropTable = isdropTable
                    // item.content.activeStep = stepKey;
                }
                return item;
            });

            setNewFlowsRedux(cloneDeepFlowsElements)

        }

        setSteps(prev => prev?.map(s => ({ ...s, active: stepKey === s.key })))
    }

    useEffect(() => {
        if (props.currentStepKey) {
            setSteps(prev => prev?.map(s => ({ ...s, active: props.currentStepKey === s.key })))
        }
    }, [props.currentStepKey])

    let toggleBtnOption = [
        {
            label: (
                <>

                    {`Overwrite`}
                </>
            ),
            value: "overwrite",
            disabled: !isExecutePermission || currentStepKey === lastStepKey ? true : false
        },
        {
            label: (
                <>

                    {`Insert`}
                </>
            ),
            value: "insert",
            disabled: !isExecutePermission || currentStepKey === lastStepKey ? true : false
        },
        {
            label: (
                <>

                    {`Update`}
                </>
            ),
            value: "update",
            disabled: !isExecutePermission || currentStepKey === lastStepKey ? true : false
        },
    ];
    const stepKeyName = {
        connection: 0,
        explorer: 1,
        schema: 2,
    }
    const nextButtonVisibility = React.useMemo(() => {
        if (!isExecutePermission) {
            return false
        }
        if (currentStepKey === stepKeyName?.connection) {
            const status = isArray(selectedConn) ? true : !getObjectLength(selectedConn)
            return status
        } else if (currentStepKey === stepKeyName?.explorer) {
            let status;
            let content = NewFlowsRedux?.nodes?.find(
                (ele) => ele.id === currentTransformer.id
            )?.content;
            let targetTableDetails = content?.targetTableDetails;

            if ((selectedConn?.source_type === 's3' || selectedConn?.source_type === 'azure_adls') && targetTableDetails?.prefix) {
                status = false;
            } else {
                if (
                    targetTableDetails &&
                    targetTableDetails.targetColumns?.length &&
                    targetTableDetails.typeOfTable === 'Existing'
                ) {
                    status = false;
                } else if (
                    targetTableDetails &&
                    targetTableDetails.targetColumns?.length &&
                    targetTableDetails.typeOfTable === 'New'
                ) {

                    if (targetTableDetails.tableName === '') {
                        // emitToastNotification('info', 'Please Enter Table Name and Schema datails')
                        status = true;
                    } else {
                        status = false;

                    }
                } else {
                    status = true;

                }
            }



            return status
        }
        return currentStepKey === lastStepKey
    }, [
        steps, selectedConn, NewFlowsRedux, isExecutePermission
    ])

    const handlePreviousButton = () => {

        if (currentStepKey === firstStepKey) return
        if (!isExecutePermission) {
            onStepChange(currentStepKey - 1);
            return;
        }

        let cloneDeepFlowsElements = {}
        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
            cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

        } else {
            cloneDeepFlowsElements = cloneDeep(flowsElements)

        }
        let finalNodes = cloneDeepFlowsElements?.nodes?.find((item) => item.id === currentTransformer.id);
        let transformerContent = finalNodes?.content;
        if (getObjectLength(transformerContent?.targetTableDetails) && currentStepKey === 1) {

            if (transformerContent?.targetTableDetails?.targetColumns?.length) {
                if (window.confirm('Your selected data will be lost, are you sure want to continue?')) {

                    finalNodes['content']['targetTableDetails'] = {}
                    finalNodes['content']['mode'] = '';
                    finalNodes['content']['treeData'] = []
                    cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map((item) => {
                        if (item.id === currentTransformer.id) {
                            item = finalNodes
                        }
                        return item;
                    });
                    setNewFlowsRedux(cloneDeepFlowsElements);
                    store.dispatch(setSchemaTreeMap([]));
                    store.dispatch(setSchemaDirectoryTreeMap([]));
                    // store.dispatch(updateFlowsElements(cloneDeepFlowsElements))
                    onStepChange(currentStepKey - 1);
                }
            } else if (transformerContent?.editorQuery || (selectedConn?.source_type === 's3' || selectedConn?.source_type === 'azure_adls')) {
                if (window.confirm('Your changes will be lost, are you sure want to continue?')) {

                    finalNodes['content']['targetTableDetails'] = {}
                    finalNodes['content']['mode'] = '';
                    finalNodes['content']['isDropTable'] = false;
                    finalNodes['content']['treeData'] = []
                    finalNodes['content']['editorQuery'] = ''
                    store.dispatch(setSchemaTreeMap([]));
                    store.dispatch(setSchemaDirectoryTreeMap([]));
                    cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map((item) => {
                        if (item.id === currentTransformer.id) {
                            item = finalNodes
                        }
                        return item;
                    });
                    setNewFlowsRedux(cloneDeepFlowsElements);
                    // store.dispatch(updateFlowsElements(cloneDeepFlowsElements))
                    setToggleValue('overwrite')

                    onStepChange(currentStepKey - 1);

                }
            } else {
                setToggleValue('overwrite')

                onStepChange(currentStepKey - 1);

            }

        } else if (transformerContent?.mappedColumns?.length && currentStepKey === 2) {


            if (window.confirm('Your selected data will be lost, are you sure want to continue?')) {

                finalNodes['content']['mappedColumns'] = []
                cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map((item) => {
                    if (item.id === currentTransformer.id) {
                        item = finalNodes
                    }
                    return item;
                });
                setNewFlowsRedux(cloneDeepFlowsElements);
                onStepChange(currentStepKey - 1);
            }


        } else {
            onStepChange(currentStepKey - 1);
        }



    }


    const handleNextButton = () => {


        if (currentStepKey === lastStepKey) return


        onStepChange(props.currentStepKey + 1)
    }


    const setPageView = (view) => {
        let cloneDeepFlowsElements = {}
        if (getObjectLength(NewFlowsRedux) && NewFlowsRedux?.nodes.length) {
            cloneDeepFlowsElements = cloneDeep(NewFlowsRedux)

        } else {
            cloneDeepFlowsElements = cloneDeep(flowsElements)

        }
        cloneDeepFlowsElements['nodes'] = cloneDeepFlowsElements?.nodes?.map((item) => {
            if (item.id === currentTransformer.id) {
                item.content.mode = view;
                item.content.isdropTable = isdropTable;

            }
            return item;
        });
        // cloneDeepFlowsElements['nodes'] = finalNodes;
        if (view === 'insert' && isdropTable) {
            setisdropTable(false)
        }


        setNewFlowsRedux(cloneDeepFlowsElements)

        setToggleValue(view);
    };

    return (
        <div className='pod-creation-header'>


            <div className={`${currentStepKey === firstStepKey ? 'col-1' : 'col-2 col-md-3'}  triple-toggle-btn overwrite-insert-toggle"`}>
                {currentStepKey === stepKeyName.explorer || currentStepKey === lastStepKey ? <Buttons
                    props={{
                        buttonText: "",
                        toggleBtnOption: selectedConn?.source_type === 's3' || selectedConn?.source_type === 'azure_adls' ? toggleBtnOption?.slice(0, 2) : toggleBtnOption,
                        toggleBtnValue: toggleValue,
                        buttonClassName: "btn custom-btn",
                        buttonEvent: setPageView,
                        ImgSrc: () => <></>,
                        isDisable: false,
                        buttonType: Literals.BTN_TOGGLE,
                    }}
                /> : <></>}
            </div>



            <div className='pod-crt-step-container'>
                {
                    steps?.map((el, index) => (
                        <div
                            key={`p-crt-stp-${el.key}`}
                            className={`${el.active ? 'active' : ''} ${currentStepKey > el.key ? 'visited' : ''}`}
                        // onClick={() => onStepChange(el?.key)}
                        >
                            <span className='mr-2 fontSizeHeading indic'>
                                {index + 1}
                            </span>
                            <label className='mb-0 fontSizeHeading'>
                                {el.title}
                            </label>
                        </div>
                    ))
                }
            </div>
            <div className='d-flex align-items-center justify-content-end'>

                {
                    currentStepKey === firstStepKey || showResetColumns ? ''
                        :
                        <Buttons
                            props={{
                                tooltip: currentStepKey === firstStepKey ? "" : "Previous",
                                tooltipPlacement: "top",
                                buttonText: "",
                                buttonClassName: "custom-btn-outline custom-btn btn-with-icon mr-2",
                                buttonEvent: handlePreviousButton,
                                ImgSrc: () => <ICON_ARROW_LEFT />,
                                isDisable: currentStepKey === firstStepKey,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                }
                {
                    currentStepKey === lastStepKey || (currentStepKey === stepKeyName.explorer && toggleValue === 'update')
                        ? ''
                        :
                        <Buttons
                            props={{
                                buttonId: 'next',
                                tooltip: nextButtonVisibility ? "" : "Next",
                                tooltipPlacement: "top",
                                buttonText: "",
                                buttonClassName: `custom-btn-primary custom-btn btn-with-icon mr-2`,
                                buttonEvent: handleNextButton,
                                ImgSrc: () => <ICON_ARROW_RIGHT />,
                                isDisable: nextButtonVisibility,
                                buttonType: Literals.BTN_PRIMARY_WITH_TEXT,
                            }}
                        />
                }

            </div>

        </div>
    )
}

export default TargetStepsContainer