import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import logoImage from "../../../assets/images/new-logo-dark.png";
import {

  CLUSTERMENUICON,
  FLOWS,
} from "../../Common/iconSource";
import "./newSidebar.scss";

import Menu from "./Menu";
import {
  setSidebarActiveRoute,
  setSidebarCollapse,
  toggleSidebarCollapse,
} from "../../../store/layout/actions";

import { Literals } from "../../modules/common/literals";

import { ICON_CONNECTION, ICON_DASHBOARD, ICON_DATA_ANALYSE, ICON_DISCOVER, ICON_HOME, ICON_SETTINGS, ICON_USAGE, ICON_USER, ICON_USERS_GROUP } from "../newIconSource";
import TutorialHelpButton from "../tutorialButton/TutorialHelpButton";
import DomainTopStrip from "../../modules/common/DomainTopStrip";

const routeLinks = Literals.links;

const Sidebar = ({ activeRoute }) => {
  const isSidebarCollapsed = useSelector(
    (state) => state.Layout.isSidebarCollapsed
  );
  const isSidebarVisible = useSelector(
    (state) => state.Layout.isSidebarVisible
  );
  let userDetails = useSelector(state => state.UserReducer?.user?.userDetails)

  let showHideKey = window._env_.REACT_APP_SHOW_VISUALIZATION;

  const [openTutorialModal, setTutorialModal] = React.useState(false)


  let MENU_LIST = React.useMemo(() => {
    let list = [
      // {
      //   key: "1",
      //   label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.CLARISTA_HOME}>Home</a>,
      //   // title: "Clarista Pulse",
      //   to: routeLinks.CLARISTA_HOME,
      //   icon: <ICON_HOME width="20" height="20" />,
      // },
      {
        key: "17",
        label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.CLARISTA_PULSE}>Pulse</a>,
        // title: "Clarista Pulse",
        to: routeLinks.CLARISTA_PULSE,
        icon: <ICON_DASHBOARD width="20" height="20" />,
      },
      {
        key: "0",
        label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.CLARISTA_NAVIGATOR}>Navigator</a>,
        // title: "Clarista Navigator",
        to: routeLinks.CLARISTA_NAVIGATOR,
        icon: (
          <ICON_DISCOVER width="20" height="20" />
        ),
      },
      {
        key: "16",
        label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.CLARISTA_LAB}>Lab</a>,
        // title: "Clarista Lab",
        to: routeLinks.CLARISTA_LAB,
        icon: <FLOWS width="20" height="20" />,
      }

    ];

    if (!userDetails?.openAi?.is_active) {
      list = list?.filter(m => m?.key !== "1")
    }

    if (userDetails?.designation?.toLowerCase() === Literals.DATA_CONSUMER?.toLowerCase()) {
      list = list?.filter(m => m?.key === "17" || (userDetails?.openAi?.is_active && m?.key === '1'))
    }

    return list
  }, [isSidebarCollapsed, userDetails]);

  const SETTINGS = React.useMemo(() => {
    let list = [
      {
        key: "7",
        label: "Manage",
        // title: "Manage",
        popupClassName: "submenu-popup",

        icon: <ICON_SETTINGS width="20" height="20" />,
        children: [
          {
            key: "13",
            label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.CLUSTERS}>Clusters</a>,
            to: routeLinks.CLUSTERS,
            // title: "Usage",
            icon: <CLUSTERMENUICON width="20" height="20" />,
          },
          {
            key: "9",
            label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.USAGE}>Usage</a>,
            to: routeLinks.USAGE,
            // title: "Usage",
            icon: <ICON_USAGE width="20" height="20" />,
          },

          {
            key: "11",
            label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.CONNECTIONS}>Connections</a>,
            to: routeLinks.CONNECTIONS,
            // title: "Connections",
            icon: <ICON_CONNECTION width="20" height="20" />,
          },
          {
            key: "12",
            label: <a onClick={(e) => e.preventDefault()} className="newTab-Hyperlink" href={routeLinks.USER_MANAGEMENT}>Domains & Users</a>,
            // title: "Users",
            to: routeLinks.USER_MANAGEMENT,
            icon: <ICON_USERS_GROUP width="20" height="20" />,
          },
        ],
      }
    ];
    let cloneList = [...list];
    if (userDetails?.designation?.toLowerCase() === Literals.DATA_CONSUMER?.toLowerCase()) {
      list = []
    } else if (!userDetails?.is_superuser) {


      cloneList[0]['children'] = cloneList[0]?.children?.filter(item => item.key !== "13");
      list = [...cloneList]
    }


    return list

  }, [isSidebarCollapsed, userDetails])

  let MENU_LIST2 = React.useMemo(() => {
    return [

      {
        key: "7",
        label: "Monitor",
        popupClassName: "submenu-popup",

        icon: <ICON_DATA_ANALYSE width="20" height="20" />,
        children: [
          {
            key: "9",
            label: "Usage",
            to: routeLinks.USAGE,
            // title: "Usage",
            icon: <ICON_USAGE width="20" height="20" />,
          },
        ],
      },
      {
        key: "12",
        label: "Admin",
        // title: "Admin",
        popupClassName: "submenu-popup",
        icon: <ICON_USER width="20" height="20" />,
        children: [
          {
            key: "13",
            label: "Connections",
            to: routeLinks.CONNECTIONS,
            // title: "Connections",
            icon: <ICON_CONNECTION width="20" height="20" />,
          },
          {
            key: "14",
            label: "Users",
            // title: "Users",
            to: routeLinks.USER_MANAGEMENT,
            icon: <ICON_USERS_GROUP width="20" height="20" />,
          },
        ],
      },
    ];
  }, [isSidebarCollapsed]);
  const dispatch = useDispatch();
  const history = useNavigate();

  const collapseSidebarHandler = () => {
    dispatch(setSidebarCollapse(true));
  };

  const toggleSidebarHandler = () => {
    dispatch(toggleSidebarCollapse());
  };

  const onRouteChangeHandler = (route) => {
    if (route) {
      collapseSidebarHandler();
      if (route === routeLinks.VISUALIZATION) {
        // window.open(link, "_blank");
      } else {
        // if (route === routeLinks.DATA_CATALOG) {
        //   onDataCataloglueClick();
        // }
        dispatch(setSidebarActiveRoute(route));
        history(route);
      }
    }
  };

  return (
    <Fragment>
      {!isSidebarCollapsed ? (
        <div
          className={"new-backdrop new-backdrop-open"}
          onClick={toggleSidebarHandler}
        ></div>
      ) : (
        ""
      )}
      <aside className={`new-sidebar ${isSidebarCollapsed ? 'new-sidebar-hide' : ''}`}
      // onMouseLeave={() => {
      //   dispatch(setSidebarCollapse(true))
      // }}
      >
        {/* <aside className={finalSidebarClasses}> */}
        <div className="new-sidebar-top">
          {/* <div className="hamburger" onClick={toggleSidebarHandler}>
            <ICON_MENU width="20" height="20" />
          </div> */}
          <div className="new-logo">
            {/* <Link to="/" onClick={collapseSidebarHandler}> */}
            <img src={logoImage} alt="Clarista"
              onClick={() => {
                // if (userDetails?.openAi?.is_active) {
                //   return history(Literals.links.CLARISTA_HOME)
                // }
                // else 
                if (userDetails?.designation === Literals.DATA_ANALYST)
                  return history(Literals.links.CLARISTA_NAVIGATOR)
                else if (userDetails?.designation === Literals.DATA_SCIENTIST)
                  return history(Literals.links.CLARISTA_LAB)
                else if (userDetails?.designation === Literals.DATA_CONSUMER)
                  return history(Literals.links.CLARISTA_PULSE)
                else
                  return history(Literals.links.CLARISTA_NAVIGATOR)
              }}
            />
            {/* </Link> */}
          </div>
        </div>
        <div className="new-sidebar-bottom">
          <nav className="new-navigation nav-vertical-scroll">
            <Menu
              collapsed={isSidebarCollapsed}
              list={MENU_LIST}
              onRouteChange={onRouteChangeHandler}
              activeRoute={activeRoute}
            />
            {
              SETTINGS?.length > 0
                ?
                <Menu
                  collapsed={isSidebarCollapsed}
                  list={SETTINGS}
                  onRouteChange={onRouteChangeHandler}
                  activeRoute={activeRoute}
                />
                : ''
            }
          </nav>
        </div>
      </aside>

      <TutorialHelpButton openModal={openTutorialModal} setOpenModal={setTutorialModal} />
    </Fragment>
  );
};

export default React.memo(Sidebar);

// const [navigationList, setNavigationList] = useState([

//     {key:'1', label:"Home",to:routeLinks.HOME,onClick:()=>{}, icon: <span style={{padding:"0 5px"}}><HomeOutlined style={{ fontSize: '100%', opacity:"0.7" }} /></span>, activeIcon: <HomeFilled  style={{ fontSize: '100%',opacity:"1"}}/>

// },

//     {
//         key:'2' , icon: <span style={{padding:"0 5px"}}><Box style={{ fontSize: '100%', opacity:"0.7" }} /></span>, activeIcon: <BoxFill  style={{ fontSize: '100%',opacity:"1"}}/>,

//         children: [
//             { key: 'sub-2-1',   icon: <CATALOGUE />, activeIcon: <CATALOGUEACTIVE />, label: "Data Catalog", to: routeLinks.DATA_CATALOG, onClick: () => { toggleSidebarHandler(); onRouteChangeHandler(routeLinks.DATA_CATALOG); onDataCataloglueClick() } },

//             { key: 'sub-2-2',   icon: <DATADICTIONARY />, activeIcon: <DATADICTIONARY />, label: "Data Dictionary", to: routeLinks.DATA_DICTIONARY, onClick: () => { toggleSidebarHandler(); onRouteChangeHandler(routeLinks.DATA_DICTIONARY); setConnntectionDefaultHandler() } },

//         ],

//         label: "Discover", to: "", onClick: () => { }, type: "group"
//     },

//     {
//         key:'3', icon: <span style={{ fontSize: '100%' ,  opacity:"0.7" , padding:"0 2px" }}> <ANALYZE_MENU  /></span>, activeIcon: <LightbulbFill style={{ fontSize: '100%' ,  opacity:"1" }} />,

//         children: [{ key: 'sub-3-1',  icon: <ANALYTICS />, activeIcon: <ANALYTICSACTIVE />, label: "Visualization", to: routeLinks.VISUALIZATION, onClick: () => { } },
//         { key: 'sub-3-2',icon: <FLOWS />, activeIcon: <FLOWSACTIVE />, label: "Flows", to: routeLinks.FLOWS, onClick: setConnntectionDefaultHandler },],
//         label: "Analyze", to: "", onClick: () => { }, type: "group"
//     },

//     {
//         key: '4', icon:  <span style={{ fontSize: '100%' ,  opacity:"0.7", padding:"0 2px" }}> <MONITOR_ICON style={{ fontSize: '100%',  opacity:"0.7"  }} /></span>, activeIcon: <Search style={{ fontSize: '100%' ,  opacity:"1" }} />,

//         children: [

//             { key: 'sub-4-1',  icon: <ALERT />, activeIcon: <ALERTACTIVE />, label: "Quality", to: routeLinks.ALERTS, onClick: setConnntectionDefaultHandler },

//             { key: 'sub-4-2', icon: <USAGE />, activeIcon: <USAGEACTIVE />, label: "Usage", to: routeLinks.USAGE, onClick: collapseSidebarHandler },
//             { key: 'sub-4-3', icon: <SCHEDULER />, activeIcon: <SCHEDULER />, label: "Scheduler", to: routeLinks.SCHEDULER, onClick: collapseSidebarHandler },

//             { key: 'sub-4-4', icon: <span id='lineage-sb-ic'><LINEAGE_ICON /></span>, activeIcon: <span id='lineage-sb-ic'><LINEAGE_ICON /></span>, label: "Lineage", to: routeLinks.LINEAGE, onClick: collapseSidebarHandler },

//         ],

//         label: "Monitor", to: "", onClick: () => { }, type: "group"
//     },

//     {
//         key: '5',icon: <span style={{padding:"0 5px"}}> <Person  style={{ fontSize: '120%' , opacity:"0.7"  }} /></span>, activeIcon: <PersonFill style={{ fontSize: '120%' ,  opacity:"1" }} />,
//         children: [
//             { key: 'sub-5-1', icon: <CONNECTIONS />, activeIcon: <CONNECTIONSACTIVE />, label: "Connections", to: routeLinks.CONNECTIONS, onClick: setConnntectionDefaultHandler },

//             { key: 'sub-5-2', icon: <USERMANAGEMENT />, activeIcon: <USERMANAGEMENTACTIVE />, label: "Users", to: routeLinks.USER_MANAGEMENT, onClick: setConnntectionDefaultHandler },

//         ],
//         label: "Admin", to: "", onClick: () => { }, type: "group"
//     },

// ]);
