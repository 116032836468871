import React, { useEffect } from 'react'
import '../dataCatalogue/landingPage/components/LandingPageHeader.scss';
import Profile from "../common/Profile";
import { setOpenAnalysis } from '../../../store/modules/analytics/dashboard/dashboardActions'
import { toggleSidebarCollapse } from '../../../store/layout/actions';
import { useDispatch, useSelector } from 'react-redux'
import { CLUSTERMENUICON, HAMBURGER } from "../../Common/iconSource"
import { useNavigate } from 'react-router-dom';
import { Literals } from './literals';
import logoImage from "../../../assets/images/new-logo-2.png";
import { getMonthName, getTime } from './helperFunctions';
import { getDataDomainList, setDataDomainList } from '../../../store/actions';
import ClusterStatusNotification from '../clusters/ClusterStatusNotification';
import Notifications from '../../Common/notifications/Notifications';
import { ICON_CONNECTION, ICON_SETTINGS, ICON_USAGE, ICON_USERS_GROUP } from '../../Common/newIconSource';
import { Popover } from 'antd';

const routeLinks = Literals.links;

const LandingpageFirstHeader = React.forwardRef(({
    title,
    backRoute,
    flag,
    from,
    clickEdit,
    refreshAnalysisData,
    view,
    isPulse = false,
    showFeedNotification = false,
    activeDomain,
    hideLogo = false,
    hideFeedbackNotification = false
}, ref) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { isSidebarVisible } = useSelector(state => state.Layout)
    const searchParams = new URLSearchParams(document.location.search)
    const history_id = searchParams.get("history_id");
    const versionHistory = JSON.parse(localStorage.getItem("versionHistory"));

    const { datadomainList } = useSelector(state => state.UserManagement.DataDomain)
    const { activeRoute } = useSelector(state => state.Layout)
    
    let userDetails = useSelector(state => state.UserReducer?.user?.userDetails)

    const [settingsOpen, setSettingsOpen] = React.useState(false)

    const notificationRef = React.useRef({})
    const clusterRef = React.useRef({})

    React.useImperativeHandle(ref, () => ({
        refreshNotification: () => {
            notificationRef?.current?.refreshNotification()
        },
        // refreshClusterNotification: () => {
        //     clusterRef?.current?.refreshNotification()
        // }
    }), [datadomainList])

    React.useEffect(() => {
        if (datadomainList?.length === 0) {
            dispatch(getDataDomainList(false, false, false))
                .then(res => {
                    dispatch(setDataDomainList(res.data))
                }).catch(() => {

                })
        }
    }, [])

    useEffect(() => {
        return () => {
            if (view && view === 'Analysis') {
                dispatch(setOpenAnalysis({ layout: 'analysis', activeTab: 'analysis' }))
            }
        }
    }, [view])

    const MENU_LIST = React.useMemo(() => {
        let list = [
          {
            key: "pulse",
            title: "Pulse",
            to: routeLinks.CLARISTA_PULSE,
          },
          {
            key: "navigator",
            title: "Navigator",
            to: routeLinks.CLARISTA_NAVIGATOR,
          },
          {
            key: "lab",
            title: "Lab",
            to: routeLinks.CLARISTA_LAB,
          }
    
        ];
    
        if (userDetails?.designation?.toLowerCase() === Literals.DATA_CONSUMER?.toLowerCase()) {
          list = list?.filter(m => m?.key === "pulse" || userDetails?.openAi?.is_active)
        }
    
        return list
    }, [userDetails, activeRoute]);

    const SETTINGS = React.useMemo(() => {
        let list = [
            {
                key: "clusters",
                title: "Clusters",
                to: routeLinks.CLUSTERS,
                icon: <CLUSTERMENUICON width="1rem" height="0.8rem" color='currentColor' />,
            },
            {
                key: "usage",
                title: "Usage",
                to: routeLinks.USAGE,
                icon: <ICON_USAGE width="1rem" height="1rem" color='currentColor'/>,
            },
    
            {
                key: "connections",
                title: "Connections",
                to: routeLinks.CONNECTIONS,
                icon: <ICON_CONNECTION width="1rem" height="1rem" color='currentColor'/>,
            },
            {
                key: "domains",
                title: "Domains & Users",
                to: routeLinks.USER_MANAGEMENT,
                icon: <ICON_USERS_GROUP width="1rem" height="1rem" color='currentColor'/>,
            },
        ];
        let cloneList = [...list];
        if (userDetails?.designation?.toLowerCase() === Literals.DATA_CONSUMER?.toLowerCase()) {
        list = []
        } else if (!userDetails?.is_superuser) {
    
    
        cloneList = cloneList?.filter(item => item.key !== "clusters");
        list = [...cloneList]
        }
    
    
        return list
    
    }, [userDetails, activeRoute])

    const activeSettingMenu = React.useMemo(() => {
        const menu = SETTINGS?.find(s => s?.to === activeRoute)
        return menu
    }, [SETTINGS, activeRoute])

    const toggleSidebarHandler = () => {
        dispatch(toggleSidebarCollapse());
    }

    return (
        <div id='firstHeader' className={`main-header position-relative ${isPulse ? 'light-theme' : ''}`} style={history_id ? { backgroundColor: "#6b6b6b", filter: 'grayscale(1)' } : {}}>
            <div className="left">
                {/* {
                    <div 
                        className="hamburger" 
                        onClick={() => { toggleSidebarHandler() }}
                    >
                        <HAMBURGER />
                    </div>
                } */}
                {
                    hideLogo ? ''
                    :
                    <img className="cursor-pointer" src={logoImage}
                        onClick={() => {
                            // if(userDetails?.openAi?.is_active) {
                            //     return history(Literals.links.CLARISTA_HOME)
                            // }
                            // else 
                            if (userDetails?.designation === Literals.DATA_ANALYST)
                                return history(Literals.links.CLARISTA_NAVIGATOR)
                            else if (userDetails?.designation === Literals.DATA_SCIENTIST)
                                return history(Literals.links.CLARISTA_LAB)
                            else if (userDetails?.designation === Literals.DATA_CONSUMER)
                                return history(Literals.links.CLARISTA_PULSE)
                            else
                                return history(Literals.links.CLARISTA_NAVIGATOR)
                        }}
                        alt="Clarista" style={{ filter: isPulse ? 'none' : 'brightness(9)', height: '1.125rem' }} />
                }
                <div className='vertical-separator' style={{ marginLeft: 0, background: isPulse ? '#b8b8b85e' : '#ffffff5e', width: '1px', height: isPulse ? '1.3rem' : '1rem' }}></div>
                
                <div className='custom-header-nav-wrap'>
                    <div className={`custom-header-nav ${isPulse ? 'light' : ''}`}>
                        {
                            MENU_LIST?.map(m => (
                                <a 
                                    key={m?.key} 
                                    onClick={e => {
                                        e?.preventDefault()
                                        history(m?.to)
                                    }}
                                    href={m?.to}
                                    className={`custom-nav-item ${activeRoute === m?.to ? 'active' : ''}`}>
                                    {m?.title}
                                </a>
                            ))
                        }
                        {/* {
                            !MENU_LIST?.map(m => m?.to)?.includes(activeRoute)
                            && activeSettingMenu
                            ?
                            <a 
                                key={activeSettingMenu?.key} 
                                onClick={e => {
                                    e?.preventDefault()
                                    history(activeSettingMenu?.to)
                                }}
                                href={activeSettingMenu?.to}
                                className={`custom-nav-item active`}
                            >
                                {activeSettingMenu?.title}
                            </a>
                            : ''
                        } */}
                    </div>
                </div>
                {/* {
                    isPulse ? ''
                        :
                        <h3 className={`section-heading text-capitalize ${isPulse ? 'color-primary' : 'text-white'}`}>{`${title}`}</h3>
                }

                {
                    isPulse && !hideLogo ?
                        <>
                            <div className='vertical-separator header-sep' style={isPulse ? { marginLeft: 0 } : {}}></div>
                            <div className='fontInterSemiBold' style={{ color: '#C60C4F', fontSize: '0.875rem' }}>
                             Pulse
                            </div>
                        </>
                        : ''
                } */}
            </div>
            <div className='d-flex align-items-center h-100'>

                <ClusterStatusNotification fromRoute={isPulse?'pulse':''} />
                {
                    history_id ?
                        <div style={{ color: "white", display: "table-cell", gap: '10px' }} className="align-items-center d-flex flex-row-reverse h-100 mr-3" >

                            <p className="align-items-center d-block d-flex label p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", margin: "0", background: '#ababab' }}>
                                <i className="mr-1 bx bxs-calendar-alt"></i>
                                {versionHistory?.log_time === null ? '' : new Date(versionHistory?.log_time).getDate()}
                                {versionHistory?.log_time === null ? '' : ' ' + getMonthName.short[new Date(versionHistory?.log_time).getMonth()]}
                                {versionHistory?.log_time === null ? '' : ' ' + new Date(versionHistory?.log_time).getFullYear()}
                            </p>
                            <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                                <i className="mr-1 bx bxs-time"></i>{versionHistory?.log_time === null ? '00:00' : getTime(versionHistory?.log_time)}
                            </p>
                            <p className="align-items-center d-flex font-w-600 mb-0 p-1 rounded" style={{ lineHeight: 1, color: "#444444", fontSize: "13px", textAlign: "end", margin: "0", background: '#ababab' }}>
                                <i className="mr-1 bx bxs-purchase-tag"></i>{`Version ID: ${versionHistory?.log_id}`}
                            </p>
                        </div> : ""
                }



                {
                    /***
                     * For now we are showing Feedback Notification option to the user
                     * who are editor of atleast one domain
                     */
                    // showFeedNotification &&


                    /***
                     * Deprecating This Component By <Notifications/>
                     */
                    /* datadomainList &&
                        datadomainList?.some(d => d?.permission?.includes('editor_datadomain'))
                        && !hideFeedbackNotification
                        // datadomainList?.find(d => d?.data_domain_id === Number(activeDomain))?.permission?.includes('editor_datadomain') 
                        ? <FeedbackNotification ref={notificationRef} /> : '' */

                }

                {
                    // window?.location?.href?.includes('new-usage')
                    // ?
                    <Notifications isPulse={isPulse} />
                    // :   ''
                }

                <Popover
                    open={settingsOpen }
                    onOpenChange={bool => setSettingsOpen(bool)}
                    placement='bottomRight'
                    arrow={false}
                    title={<></>}
                    // title={
                    //     <div className='px-3 py-1 fontSizeLabel text-color-grey'>
                    //         Manage
                    //     </div>
                    // }
                    content={
                        <div className='py-2 d-flex flex-column' style={{gap: '.3rem'}}>
                            {
                                SETTINGS?.map(s => (
                                    <a key={`settingMenu-${s?.key}`} 
                                        href={s?.to}
                                        className={`d-flex align-items-center fontSizeHeading text-dark py-1 px-3 fontInterMedium setting-item-nav ${activeRoute === s?.to ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e?.preventDefault()
                                            history(s?.to)
                                        }}
                                    >
                                        <span className='d-flex mr-1'>
                                            {s?.icon}
                                        </span>
                                        {s?.title}
                                    </a>
                                ))
                            }
                        </div>
                    }
                >
                    <button className={`custom-btn custom-btn-outline btn-with-icon mr-3 ml-1 btn-large border-0 header-setting-btn ${settingsOpen ? 'open' : 'bg-transparent'} ${isPulse ? 'light' : ''}`}>
                        <ICON_SETTINGS/>
                    </button>
                </Popover>



                {from && from === 'viewAnalytics' ?
                    <div className="d-flex">
                        <button className="btn btn-secondary btn-icon mr-2" onClick={() => refreshAnalysisData()}>
                            <i className='bx bx-refresh'></i>
                        </button>
                        <button className="btn btn-primary" onClick={() => clickEdit()}>
                            <i className="bx bx-edit-alt"></i>
                            Edit
                        </button>
                    </div>
                    : <Profile />}
            </div>
        </div>
    )
})

export default LandingpageFirstHeader
