import { Radio, } from "antd";
import _, { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useStore } from "react-redux";

import {
  updateFlowsElements,
} from "../../../../../store/modules/flows/NewFlowsRedux/flowsActions";

import {

  PROPERTIES,

} from "../../../../Common/iconSource";
import { SCRIPTDEFAULTVIEW } from "../commonFunctions/ScriptDefaultInfography";
import SampleRows from "./SampleRows";

import DataGridAddFilter from "../../../../Common/dataGridTable/DataGridAddFilter";

import { checkIsEditorFlow, checkOutcomeColumnPresent, getInputTransformerDetails } from "../commonFunctions/FlowsHelperFunction";
import { getObjectLength, showSimpleLoading } from "../../../common/helperFunctions";



const FiltersIndex = ({
  flowsElements,
  setupdatedFlowRedux,
  currentTransformer,
  updatedFlowRedux,
  setshowOverlay,
  setshowCloseWarning,

}) => {
  const [showSampleRow, setshowSampleRow] = useState('filter');
  const [columnList, setcolumnList] = useState([]);

  const [toggleValue, setToggleValue] = useState("count");
  const [rowCount, setrowCount] = useState("");


  const [showLoader, setshowLoader] = useState(false);
  const [appliedFilter, setappliedFilter] = useState([])
  const store = useStore();

  const dispatch = useDispatch();


  const [toggleDefaultView, setToggleDefaultView] = useState(false);

  const childAddFilterRef = useRef('');



  const changeFilter = (filterGroup) => {
    // if (filterGroup?.length) {
    let cloneFlowsElements = cloneDeep(flowsElements)
    cloneFlowsElements?.nodes.forEach(
      (item) => {
        if (item.id === currentTransformer.id) {
          item.content.appliedFilterCriteria = filterGroup;
          item.content.showSampleRow = showSampleRow;
          item.content.selectedToggleValue = toggleValue;
          item.content.rowCount = rowCount
        }
        return item
      }
    )

    setupdatedFlowRedux(cloneFlowsElements)

  }

  useEffect(() => {
    changeFilter(appliedFilter)
  }, [showSampleRow, rowCount, toggleValue])



  useEffect(() => {

    const getColumn = async () => {

      let InputTransformer = dispatch(getInputTransformerDetails())
      let newcurrentTransformer = {}

      if (getObjectLength(updatedFlowRedux) && updatedFlowRedux?.nodes?.length) {

        newcurrentTransformer = updatedFlowRedux?.nodes.find((item) => item.id === currentTransformer.id)
      } else {
        newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)

      }

      // let inputColumns = cloneDeep(InputTransformer[0]?.content?.outcomeColumns);
      let inputColumns = [];

      if (InputTransformer && InputTransformer?.length) {
        setshowLoader(true)
        inputColumns = await dispatch(checkOutcomeColumnPresent(InputTransformer[0]));
        setshowLoader(false)
      }
      if (InputTransformer && InputTransformer?.length && inputColumns?.length) {
        let column = [];
        inputColumns.forEach((item) => {
          column.push({
            id: item.id,
            Name: item.Name,
            Type: item.Type,
            Label: item.Name,
            checked: false,
            columnValue: item.Name,
          });
        });
        if (column?.length) {
          setcolumnList([...column])
          setToggleDefaultView(false)
          setshowOverlay(false)


        } else {
          setcolumnList([])
          setToggleDefaultView(false)
          setshowOverlay(false)

        }

      } else if (!InputTransformer?.length) {
        setToggleDefaultView(true)
        setshowLoader(false)


      } else if (!inputColumns?.length) {
        setshowOverlay(true);
        setshowLoader(false)

      }


      let criteria = cloneDeep(newcurrentTransformer?.content?.appliedFilterCriteria);

      if (newcurrentTransformer?.content?.showSampleRow) {
        setshowSampleRow(newcurrentTransformer?.content?.showSampleRow);
        setrowCount(newcurrentTransformer?.content?.rowCount)
        setToggleValue(newcurrentTransformer?.content?.selectedToggleValue)
      }

      if (criteria && criteria.length) {
        criteria?.forEach(element => {
          let incomingDataType = inputColumns?.find((item) => item.Name === element?.column)?.Type ?? null
          if (incomingDataType && incomingDataType !== element['datatype']) {

            element['datatype'] = incomingDataType;
          }
          if (!element?.actualCriteria) {

            element['actualCriteria'] = element['criteria'];
          }
          if (!element?.actualValue) {

            element['actualValue'] = element['value'];
          }
        });

        setappliedFilter(criteria)

      } else { //// this full else need to remove after some time its used for backword compatability 
        let oldcriteria = flowsElements?.nodes.find(
          (item) => item.id === newcurrentTransformer.id
        )?.appliedFilterCriteria;
        if (!criteria?.length && oldcriteria && oldcriteria?.length) {  //// this need to remove after some time its used for backword compatability
          oldcriteria?.forEach(element => {
            let incomingDataType = inputColumns?.find((item) => item.Name === element?.column)?.Type ?? null
            if (incomingDataType && incomingDataType !== element['datatype']) {

              element['datatype'] = incomingDataType;
            }
            if (!element?.actualoldcriteria) {

              element['actualCriteria'] = element['criteria'];
            }
            if (!element?.actualValue) {

              element['actualValue'] = element['value'];
            }
          });


          setappliedFilter(oldcriteria)

          let nodes = flowsElements?.nodes.map((item) => {
            if (item.id === newcurrentTransformer.id) {

              if (oldcriteria && oldcriteria?.length) {  //// this need to remove after some time its used for backword compatability
                item.content['appliedFilterCriteria'] = oldcriteria
              }

            }
            return item;
          });
          let finalFlowsElements = cloneDeep(flowsElements)
          finalFlowsElements['nodes'] = nodes
          store.dispatch(updateFlowsElements(finalFlowsElements));

        } else {
          setappliedFilter([])
        }



      }

    }

    getColumn();
  }, []); //showSampleRow



  return (
    <>
      {!showLoader ? <div className="transformer-details-maindiv">
        <>
          {" "}
          {!toggleDefaultView ? (

            <div className="">
              <Radio.Group onChange={(e) => { setshowSampleRow(e.target.value); setshowCloseWarning(true) }} value={showSampleRow}>
                <Radio value={'filter'}>Filter</Radio>
                <div className="filter-parent-div h-100">
                  {" "}
                  <DataGridAddFilter
                    ref={childAddFilterRef}
                    props={{
                      icon: () => <PROPERTIES />,
                      isFilterTransformer: 'newFilterTransformer',
                      isdisabled: !dispatch(checkIsEditorFlow()) || showSampleRow === 'sample' ? true : false,
                      initialColumn: columnList,
                      from: 'filterTransformer',
                      setshowCloseWarning: setshowCloseWarning,
                      setAppliedFilter: (filterGroup) => { changeFilter(filterGroup) },
                      appliedFilter: appliedFilter,
                      ctx: ``,
                    }}
                  />{" "}
                </div>
                <div className="d-flex justify-content-end card-padding custom-border-dashed flows-filter-padding pr-2 py-1">

                </div>
                <Radio value={'sample'}>Sample</Radio>
                <div className="padding-2 mt-5 py-0">

                  <SampleRows
                    rowCount={rowCount}
                    setrowCount={(rowCnt) => { setrowCount(rowCnt); setshowCloseWarning(true) }}
                    setToggleValue={(toggle) => { setToggleValue(toggle); setshowCloseWarning(true) }}
                    toggleValue={toggleValue}
                    isDisabled={!dispatch(checkIsEditorFlow()) || showSampleRow === 'filter' ? true : false}
                  />
                </div>
              </Radio.Group>




            </div>

          ) : (
            <div className="source-not-found">
              <SCRIPTDEFAULTVIEW />
            </div>
          )}
        </>
      </div> :
        showSimpleLoading('Please wait loading transformer details...')
      }

    </>
  );
};
const mapStateToProps = (state) => {
  return {
    flowsElements: state.Lab.flowsElements,
    currentTransformer: state.Lab.currentTransformer,
  };
};
export default connect(mapStateToProps)(FiltersIndex);
