import React, { useState, useEffect, useRef } from 'react';
import ConnectionsList from './ConnectionsList';
import ConnectionsHeader from './ConnectionsHeader';
import { getConnectionListCreatePODAction } from '../../../../../store/modules/dataCatalogue/createPOD/createPODActions'
import { getDataDomainList, setDataDomainList } from '../../../../../store/modules/UserManagement/dataDomains/dataDomainActions'
import { openModelConnection, setConnectionDataList } from '../../../../../store/modules/UserManagement/connection/connectionActions'
import { useDispatch, useStore, useSelector } from 'react-redux'
import { setSidebarActiveRoute } from '../../../../../store/actions';
import PagesComingsoon from '../../../../../pages/Utility/pages-comingsoon';
import { INBOUND, NETWORK, OUTBOUND } from '../../../../Common/iconSource';
import OutboundConnections from './OutboundConnections';
import _ from 'lodash';
import { getFormattedDataConnectionFilter } from '../../../common/helperFunctions';
import ConnectionGuideModal from './ConnectionGuideModal';
import '../connectionIndex.scss'
import { Literals } from '../../../common/literals';
const LandingPage = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const [searchValue, setSearchValue] = useState('')
  const [layout, setLayout] = useState('grid');
  const [datadomainLabelClone, setDataDomainLabelClone] = useState([])
  const [dbDataWithDbClone, setDbDataWithDbClone] = useState([])
  const [activeTab, setActiveTab] = useState('inbound');
  const [isAddButtonDisable, setAddButtonDisable] = useState(false);
  const [isOptionsDisable, setOptionsDisable] = useState(false);
  let outboundRef = useRef()
  const connectionFilter = useSelector(state => state.App)
  // const [selectedFilters, setselectedFilters] = useState([]) //connectionFilter.Filters.connection?.selectedFilters ??
  const [openGuideModal, setopenGuideModal] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [empty, setEmpty] = useState(false);
  
  const [filterList, setFilterList] = React.useState({sources: [], domains: []});

  const connectionData = useSelector(state => state.UserManagement.ConnectionReducer.dataList)

  const tabsMenuList = [
    { title: 'Inbound Connections', key: 'inbound', Icon: () => <span className='mr-1'><INBOUND /></span>, onClickEvent: (key) => { setActiveTab(key) } },
    { title: 'Outbound Connections', key: 'outbound', Icon: () => <span className='mr-1'><OUTBOUND /></span>, onClickEvent: (key) => { setActiveTab(key) } },
    // { title: 'Api', key: 'api', Icon: () => <span className='mr-1'><NETWORK/></span>, onClickEvent: (key) => { setActiveTab(key) } }
  ]


  useEffect(() => {
    if (activeTab === 'inbound') {
      connectionListAPI()
    }
    if (store.getState().UserManagement.DataDomain.datadomainList.length === 0) {
      dataDomainListAPI()
    }
    dispatch(setSidebarActiveRoute(Literals.links.CONNECTIONS))

    dispatch(openModelConnection(''))
  }, [activeTab])

  React.useEffect(() => {
    
    if(connectionData !== null && connectionData !== undefined) {
      setEmpty(connectionData?.length === 0)
    }

    if(connectionData) {
      let conData = [...connectionData]
      let uniqueSources = _.sortBy(_.uniq(conData?.map(c => c?.source_type)), [o => o])
      let uniqueDomains = _.sortBy(
          _.uniq(
              conData?.filter(c => c?.data_domain?.length > 0)
              ?.map(c => c?.data_domain?.map(d => d?.data_domain_name))?.flat()
            )
        , [o => o])
      
      setFilterList({
        sources: uniqueSources?.map(s => ({name: s, selected: false})),
        domains: uniqueDomains?.map(s => ({name: s, selected: false})),
      })
    }

  }, [connectionData])


  function connectionListAPI() {
    dispatch(getConnectionListCreatePODAction()).then(
      res => {
        if(res?.data) {
          setEmpty(res?.data?.length === 0)
        }
        setLayout(res.data[0]?.view_type);
        dispatch(setConnectionDataList(res.data))
      }
    )
    .catch(err => {
      setErrMsg(err.message)
    })
  }

  function dataDomainListAPI() {
    try {
      dispatch(getDataDomainList()).then(
        res => {
          dispatch(setDataDomainList(res.data))
        }
      )
    }
    catch (e) {

    }
  }


  const getDataStatus = (boolean) => {
    setOptionsDisable(boolean)
  }

  const tabContent = () => {

    switch (activeTab) {
      case 'inbound':
        return <ConnectionsList
          setdbdataWithDbClone={setDbDataWithDbClone}
          setdatadomainlabelClone={setDataDomainLabelClone}
          searchValue={searchValue}
          layout={layout}
          getDataStatus={getDataStatus}
          errMsg={errMsg}
          setErrMsg={setErrMsg}
          empty={empty}
          setEmpty={setEmpty}
          filterList={filterList}
        />
      case 'outbound':
        return <OutboundConnections
          ref={outboundRef}
          setAddButtonDisable={setAddButtonDisable}
          isAddButtonDisable={isAddButtonDisable}
        />
      case 'api':
        return <PagesComingsoon />
      default: break
    }
  }

  return (
    <div className='conn-mod-page-subheader'>
      <div className='padding-2 py-0'>
        <ConnectionsHeader
          searchValue={searchValue} layout={layout}
          setLayout={setLayout} setSearchValue={setSearchValue}
          setActiveTab={setActiveTab} activeTab={activeTab}
          tabsMenuList={tabsMenuList}
          outboundRef={outboundRef && outboundRef.current}
          hideRightSideOptions={activeTab === 'outbound'}
          isAddButtonDisable={isAddButtonDisable}
          isOptionsDisable={isOptionsDisable}
          openGuideModal={setopenGuideModal}
          filterList={filterList}
          setFilterList={setFilterList}
        />
      </div>
      <ConnectionGuideModal openModal={openGuideModal} onClose={setopenGuideModal} />


      <div style={{ background: 'white', height: 'calc(100vh - 4.75rem)', overflow: 'auto' }}>
        <div className="padding-1 connection-list h-100">
          {tabContent()}
        </div>
      </div>
    </div>
  )
}

export default LandingPage;