import React from 'react'
import './iconToggleButton.scss'
import { Radio, Tooltip } from 'antd'
import { ICON_AND, ICON_OR } from '../newIconSource'

const IconToggleButton = ({
    data = [
        {value: 'AND', tooltip: 'AND', icon: <ICON_AND/>},
        {value: 'OR', tooltip: 'OR', icon: <ICON_OR/>},
    ],
    value = 'AND',
    onChange = () => {},
    disabled = false,
    className = '',
    tooltipPlacement = 'top',
    showTooltip = false
}) => {
    return (
        <Radio.Group 
            className={`icon-toggler ${className}`}
            size='small' 
            value={value} 
            onChange={onChange}
            disabled={disabled}
            >
            {
                data?.map((itm, i) => (
                    <Tooltip open={showTooltip ? undefined : false} title={itm?.tooltip ?? ''} placement={tooltipPlacement} key={`tt_${itm}_${i}`}>
                        <Radio.Button title={itm.value} key={`${itm}_${i}`} value={itm?.value}>
                            {itm?.icon}
                            {
                                itm?.label 
                                ?   <label className={`mb-0 fontSizeLabel ${value === itm?.value ? 'text-white' : ''} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                        onClick={() => { 
                                            if(disabled) return
                                            onChange({target : {value: itm?.value}}) 
                                        }}
                                    >
                                        {itm?.label}
                                    </label> 
                                : ''
                            }
                        </Radio.Button>
                    </Tooltip>
                ))
            }
        </Radio.Group>
    )
}

export default IconToggleButton