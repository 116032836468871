import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
// Import Routes

import { authProtectedRoutes, publicRoutes, errorRoutes } from "./routes/";

// layouts
import VerticalLayout from "./components/VerticalLayout/";

import NonAuthLayout from "./components/NonAuthLayout";
import { getProfileData, getUserSession, logoutUser } from "./store/userAuthentication/userActions";
import { getConfirmationModal, isUrlIncludePublicRoute } from "./components/modules/common/helperFunctions";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/media/global_media.scss";
import { RESET_SAVED_APPLIED_FILTERS } from "./store/modules/dataCatalogue/dictionary/dictionaryActionTypes";

import ProtectedRoutes from "./routes/ProtectedRoutes";
import { WithRouterHOC } from "./services/WithRouterHOC";

import LoginRoutes from "./routes/LoginRoutes";
import claristaLogo from "./assets/icons/new-logo-2.png"
import { Literals } from "./components/modules/common/literals";
import { setLoadingStatus } from "./store/modules/common/loadingActions";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { setSidebarActiveRoute } from "./store/actions";




const pathNames = {
  home: "Clarista Home",
  datacatalogue: "Data Catalog",
  datacatalog: "Data Catalog",
  "pod-creation": "Pod Creation | Data Catalog",
  "file-upload": "File Upload | Data Catalog",
  "pod-of-pod": "POD of PODs | Data Catalog",
  "data-dictionary": "Settings | Data Dictionary",
  usermanagement: "User Management",
  createalert: "Create | Alert",
  createdatadomain: "Create Data Domain | User Management",
  createusergroup: "Create User Group | User Management",
  "import-from-db": "Import From Database | Data Dictionary",
  "clarista-navigator": "Navigator",
  "clarista-lab": "Lab",
  "clarista-pulse": "Pulse",
  "manual-creation": "Manual Creation | Data Dictionary",
  "unstructured-pod-creation": "Unstructured | Pod Creation",
  "unstructured-talk": "Unstructured | TALKdata | Pulse",
  "new-unstructured": "Unstructured | Pod Creation"
};


const App = ({ user, resetdictionaryredux }) => {
  /**
   * Returns the layout
   */


  let timeoutId;
  let timeoutId2;
  const dispatch = useDispatch();
  const location = useLocation();
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  useEffect(() => {
    getUserSession();

    if (window.location?.href?.toLowerCase()?.includes('login') || window.location?.href?.toLowerCase()?.includes('logout') || window.location?.href?.toLowerCase()?.includes('forgot-password') || window.location?.href?.toLowerCase()?.includes('verify-otp') || window.location?.href?.toLowerCase()?.includes('signup')) {

      setShowWarningPopup(false);
    }
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId2)

      if (window.location?.href?.toLowerCase()?.includes('login') || window.location?.href?.toLowerCase()?.includes('logout') || window.location?.href?.toLowerCase()?.includes('forgot-password') || window.location?.href?.toLowerCase()?.includes('verify-otp') || window.location?.href?.toLowerCase()?.includes('signup')) {

        setShowWarningPopup(false);
      }
    };

  }, []);

  useEffect(() => {

    if(location?.pathname) {
      let menuLinks = {
        pulse: Literals.links.CLARISTA_PULSE,
        navigator: Literals.links.CLARISTA_NAVIGATOR,
        lab: Literals.links.CLARISTA_LAB,
        user: Literals.links.USER_MANAGEMENT
      }

      let currentMatch = undefined 

      if(location?.pathname?.includes('pulse') || location?.pathname?.includes('dashboard')) {
        currentMatch = menuLinks?.pulse
      }
      else if(location?.pathname?.includes('dataCatalog') || location?.pathname?.includes('pod-creation') || location?.pathname?.includes('data-dictionary') || location?.pathname?.includes('accessManagement')) {
        currentMatch = menuLinks?.navigator
      }
      else if(location?.pathname?.includes('flows') || location?.pathname?.includes('lab') || location?.pathname?.includes('scheduler')) {
        currentMatch = menuLinks?.lab
      }
      else if(location?.pathname?.includes('userManagement') || location?.pathname?.includes('scheduler')) {
        currentMatch = menuLinks?.user
      }
      
      if(currentMatch) {
        dispatch(setSidebarActiveRoute(currentMatch))
      }
    }


    if (window.location?.href?.includes('login') || window.location?.href?.toLowerCase()?.includes('logout') || window.location?.href?.includes('forgot-password') || window.location?.href?.includes('verify-otp') || window.location?.href?.includes('signup')) {

      clearTimeout(timeoutId);
      clearTimeout(timeoutId2)
      setShowWarningPopup(false);
    }


  }, [location]);


  useEffect(() => {

    if (user && user.refresh_expiry) {

      if (timeoutId)
        clearTimeout(timeoutId)
      if (timeoutId2)
        clearTimeout(timeoutId2)

      const specificTime = new Date(`${user.refresh_expiry}Z`);



      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          const remainingTime = specificTime - new Date();


          timeoutId2 = setTimeout(() => {
            setShowWarningPopup(true);
          }, [remainingTime - 900000])
          timeoutId = setTimeout(() => {
            setShowWarningPopup(false);

            dispatch(logoutUser());
            if (window.location?.href?.includes('login') || window.location?.href?.toLowerCase()?.includes('logout') || window.location?.href?.includes('forgot-password') || window.location?.href?.includes('verify-otp') || window.location?.href?.includes('signup')) {

              setShowWarningPopup(false);
            }
          }, remainingTime);
        } else {
          clearTimeout(timeoutId);
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(timeoutId2)
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        if (window.location?.href?.includes('login') || window.location?.href?.includes('forgot-password') || window.location?.href?.includes('verify-otp') || window.location?.href?.includes('signup')) {

          setShowWarningPopup(false);
        }
      };

    }


  }, [user?.refresh_expiry]);


  let localStorageUsers = localStorage.getItem('user');
 
  if (!isUrlIncludePublicRoute() && !localStorageUsers) {
    dispatch(setLoadingStatus(true))
    dispatch(getProfileData())
  }



  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  const getLayout = () => {
    window.scrollTo(0, 0);
    let currentPage = window.location.pathname;
    try {
      currentPage = capitalizeFirstLetter(
        currentPage.substring(currentPage.lastIndexOf("/"), currentPage.length)
      );
    } catch (error) {
      currentPage = "";
    }

    currentPage = pathNames[currentPage?.toLowerCase()] ?? currentPage;

    if (isNaN(parseInt(currentPage)) && currentPage?.toLowerCase() !== 'create') {
      document.title = currentPage + " | Clarista";
    }

    let layoutCls = VerticalLayout;
    if (
      window.location.href.includes("data-dictionary/manual-creation") ||
      !window.location.href.includes("data-dictionary")
    ) {
      resetdictionaryredux();
    }

    // document.head = document.head || document.getElementsByTagName('head')[0];
    var link = document.createElement('link'),
      oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = claristaLogo;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);

    function addMetaTag(property, content, metaTagId) {
      const metaTag = document.createElement('meta');
      metaTag.setAttribute('id', metaTagId)
      metaTag.setAttribute('property', property);
      metaTag.setAttribute('content', content);
      let oldMeta = document.getElementById(metaTagId);


      if (oldMeta) {
        document.head.removeChild(oldMeta);
      }

      document.head.appendChild(metaTag);
    }

    // Add Open Graph tags dynamically
    let iconUrl = window.location.origin + '/new-logo-2.ico'
    addMetaTag('og:title', 'Clarista App Title', 'appTitle');
    addMetaTag('og:description', 'Clarista', 'appDescription');
    addMetaTag('og:image', iconUrl, 'appIcon');

    return layoutCls;
  };

  useEffect(() => {
    const handleLoad = () => {
      if (!isUrlIncludePublicRoute()) {
        dispatch(getProfileData());
      }
    };

    // Fetch profile on load
    handleLoad();

    // Listen to window reload event
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);


  const Layout = getLayout();

  return (
    <React.Fragment>

      <HelmetProvider>

        <Helmet>
          <link rel="icon" href="../public/new-logo-2.ico" />
        </Helmet>

        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100%",
              }}
            >
              <svg
                className="blink"
                width="49"
                height="50"
                viewBox="0 0 29 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.0504 19.7771H26.5824C24.7842 24.3164 20.5021 27.2275 14.9357 27.2275C13.2219 27.2275 11.6425 26.935 10.2148 26.4117C13.4066 25.2464 15.8149 22.8962 17.0504 19.7771Z"
                  fill="#275A89"
                />
                <path
                  d="M10.2148 2.52444C11.6425 2.00109 13.2219 1.70859 14.9357 1.70859C20.5021 1.70859 24.7842 4.61976 26.5824 9.1582H17.0504C15.8149 6.03996 13.4066 3.69001 10.2148 2.52444Z"
                  fill="#275A89"
                />
                <path
                  d="M8.32048 11.1797H2.41797C3.53157 8.36887 6.18314 6.56605 9.63009 6.56605C10.6912 6.56605 11.6693 6.74714 12.5532 7.07115C10.5767 7.793 9.08546 9.24831 8.32048 11.1797Z"
                  fill="#2C83C4"
                />
                <path
                  d="M12.5532 21.8631C11.669 22.1871 10.6912 22.3682 9.62982 22.3682C6.18286 22.3682 3.53129 20.5656 2.41797 17.7551H8.32048C9.08546 19.6862 10.5767 21.1415 12.5532 21.8631Z"
                  fill="#2C83C4"
                />
              </svg>
            </div>
            // showSimpleLoading("Please Wait Loading Page...")
          }
        >
          <Routes>
            {errorRoutes.map((route, idx) => (
              <Route
                key={idx}

                path={route.path}
                element={
                  <NonAuthLayout>
                    <route.component />
                  </NonAuthLayout>
                }
              ></Route>
            ))}
            <Route path="/" element={<Navigate to={Literals.links.LOGIN} />} />
            <Route element={<LoginRoutes />}>
              {publicRoutes.map((route, idx) => (
                <Route
                  key={idx}
                  path={route.path}
                  element={
                    <NonAuthLayout>
                      <route.component />
                    </NonAuthLayout>
                  }
                ></Route>
              ))}
            </Route>
            <Route element={<ProtectedRoutes />}>
              {authProtectedRoutes.map((route, idx) => (
                <Route
                  key={`idx${idx}`}
                  path={route.path}
                  element={
                    <Layout>
                      <route.component />
                    </Layout>
                  }
                  exact
                ></Route>
              ))}
            </Route>
          </Routes>
        </Suspense>
      </HelmetProvider>
      {
        getConfirmationModal({
          open: showWarningPopup,
          setOpen: setShowWarningPopup,
          onConfirm: () => { },
          heading: 'Warning',
          hideButton: true,
          body: <div>Hello, dedicated user! Just a friendly reminder that for security purposes,
            <strong>  Clarista will logout in approximately 15 minutes.</strong>
            Please make sure to save your progress and any
            important information before the time-out occurs.</div>
        })

      }

    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    user: state.UserReducer.user,

    // loadingStatus: state.LoadingReducer.loadingStatus,
  };
};

const mapDispatchToProps = (dispatch, storeState) => {
  return {
    getUserSession: dispatch(getUserSession()),
    // getProfileData: dispatch(getProfileData()),
    resetdictionaryredux: () =>
      dispatch({ type: RESET_SAVED_APPLIED_FILTERS, payload: {} }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouterHOC(App));
