import { Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import useDebounce from '../../../../../customHooks/useDebounce';
import { emitToastNotification } from '../../../../../helpers/toast_helper';
import { setTransformerNames, updateFlowsElements } from '../../../../../store/modules/flows/NewFlowsRedux/flowsActions';
import { getAvailableClusterList, setScriptOutputDataset } from '../../../../../store/modules/flows/NewFlowsRedux/scriptTransformer/scriptAction';

import Buttons from '../../../../Common/button/Buttons';
import SingleSelection from '../../../../Common/dropDown/SingleSelection';
import { DATEDATATYPE, MINUS, PLUS, ACCORDIONDOWNARROW, CHECK } from '../../../../Common/iconSource';
import { preventEnterEvent, preventSpaceEvent } from '../../../common/helperFunctions';
import { Literals } from '../../../common/literals';
import { cloneDeep } from 'lodash';

function OutputDataSet({
    additional_settings,
    runClick,
    setAdditionalSettings,
    setOutputDataset,
    setshowCloseWarning,
    showApplyButton,
    applyNewOuputDataSet,
    isExecutePermission,
    setisrunDisabled,
}) {
    const currentTransformer = useSelector(state => state.Lab.currentTransformer)


    let dataSet = [{ value: "output1", id: `${currentTransformer?.id}|output1|0`, showError: false, showSuccess: false }];
    const [dataSetList, setdataSetList] = useState(dataSet);
    const [cpuList, setcpuList] = useState([]);
    const [isEditPermission, setisEditPermission] = useState(isExecutePermission)

    const store = useStore()
    const outputDataSetList = useSelector(state => state.LabTransformer.Script.scriptOutputDataset)
    const flowsElements = useSelector(state => state.Lab.flowsElements)

    const [term, setTerm] = useState('')

    const [currentDataListIndex, setcurrentDataListIndex] = useState(0)

    const [tempDatasetValueObj, settempDatasetValueObj] = useState({ output1: 0 })
    const transformerNameList = useSelector(state => state.Lab.transformerName)

    const debouncedSearchTerm = useDebounce(term, 700);
    let RemoveBtnClick = useRef(false);

    useEffect(() => {
        setisEditPermission(isExecutePermission)
    }, [isExecutePermission])


    useEffect(() => {
        store.dispatch(getAvailableClusterList()).then((resp) => {
            let temp = []
            if (resp.data?.length) {
                temp = resp?.data.map((item) => {

                    return { key: item.id, Name: item.capacity, Type: "", value: item.capacity, size: item.size }
                })
                setcpuList(temp)
                // if (!additional_settings?.cluster_id || !additional_settings?.configuration) {

                //     setAdditionalSettings({ ...additional_settings, configuration: temp[0].Name, cluster_id: temp[0].key })
                // }

                setisrunDisabled(false);
                setisEditPermission(true)

            } else {
                setcpuList([]);
                setisrunDisabled(true);
                setisEditPermission(false);

            }
        }).catch(err => {
            setcpuList([])
            setisrunDisabled(true);
            setisEditPermission(false)

        })
    }, [])


    useEffect(() => {
        let newcurrentTransformer = {}
        if (currentTransformer?.id && flowsElements?.nodes?.length) {

            newcurrentTransformer = flowsElements?.nodes.find((item) => item.id === currentTransformer.id)
        }


        let currentScriptContent = newcurrentTransformer?.content;


        if (currentScriptContent && currentScriptContent?.outputdatasetList?.length > 0 && !RemoveBtnClick.current) {

            setdataSetList([...currentScriptContent?.outputdatasetList]);
        }
        
    }, [outputDataSetList])


    const addDataSetValue = () => {
        RemoveBtnClick.current = false;
        let list = [...dataSetList]
        list.push({ value: '', id: `` })
        setdataSetList([...list])


    }

    const ChangeDataSetValue = (value, i) => {
        RemoveBtnClick.current = false;
        if (value && value.match(/^[a-z][a-z0-9_]*$/)) {
            let list = [...dataSetList]
            list[i] = { value: value, id: `${currentTransformer?.id}|${value}|${i}`, showSuccess: false, showError: false }
            setdataSetList([...list])

        } else if (value && !value.match(/^[a-z][a-z0-9_]*$/)) {
            emitToastNotification('info', 'Output dataset name must start with lowercase alphabet. Uppercase letters, Special Characters and blank spaces are not allowed')
        } else if (!value && !value.match(/^[a-z][a-z0-9_]*$/)) {
            let list = [...dataSetList]
            list[i] = { value: value, id: `${currentTransformer?.id}|${value}|${i}`, showSuccess: false, showError: false }
            setdataSetList([...list])
        }

    }
    const checkTransformerNameExist = (debouncedSearchTerm) => {
        RemoveBtnClick.current = false;
        let list = [...dataSetList]
        let obj = { ...tempDatasetValueObj }
        if (!(debouncedSearchTerm in tempDatasetValueObj)) {
            list[currentDataListIndex]['showError'] = false;
            list[currentDataListIndex]['showSuccess'] = true
            setdataSetList([...list])
            for (const key in obj) {
                const element = obj[key];
                if (element === currentDataListIndex) {
                    delete obj[key]
                    obj[debouncedSearchTerm] = currentDataListIndex
                    settempDatasetValueObj(obj)
                } else {
                    obj[debouncedSearchTerm] = currentDataListIndex
                    settempDatasetValueObj(obj)
                }
            }

        }
        else {
            list[currentDataListIndex]['showError'] = true;
            list[currentDataListIndex]['showSuccess'] = false
            setdataSetList([...list])


        }

    }

    useEffect(
        () => {

            if (debouncedSearchTerm) {

                let list = [...dataSetList]
                if (Object.keys(transformerNameList)) {
                    let isTransformerNameExist = false
                    for (const key in transformerNameList) {
                        const element = transformerNameList[key];
                        if (element === debouncedSearchTerm) {
                            isTransformerNameExist = true
                        }
                    }
                    if (!isTransformerNameExist) {
                        checkTransformerNameExist(debouncedSearchTerm)
                    } else {
                        list[currentDataListIndex]['showError'] = true;
                        list[currentDataListIndex]['showSuccess'] = false
                        setdataSetList([...list])


                    }

                } else {
                    checkTransformerNameExist(debouncedSearchTerm)

                }


            }

        },
        [debouncedSearchTerm]
    );



    const removeDataSetValue = (i) => {
        RemoveBtnClick.current = true;
        let newflowsElements = cloneDeep(flowsElements);

        let list = cloneDeep(dataSetList)
        let deleteTransformerId = list[i].id?.trim()
        let filteredDatasetList;
        let generatedElementPresent = -1;
        if (deleteTransformerId?.length > 0) {
            if (outputDataSetList && outputDataSetList.length > 0) {
                generatedElementPresent = outputDataSetList.findIndex((ele) => ele.id.includes(deleteTransformerId))

                filteredDatasetList = outputDataSetList.filter((ele) => !ele.id.includes(deleteTransformerId))
            }
            let transformerNameList = store.getState().Lab.transformerName
            delete transformerNameList[deleteTransformerId]

            store.dispatch(setTransformerNames(transformerNameList))


            if (newflowsElements && newflowsElements?.nodes.length > 0 && generatedElementPresent > -1) {
                let newFilteredList = newflowsElements?.nodes.filter((ele) => !ele.id.includes(deleteTransformerId))
                let newEdge = newflowsElements?.edges.filter((ele) => !ele.source.includes(deleteTransformerId))

                if (newFilteredList && newFilteredList.length) {
                    newFilteredList.forEach((item) => {
                        if (item.id === currentTransformer.id) {
                            item.content['outputdatasetList'] = filteredDatasetList;

                        }

                    });
                    newflowsElements['nodes'] = newFilteredList
                    newflowsElements['edges'] = newEdge

                    store.dispatch(updateFlowsElements(newflowsElements))
                }
            }
        }
        list.splice(i, 1)
        if (list.length > 0) {

            setdataSetList([...list])


        } else if (list.length === 0) {

            setdataSetList(dataSet)
        }



        if (generatedElementPresent > -1) {  //filteredDatasetList && filteredDatasetList?.length && 
            store.dispatch(setScriptOutputDataset(filteredDatasetList))
        }
        emitToastNotification('success','Removed output node successfully.')

    }


    const isAllreadyDeclare = (index) => {
        if (outputDataSetList && outputDataSetList.length && outputDataSetList[index] && outputDataSetList[index]['value'] !== '') {
            return true
        } else {
            return false
        }
    }

    const resetDataSet = () => {
        setdataSetList([])
    }
    const onInputChange = (e, i) => {
        setcurrentDataListIndex(i)
        let list = [...dataSetList]
        list[i] = { value: e.target.value, id: `${currentTransformer?.id}|${e.target.value}|${i}`, showSuccess: false, showError: false }
        setdataSetList([...list])
        setTerm(e.target.value);
    }


    const getClusterid = (selectedCPURequest) => {
        return cpuList.find((item) => item.Name === selectedCPURequest)?.key
    }

    const getSelectedCluster = (selectedCPURequest) => {

        if (selectedCPURequest && cpuList?.length) {
            let index = cpuList.findIndex((item) => item.Name === selectedCPURequest);
            if (index !== -1) {
                return selectedCPURequest
            } else {
                setAdditionalSettings({ ...additional_settings, configuration: cpuList[0].Name, cluster_id: cpuList[0].key })
                return cpuList[0].Name
            }
        } else if (cpuList?.length) {
            setAdditionalSettings({ ...additional_settings, configuration: cpuList[0].Name, cluster_id: cpuList[0].key })
            return cpuList[0].Name
        }

    }


    const getMemoryRequestOption = (selectedCPURequest, mem_request) => {


        setAdditionalSettings({ ...additional_settings, configuration: selectedCPURequest, cluster_id: getClusterid(selectedCPURequest) })

    }


    const innerContent = () => {
        return (
            <div className='additional-settings-parentdiv custom-dashed-border-bottom p-2'>
                <div>
                    {/* <label className='mt-0 grey-color label'>CPU request</label> */}
                    {/* <input
                        required
                        type="number"
                        className={`custom-input-field mt-1`}
                        min={2}
                        max={16}
                        disabled={window.location.href.includes('viewFlows') ? true : false}
                        value={additional_settings.cpu_request}
                        onChange={(e) => setAdditionalSettings({ ...additional_settings,cpu_request: value, cpu_request: e.target.value > 16 ? 16 : e.target.value })}
                    /> */}
                    <div className="form-group">
                        <SingleSelection props={{
                            label: "Cluster Configuration", className: "custom-select-dropdown", placeholder: cpuList?.length ? "Select a cluster configuration" : 'You do not have access of notebook clusters.', dropDownValue: getSelectedCluster(additional_settings?.configuration), onClickEvent: (value) => {
                                setAdditionalSettings({ ...additional_settings, configuration: value, cluster_id: getClusterid(value) }); getMemoryRequestOption(value); setshowCloseWarning(true)
                            }, isDisable: window.location.href.includes('viewFlows') || !isEditPermission || runClick ? true : false, optionList: cpuList, isSortOptionDisable: true
                        }} />
                    </div>
                    {/* <div className="form-group">
                        <SingleSelection props={{
                            label: "Memory Request", className: "custom-select-dropdown", placeholder: "Select a memory request", dropDownValue: additional_settings.mem_request, onClickEvent: (value) => {
                                setAdditionalSettings({ ...additional_settings, mem_request: value })
                            }, isDisable: window.location.href.includes('viewFlows') ? true : false, optionList: Memory_list
                        }} />
                    </div> */}
                    <label className='mt-0 grey-color label'>Python Additional Libraries <Tooltip title="Please list required python libraries. Multiple libraries should be comma seprated"> <i className="bx bx-help-circle m-1 text-black-50 cursor-pointer"></i></Tooltip></label>
                    <textarea
                        required
                        type="text"
                        className={`custom-textarea-field script-text-area mt-1 mb-1`}
                        disabled={window.location.href.includes('viewFlows') || !isEditPermission || runClick ? true : false}

                        value={additional_settings.user_libs}
                        onChange={(e) => { setAdditionalSettings({ ...additional_settings, user_libs: `${e.target.value}` }); setshowCloseWarning(true) }}
                    />
                    {/* <label className='mt-0 grey-color label'>Extra resource limits</label>
                    <input
                        required
                        type="text"
                        disabled={window.location.href.includes('viewFlows') ? true : false}

                        className={`custom-input-field mt-1`}
                        value={additional_settings.extra_resource_limits}
                        onChange={(e) => setAdditionalSettings({ ...additional_settings, extra_resource_limits: `${e.target.value}` })}
                    /> */}
                </div>

            </div>
        )
    }
    let additional_settings_body = [
        {
            key: "Additional_settings",
            header: 'Additional Settings',
            innerContent: () => innerContent(),
            addButton: null
        },
    ];
    return (<>
        <div className='h-100 additional-settings-accord custom-dashed-border-right'>

            <div className='flows-scr-op-props-win'>
                <div className='p-2'>
                    <div className='subtitle mb-2'>Settings</div>
                    <div className='m-n2'>
                        {innerContent()}
                    </div>
                </div>
                <div>

                    <div className="d-flex  justify-content-between p-2 custom-border-bottom">
                        <div className="d-flex align-items-center">
                            {/* <DATEDATATYPE /> */}
                            <p className="subtitle mb-0">Output Datasets</p>
                        </div>

                        <div className="p-0 m-0 row justify-content-end">
                            {/* <Buttons props={{ buttonText: '', buttonClassName: "danger-btn custom-btn mr-2 btn-with-icon", buttonEvent: () => { resetDataSet() }, toggleBtnValue: '', ImgSrc: () => <RESETFILTER />, isDisable: window.location.href.includes('viewFlows') ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} /> */}
                            <Buttons props={{ buttonText: 'Add Output', buttonClassName: "script-add-op-btn custom-btn-outline btn-outline custom-btn  btn-with-text", buttonEvent: () => { addDataSetValue(); setshowCloseWarning(true) }, toggleBtnValue: '', ImgSrc: () => <PLUS width='14' height='14' color='#d3d3d3' />, isDisable: window.location.href.includes('viewFlows') || !isEditPermission || runClick ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} />
                        </div>

                    </div>

                    <div className="padding-2 output-list">
                        {dataSetList.length ? dataSetList.map((item, i) => <>
                            <div className="d-flex align-item-center mb-2">
                                <input key={i} disabled={(showApplyButton && isAllreadyDeclare(i)) || !isEditPermission || runClick} onKeyDown={(e) => { preventEnterEvent(e); preventSpaceEvent(e) }} onChange={(e) => { ChangeDataSetValue(e.target.value, i); setshowCloseWarning(true) }} type="text" className="custom-input-field" value={dataSetList[i][`value`]}></input>


                                <Buttons props={{ buttonText: '', buttonClassName: "danger-btn ml-2 mb-1 custom-btn btn-with-icon", buttonEvent: () => { removeDataSetValue(i); setshowCloseWarning(false) }, toggleBtnValue: '', ImgSrc: () => <MINUS />, isDisable: window.location.href.includes('viewFlows') || !isEditPermission || runClick ? true : false, buttonType: Literals.BTN_TERTIARY, toggleBtnOption: null }} />

                            </div>
                        </>) : <></>}
                    </div>
                    <div className="padding-2 d-flex justify-content-center ">
                        {!showApplyButton ? <div className='position-fixed' style={{ bottom: '45px', left: "12%" }}> <Buttons props={{ buttonText: 'Create Notebook', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { setOutputDataset(dataSetList) }, toggleBtnValue: '', ImgSrc: () => < ></>, isDisable: !isEditPermission || runClick ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} /></div>
                            :
                            <div className='position-fixed' style={{ bottom: '45px', left: "12%" }}> <Buttons props={{ buttonText: 'Apply', buttonClassName: "custom-btn-primary custom-btn btn-with-text", buttonEvent: () => { applyNewOuputDataSet(dataSetList) }, toggleBtnValue: '', ImgSrc: () => <CHECK />, isDisable: !isEditPermission || window.location.href.includes('viewFlows') || runClick ? true : false, buttonType: Literals.BTN_PRIMARY_WITH_TEXT, toggleBtnOption: null }} /></div>}
                    </div>
                </div>



            </div>

            {/* <Accordian
                props={{
                    isDisable: false,
                    accordionList: additional_settings_body,
                    Icon: () => <ACCORDIONDOWNARROW />,
                    defaultExpandedPanel: [''],
                }}
            /> */}

        </div>
    </>
    )
}

export default OutputDataSet
