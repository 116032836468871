import { Badge, Checkbox, Radio, Tooltip, Tree } from 'antd';
import { cloneDeep } from "lodash";
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";



import { DATASET, DOMAIN_SMALL } from '../../../../Common/iconSource';

import { v4 as uuidv4 } from 'uuid';
import { fetchDatabaseSchemaList, fetchDatabaseTableList, setSearchResults } from '../../../../../store/actions';
import { fetchPreviewTable } from '../../../../../store/modules/common/VirtualDataTable/action';
import { setSchemaDirectoryTreeMap, setTargetTableDetails } from '../../../../../store/modules/flows/NewFlowsRedux/targetTransformer/targetTransformer';
import { LandingSimpleTable, LandingSimpleTableInner } from '../../../../Common/landingSimpleTable/LandingSimpleTable';
import { getObjectLength, showSimpleLoading } from '../../../common/helperFunctions';
import { ICON_CATALOG_DOMAIN } from '../../../../Common/newIconSource';
import SearchInput from '../../../../Common/search/Search';

const SchemaTreeMultiSelect = ({
    connectionName,
    setSchemaDropdownValue,
    settargetTableData,
    setnewTableName,
    setactiveTabKey = () => { },
    selectedSchema,
    targetTableData,
    isExecutePermission,
    selectedMode,
    setIsPreviewDataLoading = () => { },
    isPreviewDataLoading = () => { },
    treeData = [],
    setTreeData = () => { },
    CreateSqlTableDetails = () => { },
    tabArray = [],
    settabArray = () => { },


}) => {
    const store = useStore();
    const dispatch = useDispatch();
    const { schemaDirectoryTreeMap } = useSelector(state => state.LabTransformer.TargetTransformer);
    const sourceDropdownList = [

    ];

    const targetTableDetails = useSelector(state => state.LabTransformer.TargetTransformer.targetTableDetails)

    const [data, setData] = useState([...treeData]);
    const [searchResultData, setsearchResultData] = useState([...treeData]);
    const [searchValue, setsearchValue] = useState('');
    // const [, setCheckedItems] = useState([]);
    const [showLoader, setshowLoader] = useState(false)
    const [scrollIndex, setScrollIndex] = useState(undefined);
    // const [, setIsGenerateQueryBtnVisible] = useState(false);

    useEffect(() => {
        setData([...treeData])
        // dispatch(setSchemaDirectoryTreeMap(treeData));
        if (treeData?.length) {
            treeData.forEach((ele) => {
                if (ele?.isExpanded && ele?.children?.length) {
                    ele?.children?.forEach((item) => {
                        if (item.isChecked) {
                            getName(item.parentName, item.name, item.isChecked, item.parentId)
                        }
                    })
                }
            })
        }
    }, [treeData])

    const fetchSchemaList = () => {
        setshowLoader(true)

        try {
            const requestBody = {

                "connection_name": connectionName
            }

            dispatch(fetchDatabaseSchemaList(requestBody)).then(
                res => {
                    if (res) {

                        const data = res.data.data_record;
                        const schemaNames = data.flat();
                        let temp = schemaNames.map((v, i) => (
                            {
                                name: v,
                                rawName: v,
                                id: i,
                                isExpanded: false,//targetTableDetails?.schemaName === v ? true : false,
                                isLoaded: false,
                                children: []
                            }
                        ));
                        setData([...temp]);

                        if (temp?.length) {
                            temp.forEach((item, i) => {
                                if (item.isExpanded && !item?.children?.length) {
                                    onLoadData({ key: item?.name, children: item.children, id: item?.id, completeTree: temp })
                                }
                            })
                        }

                        // setData(() => {
                        //     let temp = schemaNames.map((v, i) => (
                        //         {
                        //             name: v,
                        //             rawName: v,
                        //             id: i,
                        //             isExpanded: false,
                        //             isLoaded: false,
                        //             children: []
                        //         }
                        //     ))
                        //     return temp
                        // })

                        const { schemaDirectoryTreeMap } = store.getState().LabTransformer.TargetTransformer;
                        const newschemaDirectoryTreeMap = cloneDeep(schemaDirectoryTreeMap);

                        schemaNames.forEach((schemaName, index) => {
                            sourceDropdownList.push({ key: index, Name: schemaName, Type: "", value: schemaName })
                            newschemaDirectoryTreeMap[schemaName] = {};
                        })
                        setSchemaDropdownValue([...sourceDropdownList])
                        dispatch(setSchemaDirectoryTreeMap(newschemaDirectoryTreeMap));
                        setshowLoader(false)

                    }
                }
            )
        }
        catch (error) {
            setshowLoader(false)

        }
    }


    const onLoadData = ({ key, children, id, completeTree }) => {
        const schemaName = key;

        return new Promise((resolve) => {

            let temp = [...data]
            if (children.length) {
                resolve();
                toggleSubRow(data, id)
                return;
            }

            try {
                let requestBody = {

                    "connection_name": connectionName,
                    "schema_name": schemaName
                }
                setData(prev => {
                    prev = prev.map(item => {
                        return (
                            {
                                ...item,
                                isLoading: item.name === schemaName
                            }
                        )
                    })
                    temp = [...prev]
                    return prev
                })
                dispatch(fetchDatabaseTableList(requestBody, false)).then(
                    res => {
                        if (res) {
                            let targetDetails = { ...targetTableDetails }
                            targetDetails['connnectionTableList'] = res.data.data_record
                            let temp = []
                            if (completeTree?.length) {
                                let newcompleteTree = completeTree.map((item, i) => {
                                    return (
                                        item.name === schemaName
                                            ? {
                                                ...item,
                                                isLoaded: true,
                                                isLoading: false,
                                                children:
                                                    targetDetails.connnectionTableList.map((d, c_idx) => (
                                                        {
                                                            name: d[0],
                                                            rawName: d[0],
                                                            isChecked: false, //targetTableDetails?.tableName === d[0] ? true : false,
                                                            isChild: true,
                                                            id: `${i}-${c_idx}`,
                                                            parentId: item.id,
                                                            parentName: item.name,


                                                        }
                                                    ))
                                            }
                                            : { ...item }
                                    )
                                })
                                temp = [...newcompleteTree]
                                setData([...newcompleteTree])
                                toggleSubRow(temp, id)


                            } else {

                                setData(prev => {
                                    prev = prev.map((item, i) => {
                                        return (
                                            item.name === schemaName
                                                ? {
                                                    ...item,
                                                    isLoaded: true,
                                                    isLoading: false,
                                                    children:
                                                        targetDetails.connnectionTableList.map((d, c_idx) => (
                                                            {
                                                                name: d[0],
                                                                rawName: d[0],
                                                                isChecked: false, //targetTableDetails?.tableName === d[0] ? true : false,
                                                                isChild: true,
                                                                id: `${i}-${c_idx}`,
                                                                parentId: item.id,
                                                                parentName: item.name
                                                            }
                                                        ))
                                                }
                                                : { ...item }
                                        )
                                    })
                                    temp = [...prev]
                                    return prev
                                })
                                toggleSubRow(temp, id)


                            }



                            store.dispatch(setTargetTableDetails(targetDetails))

                            // if (!schemaName.includes("results")) {
                            //     const { schemaDirectoryTreeMap } = store.getState().LabTransformer.TargetTransformer;
                            //     const newschemaDirectoryTreeMap = cloneDeep(schemaDirectoryTreeMap);
                            //     newschemaDirectoryTreeMap[schemaName].tableCount = res?.data?.data_record?.length;
                            //     dispatch(setSchemaDirectoryTreeMap(newschemaDirectoryTreeMap));
                            // }
                        }

                    }
                )
            }
            catch (e) {

            }
            resolve();

        });
    }

    const onChildClick = (schemaName, tableName, isChecked, id) => {

        if (!isChecked) {
            importPodInTable(schemaName, tableName, id);
        }
        else {
            removePodFromTable(schemaName, tableName, id);
        }
    };

    const getName = async (schemaName, tableName, isChecked, id) => {
        try {
            if (isChecked && !targetTableData?.data_record?.length) {
                const requestBody = createRequestBodyHandler(schemaName, tableName);
                // setIsPreviewDataLoading(true)
                const { data } = await dispatch(fetchPreviewTable(requestBody, false));
                // if (data?.result) {
                //     CreateSqlTableDetails(data?.result?.data_header, '',
                //         '',
                //         `${schemaName}.${tableName}`
                //     )
                //     let cloneTabs = cloneDeep(tabArray);
                //     cloneTabs.push(
                //         {
                //             key: `${schemaName}.${tableName}`,
                //             tabTitle: `${schemaName}.${tableName}`,
                //             tabContent: null,
                //             tableResult: data?.result,
                //             podName: tableName,
                //             closable: false,
                //             bookmark: false,
                //         }
                //     )
                //     setactiveTabKey(`${schemaName}.${tableName}`);
                //     settabArray([...cloneTabs]);
                //     settargetTableData(data.result)
                //     // handleSubRowMultiSelect(dataList, id)
                //     setIsPreviewDataLoading(false)
                //     // handleSubRow(dataList, id)
                //     // selectedSchema(schemaName)
                //     // setnewTableName(tableName)
                // }
            }
        } catch (error) {
            // setIsPreviewDataLoading(false)

        }


    };

    function toggleSubRow(rows, id) {

        const rowIndex = rows.findIndex((r) => r.id === id);
        const row = rows[rowIndex];
        const { children } = row;

        if (!children) return rows;

        const newRows = [...rows];
        newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };

        if (!row.isExpanded) {
            newRows.splice(rowIndex + 1, 0, ...children);
        } else {
            newRows.splice(rowIndex + 1, children.length);

        }
        // console.log(newRows, row)

        setData([...newRows])
        setTreeData([...newRows])
    }

    function handleSubRow(rows, id) {

        const row = rows.find((r) => r.id === id);
        if (!row || !row.parentId) {
            setData([...rows])
        }

        const newRows = rows.map(r => ({ ...r, isChecked: r.id === id ? true : false }));

        const parentRowIndex = newRows.findIndex((r) => r.id === row.parentId);

        const { children } = newRows[parentRowIndex];

        if (children) {
            const newChildren = children.map((sr) => ({ ...sr, isChecked: sr.id === id ? true : false }));
            newRows[parentRowIndex] = { ...newRows[parentRowIndex], children: newChildren };
        }

        newRows.forEach((item) => {
            if (item.id !== row.parentId && item.children?.length) {
                item?.children.forEach((child) => child.isChecked = false)

            }
        })

        setData([...newRows]);

        setTreeData([...newRows])
    }

    function handleSubRowMultiSelect(rows, id) {
        const row = rows.find((r) => r.id === id);
        if (!row || !row.parentId) {
            setData([...rows])
        }

        const newRows = rows.map(r => (r.id === id ? { ...r, isChecked: !r.isChecked } : { ...r }));

        const parentRowIndex = newRows.findIndex((r) => r.id === row.parentId);
        const { children } = newRows[parentRowIndex];

        if (children) {
            const newChildren = children.map((sr) => (sr.id === id ? { ...sr, isChecked: !sr.isChecked } : { ...sr }));
            newRows[parentRowIndex] = { ...newRows[parentRowIndex], children: newChildren };
        }

        setData([...newRows])

        setTreeData([...newRows])
    }

    const createRequestBodyHandler = (schemaName, tableName) => {
        let id = uuidv4();

        let requestBody = {
            uuid: id,
            connection_name: connectionName,
            schema_name: schemaName,
            table_name: tableName,
            page: 0,
            do_count: false,
            sort_body: [],
            filter_body: [],
            table_ids: []

        }

        return requestBody;
    }


    const importPodInTable = async (schemaName, tableName, id) => {
        let dataList = [...data]
        try {

            setIsPreviewDataLoading(true)
            settargetTableData({})
            const requestBody = createRequestBodyHandler(schemaName, tableName);
            const { data } = await dispatch(fetchPreviewTable(requestBody, false));
            if (data?.result) {
                CreateSqlTableDetails(data?.result?.data_header, '',
                    'datasource',
                    `${connectionName}.${schemaName}.${tableName}`
                )
                let cloneTabs = cloneDeep(tabArray);
                cloneTabs.push(
                    {
                        key: `${schemaName}.${tableName}`,
                        tabTitle: `${schemaName}.${tableName}`,
                        tabContent: null,
                        tableResult: data?.result,
                        podName: tableName,
                        schemaName: schemaName,
                        connectionName: connectionName,
                        closable: false,
                        bookmark: false,
                    }
                )
                setactiveTabKey(`${schemaName}.${tableName}`);
                settabArray([...cloneTabs]);
                settargetTableData(data.result)
                handleSubRowMultiSelect(dataList, id)
                setIsPreviewDataLoading(false)
                // handleSubRow(dataList, id)
                // selectedSchema(schemaName)
                // setnewTableName(tableName)
            }




        }
        catch (error) {
            setIsPreviewDataLoading(false)
        }
    }

    const removePodFromTable = (schemaName, tableName, id) => {

        // const { schemaDirectoryTreeMap } = store.getState().LabTransformer.TargetTransformer;
        // const newschemaDirectoryTreeMap = cloneDeep(schemaDirectoryTreeMap);

        // const tableCount = newschemaDirectoryTreeMap[schemaName]?.tableCount;

        // const allAvailableTables = extractAllAvailableTableNames(newschemaDirectoryTreeMap);
        // let currentTableIndex, newActiveTableName;

        // console.log(schemaName, tableName, allAvailableTables,newschemaDirectoryTreeMap)
        // if (tableName) {
        //     currentTableIndex = allAvailableTables?.indexOf(tableName);
        //     delete newschemaDirectoryTreeMap[schemaName][tableName];
        // }
        // else {
        //     newschemaDirectoryTreeMap[schemaName] = {
        //         tableCount
        //     };
        // }


        // if (allAvailableTables[currentTableIndex + 1]) {
        //     newActiveTableName = `${schemaName}-${allAvailableTables[currentTableIndex + 1]}`
        // }
        // else if (allAvailableTables[currentTableIndex - 1]) {
        //     newActiveTableName = `${schemaName}-${allAvailableTables[currentTableIndex - 1]}`
        // }
        // else {
        //     newActiveTableName = "Results";
        // }

        setIsPreviewDataLoading(true)

        // dispatch(setSchemaDirectoryTreeMap(newschemaDirectoryTreeMap));

        let cloneTabs = cloneDeep(tabArray);
        cloneTabs = cloneTabs?.filter((item) => item.key !== `${schemaName}.${tableName}`);
        settargetTableData({})

        setTimeout(async () => {
            const inputTableData = cloneTabs?.find(item => item?.key?.toString() === 'input_table')
            console.log(tabArray, inputTableData)
            if (inputTableData && getObjectLength(inputTableData?.tableResult)) {
                settargetTableData(inputTableData?.tableResult)
                setactiveTabKey(`input_table`);
                settabArray([...cloneTabs]);
                handleSubRowMultiSelect(data, id)
                setIsPreviewDataLoading(false)
            } else {
                try {

                    const requestBody = createRequestBodyHandler(inputTableData?.schemaName, inputTableData?.tableName);
                    requestBody['table_ids'] = inputTableData?.table_ids ?? []
                    const { data } = await dispatch(fetchPreviewTable(requestBody, false));
                    if (data?.result) {
                        settargetTableData(data?.result)
                    }
                    setactiveTabKey(`input_table`);
                    settabArray([...cloneTabs]);
                    setIsPreviewDataLoading(false)
                    handleSubRowMultiSelect(data, id)
                } catch (error) {
                    setactiveTabKey(`input_table`);
                    settabArray([...cloneTabs]);
                    setIsPreviewDataLoading(false)
                    handleSubRowMultiSelect(data, id)
                }

            }


        }, 500);




    }


    const extractAllAvailableTableNames = (schemaDirectoryTreeMap) => {
        let availableTableNames = [];

        Object.values(schemaDirectoryTreeMap).forEach((schemaData) => {
            availableTableNames = availableTableNames.concat(Object.keys(schemaData).filter((tableKey) => tableKey !== "tableCount"));
        })


        return availableTableNames;
    }

    // const setCheckedItemsHandler = () => {
    //     const checkedItems = [];

    //     for (let key in schemaDirectoryTreeMap) {
    //         const schemaName = key;
    //         const schemaTableNames = Object.keys(schemaDirectoryTreeMap[schemaName]).filter((tableKeys) => tableKeys !== "tableCount");

    //         const tableCount = schemaDirectoryTreeMap[schemaName].tableCount;

    //         if (schemaTableNames.length === tableCount) {
    //             checkedItems.push(schemaName);
    //         }
    //         else {
    //             schemaTableNames.forEach((tableName) => {
    //                 checkedItems.push(`${schemaName}-${tableName}`);
    //             })
    //         }


    //     }
    //     setCheckedItems(checkedItems);
    // }



    useEffect(() => {
        if (connectionName && treeData.length === 0) {
            fetchSchemaList();
        }
    }, [connectionName, treeData]);


    // useEffect(() => {
    //     setCheckedItemsHandler();
    // }, [schemaDirectoryTreeMap])

    // useEffect(() => {
    //     const schemaNames = Object.keys(schemaDirectoryTreeMap);

    //     if (schemaNames.length > 1) {
    //         for (let schemaName of schemaNames) {
    //             if (Object.keys(schemaDirectoryTreeMap[schemaName]).length > 1) {
    //                 setIsGenerateQueryBtnVisible(true);
    //             }
    //         }
    //     }
    // }, [schemaDirectoryTreeMap])

    const parentNameTemplate = (val, isOpen = false, id, row) => {
        let anyChildActive = row?.children.some(v => v.isChecked)
        let colorChange = (isOpen || anyChildActive)
        let isEmpty = (row.isLoaded && row.children.length === 0)
        let selectedChilds = row?.children.filter(v => v.isChecked).length


        return (
            <div className={`target-schema-tree d-flex align-items-center ${colorChange ? 'color-primary' : ''}`}>
                <i onClick={() => {
                    if (!isOpen && !row.isLoaded) onLoadData({ key: val, children: row.children, id, completeTree: data, val })
                    else toggleSubRow(data, id)
                }}
                    className={`cursor-pointer h6 mb-0 mr-1 bx bx-${row?.isLoading ? 'loader-alt bx-spin' : !isOpen ? 'caret-right' : 'caret-down'}`}></i>

                <ICON_CATALOG_DOMAIN height='0.9375rem' width='0.9375rem' />
                <Tooltip title={val.toUpperCase()} placement={'right'}>
                    <p style={{ maxWidth: `calc(100% - ${!isEmpty ? '4.5rem' : '4.75rem'})`, fontSize: "0.8rem" }} className={`w-auto text-with-ellipsis mb-0 font-w-600 paragraph text-uppercase pl-1 ${colorChange ? 'color-primary' : ''}`}>
                        {`${val}`}
                    </p>
                </Tooltip>
                {
                    isEmpty
                        ?
                        <Badge style={{ backgroundColor: '#fbad58' }} title={`No Tables Found`} className="custom-tree-badge" count={'Empty'} />
                        : ''
                }
                <Badge
                    title={`${selectedChilds} table${selectedChilds === 1 ? '' : 's'} selected`}
                    className="custom-tree-badge"
                    count={selectedChilds}
                    onClick={() => {
                        let rowIndex = data.findIndex(item => item?.parentId === id && item?.isChecked)
                        setScrollIndex(rowIndex)
                        setTimeout(() => setScrollIndex(undefined), 500)
                    }}
                />
            </div>
            // <div className={`create-pod-tree-schema ${colorChange ? 'color-primary' : ''}`}>
            //     <i onClick={() => {
            //         if (!isOpen && !row.isLoaded) onLoadData({ key: val, children: row.children, id, completeTree: data, val })
            //         else toggleSubRow(data, id)
            //     }}
            //         className={`cursor-pointer h6 mb-0 mr-1 bx bx-${row?.isLoading ? 'loader-alt bx-spin' : !isOpen ? 'caret-right' : 'caret-down'}`}></i>
            //     <ICON_CATALOG_DOMAIN height='15' width='15' />
            //     <Tooltip title={val.toUpperCase()} placement={'right'}>
            //         <p style={{ maxWidth: `calc(100% - ${!isEmpty ? '57' : '76'}px)` }} className={`w-auto text-with-ellipsis mb-0 font-w-600 paragraph text-uppercase pl-1 ${colorChange ? 'color-primary' : ''}`}>
            //             {`${val}`}
            //         </p>
            //     </Tooltip>
            //     {
            //         isEmpty
            //             ?
            //             <Badge style={{ backgroundColor: '#fbad58' }} title={`No Tables Found`} className="custom-tree-badge" count={'Empty'} />
            //             : ''
            //     }
            //     <Badge
            //         title={`${selectedChilds} table${selectedChilds === 1 ? '' : 's'} selected`}
            //         className="custom-tree-badge"
            //         count={selectedChilds}
            //         onClick={() => {
            //             let rowIndex = data.findIndex(item => item?.parentId === id && item?.isChecked)
            //             setScrollIndex(rowIndex)
            //             setTimeout(() => setScrollIndex(undefined), 500)
            //         }}
            //     />
            // </div>
        )
    }

    const childNameTemplate = ({ name, isChecked = false, ...row }) => {

        const parentName = row.parentId !== undefined
            && data.find(
                item => (item.id === row.parentId)
            )?.name
        return (
            <React.Fragment>

                <p
                    className={`${isChecked ? 'color-primary font-w-600' : ''} paragraph mb-0 mx-n2 text-with-ellipsis position-relative`}

                    onClick={() => {
                        if (!isExecutePermission) {
                            return
                        } else {
                            if (selectedMode === 'update') {
                                handleSubRowMultiSelect(data, row.id)
                            } else {

                                handleSubRow(data, row.id)
                            }

                            onChildClick(parentName, name, isChecked, row.id)
                        }
                    }}
                    style={{ paddingLeft: '51px' }}
                >
                    {/* <i style={{
                        position: 'absolute',
                        top: '4px',
                        fontSize: '24px',
                        left: '22px',
                        cursor: !isExecutePermission ? "default" : "pointer"
                    }}
                        className={`bx bx-radio-circle${isChecked ? '-marked color-primary' : ' text-black-50'}`}></i> */}
                    <Checkbox disabled={!isExecutePermission || isPreviewDataLoading} checked={isChecked} className="ml-1 mr-2" />
                    <DATASET height='15' width='15' /><span className="p-1"></span>
                    <Tooltip title={name} placement="right"> {name}</Tooltip>
                </p>

            </React.Fragment>
        )
    }

    const columns = [
        {
            name: 'Tables',
            key: "name",
            sortable: false,
            resizable: false,
            renderCell: ({ row }) => {

                const hasChildren = row.children !== undefined;
                return (
                    <>
                        {hasChildren && (
                            parentNameTemplate(row.name, row.isExpanded, row.id, row)
                        )}
                        <div className="rdg-cell-value">
                            {

                                !hasChildren && (childNameTemplate(row))
                            }
                        </div>
                    </>
                );
            }
        }
    ];

    const treeViewData = useMemo(() => {

        if (searchValue?.trim()?.length > 0 && searchResultData.length === 0) return []
        if (searchResultData.length) return searchResultData
        return data
    }, [data, searchResultData, searchValue])

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            let tempData = [...data]
            let resultList = tempData.filter(
                item => {
                    return (
                        item?.children?.length
                        && item?.children?.some(v => v?.name?.includes(searchValue?.toLowerCase()))
                    )
                        || item?.name?.includes(searchValue?.toLowerCase())
                        || item?.parentName?.includes(searchValue?.toLowerCase())
                }
            )

            setsearchResultData([...resultList])
        } else setsearchResultData([])
    }, [searchValue, data])


    return (
        <div className="create-pod-table-container pt-2 custom-dgt-tree" style={{ height: 'calc(100% - 83px)' }}>
            <div className="create-pod-table-header">
                <SearchInput placeholder='Search Tables..' searchData={searchValue} setSearchData={setsearchValue} />
                {/* <h3>Search Tables...</h3> */}
            </div>
            {data?.length && !showLoader ? <div className='pl-3'>
                <LandingSimpleTable
                    cols={columns}
                    rowsData={treeViewData}
                    showCursor={false}
                    tableHeight={'calc(100vh - 335px)'} //added overflow scroll
                    rowHeight={30}
                    headerRowHeight={0}
                    scrollToRow={scrollIndex}
                />

            </div> : !showLoader ? <span className='label' style={{ justifyContent: "center", display: "flex", color: "#d3d3d3", font: "30px" }}>No Tables Found</span> : ''
            }
            {showLoader ? showSimpleLoading("Please Wait Loading Tables List...") : null}
        </div>
    )
}

export default SchemaTreeMultiSelect
